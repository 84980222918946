import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../../environments/environment';
import { FunctionsService, HttpRequestHandler, HttpService } from '../../util';

export enum TransportDiscountApply {
  LowerGrade = 1,
  GreaterGrade = 2,
}

@Injectable()
export class AcademicYearService {
  public static URL = environment.URL.API + 'academic-year';

  transportDiscountsApply = [
    { id: TransportDiscountApply.LowerGrade, key: 'transportDiscount.applyTo.lowerGrade', name: '' },
    { id: TransportDiscountApply.GreaterGrade, key: 'transportDiscount.applyTo.greaterGrade', name: '' },
  ];

  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    const lists = { transportDiscountsApply: this.transportDiscountsApply };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * Mostrar listado de Años lectivos
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(AcademicYearService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Agregar Año Lectivo
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(AcademicYearService.URL, data, httpRequestHandler);
  }

  /**
   * Eliminar Año Lectivo
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${AcademicYearService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Datos de Año Lectivo
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AcademicYearService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Listado para combo de Año Lectivo
   */
  list = () => {
    return this.http.observableGet(`${AcademicYearService.URL}/list`, { mapFn: res => res.academicYears });
  };

  /**
   * Listado de secciones de año lectivo
   * @param id Id de año lectivo
   */
  getSections(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AcademicYearService.URL}/${id}/sections`, httpRequestHandler);
  }

  /**
   * Guardar secciones de año lectivo
   */
  saveSections(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AcademicYearService.URL}/${id}/sections`, data, httpRequestHandler);
  }

  /**
   * Listado de grados de año lectivo
   * @param id Id de año lectivo
   */
  getGrades(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AcademicYearService.URL}/${id}/grades`, httpRequestHandler);
  }

  /**
   * Guardar grados de año lectivo
   */
  saveGrades(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AcademicYearService.URL}/${id}/grades`, data, httpRequestHandler);
  }

  /**
   * Obtener fechas de excepcion a los días de estudio
   */
  getStudyDaysExceptions(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AcademicYearService.URL}/${id}/study-days-exception`, httpRequestHandler);
  }

  /**
   * Guardar fechas de excepcion a los días de estudio
   */
  saveStudyDaysExceptions(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AcademicYearService.URL}/study-days-exception`, data, httpRequestHandler);
  }

  /**
   * Eliminar fechas de excepcion a los días de estudio
   */
  deleteStudyDaysExceptions(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${AcademicYearService.URL}/${id}/study-days-exception`, httpRequestHandler);
  }

  /**
   * Listado de aspectos académicos de año lectivo
   * @param id Id de año lectivo
   */
  getAcademicAspects(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AcademicYearService.URL}/${id}/academic-aspects`, httpRequestHandler);
  }

  /**
   * Guardar aspectos académicos de año lectivo
   */
  saveAcademicAspects(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AcademicYearService.URL}/${id}/academic-aspects`, data, httpRequestHandler);
  }

  /**
   * Listado de tipos de registros de asistencia del año lectivo
   * @param id Id de año lectivo
   */
  getAttendanceTypes(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AcademicYearService.URL}/${id}/attendance-types`, httpRequestHandler);
  }

  /**
   * Guardar tipos de registros de asistencia del año lectivo
   */
  saveAttendanceTypes(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AcademicYearService.URL}/${id}/attendance-types`, data, httpRequestHandler);
  }

  /**
   * Obtener Descuento de transporte del año lectivo
   * @param id Id de año lectivo
   */
  getTransportDiscount(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AcademicYearService.URL}/${id}/transport-discount`, httpRequestHandler);
  }

  /**
   * Guardar Descuento de transporte del año lectivo
   */
  saveTransportDiscount(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AcademicYearService.URL}/${id}/transport-discount`, data, httpRequestHandler);
  }

  /**
   * Obtener otra configuración académica
   */
  getOtherAcademicConfiguration(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AcademicYearService.URL}/${id}/other-config`, httpRequestHandler);
  }

  /**
   * Guardar otra configuración académica
   */
  saveOtherAcademicConfiguration(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AcademicYearService.URL}/${id}/other-config`, data, httpRequestHandler);
  }
}
