<div class="row">
  <ng-template ngFor let-item [ngForOf]="items">
    <div *ngxPermissionsOnly="item.permission" class="col-xl-4 col-md-6 col-sm-12 mb-4">
      <div [routerLink]="item.url" [queryParams]="item.queryParams" class="card widget-statstic-card borderless-card border-{{ item.color | bgColor }} mb-0 h-100" tabindex="-1">
        <div class="card-header">
          <div class="card-header-left">
            <h5 class="text-body">{{ item.title | translate }}</h5>
            <p class="p-t-10 m-b-0 text-muted">{{ item.desc | translate }}&nbsp;</p>
          </div>
        </div>
        <div class="card-block">
          <i class="st-icon bg-{{ item.color | bgColor }}" [ngClass]="item.icon"></i>
        </div>
      </div>
    </div>
  </ng-template>
</div>
