import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum TransportType {
  Pickup = 1,
  Destination = 2,
  General = 3,
}

export enum TransportReportType {
  Student = 1,
  Cost = 2,
  Citation = 3,
}

export enum TransportPlans {
  Full = 1,
  Medium = 2,
  With = 3,
  Without = 4,
}

@Injectable()
export class StudentTransportService {
  public static URL = environment.URL.API + 'student-transport';
  public static URL2 = environment.URL.API2 + 'student-transport';

  reportTypes = [
    { id: TransportReportType.Student, key: 'studentTransport.studentsList', name: '' },
    { id: TransportReportType.Cost, key: 'studentTransport.costReport', name: '', permission: 'transport_costs_report' },
    { id: TransportReportType.Citation, key: 'studentTransport.citations', name: '' },
  ];

  transportTypes = [
    { id: TransportType.Pickup, key: 'studentChanges.pickup', name: '' },
    { id: TransportType.Destination, key: 'studentChanges.destination', name: '' },
  ];

  transportPlans = [
    { id: TransportPlans.Full, key: 'studentTransport.full', name: '' },
    { id: TransportPlans.Medium, key: 'studentTransport.medium', name: '' },
    { id: TransportPlans.With, key: 'studentTransport.with', name: '' },
    { id: TransportPlans.Without, key: 'studentTransport.without', name: '' },
  ];

  /**
   * Empty constructor
   * @param http HttpService
   * @param translate TranslateService
   */
  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    fn.refreshListPermissions(this.reportTypes, array => (this.reportTypes = array));

    const lists = {
      reportTypes: this.reportTypes,
      transportTypes: this.transportTypes,
      transportPlans: this.transportPlans,
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  getChangeType(id: number) {
    return this.transportTypes.find(ch => ch.id === id);
  }

  /**
   * List students
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${StudentTransportService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get student plans
   * @param enrollmentId number
   * @param httpRequestHandler HttpRequestHandler
   */
  getPlans(enrollmentId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${StudentTransportService.URL2}/plans/${enrollmentId}`, httpRequestHandler);
  }

  /**
   * Get student plan info
   * @param planId number
   * @param httpRequestHandler HttpRequestHandler
   */
  getPlanInfo(planId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${StudentTransportService.URL2}/plan/${planId}`, httpRequestHandler);
  }

  /**
   * Save student plan
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  savePlan(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${StudentTransportService.URL}/plan`, data, httpRequestHandler);
  }

  /**
   * Delete student plan
   * @param planId number
   * @param httpRequestHandler HttpRequestHandler
   */
  deletePlan(planId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${StudentTransportService.URL}/plan/${planId}`, httpRequestHandler);
  }

  /**
   * Generate transport report
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  generateReport(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${StudentTransportService.URL}/reports`, data, httpRequestHandler);
  }
}
