import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class DelayReasonService {
  public static URL = environment.URL.API + 'delay-reason';

  constructor(private http: HttpService) {}

  /**
   * List delay reasons
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${DelayReasonService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * List delay reasons
   */
  list = () => {
    return this.http.observableGet(`${DelayReasonService.URL}/list`, { mapFn: res => res.delayReasons });
  };

  /**
   * Store delay reason
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(DelayReasonService.URL, data, httpRequestHandler);
  }

  /**
   * Delete delay reason
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${DelayReasonService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get delay reason
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${DelayReasonService.URL}/${id}`, httpRequestHandler);
  }
}
