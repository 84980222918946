import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

export enum DocumentType {
  AdmissionStudent = 1,
  AdmissionAbroadStudent = 2,
  AdmissionAttendant = 3,
}

@Injectable()
export class DocumentService {
  public static URL = environment.URL.API + 'document';

  readonly types = [
    { id: DocumentType.AdmissionStudent, name: 'Admisión - Estudiante' },
    { id: DocumentType.AdmissionAbroadStudent, name: 'Admisión - Estudiante Extranjero' },
    { id: DocumentType.AdmissionAttendant, name: 'Admisión - Acudiente' },
  ];

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de Documentos
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(DocumentService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Agregar Documento
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(DocumentService.URL, data, httpRequestHandler);
  }

  /**
   * Eliminar Documento
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${DocumentService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Editar Documento
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${DocumentService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar Documento
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${DocumentService.URL}/${id}`, data, httpRequestHandler);
  }

  /**
   * Listado para combo de Documentos
   */
  list(httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${DocumentService.URL}/list`, httpRequestHandler);
  }
}
