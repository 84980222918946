import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SlimScrollDirective } from './slimscroll.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [SlimScrollDirective],
  exports: [SlimScrollDirective]
})
export class ScrollModule {}
