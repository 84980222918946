import { Directive, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector:
    'input[type=checkbox][formControlName].border-checkbox, ' +
    'input[type=checkbox][formControl].border-checkbox, ' +
    'input[type=checkbox][ngModel].border-checkbox'
})
export class FocusableCheckboxDirective {
  constructor(el: ElementRef, ngControl: NgControl) {
    const parent = el.nativeElement.parentNode;

    parent.setAttribute('tabindex', 0);
    parent.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.keyCode === 32) {
        if (el.nativeElement.hasAttribute('disabled')) return;

        e.preventDefault();
        ngControl.control.setValue(!ngControl.value);
      }
    });
  }
}
