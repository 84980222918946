import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum YearContractType {
  Enrollment = 1,
  Transport = 2,
  ElectronicSignatureAgreement = 3,
  EnrollmentPromissoryNote = 4,
  TransportPromissoryNote = 5,
  StartAct = 6,
  ClosingAct = 7,
  RegistryAuthorization = 8,
}

@Injectable()
export class YearContractService {
  public static URL = environment.URL.API + 'year-contract';

  constructor(private http: HttpService) {}

  /**
   * Get year contract
   */
  get(id: number, type: YearContractType, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${YearContractService.URL}/${id}/${type}`, httpRequestHandler);
  }

  /**
   * Save year contract
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(YearContractService.URL, data, httpRequestHandler);
  }

  /**
   * Obtener contrato por año
   */
  preview(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${YearContractService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save contract signature
   */
  saveSignature(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${YearContractService.URL}/signature`, data, httpRequestHandler);
  }

  /**
   * Delete contract signature
   */
  deleteSignature(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${YearContractService.URL}/signature/${id}`, httpRequestHandler);
  }
}
