export * from './config.service';
export * from './tutoring-cart.service';
export * from './dashboard-info.service';
export * from './academic';
export * from './location';
export * from './security';
export * from './people';
export * from './admissions';
export * from './sports';
export * from './library';
export * from './enrollment';
export * from './labor';
export * from './portfolio';
export * from './updates';
export * from './discounts';
export * from './nursing';
export * from './transport';
export * from './mailing';
export * from './help-desk';
export * from './reports';
export * from './human-talent';
export * from './costs-centers';
export * from './extracurriculars';
export * from './baby-love';
