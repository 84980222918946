<div [formGroup]='form'>
  <app-select
    (itemChange)='valueChange.emit($event?.id)'
    [clearable]='clearable'
    [formControlName]='field'
    [isDisabled]='isDisabled'
    [searchable]='false'
    [selectOptions]='items'
    [appendTo]='appendTo'
  >
  </app-select>
</div>
