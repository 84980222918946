<div [formGroup]='form'>
  <ng-select
    [items]='options | async'
    [bindLabel]='displayField'
    [loading]='loading'
    [typeahead]='text'
    formControlName='selected'
    (blur)='innerBlur()'
    [multiple]='multi'
    [items]='items'
    [appendTo]='appendTo'
    #select
  >
    <ng-container *ngIf='labelTemplate'>
      <ng-template ng-label-tmp let-item='item'>
        <ng-container *ngTemplateOutlet='labelTemplate?.templateRef; context: { $implicit: item }'></ng-container>
      </ng-template>
    </ng-container>

    <ng-container *ngIf='optionTemplate'>
      <ng-template ng-option-tmp let-item='item'>
        <ng-container *ngTemplateOutlet='optionTemplate?.templateRef; context: { $implicit: item }'></ng-container>
      </ng-template>

      <ng-container *ngIf='!labelTemplate && !multi'>
        <ng-template ng-label-tmp let-item='item'>
          <ng-container *ngTemplateOutlet='optionTemplate?.templateRef; context: { $implicit: item }'></ng-container>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-select>
</div>
