import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum ExtendedHoursConsultationReportType {
  IncomeRelation = 1
}
@Injectable()
export class ExtendedHoursConsultationService {
  public static BASE_URL_API = environment.URL.API + 'extended-hours-consultation';

  reportTypes = [
    {
      id: ExtendedHoursConsultationReportType.IncomeRelation,
      key: 'extendedHoursConsultation.incomeRelation',
      name: '',
      permission: 'extended_hours_consultation_relation'
    }
  ];

  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    fn.refreshListPermissions(this.reportTypes, array => (this.reportTypes = array));

    const lists = { reportTypes: this.reportTypes };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * List extended hours
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtendedHoursConsultationService.BASE_URL_API}?${params}`, httpRequestHandler);
  }

  /**
   * Get student's extended hours plan information
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtendedHoursConsultationService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * List extended hours fractions
   */
  listFractions = () => {
    return this.http.observableGet(`${ExtendedHoursConsultationService.BASE_URL_API}/fractions`, { mapFn: res => res.fractions });
  };

  /**
   * Generate report
   */
  generateReport(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ExtendedHoursConsultationService.BASE_URL_API}/reports`, data, httpRequestHandler);
  }
}
