import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { FunctionsService, HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class StudentVaccineService {
  public static BASE_URL_API = environment.URL.API + 'student-vaccine';

  constructor(private http: HttpService, private fn: FunctionsService) {}

  /**
   * Obtener vacunas del estudiante
   */
  vaccines(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${StudentVaccineService.BASE_URL_API}/${id}/vaccines`, httpRequestHandler);
  }

  /**
   * Guardar vacunas del estudiante
   */
  saveVaccines(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${StudentVaccineService.BASE_URL_API}/${id}/vaccines`, data, httpRequestHandler);
  }

  /**
   * Notificar vacunas faltantes del estudiante
   */
  notifyMissingVaccines(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${StudentVaccineService.BASE_URL_API}/${id}/missing-vaccines`, data, httpRequestHandler);
  }

  /**
   * Obtener carnets de vacunas del estudiante
   */
  getVaccineDocuments(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${StudentVaccineService.BASE_URL_API}/${id}/vaccine-documents`, httpRequestHandler);
  }
}
