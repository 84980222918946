import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class SourceService {
  public static BASE_URL_API = environment.URL.API + 'source';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de Medios de Conocimiento
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(SourceService.BASE_URL_API + '?' + params, httpRequestHandler);
  }

  /**
   * Agregar Medio de Conocimiento
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(SourceService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Eliminar Medio de Conocimiento
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${SourceService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Editar Medio de Conocimiento
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${SourceService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar Medio de Conocimiento
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${SourceService.BASE_URL_API}/${id}`, data, httpRequestHandler);
  }

  /**
   * Listado para combo de Medios de Conocimiento
   */
  list = () => {
    return this.http.observableGet(`${SourceService.BASE_URL_API}/list`, { mapFn: res => res.sources });
  };
}
