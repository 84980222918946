import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'includes' })
export class IncludesPipe implements PipeTransform {
  constructor() {}

  /**
   * @param items listado de items
   * @param value valor a buscar
   */
  transform(items: any[], value: object): boolean {
    if (!items) return false;

    return items.includes(value);
  }
}
