import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDatepickerModule, NgbPopoverModule, NgbTimepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxPermissionsModule } from 'ngx-permissions';

import { CheckBoxModule } from '../checkbox';
import {
  PagerAddComponent,
  PagerButtonComponent,
  PagerButtonDeleteComponent,
  PagerButtonEditComponent,
  PagerCheckboxFilterComponent,
  PagerDatatableDirective,
  PagerDateColumnFilterComponent,
  PagerFilterComponent,
  PagerInputColumnFilterComponent,
  PagerRangeDateColumnFilterComponent,
  PagerSelectColumnFilterComponent,
  PagerSelectYearColumnFilterComponent,
  PagerSizeComponent,
  PagerSizeFilterComponent,
} from '../pager';
import { SelectModule } from '../select/select.module';
import { PagerDateColumnFilterDatepickerComponent } from './pager-date-column-filter/datepicker/pager-date-column-filter-datepicker.component';
import {
  PagerRangeDateColumnFilterDatepickerComponent,
} from './pager-range-date-column-filter/datepicker/pager-range-date-column-filter-datepicker.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    NgxPermissionsModule,
    FormsModule,
    SelectModule,
    NgbPopoverModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbTooltipModule,
    CheckBoxModule,
    ClickOutsideModule,
  ],
  declarations: [
    PagerAddComponent,
    PagerFilterComponent,
    PagerSizeComponent,
    PagerSelectColumnFilterComponent,
    PagerInputColumnFilterComponent,
    PagerDateColumnFilterComponent,
    PagerRangeDateColumnFilterComponent,
    PagerDatatableDirective,
    PagerButtonEditComponent,
    PagerButtonDeleteComponent,
    PagerButtonComponent,
    PagerSizeFilterComponent,
    PagerSelectYearColumnFilterComponent,
    PagerCheckboxFilterComponent,
    PagerDateColumnFilterDatepickerComponent,
    PagerRangeDateColumnFilterDatepickerComponent,
  ],
  exports: [
    PagerAddComponent,
    PagerFilterComponent,
    PagerSizeComponent,
    PagerSelectColumnFilterComponent,
    PagerInputColumnFilterComponent,
    PagerDateColumnFilterComponent,
    PagerRangeDateColumnFilterComponent,
    PagerDatatableDirective,
    PagerButtonEditComponent,
    PagerButtonDeleteComponent,
    PagerButtonComponent,
    PagerSizeFilterComponent,
    PagerSelectYearColumnFilterComponent,
    PagerCheckboxFilterComponent,
  ],
})
export class PagerModule {}
