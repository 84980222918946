import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class GradingTypeService {
  public static URL = environment.URL.API + 'grading-type';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de Tipo de Notas
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(GradingTypeService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Eliminar Tipo de Nota
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${GradingTypeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Editar Tipo de Nota
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${GradingTypeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Guardar Tipo de Nota
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${GradingTypeService.URL}`, data, httpRequestHandler);
  }

  /**
   * Listado para combo de periodos por año lectivo
   */
  fromYear = data => {
    return this.http.observableGet(`${GradingTypeService.URL}/list/${data.yearId}`, { mapFn: res => res.gradingTypes });
  };
}
