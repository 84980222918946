import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class BiReportService {
  public static URL = environment.URL.API2 + 'bi-report';
  constructor(private http: HttpService) {}
  /**
   * Index Bi report
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${BiReportService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Delete Bi report
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${BiReportService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get Bi report
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${BiReportService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Bi report
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(BiReportService.URL, data, httpRequestHandler);
  }

  /**
   * Get Bi report
   */
  getToken(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${BiReportService.URL}/generate-token/${id}`, httpRequestHandler);
  }
}
