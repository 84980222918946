import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class AttendantAcademicService {
  public static URL = environment.URL.API + 'attendant-academic';
  public static URL2 = environment.URL.API2 + 'attendant-academic';

  constructor(private http: HttpService) {}

  /**
   * Listado de las familias asociadas al usuario actual
   */
  myFamilies(yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AttendantAcademicService.URL}/my-families/${yearId}`, httpRequestHandler);
  }

  /**
   * Información académica de estudiante
   */
  studentInfo(enrollmentId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AttendantAcademicService.URL}/student-info/${enrollmentId}`, httpRequestHandler);
  }

  /**
   * Informe de periodo de estudiante
   */
  studentPeriodReport(enrollmentId: number, periodId: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${AttendantAcademicService.URL}/student-info/${enrollmentId}/period/${periodId}`, httpRequestHandler);
  }

  /**
   * Cartas de desempeño de periodo
   */
  performanceReports(enrollmentId: number, periodId: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${AttendantAcademicService.URL}/performance-reports/${enrollmentId}/period/${periodId}`, httpRequestHandler);
  }

  /**
   * Reportes adicionales y actividades de refuerzo
   */
  additionalReports(enrollmentId: number, periodId: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${AttendantAcademicService.URL}/additional-reports/${enrollmentId}/period/${periodId}`, httpRequestHandler);
  }

  /**
   * Informes de mitad de periodo
   */
  middleReports(enrollmentId: number, periodId: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${AttendantAcademicService.URL}/middle-reports/${enrollmentId}/period/${periodId}`, httpRequestHandler);
  }

  /**
   * Listado de evaluaciones
   */
  assessments(gradeGroupStudentId: number, periodId: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${AttendantAcademicService.URL}/assessments/${gradeGroupStudentId}/period/${periodId}`, httpRequestHandler);
  }

  /**
   * Listado de asistencias
   */
  attendances(gradeGroupStudentId: number, periodId: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${AttendantAcademicService.URL}/attendances/${gradeGroupStudentId}/period/${periodId}`, httpRequestHandler);
  }

  /**
   * Consulta listado de asignaturas para visutalización de notas
   */
  listClasses(enrollmentId: number, periodId: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${AttendantAcademicService.URL}/class-grading/${enrollmentId}/list-classess/${periodId}`, httpRequestHandler);
  }

  /**
   * Consulta de informe final
   */
  finalReport(id: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${AttendantAcademicService.URL}/final-report/${id}`, httpRequestHandler);
  }

  /**
   * Consulta de agenda escolar
   */
  schoolDiary(id: number, params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AttendantAcademicService.URL2}/school-diary/${id}?${params}`, httpRequestHandler);
  }
}
