import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[formControlName], textarea[formControlName]'
})
export class MarkTouchedOnBlurDirective implements OnInit {
  constructor(private el: ElementRef, private ngControl: NgControl) {}

  ngOnInit() {}

  @HostListener('blur', ['$event'])
  onBlur(e) {
    this.ngControl.control.updateValueAndValidity();
  }
}
