import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum PersonNotificationType {
  PenaltyInterest = 1,
  FailedJob = 2,
  AutomaticReportByAges = 3
}

@Injectable()
export class PersonNotificationService {
  public static URL = environment.URL.API + 'person-notification';

  types = [
    { id: PersonNotificationType.PenaltyInterest, key: 'personNotification.type.penaltyInterest', name: '' },
    { id: PersonNotificationType.FailedJob, key: 'personNotification.type.failedJob', name: '' },
    { id: PersonNotificationType.AutomaticReportByAges, key: 'personNotification.type.automaticReportByAges', name: '' }
];

  constructor(private http: HttpService, private fn: FunctionsService, translate: TranslateService) {
    const lists = {
      types: this.types
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  getType(type: PersonNotificationType) {
    return this.types.find(t => t.id === type);
  }

  /**
   * List Person Notification
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PersonNotificationService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get Person Notification
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PersonNotificationService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Person Notification
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(PersonNotificationService.URL, data, httpRequestHandler);
  }

  /**
   * Delete Person Notification
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${PersonNotificationService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List Person Notification
   * @param httpRequestHandler HttpRequestHandler
   */
  list = () => {
    return this.http.observableGet(`${PersonNotificationService.URL}/list`, { mapFn: res => res.personNotification });
  };
}
