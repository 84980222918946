import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class StudyDaysService {
  public static URL = environment.URL.API + 'study-days';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de Dias de estudio
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(StudyDaysService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Guardar Dias de estudio
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(StudyDaysService.URL, data, httpRequestHandler);
  }

  /**
   * Eliminar Dias de estudio
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${StudyDaysService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Editar Dias de estudio
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${StudyDaysService.URL}/${id}`, httpRequestHandler);
  }
}
