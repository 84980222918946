import { Injectable } from '@angular/core';
import { HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class BigpassTypeService {
  public static URL = environment.URL.API2 + 'bigpass-type';

  constructor(private http: HttpService) {}

  /**
   * List bigpass types for select component
   */
  list = () => {
    return this.http.observableGet(`${BigpassTypeService.URL}/list`, { mapFn: res => res.bigPassTypes });
  };
}
