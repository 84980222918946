import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class GroupDirectionService {
  public static URL = environment.URL.API + 'group-direction';

  constructor(private http: HttpService) {}

  /**
   * Listado de grupos de director/subdirector
   */
  groups(yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${GroupDirectionService.URL}/groups/${yearId}`, httpRequestHandler);
  }

  /**
   * Cartas de desempeño de estudiantes de grupo
   */
  performanceReports(groupId: number, params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${GroupDirectionService.URL}/${groupId}/performance-reports?${params}`, httpRequestHandler);
  }

  /**
   * Asistencia de estudiantes de grupo
   */
  attendances(groupId: number, params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${GroupDirectionService.URL}/${groupId}/attendances?${params}`, httpRequestHandler);
  }

  /**
   * Reuniones de grupo
   */
  meetings(groupId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${GroupDirectionService.URL}/${groupId}/meetings`, httpRequestHandler);
  }
}
