import { Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AutocompleteTypeahead } from 'app/services/util';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-input-autocomplete',
  templateUrl: './input-autocomplete.component.html',
  styleUrls: ['./input-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputAutocompleteComponent)
    }
  ]
})
export class InputAutocompleteComponent implements OnInit, ControlValueAccessor {
  @Input() asyncFunction: AutocompleteTypeahead;
  @Input() data?: any;
  @Input() formControlName: string;

  value: string;
  disabled: boolean;
  control: AbstractControl;
  loading: boolean;

  propagateChange = (val: any) => {};
  touchChange = () => {};

  /**
   * Empty constructor
   * @param controlContainer ControlContainer
   */
  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer
  ) {}

  /**
   * On init, set control
   */
  ngOnInit() {
    if (this.formControlName) this.control = this.controlContainer.control.get(this.formControlName);
  }

  /**
   * Write value
   * @param value any
   */
  writeValue(value: any) {
    this.value = value;
  }

  /**
   * Register changes
   * @param fn any
   */
  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  /**
   * Register on touch event
   * @param fn any
   */
  registerOnTouched(fn) {
    this.touchChange = fn;
  }

  /**
   * Set control disabled
   * @param isDisabled boolean
   */
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  /**
   * Blur event
   */
  blur() {
    this.touchChange();
    this.loading = false;
    if (this.control) this.control.updateValueAndValidity();
  }

  /**
   * Search function as variable
   */
  search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => (this.loading = true)),
      switchMap(term => (term.length > 2 ? this.asyncFunction(term, this.data) : of([]))),
      tap(() => (this.loading = false))
    );
  };
}
