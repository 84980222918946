import { EventEmitter, Injectable } from '@angular/core';

import { SignalRService } from '../util';
import { BaseBroadcast } from './base-broadcast';

@Injectable()
export class MailLogDetailBroadcast extends BaseBroadcast {
  onReady = new EventEmitter<any>();
  private _onReady = data => this.onReady.emit(data);

  constructor(signalRService: SignalRService) {
    super(signalRService, 'mail-log-detail-');

    this.setEvents({
      ready: this._onReady,
    });
  }
}
