import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class RelationshipService {
  public static BASE_URL_API = environment.URL.API + 'relationship';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de Parentescos
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(RelationshipService.BASE_URL_API + '?' + params, httpRequestHandler);
  }

  /**
   * Agregar Parentesco
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(RelationshipService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Eliminar Parentesco
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${RelationshipService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Editar Parentesco
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${RelationshipService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar Parentesco
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${RelationshipService.BASE_URL_API}/${id}`, data, httpRequestHandler);
  }

  /**
   * Listado para combo de Medios de Conocimiento
   */
  list = () => {
    return this.http.observableGet(`${RelationshipService.BASE_URL_API}/list`, { mapFn: res => res.relationships });
  };
}
