import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectLookupFn } from 'app/modules/shared/select';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum EmployeeStatus {
  Inactive,
  Active,
}

export enum External {
  Yes = 1,
  No = 0,
}

export enum EmployeeFormFrom {
  Admin = 1,
  Employee = 2,
}

export enum EmployeeOption {
  TypeDocIssuedDownloadError = 1,
  TypeMailStorageDownloadError = 2,
  TaskScheduleNotification = 3,
  TypeDocExpiredNotification = 4,
}

export enum EmployeeReportType {
  General = 1
}

@Injectable()
export class EmployeeService {
  public static URL = environment.URL.API + 'employee';
  public static URL2 = environment.URL.API2 + 'employee';

  employeeOptions = [
    {
      id: EmployeeOption.TypeDocIssuedDownloadError,
      key: 'employee.options.docIssuedDownloadError',
      name: ''
    },
    {
      id: EmployeeOption.TypeMailStorageDownloadError,
      key: 'employee.options.mailStorageDownloadError',
      name: ''
    },
    {
      id: EmployeeOption.TaskScheduleNotification,
      key: 'employee.options.taskScheduleNotification',
      name: ''
    },
    {
      id: EmployeeOption.TypeDocExpiredNotification,
      key: 'employee.options.docExpiredNotification',
      name: ''
    }
  ];

  employeeStatus = [
    {
      id: EmployeeStatus.Active,
      key: 'employee.status.active',
      name: ''
    },
    {
      id: EmployeeStatus.Inactive,
      key: 'employee.status.inactive',
      name: ''
    }
  ];

  employeeReportTypes = [
    { id: EmployeeReportType.General, key: 'general.general', name: '', permission: 'employee_consultation' }
  ];

  employeeExternal = [
    { id: External.Yes, name: '', key: 'employee.employeeExternal.yes', inForm: true, class: 'label label-warning' },
    { id: External.No, name: '', key: 'employee.employeeExternal.no', inForm: true, class: 'label label-success' }
  ];


  constructor(private http: HttpService, private fn: FunctionsService, translate: TranslateService) {
    const lists = {
      employeeOptions: this.employeeOptions,
      employeeStatus: this.employeeStatus,
      employeeReportTypes: this.employeeReportTypes,
      employeeExternal: this.employeeExternal
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * Index Employee in contracts
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${EmployeeService.URL2}?${params}`, httpRequestHandler);
  }

  /**
   * Obtener datos de persona
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${EmployeeService.URL2}/${id}`, httpRequestHandler);
  }

  /**
   * Guardar datos de persona
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    // this.http.post(PersonService.URL, data, httpRequestHandler);
    this.http.post(EmployeeService.URL2, data, httpRequestHandler);
  }

  /**
   * Eliminar persona
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${EmployeeService.URL2}/${id}`, httpRequestHandler);
  }

  /**
   * Select autocomplete
   */
  list: SelectLookupFn = data => {
    return this.http.observablePost(EmployeeService.URL + '/list', data, { mapFn: res => res.data });
  };

  /**
   * Select autocomplete
   */
  listTeachers: SelectLookupFn = data => {
    return this.http.observablePost(EmployeeService.URL + '/list-teachers', data, { mapFn: res => res.data });
  };

  /**
   * Export Report
   */
  export(httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${EmployeeService.URL2}/export/`, httpRequestHandler);
  }
}
