import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'humanFileSize' })
export class HumanFileSizePipe implements PipeTransform {
  constructor() {}

  /**
   * @param value tamaño en bytes
   * @param si true = base 1000, false = base 1024
   */
  transform(value: number, si: boolean = true): string {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(value) < thresh) {
      return value + ' B';
    }

    const units = si ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    do {
      value /= thresh;
      ++u;
    } while (Math.abs(value) >= thresh && u < units.length - 1);

    return value.toFixed(1) + ' ' + units[u];
  }
}
