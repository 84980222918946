import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FunctionsService } from 'app/services/util';

@Component({
  selector: 'app-label-money',
  templateUrl: './label-money.component.html',
  styleUrls: ['./label-money.component.scss']
})
export class LabelMoneyComponent implements OnInit, OnChanges {
  @Input() value: string | number;
  @Input() accounting = false;
  @Input() showDecimals = false;
  @Input() symbolClass: string | any = 'text-default';

  intValue: string;
  decValue: string;

  constructor(private fn: FunctionsService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      const value = changes.value.currentValue;

      if (value == null) {
        this.intValue = null;
        this.decValue = null;
        return;
      }

      const values = value.toString().split('.');
      this.intValue = this.fn.formatNumber(+values[0]);
      this.decValue = values[1];
    }
  }
}
