import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpRequestHandler, HttpService } from 'app/services/util';

@Injectable()
export class SectorService {
  public static BASE_URL_API = environment.URL.API + 'sector';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de sectores
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(SectorService.BASE_URL_API + '?' + params, httpRequestHandler);
  }

  /**
   * Agregar sector
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(SectorService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Eliminar sector
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${SectorService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Obtener sector
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${SectorService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar sector
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${SectorService.BASE_URL_API}/${id}`, data, httpRequestHandler);
  }

  /**
   * Listado de sectores para combos
   */
  list = () => {
    return this.http.observableGet(`${SectorService.BASE_URL_API}/list`, { mapFn: res => res.sectors });
  };

  /**
   * Barrios del sector
   */
  getNeighborhoods(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${SectorService.BASE_URL_API}/${id}/neighborhoods`, httpRequestHandler);
  }

  /**
   * Guardar barrios del sector
   */
  saveNeighborhoods(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${SectorService.BASE_URL_API}/${id}/neighborhoods`, data, httpRequestHandler);
  }

  /**
   * Listado de Sectores asociados a un año lectivo
   */
  fromYear = data => {
    return this.http.observableGet(`${SectorService.BASE_URL_API}/list/year/${data.yearId}`, { mapFn: res => res.sectors });
  };
}
