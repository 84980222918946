import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class RankingService {
  public static URL = environment.URL.API + 'ranking';

  constructor(private http: HttpService) {}

  /**
   * Ranking
   */
  get(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(RankingService.URL, data, httpRequestHandler);
  }

  /**
   * Exportar Ranking
   */
  export(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${RankingService.URL}/export`, data, httpRequestHandler);
  }
}
