import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class ReferralPlaceService {
  public static URL = environment.URL.API + 'referral-place';

  constructor(private http: HttpService) {}

  /**
   * List referral places
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ReferralPlaceService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get referral place
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ReferralPlaceService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save referral place
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(ReferralPlaceService.URL, data, httpRequestHandler);
  }

  /**
   * Delete referral place
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ReferralPlaceService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List referral places
   * @param httpRequestHandler HttpRequestHandler
   */
  list = () => {
    return this.http.observableGet(`${ReferralPlaceService.URL}/list`, { mapFn: res => res.referralPlaces });
  };
}
