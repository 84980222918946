import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class SendStudentCovidPollService {
  public static URL = environment.URL.API + 'send-student-covid-poll';

  constructor(private http: HttpService) {}

  /**
   * List authorizations
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${SendStudentCovidPollService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get poll autothorization
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${SendStudentCovidPollService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save authorization
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(SendStudentCovidPollService.URL, data, httpRequestHandler);
  }

  /**
   * Delete authorization
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${SendStudentCovidPollService.URL}/${id}`, httpRequestHandler);
  }
}
