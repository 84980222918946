import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum AccountingDocTypeApplyOn {
  No = 1,
  Yes = 2,
  YesWithReduction = 3,
}

@Injectable()
export class AccountingDocTypeService {
  public static URL = environment.URL.API + 'accounting-doc-type';

  applyOn = [
    { id: AccountingDocTypeApplyOn.No, key: 'general.no', name: '' },
    { id: AccountingDocTypeApplyOn.Yes, key: 'general.yes', name: '' },
    { id: AccountingDocTypeApplyOn.YesWithReduction, key: 'accountingDocType.yesWithReduction', name: '' },
  ];

  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    const lists = {
      applyOn: this.applyOn,
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * List accounting doc types
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AccountingDocTypeService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get accounting doc type
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AccountingDocTypeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save accounting doc type
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(AccountingDocTypeService.URL, data, httpRequestHandler);
  }

  /**
   * Delete accounting doc type
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${AccountingDocTypeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List accounting doc types
   * @param httpRequestHandler HttpRequestHandler
   */
  list = () => {
    return this.http.observableGet(`${AccountingDocTypeService.URL}/list`, { mapFn: res => res.accountingDocTypes });
  };
}
