import { Injectable } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { environment } from 'environments/environment';

import { SessionService } from './session.service';

@Injectable()
export class SignalRService {
  private readonly RetryTimer = 5;
  private readonly MaxRetries = 12;

  private URL: string;
  private connection: signalR.HubConnection;
  private logToConsole = false;
  private retries = 0;
  private connectionId: string;
  private subs: string[] = [];

  constructor(private sessionService: SessionService) {
    this.URL = environment.URL.WEB2 + 'hub/main';

    if (!environment.production) this.logToConsole = true;

    setInterval(() => {
      if (this.connection.state === signalR.HubConnectionState.Connected) {
        this.connection.invoke('ping', sessionService.user ? sessionService.user.id : null).then(() => {
          this.consoleLog('Ping');
        });
      }
    }, 15000);
  }

  private consoleLog(content: any) {
    if (this.logToConsole) {
      console.log('[SignalR] ' + content);
    }
  }

  start() {
    this.connection = new signalR.HubConnectionBuilder().configureLogging(signalR.LogLevel.None).withUrl(this.URL).build();
    this.connect();

    this.connection.onclose(() => {
      this.consoleLog(`Connection closed: ` + this.URL);
      this.retry();
    });
  }

  private connect() {
    this.connection
      .start()
      .then(() => {
        this.consoleLog('Connected to: ' + this.URL);
        this.retries = 0;

        this.connection
          .invoke('getConnectionId', this.subs, this.sessionService.user ? this.sessionService.user.id : null)
          .then(({ connectionId, resubscribed }) => {
            this.connectionId = connectionId;
            this.consoleLog('Connection Id: ' + connectionId);
            if (resubscribed) this.consoleLog('Resubscribed to groups');
          });
      })
      .catch(() => {
        this.consoleLog('Error while starting connection to: ' + this.URL);
        this.retry();
      });
  }

  private retry() {
    if (!environment.production && ++this.retries > this.MaxRetries) return;

    this.consoleLog(`Retrying connection to ${this.URL} in ${this.RetryTimer} seconds...`);

    setTimeout(() => {
      this.connect();
    }, this.RetryTimer * 1000);
  }

  public subscribe(group: string, events: Record<string, (data) => void>) {
    if (!this.connection) return;

    this.subs.push(group);

    this.connection.invoke('subscribe', group).then(() => {
      this.consoleLog('Subscribed to: ' + group);
    });

    Object.keys(events).forEach(key => {
      this.connection.on(key, events[key]);
    });
  }

  public unsubscribe(group: string, events: Record<string, (data) => void>) {
    if (!this.connection) return;

    const index = this.subs.indexOf(group);
    if (index > -1) this.subs.splice(index, 1);

    Object.keys(events).forEach(key => {
      this.connection.off(key, events[key]);
    });

    this.connection.invoke('unsubscribe', group).then(() => {
      this.consoleLog('Unsubscribed from: ' + group);
    });
  }
}
