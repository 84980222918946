<div class="pull-right">
  <label>
    {{ 'general.search' | translate }}:
    <input
      type="search"
      [(ngModel)]="filter"
      class="form-control form-control-sm full-data-search"
      [placeholder]="'general.search' | translate"
      (ngModelChange)="changeSubject.next($event)"
    />
  </label>
</div>
