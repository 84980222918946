import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class ContractTypeService {
  public static URL = environment.URL.API2 + 'contract-type';

  constructor(private http: HttpService) {}

  /**
   * List contract types for select component
   */
  list = () => {
    return this.http.observableGet(`${ContractTypeService.URL}/list`, { mapFn: res => res.contractTypes });
  };

  /**
   * Index Contract Types
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ContractTypeService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Delete Contract Type
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ContractTypeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get Contract Type
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ContractTypeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Contract Type
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(ContractTypeService.URL, data, httpRequestHandler);
  }
}
