import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class GenreService {
  public static BASE_URL_API = environment.URL.API + 'genre';

  constructor(private http: HttpService) {}

  /**
   * List genres
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${GenreService.BASE_URL_API}?${params}`, httpRequestHandler);
  }

  /**
   * List genres
   */
  list = () => {
    return this.http.observableGet(`${GenreService.BASE_URL_API}/list`, { mapFn: res => res.genres });
  };

  /**
   * Store genre
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(GenreService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Delete genre
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${GenreService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Get genre
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${GenreService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Update genre
   */
  update(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${GenreService.BASE_URL_API}`, data, httpRequestHandler);
  }
}
