import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { NotifyMessageService } from '../../services/util';

export interface ICustomErrorMessages {
  [controlName: string]: { [validatorName: string]: string };
}

declare module '@angular/forms' {
  interface FormGroup {
    /**
     * Datos personalizados
     */
    data: any;

    /**
     * Mensajes de error personalizados para las validaciones de los controles
     */
    customErrorMessages: ICustomErrorMessages;

    /**
     * Controles por defecto deshabilitados
     */
    defaultDisabledControls: string[];

    /**
     * Valida si el formulario es valido y genera todos los subsecuentes errores de validación para cada uno de los subcontroles
     * @param notify Si se pasa este parametro y el formulario es invalido, se mostrará un mensaje generico de validación de datos
     */
    validate(this: FormGroup, notify?: NotifyMessageService): boolean;

    /**
     * Valida si el formulario es valido y genera todos los subsecuentes errores de validación para cada uno de los subcontroles
     * (Solo para formularios que contengan controles con validaciones asincronas)
     * @param onValid Funcion que se ejecuta al terminar la validación como correcta 'VALID'
     * @param notify @optional Si se pasa este parametro y el formulario es invalido, se mostrará un mensaje generico de validación de datos
     */
    validateAsync(this: FormGroup, onValid: () => void, notify?: NotifyMessageService): void;

    /**
     * Asigna y deshabilita los controles por defecto deshabilitados
     * @param controls Asignar nuevos controles por defecto a deshabilitar
     */
    setAndDisableDefult(this: FormGroup, controls: string[]): void;

    /**
     * @custom Habilita todos los controles del formulario excepción de los controles deshabilitados por defecto
     *
     * Enables the control. This means the control will be included in validation checks and
     * the aggregate value of its parent. Its status is re-calculated based on its value and
     * its validators.
     *
     * If the control has children, all children will be enabled.
     */
    enableExceptDefault(opts?: { onlySelf?: boolean; emitEvent?: boolean }): void;
  }
}

declare module '@angular/forms' {
  interface FormArray {
    /**
     * Valida si el formulario es valido y genera todos los subsecuentes errores de validación para cada uno de los subcontroles
     * @param notify Si se pasa este parametro y el formulario es invalido, se mostrará un mensaje generico de validación de datos
     */
    validate(this: FormArray, notify?: NotifyMessageService): boolean;

    /**
     * Limpiar el formulario
     */
    clear(this: FormArray): void;
  }
}

declare module '@angular/forms' {
  interface FormControl {
    /**
     * Valida si el control es valido y genera todos los subsecuentes errores de validación para cada uno de los subcontroles
     * @param notify Si se pasa este parametro y el formulario es invalido, se mostrará un mensaje generico de validación de datos
     */
    validate(this: FormControl, notify?: NotifyMessageService): boolean;
  }
}

FormGroup.prototype.validate = function (this: FormGroup, notify?: NotifyMessageService): boolean {
  Object.keys(this.controls).forEach(field => {
    const control = this.controls[field];

    if (control instanceof FormGroup) control.validate();
    else if (control instanceof FormArray) control.validate();
    else if (control instanceof FormControl) control.validate();
  });

  if (notify && !this.valid) notify.warning('validation.msgTitle', 'validation.general');
  return this.valid;
};

FormGroup.prototype.validateAsync = function (this: FormGroup, onValid: () => void, notify?: NotifyMessageService): void {
  Object.keys(this.controls).forEach(field => {
    const control = this.controls[field];

    if (control instanceof FormGroup) control.validate();
    else if (control instanceof FormControl) control.validate();
    else if (control instanceof FormArray) (<FormArray>control).controls.forEach(subform => (<FormGroup>subform).validate());
  });

  const sub = this.statusChanges.subscribe(status => {
    if (status === 'VALID') {
      sub.unsubscribe();
      onValid.call(this);
    } else if (status === 'INVALID') {
      sub.unsubscribe();
      if (notify) notify.warning('validation.msgTitle', 'validation.general');
    }
  });
};

FormGroup.prototype.setAndDisableDefult = function (this: FormGroup, controls: string[]): void {
  this.defaultDisabledControls = controls;
  this.defaultDisabledControls.forEach(control => this.controls[control].disable());
};

FormGroup.prototype.enableExceptDefault = function (this: FormGroup, opts?: { onlySelf?: boolean; emitEvent?: boolean }): void {
  this.enable(opts);
  this.defaultDisabledControls.forEach(control => this.controls[control].disable());
};

FormArray.prototype.validate = function (this: FormArray, notify?: NotifyMessageService): boolean {
  this.controls.forEach((form: FormGroup) => form.validate());

  if (notify && !this.valid) notify.warning('validation.msgTitle', 'validation.general');
  return this.valid;
};

FormArray.prototype.clear = function (this: FormArray): void {
  while (this.controls.length) this.controls.splice(0, 1);
};

FormControl.prototype.validate = function (this: FormControl): boolean {
  this.markAsTouched();
  this.updateValueAndValidity();
  return this.valid;
};

declare global {
  interface String {
    /**
     * Replaces all found text in a string, using an object that supports replacement within a string.
     * @param searchValue A object can search for and replace matches within a string.
     * @param replaceValue A string containing the text to replace for every successful match of searchValue in this string.
     */
    replaceAll(this: string, search: string, replacement: string): string;
  }

  interface Array<T> {
    /**
     * Return a new array group by a given key, the source array is not modified
     * @param key Key to group by
     * @param keyForValues Key to set the gruuped values
     */
    groupBy(this: T[], key: string, keyForValues?: string): any[];

    /**
     * Sort an array by a given key
     * @param key Key to sort by
     */
    sortBy(this: T[], key: string): T[];

    /**
     * Returns the difference (added, removed) between 2 arrays
     * @param final Final array
     */
    difference(this: T[], final: T[]): { added: T[]; removed: T[] };

    /**
     * Check if an array has duplicated values
     */
    hasDuplicates(this: T[]): boolean;

    /**
     * Sum all the items in the array
     */
    sum(this: number[]): number;

    /**
     * Concat two arrays excluding duplicated items
     */
    concatUnique(this: T[], items: T[]): T[];
  }

  interface Math {
    /**
     * Round number to floor with 'n' decimals
     * @param number Number to round, default 2
     * @param decimals Decimal numbers
     */
    cutDecimals(number: number, decimals?: number): number;

    /**
     * Round number witn 'n' decimals
     * @param number Number to round
     * @param decimals Decimal numbers
     */
    realRound(number: number, decimals: number): number;
  }
}

String.prototype.replaceAll = function (this: string, search, replacement) {
  return this.split(search).join(replacement);
};

Array.prototype.groupBy = function (this: any[], key: string, keyForValues?: string) {
  const temp = this.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});

  if (keyForValues) {
    return Object.keys(temp).map(k => {
      const g: any = {};
      g.key = k;
      g[keyForValues] = temp[k];
      return g;
    });
  } else {
    return Object.keys(temp).map(k => temp[k]);
  }
};

Array.prototype.sortBy = function (this: any[], key: string) {
  return this.sort(function (a, b) {
    const x = a[key];
    const y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
};

Array.prototype.difference = function (this: any[], final: any[]) {
  final = final || [];

  return {
    added: final.filter(item => !this.includes(item)),
    removed: this.filter(item => !final.includes(item)),
  };
};

Array.prototype.hasDuplicates = function (this: any[]) {
  return new Set(this).size !== this.length;
};

Array.prototype.sum = function (this: number[]) {
  return this.reduce((a, b) => a + b, 0);
};

Math.cutDecimals = function (number: number, decimals?: number) {
  const mult = Math.pow(10, decimals ? decimals : 2);
  return Math.floor(number * mult) / mult;
};

Math.realRound = function (number: number, decimals: number) {
  const value = number + 'e' + decimals;
  return Number(Math.round(+value) + 'e-' + decimals);
};

declare module 'rxjs/internal/BehaviorSubject' {
  interface BehaviorSubject<T> {
    arrayPush(this: BehaviorSubject<T>, ...items: any[]);

    arraySplice(this: BehaviorSubject<T>, start: number, deleteCount?: number, ...items: any[]);
  }
}

BehaviorSubject.prototype.arrayPush = function (this: BehaviorSubject<any[]>, ...items: any[]) {
  const temp = [...this.value];
  temp.push(...items);
  this.next(temp);
};

BehaviorSubject.prototype.arraySplice = function (this: BehaviorSubject<any[]>, start: number, deleteCount?: number, ...items: any[]) {
  const temp = [...this.value];
  temp.splice(start, deleteCount, ...items);
  this.next(temp);
};

Array.prototype.concatUnique = function (this: any[], items: any[]) {
  const concated = this.concat(items);
  return concated.filter((item, index) => concated.indexOf(item) === index);
};
