import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  ContentChildren,
  Directive,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';

let nextId = 0;

@Directive({
  selector: 'ng-template[vTabHeader]'
})
export class VerticalTabHeaderDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: 'ng-template[vTabContent]'
})
export class VerticalTabContentDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: 'v-custom-content'
})
export class VerticalTabsetCustomContentDirective {
  constructor() {}
}

@Directive({
  selector: 'v-tab'
})
export class VerticalTabDirective implements AfterContentChecked {
  @Input() id: string;
  @Input() headerClass = '';

  headerTpl: VerticalTabHeaderDirective | null;
  contentTpl: VerticalTabContentDirective | null;

  @ContentChildren(VerticalTabHeaderDirective, { descendants: false })
  headerTpls: QueryList<VerticalTabHeaderDirective>;

  @ContentChildren(VerticalTabContentDirective, { descendants: false })
  contentTpls: QueryList<VerticalTabContentDirective>;

  constructor() {
    this.id = 'v-tab-' + nextId++;
  }

  ngAfterContentChecked() {
    this.headerTpl = this.headerTpls.first;
    this.contentTpl = this.contentTpls.first;
  }
}

export interface IVerticalTabChangeEvent {
  activeId: string;
  nextId: string;
  preventDefault: () => void;
}

@Component({
  selector: 'v-tabset',
  templateUrl: './vertical-tabset.component.html',
  styleUrls: ['./vertical-tabset.component.scss']
})
export class VerticalTabsetComponent implements AfterContentChecked, AfterViewInit {
  activeId: string;

  @Input() leftCols = 2;

  @Output() tabChange = new EventEmitter<IVerticalTabChangeEvent>();
  @Output() ready = new EventEmitter<VerticalTabsetComponent>();

  @ContentChildren(VerticalTabDirective) tabs: QueryList<VerticalTabDirective>;

  constructor() {}

  ngAfterViewInit() {
    this.ready.emit(this);
  }

  ngAfterContentChecked() {
    const activeTab = this.tabs.find(tab => tab.id === this.activeId);
    this.activeId = activeTab ? activeTab.id : this.tabs.length ? this.tabs.first.id : null;
  }

  select(tabId: string) {
    const selected = this.tabs.find(tab => tab.id === tabId);

    if (selected && this.activeId !== selected.id) {
      let defaultPrevented = false;

      this.tabChange.emit({
        activeId: this.activeId,
        nextId: selected.id,
        preventDefault: () => {
          defaultPrevented = true;
        }
      });
      if (!defaultPrevented) {
        this.activeId = selected.id;
      }
    }
  }
}
