import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { FunctionsService, HttpRequestHandler, HttpService } from '../../util';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

export enum RiskCentralsReportType {
  TotalUpdate = 1,
  PartialUpdate = 2
}

@Injectable()
export class RiskCentralsReportService {
  public static URL = environment.URL.API2 + 'risk-centrals-report';

  reportTypes = [
    { id: RiskCentralsReportType.TotalUpdate, key: 'riskCentralsReport.types.totalUpdate', name: '' },
    { id: RiskCentralsReportType.PartialUpdate, key: 'riskCentralsReport.types.partialUpdate', name: '' }
  ];

  constructor(private http: HttpService, fn: FunctionsService, translate: TranslateService) {
    const lists = {
      reportTypes: this.reportTypes
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  index(params: string, httpRequestHandler: HttpRequestHandler): Subscription {
    return this.http.get(`${RiskCentralsReportService.URL}?${params}`, httpRequestHandler);
  }

  generate(data: any, httpRequestHandler: HttpRequestHandler): Subscription {
    return this.http.post(`${RiskCentralsReportService.URL}/generate`, data, httpRequestHandler);
  }

  delete(id: number, httpRequestHandler: HttpRequestHandler): Subscription {
    return this.http.delete(`${RiskCentralsReportService.URL}/${id}`, httpRequestHandler);
  }

  download(id: number, httpRequestHandler: HttpRequestHandler): Subscription {
    return this.http.get(`${RiskCentralsReportService.URL}/${id}/download`, httpRequestHandler);
  }
}
