import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class AttendanceObsTypeService {
  public static URL = environment.URL.API + 'attendance-obs-type';

  constructor(private http: HttpService) {}

  /**
   * Index attendance observation types
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(AttendanceObsTypeService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Delete attendance observation type
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${AttendanceObsTypeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get attendance observation type
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AttendanceObsTypeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save attendance observation type
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AttendanceObsTypeService.URL}`, data, httpRequestHandler);
  }

  /**
   * List attendance observation type
   */
  list = () => {
    return this.http.observableGet(`${AttendanceObsTypeService.URL}/list`, { mapFn: res => res.observationTypes });
  };
}
