import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

export enum StepType {
  Static = 1,
  Student = 2,
  Family = 3,
}

export enum StepStaticType {
  Student = 1,
  Representative = 2,
  Sport = 3,
  Transport = 4,
  Settlement = 5,
  Summary = 6,
  Signatures = 7,
  Extracurricular = 8,
}

export enum StepRuleType {
  Header = 1,
  Body = 2,
  Footer = 3,
  Options = 4,
  Main = 5,
}

export enum StepOptionValueType {
  Direct = 1,
  Rules = 2,
}

@Injectable()
export class StepService {
  public static URL = environment.URL.API + 'step';

  types = [
    { id: StepType.Static, key: 'step.type.static', name: '', class: 'label label-default', create: false },
    { id: StepType.Student, key: 'step.type.student', name: '', class: 'label label-primary', create: true },
    { id: StepType.Family, key: 'step.type.family', name: '', class: 'label label-success', create: true },
  ];

  valueTypes = [
    { id: StepOptionValueType.Direct, key: 'prices.optionValueType.direct', name: '' },
    { id: StepOptionValueType.Rules, key: 'prices.optionValueType.rules', name: '' },
  ];

  constructor(private http: HttpService, private translate: TranslateService) {
    const udpateLangValues = () => {
      this.types.forEach(item => (item.name = translate.instant(item.key)));
      this.valueTypes.forEach(item => (item.name = translate.instant(item.key)));

      this.types = [...this.types];
      this.valueTypes = [...this.valueTypes];
    };
    udpateLangValues();
    translate.onLangChange.subscribe(value => udpateLangValues());
  }

  getStatus(id: StepType) {
    return this.types.find(s => s.id === id);
  }

  /**
   * Crear paso de matricula
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(StepService.URL, data, httpRequestHandler);
  }

  /**
   * Modificar paso de matricula
   */
  udpate(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(StepService.URL, data, httpRequestHandler);
  }

  /**
   * Reordenar pasos de matricula
   */
  reorder(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${StepService.URL}/reorder`, data, httpRequestHandler);
  }

  /**
   * Eliminar paso de matricula
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${StepService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Pasos matricula
   * @param id Id de año lectivo
   */
  fromYear(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${StepService.URL}/year/${id}`, httpRequestHandler);
  }

  /**
   * Datos de paso matricula
   * @param id Id de paso matricula
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${StepService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Guardar datos de regla
   */
  saveRule(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${StepService.URL}/rule`, data, httpRequestHandler);
  }

  /**
   * Eliminar regla
   * @param id Id de regla
   */
  deleteRule(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${StepService.URL}/rule/${id}`, httpRequestHandler);
  }

  /**
   * Guardar opciones generales de paso
   */
  saveOptions(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${StepService.URL}/options`, data, httpRequestHandler);
  }

  /**
   * Orden de paso de seleccion de representante del menor
   * @param id Id de año lectivo
   */
  representativeOrder(yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${StepService.URL}/representative-order/${yearId}`, httpRequestHandler);
  }
}
