import './extensions';
import 'd3';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { TextMaskModule } from 'angular2-text-mask';
import { ClickOutsideModule } from 'ng-click-outside';
import { CKEditorModule } from 'ng2-ckeditor';
import { AutosizeModule } from 'ngx-autosize';
import { AvatarModule } from 'ngx-avatar';
import { MomentModule } from 'ngx-moment';
import { OrderModule } from 'ngx-order-pipe';
import { NgxPermissionsModule } from 'ngx-permissions';

import { IFrameResizerDirective, MarkTouchedOnBlurDirective, RxModelDirective } from '../../directives';
import {
  AgeFromDatePipe,
  BgColorPipe,
  ExplodePipe,
  FindByPipe,
  FormatNumberPipe,
  HumanFileSizePipe,
  ImplodePipe,
  IncludesPipe,
  LineBreakerPipe,
  MimeIconPipe,
  SafeHtmlPipe,
  SafeUrlPipe,
  SumPipe
} from '../../pipes';
import { ScrollModule } from '../scroll/scroll.module';
import { AccordionAnchorDirective, AccordionDirective, AccordionLinkDirective } from './accordion';
import { AddressGeneratorModule } from './address-generator/address-generator.module';
import { BackComponent } from './back/back.component';
import { CheckBoxModule } from './checkbox';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DatatableHeaderComponent } from './datatable-header/datatable-header.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DimmerModule } from './dimmer';
import { DataFilterPipe } from './elements/data-filter.pipe';
import { ParentRemoveDirective } from './elements/parent-remove.directive';
import { FormControlErrorModule } from './form-control-error';
import { ToggleFullscreenDirective } from './fullscreen/toggle-fullscreen.directive';
import { HtmlEditorComponent } from './html-editor/html-editor.component';
import { InlineEditorComponent } from './inline-editor/inline-editor.component';
import { InputAutocompleteComponent } from './input-autocomplete/input-autocomplete.component';
import { InputMoneyComponent } from './input-money/input-money.component';
import { InputNumberComponent } from './input-number/input-number.component';
import { InputPercentageComponent } from './input-percentage/input-percentage.component';
import { LabelMoneyComponent } from './label-money/label-money.component';
import { LabelPersonComponent } from './label-person/label-person.component';
import { LoadingContentModule } from './loading-content';
import { LocalDatatableDirective, LocalFilterSizeComponent, LocalInputColumnFilterComponent, LocalSelectColumnFilterComponent } from './local-dt';
import { ModalModule } from './modal/modal.module';
import { PagerModule } from './pager/pager.module';
import { ReturnBackComponent } from './return-back/return-back.component';
import { ReturnSwitchComponent } from './return-switch/return-switch.component';
import { SelectModule } from './select/select.module';
import { SpinnerComponent } from './spinner/spinner.component';
import { SubmitButtonComponent } from './submit-button/submit-button.component';
import {
  VerticalTabContentDirective,
  VerticalTabDirective,
  VerticalTabHeaderDirective,
  VerticalTabsetComponent,
  VerticalTabsetCustomContentDirective
} from './vertical-tabset/vertical-tabset.component';
import { SwitchModule } from './switch';
import { AppReactiveFormsModule } from './app-reactive-forms.module';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';

@NgModule({
  declarations: [SafeHtmlPipe],
  exports: [SafeHtmlPipe]
})
export class SafeHtmlPipeModule {
}

@NgModule({
  imports: [
    CommonModule,
    AppReactiveFormsModule,
    NgbModule,
    SimpleNotificationsModule.forRoot(),
    NgxDatatableModule,
    ClickOutsideModule,
    OrderModule,
    TranslateModule,
    RouterModule,
    AvatarModule,
    NgxPermissionsModule,
    MomentModule,
    CKEditorModule,
    ScrollModule,
    TextMaskModule,
    DimmerModule,
    LoadingContentModule,
    FormControlErrorModule,
    CheckBoxModule,
    SafeHtmlPipeModule,
    SelectModule,
    SwitchModule,
    PasswordStrengthMeterModule
  ],
  declarations: [
    AccordionAnchorDirective,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ToggleFullscreenDirective,
    ParentRemoveDirective,
    SpinnerComponent,
    DataFilterPipe,
    DashboardComponent,
    ReturnSwitchComponent,
    ReturnBackComponent,
    SubmitButtonComponent,
    MarkTouchedOnBlurDirective,
    RxModelDirective,
    HtmlEditorComponent,
    AgeFromDatePipe,
    LineBreakerPipe,
    ImplodePipe,
    ExplodePipe,
    SafeUrlPipe,
    FormatNumberPipe,
    FormatNumberPipe,
    SumPipe,
    HumanFileSizePipe,
    MimeIconPipe,
    BgColorPipe,
    FindByPipe,
    IncludesPipe,
    DatePickerComponent,
    LocalFilterSizeComponent,
    LocalInputColumnFilterComponent,
    DatatableHeaderComponent,
    BackComponent,
    LocalDatatableDirective,
    VerticalTabsetComponent,
    VerticalTabDirective,
    VerticalTabHeaderDirective,
    VerticalTabContentDirective,
    VerticalTabsetCustomContentDirective,
    InputAutocompleteComponent,
    InlineEditorComponent,
    InputPercentageComponent,
    InputMoneyComponent,
    InputNumberComponent,
    LabelMoneyComponent,
    LabelPersonComponent,
    IFrameResizerDirective,
    LocalSelectColumnFilterComponent
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ToggleFullscreenDirective,
    ParentRemoveDirective,
    SpinnerComponent,
    NgbModule,
    AppReactiveFormsModule,
    SimpleNotificationsModule,
    NgxDatatableModule,
    DataFilterPipe,
    ClickOutsideModule,
    DashboardComponent,
    ReturnSwitchComponent,
    ReturnBackComponent,
    SubmitButtonComponent,
    TranslateModule,
    RouterModule,
    OrderModule,
    NgxPermissionsModule,
    AvatarModule,
    MomentModule,
    AgeFromDatePipe,
    LineBreakerPipe,
    ImplodePipe,
    ExplodePipe,
    SafeUrlPipe,
    FormatNumberPipe,
    SumPipe,
    HumanFileSizePipe,
    MimeIconPipe,
    BgColorPipe,
    FindByPipe,
    IncludesPipe,
    MarkTouchedOnBlurDirective,
    RxModelDirective,
    HtmlEditorComponent,
    DatePickerComponent,
    LocalFilterSizeComponent,
    LocalInputColumnFilterComponent,
    DatatableHeaderComponent,
    BackComponent,
    LocalDatatableDirective,
    VerticalTabsetComponent,
    VerticalTabDirective,
    VerticalTabHeaderDirective,
    VerticalTabContentDirective,
    VerticalTabsetCustomContentDirective,
    InputAutocompleteComponent,
    InlineEditorComponent,
    InputPercentageComponent,
    InputMoneyComponent,
    InputNumberComponent,
    LabelMoneyComponent,
    LabelPersonComponent,
    IFrameResizerDirective,
    SelectModule,
    PagerModule,
    AddressGeneratorModule,
    ModalModule,
    DimmerModule,
    LoadingContentModule,
    FormControlErrorModule,
    CheckBoxModule,
    SafeHtmlPipe,
    AutosizeModule,
    LocalSelectColumnFilterComponent,
    SwitchModule,
    PasswordStrengthMeterModule
  ]
})
export class SharedModule {
}
