import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectLookupFn } from 'app/modules/shared/select';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

export enum PersonFormMode {
  Family,
  Person,
}

export enum PersonProfile {
  Employee = 1,
  Attendant = 2,
  Student = 3,
  ThirdParty = 4,
}

@Injectable()
export class PersonService {
  public static readonly URL = environment.URL.API + 'person';
  public static readonly URL2 = environment.URL.API2 + 'person';

  public profiles = [
    { id: PersonProfile.Employee, name: '', key: 'employee.msgTitle', icon: 'fa-briefcase' },
    { id: PersonProfile.Attendant, name: '', key: 'attendant.msgTitle', icon: 'fa-user' },
    { id: PersonProfile.Student, name: '', key: 'student.msgTitle', icon: 'fa-graduation-cap' },
    { id: PersonProfile.ThirdParty, name: '', key: 'thirdParty.msgTitle', icon: 'fa-user-secret' },
  ];

  constructor(private http: HttpService, translate: TranslateService) {
    const tran = translate.instant(this.profiles.map(r => r.key));
    Object.keys(tran).forEach((key, i) => (this.profiles[i].name = tran[key]));
    this.profiles = [...this.profiles];
  }

  /**
   *Listado de personas
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(PersonService.URL2 + '?' + params, httpRequestHandler);
  }

  /**
   * Obtener datos de persona
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PersonService.URL2}/${id}`, httpRequestHandler);
  }

  /**
   * Guardar datos de persona
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    // this.http.post(PersonService.URL, data, httpRequestHandler);
    this.http.post(PersonService.URL2, data, httpRequestHandler);
  }

  /**
   * Recuperar contraseña
   */
  recoverPassword(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(PersonService.URL + '/' + id + '/recover-password', null, httpRequestHandler);
  }

  /**
   * Desbloquear
   */
  unblock(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(PersonService.URL + '/' + id + '/unblock', null, httpRequestHandler);
  }

  /**
   * Crear tercero financiero
   */
  createFtp(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(PersonService.URL + '/' + id + '/create-ftp', null, httpRequestHandler);
  }

  /**
   * Eliminar persona
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${PersonService.URL2}/${id}`, httpRequestHandler);
  }

  /**
   * Listar todas las personas
   */
  list: SelectLookupFn = data => {
    return this.http.observablePost(PersonService.URL + '/list-all', data, { mapFn: res => res.data });
  };

  /**
   * Listar solo empleados y estudiantes
   */
  listEmployeeStudent: SelectLookupFn = data => {
    return this.http.observablePost(PersonService.URL + '/list-employee-student', data, { mapFn: res => res.data });
  };

  /**
   * Datos para carta de persona
   * @param id Person Id
   */
  getCard(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PersonService.URL}/${id}/card`, httpRequestHandler);
  }

  /**
   * Guardar foto de persona
   * @param id Id de persona
   */
  savePhoto(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PersonService.URL}/${id}/photo`, data, httpRequestHandler);
  }

  /**
   * Listado de empleados con un cargo específico
   * @param positionId ID del cargo a filtrar
   */
  getByPosition = data => {
    return this.http.observableGet(`${PersonService.URL}/list/position/${data.positionId}`, { mapFn: res => res.employees });
  }
}
