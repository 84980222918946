import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum HealthPassportTrackingTab {
  PendingPassports = 'pending-passports',
  ExpiredPassports = 'expired-passports',
  Historic = 'historic',
}

export enum HealthPassportStatus {
  Created = 1,
  Updated = 2,
  Rejected = 3,
  Approved = 4,
}

@Injectable()
export class HealthPassportTrackingService {
  public static URL = environment.URL.API2 + 'health-passport';

  statuses = [
    { id: HealthPassportStatus.Created, key: 'healthPassportTracking.status.created', name: '', class: 'label label-default' },
    { id: HealthPassportStatus.Updated, key: 'healthPassportTracking.status.updated', name: '', class: 'label label-info' },
    { id: HealthPassportStatus.Rejected, key: 'healthPassportTracking.status.rejected', name: '', class: 'label label-danger' },
    { id: HealthPassportStatus.Approved, key: 'healthPassportTracking.status.approved', name: '', class: 'label label-success' },
  ];

  constructor(private http: HttpService, fn: FunctionsService, translate: TranslateService) {
    const lists = {
      statuses: this.statuses,
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * List Health Passport for tracking
   *
   * @param params string
   * @param tab TicketAuthorizationTab
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, tab: HealthPassportTrackingTab, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${HealthPassportTrackingService.URL}/${tab}?${params}`, httpRequestHandler);
  }

  /**
   * Get Health Passport status
   * @param status HealthPassportStatus
   */
  getStatus(status: HealthPassportStatus) {
    return this.statuses.find(s => s.id === status);
  }

  /**
   * Delete Health Passport
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
   delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${HealthPassportTrackingService.URL}/${id}`, httpRequestHandler);
  }

   /**
   * Set attendance class to student
   */
    setStatus(data: any, httpRequestHandler: HttpRequestHandler) {
      this.http.post(`${HealthPassportTrackingService.URL}/set-status`, data, httpRequestHandler);
    }  
}
