import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class AcademicConfigurationService {
  public static URL = environment.URL.API + 'academic-configuration';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de Tipo de Notas
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(AcademicConfigurationService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Eliminar Tipo de Nota
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${AcademicConfigurationService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Editar Tipo de Nota
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AcademicConfigurationService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Guardar Tipo de Nota
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AcademicConfigurationService.URL}`, data, httpRequestHandler);
  }

  /**
   * Listado para combo de tipos de nota
   */
  list = () => {
    return this.http.observableGet(`${AcademicConfigurationService.URL}/list`, { mapFn: res => res.gradingTypes });
  };
}
