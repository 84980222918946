import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class PayrollProvisionConceptService {
  public static URL = environment.URL.API2 + 'payroll-provision-concept';

  constructor(private http: HttpService) {}

  /**
   * Index Payroll provision concept
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PayrollProvisionConceptService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Delete payroll provision concept
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${PayrollProvisionConceptService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get payroll provision concept
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PayrollProvisionConceptService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save payroll provision concept
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(PayrollProvisionConceptService.URL, data, httpRequestHandler);
  }

  list = () => {
    return this.http.observableGet(`${PayrollProvisionConceptService.URL}/list`, { mapFn: res => res.payrollProvisionConcept });
  };
}
