import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class MiddleReportConceptService {
  public static URL = environment.URL.API + 'middle-report-concept';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de Conceptos de mitad de período
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(MiddleReportConceptService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Eliminar Concepto de mitad de período
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${MiddleReportConceptService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Editar Concepto de mitad de período
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${MiddleReportConceptService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar Concepto de mitad de período
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${MiddleReportConceptService.URL}/${id}`, data, httpRequestHandler);
  }

  /**
   * Guardar Concepto de mitad de período
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${MiddleReportConceptService.URL}`, data, httpRequestHandler);
  }

  /**
   * Listado de Conceptos de mitad de período para combo.
   */
  list = () => {
    return this.http.observableGet(`${MiddleReportConceptService.URL}/list`, { mapFn: res => res.middleReportConcepts });
  };
}
