import { Injectable } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class DocumentTypeService {
  public static URL = environment.URL.API + 'document-type';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de tipo de documento
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(DocumentTypeService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Save Document Type
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(DocumentTypeService.URL, data, httpRequestHandler);
  }

  /**
   * Eliminar tipo de documento
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${DocumentTypeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Editar tipo de documento
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${DocumentTypeService.URL}/${id}`, httpRequestHandler);
  }

  list = () => {
    return this.http.observableGet(`${DocumentTypeService.URL}/list`, { mapFn: res => res.documentTypes });
  };

  /**
   * Mostrar listado de tipo de documento
   */
  getAll(httpRequestHandler: HttpRequestHandler) {
    this.http.get(DocumentTypeService.URL + '/list', httpRequestHandler);
  }

  /**
   * Set document validations
   * @param docType { id: number, regex: string }
   * @param form FormGroup
   */
  setDocumentValidators(docType: any, form: FormGroup, maxLength = 30) {
    form.controls.document.clearValidators();
    const validators = [Validators.required, Validators.maxLength(maxLength)];
    if (docType) {
      validators.push(Validators.pattern(docType.regex));
      form.controls.document.setValidators(validators);
    } else {
      form.controls.document.setValidators(validators);
    }
    if (form.value.document) {
      form.controls.document.updateValueAndValidity();
    }
  }

  /**
   * Set document validations on get data
   * @param documentTypes any[]
   * @param form FormGroup
   */
  setDocumentValidatorsOnGet(documentTypes: any[], form: FormGroup) {
    if (documentTypes.length) {
      const docType = documentTypes.find(dt => dt.id === form.controls.docTypeId.value);
      this.setDocumentValidators(docType, form);
    }
  }
}
