<div class="input-group input-group-sm" *ngIf="addon; else noAddonTpl">
  <div class="input-group-prepend">
    <span class="input-group-text"><i class="fa fa-usd"></i></span>
  </div>
  <ng-container *ngTemplateOutlet="noAddonTpl"></ng-container>
</div>
<ng-template #noAddonTpl>
  <input
    type="text"
    class="form-control form-control-sm"
    [(ngModel)]="value"
    [textMask]="moneyMask"
    (ngModelChange)="valueChanged($event)"
    [disabled]="disabled || isDisabled"
    [readOnly]="readonly"
    (blur)="onBlur()"
    (keypress)="handleKeyboardEvent($event)"
    #input
  />
</ng-template>
