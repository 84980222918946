import { Injectable } from '@angular/core';
import { HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class ContractPaymentTypeService {
  public static URL = environment.URL.API2 + 'contract-payment-type';

  constructor(private http: HttpService) {}

  /**
   * List companies for select component
   */
  list = () => {
    return this.http.observableGet(`${ContractPaymentTypeService.URL}/list`, { mapFn: res => res.paymentTypes });
  };
}
