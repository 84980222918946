import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'app/services/api';

@Component({
  selector: 'app-place-to-pay-faq',
  templateUrl: './place-to-pay-faq.component.html',
  styleUrls: ['./place-to-pay-faq.component.scss'],
  providers: [ConfigService]
})
export class PlaceToPayFaqComponent implements OnInit {
  placeToPayFAQInfo: any;
  loading = true;

  constructor(configService: ConfigService) {
    this.loading = true;
    configService.getPlaceToPayFAQInfo({
      success: ({ info }) => (this.placeToPayFAQInfo = info),
      always: () => (this.loading = false)
    });
  }

  ngOnInit() {}
}
