import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class RiskFactorService {
  public static URL = environment.URL.API + 'risk-factor';
  public static URL2 = environment.URL.API2 + 'risk-factor';

  constructor(private http: HttpService) {}

  /**
   * List risk factors
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${RiskFactorService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get risk factor
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${RiskFactorService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save risk factor
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(RiskFactorService.URL, data, httpRequestHandler);
  }

  /**
   * Delete risk factor
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${RiskFactorService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List risk factors
   * @param httpRequestHandler HttpRequestHandler
   */
  list = () => {
    return this.http.observableGet(`${RiskFactorService.URL}/list`, { mapFn: res => res.riskFactors });
  };
}
