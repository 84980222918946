import { EventEmitter, Injectable } from '@angular/core';

import { SignalRService } from '../util';
import { BaseBroadcast } from './base-broadcast';

@Injectable()
export class RequestAuthorizationBroadcast extends BaseBroadcast {
  onRiskAuthorized = new EventEmitter<any>();
  onDataAuthorized = new EventEmitter<any>();

  private _onRiskAuthorization = data => this.onRiskAuthorized.emit(data);
  private _onDataAuthorization = data => this.onDataAuthorized.emit(data);

  constructor(signalRService: SignalRService) {
    super(signalRService, 'request-authorization-');

    this.setEvents({
      'risk-authorization': this._onRiskAuthorization,
      'data-authorization': this._onDataAuthorization,
    });
  }
}
