import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[iFrameResizer]'
})
export class IFrameResizerDirective implements OnInit, OnDestroy {
  private listener: () => void;
  private observer: MutationObserver;

  get element() {
    return this.elementRef.nativeElement;
  }

  get contentHeight() {
    return this.element.contentDocument.body.scrollHeight;
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.listener = this.renderer.listen(this.element, 'load', () => {
      this.setFrameHeight(this.contentHeight);

      this.observer = new MutationObserver(mutations => this.setFrameHeight(this.contentHeight));

      this.observer.observe(this.element.contentDocument.body, {
        attributes: true,
        childList: true,
        characterData: true,
        subtree: true
      });
    });
  }

  setFrameHeight(height: number) {
    this.renderer.setStyle(this.element, 'height', `${height + 26}px`);
  }

  ngOnDestroy() {
    this.observer.disconnect();
    this.listener();
  }
}
