import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { SignalRService } from './services/util';

@Component({
  selector: 'app-root',
  template: '<router-outlet><spinner></spinner></router-outlet>',
})
export class AppComponent {
  constructor(translate: TranslateService, signalRService: SignalRService) {
    translate.addLangs(['es', 'en']);
    translate.setDefaultLang('es');
    translate.use('es');

    signalRService.start();
  }
}
