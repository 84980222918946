import { EventEmitter, Injectable } from '@angular/core';

import { SignalRService } from '../util';
import { BaseBroadcast } from './base-broadcast';

@Injectable()
export class EnrollmentFinancialBroadcast extends BaseBroadcast {
  onSent = new EventEmitter<any>();
  onFinished = new EventEmitter<any>();
  onRolledBack = new EventEmitter<any>();

  private _onSent = data => this.onSent.emit(data);
  private _onFinished = data => this.onFinished.emit(data);
  private _onRolledBack = data => this.onRolledBack.emit(data);

  constructor(signalRService: SignalRService) {
    super(signalRService, 'enrollment-financial');

    this.setEvents({
      sent: this._onSent,
      finished: this._onFinished,
      'rolled-back': this._onRolledBack,
    });
  }
}
