import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class WorkerCompensationFundService {
  public static URL = environment.URL.API2 + 'worker-compensation-fund';

  constructor(private http: HttpService) {
  }

  /**
   * Index worker compensation fund
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${WorkerCompensationFundService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get worker compensation fund
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${WorkerCompensationFundService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save worker compensation fund
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(WorkerCompensationFundService.URL, data, httpRequestHandler);
  }

  /**
   * Delete worker compensation fund
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${WorkerCompensationFundService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List worker compensation fund for select component
   */
  list = () => {
    return this.http.observableGet(`${WorkerCompensationFundService.URL}/list`, { mapFn: res => res.workerCompensationFunds });
  };
}
