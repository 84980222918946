import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class AccountingDocIssueService {
  public static URL = environment.URL.API + 'accounting-doc-issue';

  constructor(private http: HttpService) {}

  /**
   * Listado de documentos pendientes por emitir
   */
  index(httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AccountingDocIssueService.URL}`, httpRequestHandler);
  }

  /**
   * Emitir documentos contables
   */
  issue(data: any, httpRequestHandler: HttpRequestHandler) {
    return this.http.post(`${AccountingDocIssueService.URL}/issue`, data, httpRequestHandler);
  }

  /**
   * Listado paginador de documentos contables expirados
   */
  expired(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AccountingDocIssueService.URL}/expired?${params}`, httpRequestHandler);
  }

  /**
   * Descargar PDF de emisión
   * @param id accountingDocIssueId
   */
  downloadIssuedPDF(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AccountingDocIssueService.URL}/${id}/issued-pdf`, httpRequestHandler);
  }
}
