import { Component, EventEmitter, forwardRef, Host, Input, OnInit, Optional, Output, SkipSelf } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SwitchComponent)
    }
  ]
})
export class SwitchComponent implements OnInit, ControlValueAccessor {
  @Input() formControlName: string;
  @Input() isDisabled = false;
  @Output() valueChange = new EventEmitter<boolean>();
  @Output() blur = new EventEmitter();

  checked: boolean;
  disabled: boolean;
  private control: AbstractControl;
  private propagateChange = (val: any) => {
  };
  private touchChange = () => {
  };


  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer
  ) {
  }

  ngOnInit(): void {
    if (this.formControlName) this.control = this.controlContainer.control.get(this.formControlName);
  }

  writeValue(value: any) {
    this.checked = value;
  }

  valueChanged() {
    this.propagateChange(this.checked);
    this.valueChange.emit(this.checked);
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.touchChange = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
