import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SelectLookupFn } from 'app/modules/shared/select';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class BlStudentService {
  public static URL = environment.URL.API + 'bl-student';

  constructor(private http: HttpService, translate: TranslateService, private fb: FormBuilder) {}

  /**
   * Crea un `FormGroup` con los campos de estudiante
   * @param data datos para asignar al formulario
   */
  createForm(data?: any): FormGroup {
    const form = this.fb.group({
      id: [null],
      civilRegistrationFileId: [null],
      civilRegistrationNumber: [null, [Validators.required, Validators.maxLength(30)]],
      firstName: [null, [Validators.required, Validators.maxLength(30)]],
      middleName: [null, [Validators.maxLength(30)]],
      firstSurname: [null, [Validators.required, Validators.maxLength(30)]],
      secondSurname: [null, [Validators.maxLength(30)]],
      birthDate: [null, [Validators.required]],
      bloodTypeId: [null],
      epsId: [null, []],
      prepaidMedicineId: [null, []],
      medicalBackground: [null, [Validators.maxLength(1000)]],
      allergies: [null, [Validators.maxLength(1000)]],
      controlMedications: [null, [Validators.maxLength(1000)]],
    });

    if (data) {
      form.patchValue(data);
    }

    return form;
  }

  /**
   * Mostrar listado de estudiantes
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(BlStudentService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Datos de estudiante
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${BlStudentService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Guardar datos de estudiante
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${BlStudentService.URL}/save`, data, httpRequestHandler);
  }

  /**
   * Select autocomplete
   */
  list: SelectLookupFn = data => {
    return this.http.observablePost(BlStudentService.URL + '/list', data, { mapFn: res => res.data });
  };
}
