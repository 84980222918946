import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { SessionService } from '../services/util';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private sessionService: SessionService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const guest = next.data.guest;

    if (this.sessionService.token) {
      if (!guest && this.sessionService.isGuest()) {
        this.router.navigate(['/g']);
        return false;
      } else if (guest && !this.sessionService.isGuest()) {
        this.router.navigate(['/']);
        return false;
      }
      return true;
    }

    this.router.navigate(['/auth', 'login'], { queryParams: { returnUrl: encodeURIComponent(state.url) } });
    return false;
  }
}
