import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sum' })
export class SumPipe implements PipeTransform {
  constructor() {}

  /**
   * @param items listado de items
   * @param key llave con valor a sumar
   */
  transform(items: any[], key: string): number {
    if (!items) return null;

    return items.map(i => +i[key]).sum();
  }
}
