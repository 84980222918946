import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputSwitchModule } from 'primeng-lts';
import { SwitchComponent } from './switch.component';
import { AppReactiveFormsModule } from '../app-reactive-forms.module';


@NgModule({
  declarations: [SwitchComponent],
  imports: [CommonModule, InputSwitchModule, AppReactiveFormsModule],
  exports: [SwitchComponent]
})
export class SwitchModule {
}
