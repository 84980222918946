import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {
  contentURL: string;

  constructor(private activated: ActivatedRoute, router: Router, location: Location) {
    activated.queryParams.subscribe(params => {
      if (!params.url) router.navigate(['/not-found'], { queryParams: { url: encodeURIComponent(router.url) }, replaceUrl: true });
      else this.contentURL = decodeURIComponent(params.url);
    });
  }

  ngOnInit() {}
}
