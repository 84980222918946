import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class CancellationReasonService {
  public static BASE_URL_API = environment.URL.API + 'cancellation-reason';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de Motivos de Cancelación
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(CancellationReasonService.BASE_URL_API + '?' + params, httpRequestHandler);
  }

  /**
   * Agregar Motivo de Cancelación
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(CancellationReasonService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Eliminar Motivo de Cancelación
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${CancellationReasonService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Editar Motivo de Cancelación
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${CancellationReasonService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar Motivo de Cancelación
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${CancellationReasonService.BASE_URL_API}/${id}`, data, httpRequestHandler);
  }

  /**
   * Listado para combo de Motivos de Cancelación
   */
  list = () => {
    return this.http.observableGet(`${CancellationReasonService.BASE_URL_API}/list`, { mapFn: res => res.cancellationReasons });
  };
}
