import {
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Host,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  SkipSelf,
  ViewChild
} from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FunctionsService } from 'app/services/util';

import { SelectLabelDirective, SelectOptionDirective, SelectOptions } from '../utils';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectComponent)
    }
  ]
})
export class SelectComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() valueField = 'id';
  @Input() displayField = 'name';
  @Input() selectOptions: SelectOptions;
  @Input() options: any[];
  @Input() isDisabled: boolean;
  @Input() formControlName: string;
  @Input() multi = false;
  @Input() searchable = true;
  @Input() clearable = true;
  @Input() appendTo: string;
  @Input() placeholder: string;
  @Input() searchField: string;
  @Input() groupBy: string;
  @Input() emitOnWrite: boolean;
  @Input() checkbox = false;
  @Input() comparer: (item) => boolean;

  @Output() itemChange = new EventEmitter<any>();
  @Output() blur = new EventEmitter<any>();

  loading: boolean;
  form: FormGroup;
  control: AbstractControl;

  private disabledByForm: boolean;

  @ContentChild(SelectLabelDirective, { static: false }) labelTemplate: SelectLabelDirective;
  @ContentChild(SelectOptionDirective, { static: false }) optionTemplate: SelectOptionDirective;

  @ViewChild('select', { static: false }) private select: NgSelectComponent;

  private propagateChange = (val: any) => {
  };
  private touchChange = () => {
  };

  get selected() {
    return this.form.controls.selected.value;
  }

  searchFn = (term: string, item: any) => {
    const regex = new RegExp(this.fn.removeAccents(term.trim()).replaceAll(' ', '.*.'));
    return !!this.fn.removeAccents(item[this.searchField || this.displayField]).match(regex);
  };

  writeValue(value: any) {
    if (this.multi) {
      if (value == null) {
        this.form.patchValue({ selected: [], last: [] }, { emitEvent: false });
        return;
      }
      let selected = [];
      if (this.options) selected = this.options.filter(o => value.includes(o[this.valueField]));
      this.form.patchValue({ selected: selected, last: value }, { emitEvent: false });
    } else {
      if (value == null) {
        this.form.patchValue({ selected: null, last: null }, { emitEvent: false });
        return;
      }
      let selected = null;
      if (this.options) selected = this.options.find(o => o[this.valueField] === value);
      this.form.patchValue({ selected: selected, last: value }, { emitEvent: false });

      if (this.emitOnWrite) this.itemChange.emit(selected);
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.touchChange = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabledByForm = isDisabled;
    if (isDisabled) {
      this.disable();
    } else {
      this.enable();
    }
  }

  private disable() {
    this.form.controls.selected.disable({ emitEvent: false });
  }

  private enable() {
    if (this.loading || this.isDisabled || this.disabledByForm) return;
    this.form.controls.selected.enable({ emitEvent: false });
  }

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private cc: ControlContainer,
    private fn: FunctionsService,
    fb: FormBuilder
  ) {
    this.form = fb.group({ selected: [null], last: [null] });

    this.form.controls.selected.valueChanges.subscribe(e => {
      if (this.multi) {
        const value = e.map(o => o[this.valueField]);
        this.form.controls.last.setValue(value);
        this.propagateChange(value);
        this.itemChange.emit(e);
      } else {
        if (!e) {
          this.form.controls.last.setValue(null);
          this.propagateChange(null);
          this.itemChange.emit(null);
          return;
        }

        const value = e[this.valueField];
        if (value === this.form.controls.last.value) return;

        this.form.controls.last.setValue(value);
        this.propagateChange(value);
        this.itemChange.emit(e);
      }
    });
  }

  private setValue() {
    const value = this.form.controls.last.value;
    if (!value) return;

    let selected = null;

    if (this.multi) {
      selected = this.options.filter(o => value.includes(o[this.valueField]));
    } else {
      selected = this.options.find(o => o[this.valueField] === value);
    }

    this.form.patchValue({ selected: selected, last: value }, { emitEvent: false });

    if (this.emitOnWrite) this.itemChange.emit(selected);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.setValue();
    }

    if (changes.disabled || changes.isDisabled) {
      if (this.isDisabled) {
        this.disable();
      } else {
        this.enable();
      }
    }
  }

  setOptions(options: any[]) {
    if (this.comparer && options) {
      options = options.filter(o => this.comparer(o));
    }

    this.options = options;
  }

  ngOnInit() {
    if (this.formControlName) this.control = this.cc.control.get(this.formControlName);

    if (this.selectOptions) {
      this.setOptions(this.selectOptions.options);

      this.selectOptions.changed.subscribe(options => {
        this.setOptions(options);
        this.setValue();
      });

      this.selectOptions.loading.subscribe(value => {
        if (value == null) return;
        this.loading = value;

        if (value) {
          this.disable();
        } else {
          this.enable();
        }
      });
    }
  }

  focus() {
    this.select.focus();
  }

  innerBlur() {
    this.touchChange();
    if (this.control) this.control.updateValueAndValidity();
    this.blur.emit();
  }

  getItem() {
    return this.form.controls.selected.value;
  }
}
