import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectLookupFn } from 'app/modules/shared/select';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum PhoneType {
  Home = 1,
  Office = 2,
  Mobile = 3,
}

export enum ProviderReportType {
  MasterData = 1,
}

@Injectable()
export class ProviderService {
  public static URL = environment.URL.API + 'provider';
  public static URL2 = environment.URL.API2 + 'provider';
  phoneType = [
    { id: PhoneType.Home, key: 'phone.phoneType.home', name: '' },
    { id: PhoneType.Office, key: 'phone.phoneType.office', name: '' },
    { id: PhoneType.Mobile, key: 'phone.phoneType.mobile', name: '' },
  ];

  reportTypes = [{ id: ProviderReportType.MasterData, key: 'provider.masterData', name: '', permission: 'provider_master_data' }];

  constructor(private http: HttpService, private fn: FunctionsService, translate: TranslateService) {
    fn.refreshListPermissions(this.reportTypes, array => (this.reportTypes = array));
    const lists = {
      phoneType: this.phoneType,
      reportTypes: this.reportTypes,
    };
    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  getType(type: PhoneType) {
    return this.phoneType.find(t => t.id === type);
  }

  /**
   * List Providers
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ProviderService.URL2}?${params}`, httpRequestHandler);
  }

  /**
   * Get Provider
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ProviderService.URL2}/${id}`, httpRequestHandler);
  }

  /**
   * Save Provider
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(ProviderService.URL2, data, httpRequestHandler);
  }

  /**
   * Delete Provider
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ProviderService.URL2}/${id}`, httpRequestHandler);
  }

  /**
   * List Provider
   * @param httpRequestHandler HttpRequestHandler
   */
  list: SelectLookupFn = data => {
    return this.http.observablePost(`${ProviderService.URL2}/list`, data, { mapFn: res => res.providers });
  };

  /**
   * Master data excel report
   */
  masterData(httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${ProviderService.URL2}/master-data/`, httpRequestHandler);
  }
}
