import { SignalRService } from '../util';

export class BaseBroadcast {
  private group: string;
  private events: Record<string, (data) => void>;
  private key: any;

  constructor(private signalRService: SignalRService, group: string) {
    this.group = group;
  }

  public subscribe(key?: any) {
    this.key = key;
    this.signalRService.subscribe(this.group + (this.key || ''), this.events);
  }

  public unsubscribe() {
    this.signalRService.unsubscribe(this.group + (this.key || ''), this.events);
  }

  protected setEvents(events: Record<string, (data) => void>) {
    this.events = events;
  }
}
