import { Injectable } from '@angular/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';

export enum EnrollExtraPlanStatus {
  Generated = 1,
  TransportRequested = 2,
  TransportApproved = 3,
  Created = 4,
  Notified = 5,
  TransportRejected = 6,
  Disabled,
  CreatedAndRequestingTransport = 8,
  CreatedAndTransportApproved = 9,
  CreatedAndTransportRejected = 10,
  Finished = 11
}

@Injectable()
export class ExtracurricularPlanService {
  public static URL = environment.URL.API2 + 'extracurricular-price';

  statuses = [
    { id: EnrollExtraPlanStatus.Generated, key: 'studentExtracurricular.status.generated', name: '', class: 'label label-default' },
    { id: EnrollExtraPlanStatus.TransportRequested, key: 'studentExtracurricular.status.transportRequested', name: '', class: 'label label-info' },
    { id: EnrollExtraPlanStatus.TransportApproved, key: 'studentExtracurricular.status.transportApproved', name: '', class: 'label label-info' },
    { id: EnrollExtraPlanStatus.Created, key: 'studentExtracurricular.status.created', name: '', class: 'label label-primary' },
    { id: EnrollExtraPlanStatus.Notified, key: 'studentExtracurricular.status.notified', name: '', class: 'label label-success' },
    { id: EnrollExtraPlanStatus.TransportRejected, key: 'studentExtracurricular.status.transportRejected', name: '', class: 'label label-success' },
    { id: EnrollExtraPlanStatus.Disabled, key: 'studentExtracurricular.status.disabled', name: '', class: 'label label-success' },
    {
      id: EnrollExtraPlanStatus.CreatedAndRequestingTransport,
      key: 'studentExtracurricular.status.createdAndRequestingTransport',
      name: '',
      class: 'label label-success'
    },
    {
      id: EnrollExtraPlanStatus.CreatedAndTransportApproved,
      key: 'studentExtracurricular.status.createdAndTransportApproved',
      name: '',
      class: 'label label-success'
    },
    {
      id: EnrollExtraPlanStatus.CreatedAndTransportRejected,
      key: 'studentExtracurricular.status.createdAndTransportRejected',
      name: '',
      class: 'label label-success'
    },
    {
      id: EnrollExtraPlanStatus.Finished,
      key: 'studentExtracurricular.status.finished',
      name: '',
      class: 'label label-success'
    }
  ];

  constructor(private http: HttpService, private fn: FunctionsService, translate: TranslateService) {
    const lists = {
      statuses: this.statuses
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  getStatus(status: EnrollExtraPlanStatus) {
    return this.statuses.find(s => s.id === status);
  }

  /**
   * List Extracurricular Plans
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtracurricularPlanService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get Extracurricular Plan
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtracurricularPlanService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Extracurricular Plan
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(ExtracurricularPlanService.URL, data, httpRequestHandler);
  }

  /**
   * Delete Extracurricular Plan
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ExtracurricularPlanService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List Extracurricular Plans for select component
   */
  list = () => {
    return this.http.observableGet(`${ExtracurricularPlanService.URL}/list`, { mapFn: res => res.extracurricularPlans });
  };
}
