import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'lineBreaker' })
export class LineBreakerPipe implements PipeTransform {
  constructor() {}

  transform(value: string): string {
    return value ? value.replace(/(?:\r\n|\r|\n)/g, '<br>') : null;
  }
}
