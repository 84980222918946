import { Component, Input, OnInit } from '@angular/core';
import { IPagerFilter, PagerService } from 'app/services/util';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-pager-checkbox-filter',
  templateUrl: './pager-checkbox-filter.component.html',
  styleUrls: ['./pager-checkbox-filter.component.scss'],
})
export class PagerCheckboxFilterComponent implements OnInit, IPagerFilter {
  @Input() name: string;
  @Input() default: boolean;
  @Input() label: string;

  checked: boolean;
  value: Subject<any> = new Subject();

  constructor(private pager: PagerService) {}

  ngOnInit() {
    this.pager.filterInitiated(this);
  }

  valueReady(value) {
    this.checked = value === 'true';
  }

  next(e) {
    this.value.next(e);
  }
}
