import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum NursingConsultationReportType {
  StudentMedicalInformation = 1,
  HistoricalNursingConsultation = 2,
}

export enum PersonTypes {
  Student = 1,
  Employee = 2,
}

@Injectable()
export class NursingConsultationService {
  public static URL = environment.URL.API + 'nursing-consultation';

  reportTypes = [
    { id: NursingConsultationReportType.StudentMedicalInformation, name: '', key: 'nursingConsultation.reports.studentMedicalInformation' },
    { id: NursingConsultationReportType.HistoricalNursingConsultation, name: '', key: 'nursingConsultation.reports.NursingConsultationHistory' },
  ];

  personTypes = [
    { id: PersonTypes.Employee, name: '', key: 'nursingConsultation.personType.employee' },
    { id: PersonTypes.Student, name: '', key: 'nursingConsultation.personType.student' },
  ];

  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    const lists = { reportTypes: this.reportTypes, personTypes: this.personTypes };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  getType(person: PersonTypes) {
    return this.personTypes.find(p => p.id === person);
  }

  /**
   * List nursing consultations active from people
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${NursingConsultationService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get nursing consultation active from a person
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${NursingConsultationService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get nursing consultation historical from a person
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  personHistorical(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${NursingConsultationService.URL}/historical/${id}`, httpRequestHandler);
  }

  /**
   * Save nursing consultation from a person
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(NursingConsultationService.URL, data, httpRequestHandler);
  }

  /**
   * Delete nursing consultation from a person
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${NursingConsultationService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List nursing consultations
   * @param httpRequestHandler HttpRequestHandler
   */
  list = () => {
    return this.http.observableGet(`${NursingConsultationService.URL}/list`, { mapFn: res => res.consultations });
  };

  /**
   * List historical nursing consultations
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  historical(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${NursingConsultationService.URL}/historical?${params}`, httpRequestHandler);
  }

  /**
   * Check if the person is already in a nursing consultation
   *
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  checkPerson(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${NursingConsultationService.URL}/check-person`, data, httpRequestHandler);
  }

  /**
   * Generate report
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  generateReport(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${NursingConsultationService.URL}/reports`, data, httpRequestHandler);
  }
}
