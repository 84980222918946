import { Component, ElementRef, EventEmitter, forwardRef, Host, Input, OnInit, Optional, Output, SkipSelf, ViewChild } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

@Component({
  selector: 'app-input-percentage',
  templateUrl: './input-percentage.component.html',
  styleUrls: ['./input-percentage.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputPercentageComponent)
    }
  ]
})
export class InputPercentageComponent implements OnInit, ControlValueAccessor {
  @Input() formControlName: string;
  @Input() addon = true;
  @Input() readonly = false;

  @Output() valueChange = new EventEmitter<any>();

  value: string;
  isDisabled: boolean;
  control: AbstractControl;
  percentMask = createNumberMask({
    prefix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    decimalSymbol: ',',
    allowDecimal: true,
    allowNegative: false,
    allowLeadingZeroes: false
  });
  input: ElementRef;

  propagateChange = (val: any) => {
  };
  touchChange = () => {
  };

  @ViewChild('input', { static: false })
  set inputContent(value: ElementRef) {
    this.input = value;
  }

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer
  ) {
  }

  ngOnInit() {
    if (this.formControlName) this.control = this.controlContainer.control.get(this.formControlName);
  }

  writeValue(value: any) {
    this.value = value ? value.toString().replace('.', ',') : value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.touchChange = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  blur() {
    this.touchChange();
    this.normalizeValue();
    if (this.control) this.control.updateValueAndValidity();
  }

  onModelChange(e) {
    if (this.value != null && this.value !== '') {
      let val: string = this.value.replaceAll('.', '');
      val = val.replaceAll(',', '.');
      this.propagateChange(parseFloat(val));
    } else {
      this.propagateChange(null);
    }

    this.valueChange.emit(this.value);
  }

  normalizeValue() {
    if (!this.value) return;
    if (this.value.toString().indexOf(',') === this.value.length - 1) this.value += '00';
  }

  handleKeyboardEvent(event: KeyboardEvent) {
    const target = <any>event.target;
    if (event.key === '.') {
      if (target.selectionStart === 0 && target.value === '') {
        target.value = '0,';
      } else {
        const temp = target.selectionStart;
        target.value = target.value.slice(0, temp) + ',' + target.value.slice(temp);
      }
    }
  }

  focus() {
    this.input.nativeElement.focus();
  }
}
