import {
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Host,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild
} from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

import { SelectLabelDirective, SelectLookupFn, SelectOptionDirective } from '../utils';

@Component({
  selector: 'app-select-async',
  templateUrl: './select-async.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectAsyncComponent)
    }
  ]
})
export class SelectAsyncComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() valueField = 'id';
  @Input() displayField = 'name';
  @Input() lookupFn: SelectLookupFn;
  @Input() data: any;
  @Input() disabled: boolean;
  @Input() appendTo = 'body';
  @Input() formControlName: string;
  @Input() multi = false;

  @Output() itemChange = new EventEmitter<any>();
  @Output() firstChange = new EventEmitter<any>();
  @Output() blur = new EventEmitter<any>();

  items: any[];

  form: FormGroup;
  options: Observable<any[]>;
  loading = false;
  text = new Subject<string>();
  control: AbstractControl;
  subs = new Subscription();

  @ContentChild(SelectLabelDirective, { static: false }) labelTemplate: SelectLabelDirective;
  @ContentChild(SelectOptionDirective, { static: false }) optionTemplate: SelectOptionDirective;

  @ViewChild('select', { static: false }) private select: NgSelectComponent;

  private propagateChange = (val: any) => {
  };
  private touchChange = () => {
  };

  writeValue(value: any) {
    if (value == null) {
      if (this.multi) {
        this.form.patchValue({ selected: [], last: [] }, { emitEvent: false });
      } else {
        this.form.patchValue({ selected: null, last: null }, { emitEvent: false });
      }
      return;
    }

    this.loading = true;
    this.form.controls.selected.disable({ emitEvent: false });
    this.subs.add(
      this.lookupFn({ initial: value, data: this.data })
        .pipe(
          catchError(() => of(null)),
          tap(() => {
            this.loading = false;
            if (!this.disabled) this.form.controls.selected.enable({ emitEvent: false });
          })
        )
        .subscribe(res => {
          this.form.patchValue({ selected: res, last: value }, { emitEvent: false });
          this.firstChange.emit(res);
        })
    );
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.touchChange = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.form.controls.selected.disable({ emitEvent: false });
    } else if (!this.loading) this.form.controls.selected.enable({ emitEvent: false });
  }

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private cc: ControlContainer,
    fb: FormBuilder
  ) {
    this.form = fb.group({ selected: [null], last: [null] });

    this.form.controls.selected.valueChanges.subscribe(e => {
      if (this.multi) {
        const value = e.map(o => o[this.valueField]);
        this.form.controls.last.setValue(value);
        this.propagateChange(value);
        this.itemChange.emit(e);
      } else {
        if (!e) {
          this.form.controls.last.setValue(null);
          this.propagateChange(null);
          this.itemChange.emit(null);
          return;
        }

        const value = e[this.valueField];
        if (value === this.form.controls.last.value) return;

        this.form.controls.last.setValue(value);
        this.propagateChange(value);
        this.itemChange.emit(e);
      }
    });
  }

  ngOnInit() {
    if (this.formControlName) this.control = this.cc.control.get(this.formControlName);

    this.options = this.text.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => (this.loading = true)),
      switchMap(term => {
        if (!term) {
          this.loading = false;
          return of([]);
        } else {
          return this.lookupFn({ q: term, data: this.data }).pipe(
            catchError(() => of([])),
            tap(() => (this.loading = false))
          );
        }
      })
    );
  }

  focus() {
    this.select.focus();
  }

  innerBlur() {
    this.touchChange();
    if (this.control) this.control.updateValueAndValidity();
    this.blur.emit();
  }

  clearOptions() {
    this.text.next('');
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
