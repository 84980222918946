import { Component, EventEmitter, forwardRef, Host, Input, OnInit, Optional, Output, SkipSelf } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FunctionsService } from 'app/services/util';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CheckboxComponent)
    }
  ]
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {
  @Input() label;
  @Input() formControlName: string;
  @Input() isDisabled = false;

  @Output() valueChange = new EventEmitter<any>();

  control: AbstractControl;
  value: any;
  inputId: string;
  private propagateChange = (val: any) => {};
  private touchChange = () => {};

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private cc: ControlContainer,
    fn: FunctionsService
  ) {
    this.inputId = 'chk' + fn.randomId() + fn.randomId();
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.touchChange = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  ngOnInit() {
    if (this.formControlName) this.control = this.cc.control.get(this.formControlName);
  }

  blur() {
    this.touchChange();
    if (this.control) this.control.updateValueAndValidity();
  }

  change(e) {
    this.propagateChange(e);
    this.valueChange.emit(e);
  }
}
