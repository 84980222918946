import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class UserManualService {
  public static URL = environment.URL.API2 + 'user-manual';

  constructor(private http: HttpService) {
  }

  /**
   * List user manuals
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${UserManualService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get user manual
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${UserManualService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save user manual
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(UserManualService.URL, data, httpRequestHandler);
  }

  /**
   * Delete user manual
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${UserManualService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Download user manual
   */
  download(key: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${UserManualService.URL}/${key}/download`, httpRequestHandler);
  }
}
