import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum ActionType {
  Main = 1,
  Config = 2
}

@Injectable()
export class ActionService {
  public static URL = environment.URL.API + 'action';

  actionTypes = [
    { id: ActionType.Main, key: 'action.main', name: '' },
    { id: ActionType.Config, key: 'action.config', name: '' }
  ];

  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    const lists = { actionTypes: this.actionTypes };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  getType(type: ActionType) {
    return this.actionTypes.find(at => at.id === type);
  }

  /**
   * List of Actions
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(ActionService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Delete Action
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ActionService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Edit Action
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ActionService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Action
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ActionService.URL}`, data, httpRequestHandler);
  }
}
