import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class CountryService {
  public static BASE_URL_API = environment.URL.API + 'country';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de paises
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(CountryService.BASE_URL_API + '?' + params, httpRequestHandler);
  }

  /**
   * Guardar país
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(CountryService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Eliminar país
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${CountryService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Obtener país
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${CountryService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Listao de paises
   */
  list = () => {
    return this.http.observableGet(`${CountryService.BASE_URL_API}/list`, { mapFn: res => res.countries });
  };
}
