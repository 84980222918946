import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class RejectionReasonService {
  public static BASE_URL_API = environment.URL.API + 'rejection-reason';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de Medios de Rechazo
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(RejectionReasonService.BASE_URL_API + '?' + params, httpRequestHandler);
  }

  /**
   * Agregar Medio de Rechazo
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(RejectionReasonService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Eliminar Medio de Rechazo
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${RejectionReasonService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Editar Medio de Rechazo
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${RejectionReasonService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar Medio de Rechazo
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${RejectionReasonService.BASE_URL_API}/${id}`, data, httpRequestHandler);
  }

  /**
   * Listado para combo de Medios de Rechazo
   */
  list = () => {
    return this.http.observableGet(`${RejectionReasonService.BASE_URL_API}/list`, { mapFn: res => res.rejectionReasons });
  };
}
