import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum SportStudentReportType {
  StudentsList = 1
}

@Injectable()
export class SportStudentService {
  public static URL = environment.URL.API + 'sport-student';

  reportTypes = [{ id: SportStudentReportType.StudentsList, key: 'sportStudent.studentsList', name: '' }];

  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    const lists = { reportTypes: this.reportTypes };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * List sport students
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${SportStudentService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get student sport
   * @param enrollmentId number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(enrollmentId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${SportStudentService.URL}/${enrollmentId}`, httpRequestHandler);
  }

  /**
   * Save student sport
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${SportStudentService.URL}`, data, httpRequestHandler);
  }

  /**
   * Generate report
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  generateReport(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${SportStudentService.URL}/reports`, data, httpRequestHandler);
  }
}
