import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class CompDiscountService {
  public static URL = environment.URL.API + 'comp-discount';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de descuentos complementarios
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(CompDiscountService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Guardar descuento complementario
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(CompDiscountService.URL, data, httpRequestHandler);
  }

  /**
   * Eliminar descuento complementario
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${CompDiscountService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Editar descuento complementario
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${CompDiscountService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Listado de descuentos complementarios para combo
   */
  fromYear = data => {
    return this.http.observableGet(`${CompDiscountService.URL}/list/year/${data.yearId}`, { mapFn: res => res.compDiscounts });
  };
}
