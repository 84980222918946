<input
  #input
  type="text"
  class="form-control form-control-{{ size }} {{ inputClass }}"
  [(ngModel)]="value"
  (ngModelChange)="change($event)"
  [textMask]="numberMask"
  [disabled]="disabled"
  [readOnly]="readonly"
  (blur)="onBlur()"
  (focus)="onFocus.emit()"
  (keypress)="onKeypress($event)"
/>
