<div class="input-group input-group-sm">
  <input
    class="form-control"
    placeholder="DD-MM-YYYY"
    [(ngModel)]="date"
    ngbDatepicker
    #dp="ngbDatepicker"
    (ngModelChange)="valueChange(date)"
    (focus)="touchChange()"
    [disabled]="disabled"
    [firstDayOfWeek]="7"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [markDisabled]="validDay"
    (blur)="blur()"
    container="body"
  />
  <div class="input-group-append">
    <button class="btn btn-{{ disabled ? 'default' : 'primary' }}" (click)="toggle()" type="button">
      <i class="fa fa-calendar mr-0"></i>
    </button>
  </div>
</div>
