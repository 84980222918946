import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class JobTypeService {
  public static BASE_URL_API = environment.URL.API + 'job-type';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de Tipos de Trabajo
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(JobTypeService.BASE_URL_API + '?' + params, httpRequestHandler);
  }

  /**
   * Agregar Tipo de Trabajo
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(JobTypeService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Eliminar Tipo de Trabajo
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${JobTypeService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Editar Tipo de Trabajo
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${JobTypeService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar Tipo de Trabajo
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${JobTypeService.BASE_URL_API}/${id}`, data, httpRequestHandler);
  }

  /**
   * Listado de Tipos de Trabajo para combo.
   */
  list = () => {
    return this.http.observableGet(`${JobTypeService.BASE_URL_API}/list`, { mapFn: res => res.jobTypes });
  };
}
