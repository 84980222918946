import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';

import { FunctionsService, HttpRequestHandler, HttpService } from '../../util';

export enum LibraryItemFieldType {
  Text,
  Checkbox,
  Textarea,
}

@Injectable()
export class LibraryItemTypeService {
  public static URL = environment.URL.API2 + 'library-item-type';

  itemFieldTypes = [
    { id: LibraryItemFieldType.Text, name: '', key: 'libraryItemType.text' },
    { id: LibraryItemFieldType.Checkbox, name: '', key: 'libraryItemType.checkbox' },
    { id: LibraryItemFieldType.Textarea, name: '', key: 'libraryItemType.textarea' },
  ];

  /**
   * Empty constructor
   */
  constructor(private http: HttpService, fn: FunctionsService, translate: TranslateService) {
    const lists = { itemFieldTypes: this.itemFieldTypes };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * List library item types
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(LibraryItemTypeService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Save library item type
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(LibraryItemTypeService.URL, data, httpRequestHandler);
  }

  /**
   * Delete library item type
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${LibraryItemTypeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get library item type
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${LibraryItemTypeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List library item types
   * @param httpRequestHandler HttpRequestHandler
   */
  list(httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${LibraryItemTypeService.URL}/list`, httpRequestHandler);
  }

  /**
   * List library item types
   * @param id number
   */
  listFields = id => {
    return this.http.observableGet(`${LibraryItemTypeService.URL}/list-fields/${id}`, { mapFn: res => res.fields });
  };
}
