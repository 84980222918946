import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class PromotionService {
  public static URL = environment.URL.API + 'promotion';

  constructor(private http: HttpService) {}

  /**
   * List promotions
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PromotionService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get student data
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  getStudentData(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PromotionService.URL}/data/${id}`, httpRequestHandler);
  }

  /**
   * Save
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PromotionService.URL}`, data, httpRequestHandler);
  }
}
