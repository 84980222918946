import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum VoucherConceptType {
  Eps = 1,
  PensionFund = 2,
}

@Injectable()
export class VoucherConceptService {
  public static URL = environment.URL.API2 + 'voucher-concept';

  voucherConceptTypes = [
    { id: VoucherConceptType.Eps, name: '', key: 'eps.msgTitle' },
    { id: VoucherConceptType.PensionFund, name: '', key: 'pensionFund.msgTitle' },
  ];

  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    const lists = {
      voucherConceptTypes: this.voucherConceptTypes,
    };
    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * Index vouchers
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${VoucherConceptService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get voucher
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${VoucherConceptService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save voucher
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(VoucherConceptService.URL, data, httpRequestHandler);
  }

  /**
   * Delete voucher
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${VoucherConceptService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List vouchers for select component
   */
  list = () => {
    return this.http.observableGet(`${VoucherConceptService.URL}/list`, { mapFn: res => res.voucher });
  };
}
