import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class StudentAcademicService {
  public static URL = environment.URL.API + 'student-academic';

  constructor(private http: HttpService) {}

  /**
   * Listado de evaluaciones de estudiante actual
   */
  assessments(yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${StudentAcademicService.URL}/assessments/${yearId}`, httpRequestHandler);
  }

  /**
   * Consultar el id de la matrícula para el año lectivo solicitado
   */
  getEnrollmentId(studentId: number, yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${StudentAcademicService.URL}/${studentId}/enrollment/${yearId}`, httpRequestHandler);
  }
}
