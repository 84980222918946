import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { AcademicYearService } from 'app/services/api';
import { Mod, SessionService } from 'app/services/util';

import { SelectOptions } from '../../select/utils';

@Component({
  selector: 'app-select-year',
  templateUrl: './select-year.component.html',
  styleUrls: ['./select-year.component.scss'],
  providers: [AcademicYearService]
})
export class SelectYearComponent implements OnInit {
  items = new SelectOptions(this.yearService.list);

  @Input() form: FormGroup;
  @Input() field: string;
  @Input() mod: Mod;
  @Input() clearable = true;
  @Input() isDisabled = false;
  @Input() appendTo = 'body';
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  control: AbstractControl;
  selected: any;

  constructor(private yearService: AcademicYearService, private sessionService: SessionService) {
    this.items.fetch({
      onLoaded: () => {
        if (this.mod === Mod.Create) {
          const year = this.sessionService.user.yearId;
          if (year) {
            this.form.controls[this.field].setValue(year);
            this.valueChange.emit(year);
          }
        }
      }
    });
  }

  ngOnInit() {
  }
}
