<div class="card">
  <app-dimmer [loading]="loading"></app-dimmer>
  <div class="card-body">
    <h5 class="text-body">
      1. ¿Qué es PlacetoPay?
    </h5>
    <p class="text-justify">
      PlacetoPay es la plataforma de pagos electrónicos que usa EL COLEGIO BENNETT para procesar en línea las transacciones generadas en BENNETTSOFT
      con las formas de pago habilitadas para tal fin.
      <br />
    </p>

    <h5 class="text-body">
      2. ¿Cómo puedo pagar?
    </h5>
    <p class="text-justify">
      En BENNETTSOFT de EL COLEGIO BENNETT usted podrá realizar su pago con los medios habilitados para tal fin. Usted, de acuerdo a las opciones de
      pago escogidas por el comercio, podrá pagar a través de PSE (débitos desde cuentas de ahorros y corrientes en Colombia) y tarjetas de crédito
      Visa, MasterCard, American Express, Credencial y Diners.
    </p>
    <h5 class="text-body">
      3. ¿Es seguro ingresar mis datos bancarios en este sitio web?
    </h5>
    <p class="text-justify">
      Para proteger tus datos EL COLEGIO BENNETT delega en PlacetoPay la captura de la información sensible. Nuestra plataforma de pagos cumple con
      los más altos estándares exigidos por la norma internacional PCI DSS de seguridad en transacciones con tarjeta de crédito. Además tiene
      certificado de seguridad SSL expedido por GeoTrust una compañía Verisign, el cual garantiza comunicaciones seguras mediante la encriptación de
      todos los datos hacia y desde el sitio; de esta manera te podrás sentir seguro a la hora de ingresar la información de su tarjeta.
      <br />
      Durante el proceso de pago, en el navegador se muestra el nombre de la organización autenticada, la autoridad que lo certifica y la barra de
      dirección cambia a color verde. Estas características son visibles de inmediato y dan garantía y confianza para completar la transacción en
      PlacetoPay.
      <br />
      PlacetoPay también cuenta con el monitoreo constante de McAfee Secure y la firma de mensajes electrónicos con Certicámara.
    </p>
    <h5 class="text-body">
      4. ¿Puedo realizar el pago cualquier día y a cualquier hora?
    </h5>
    <p class="text-justify">
      Sí, en EL COLEGIO BENNETT podrás realizar tus pagos en línea los 7 días de la semana, las 24 horas del día a sólo un clic de distancia.
    </p>
    <h5 class="text-body">
      5. ¿Puedo cambiar la forma de pago?
    </h5>
    <p class="text-justify">
      Si aún no has finalizado tu pago, podrás volver al paso inicial y elegir la forma de pago que prefieras. Una vez finalizada la compra no es
      posible cambiar la forma de pago.
      <br />
      <!-- <b>
        ESTABLECIMIENTO DE COMERCIO: el punto anterior aplica a la forma de pago, pero deberán mencionar las políticas de devolución que tenga
        BENNETTSOFT para dar cumplimiento al artículo 51 de la Ley del Estatuto del Consumidor.
      </b> -->
    </p>
    <h5 class="text-body">
      6. ¿Pagar electrónicamente tiene algún valor para mí como comprador?
    </h5>
    <p class="text-justify">
      No, los pagos electrónicos realizados a través de PlacetoPay no generan costos adicionales para el comprador.
    </p>
    <h5 class="text-body">
      7. ¿Qué debo hacer si mi transacción no concluyó?
    </h5>
    <p class="text-justify">
      En primera instancia, revisar si llegó un email de confirmación de la transacción a la cuenta de correo electrónico inscrita en el momento de
      realizar el pago, en caso de no haberlo recibido, deberás contactar a {{ placeToPayFAQInfo?.fullName }} para confirmar el estado de la
      transacción.
    </p>
    <h5 class="text-body">
      8. ¿Qué debo hacer si no recibí el comprobante de pago?
    </h5>
    <p class="text-justify">
      Por cada transacción aprobada a través de PlacetoPay, recibirás un comprobante del pago con la referencia de compra en la dirección de correo
      electrónico que indicaste al momento de pagar.
      <br />
      Si no lo recibes, podrás contactar a {{ placeToPayFAQInfo?.fullName }} o a la línea {{ placeToPayFAQInfo?.phone }} o al correo electrónico
      <a href="mailto:{{ placeToPayFAQInfo?.email }}">{{ placeToPayFAQInfo?.email }}</a
      >, para solicitar el reenvío del comprobante a la misma dirección de correo electrónico registrada al momento de pagar.
    </p>
  </div>
</div>
