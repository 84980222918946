import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AcademicYearService } from 'app/services/api';
import { Subject } from 'rxjs';

import { IPagerFilter, PagerService, SessionService } from '../../../../services/util';
import { SelectOptions } from '../../select';

@Component({
  selector: 'app-pager-select-year-column-filter',
  templateUrl: './pager-select-year-column-filter.component.html',
  providers: [AcademicYearService],
})
export class PagerSelectYearColumnFilterComponent implements OnInit, IPagerFilter {
  items = new SelectOptions(this.yearService.list);
  selected: any;
  default: any;
  value: Subject<any> = new Subject();

  @Input() name = 'year';
  @Input() withLabel: boolean;
  @Input() clearable = true;

  @Output() yearChange = new EventEmitter<any>();

  constructor(private pager: PagerService, private yearService: AcademicYearService, private sessionService: SessionService) {
    this.items.fetch();
  }

  ngOnInit() {
    this.default = this.sessionService.user.yearId;
    this.pager.filterInitiated(this);
  }

  valueReady(value) {
    if (value) this.selected = +value;
  }

  itemChange() {
    this.yearChange.emit(this.selected);
  }
}
