import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { CheckboxComponent } from './checkbox.component';
import { FocusableCheckboxDirective } from './focusable-checkbox.directive';

@NgModule({
  imports: [CommonModule, FormsModule, TranslateModule],
  declarations: [CheckboxComponent, FocusableCheckboxDirective],
  exports: [CheckboxComponent, FocusableCheckboxDirective],
})
export class CheckBoxModule {}
