import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class CompanyService {
  public static URL = environment.URL.API2 + 'company';

  constructor(private http: HttpService) {}

  /**
   * Index companies
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${CompanyService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get company
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${CompanyService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save company
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(CompanyService.URL, data, httpRequestHandler);
  }

  /**
   * Delete company
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${CompanyService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List companies for select component
   */
  list = () => {
    return this.http.observableGet(`${CompanyService.URL}/list`, { mapFn: res => res.companies });
  };
}
