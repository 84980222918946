import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum InvoiceStatus {
  Registered = 1,
  Authorized = 2,
  Delivered = 3,
  Paid = 4,
}

export enum InvoiceLogType {
  Registered = 1,
  Authorized = 2,
  Delivered = 3,
  Paid = 4,
  Undelivered = 5,
  Unauthorized = 6,
  Normal = 7,
}

export enum PaymentStatus {
  Expired = 1,
  Unexpired = 2,
}

@Injectable()
export class InvoiceService {
  public static URL = environment.URL.API2 + 'invoice';

  invoiceStatus = [
    { id: InvoiceStatus.Registered, key: 'invoice.registered', name: 'invoice.registered', class: 'label label-info' },
    { id: InvoiceStatus.Authorized, key: 'invoice.authorized', name: 'invoice.authorized', class: 'label label-primary' },
    { id: InvoiceStatus.Delivered, key: 'invoice.delivered', name: 'invoice.delivered', class: 'label bg-purple' },
    { id: InvoiceStatus.Paid, key: 'invoice.paid', name: 'invoice.paid', class: 'label label-success' },
  ];

  invoiceLogTypes = [
    { id: InvoiceLogType.Registered, key: 'invoice.trackingStatus.registered', name: '', class: 'bg-warning-light' },
    { id: InvoiceLogType.Authorized, key: 'invoice.trackingStatus.authorized', name: '', class: 'bg-primary-light' },
    { id: InvoiceLogType.Delivered, key: 'invoice.trackingStatus.delivered', name: '', class: 'bg-purple-light' },
    { id: InvoiceLogType.Paid, key: 'invoice.trackingStatus.paid', name: '', class: 'bg-success-light' },
    { id: InvoiceLogType.Undelivered, key: 'invoice.trackingStatus.undelivered', name: '', class: 'bg-primary-light' },
    { id: InvoiceLogType.Unauthorized, key: 'invoice.trackingStatus.unauthorized', name: '', class: 'bg-warning-light' },
    { id: InvoiceLogType.Normal, key: 'enroll.trackingStatus.normal', name: '', class: '' },
  ];

  paymentStatus = [
    { id: PaymentStatus.Expired, key: 'invoice.expired', name: 'invoice.expired', class: 'label label-info' },
    { id: PaymentStatus.Unexpired, key: 'invoice.unexpired', name: 'invoice.unexpired', class: 'label label-primary' },
  ];

  constructor(private http: HttpService, private fn: FunctionsService, translate: TranslateService) {
    const lists = {
      invoiceStatus: this.invoiceStatus,
      invoiceLogTypes: this.invoiceLogTypes,
      paymentStatus: this.paymentStatus,
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  getType(type: InvoiceStatus) {
    return this.invoiceStatus.find(t => t.id === type);
  }

  getStatus(status: InvoiceStatus) {
    return this.invoiceStatus.find(s => s.id === status);
  }

  getPaymentStatus(paymentStatus: PaymentStatus) {
    return this.paymentStatus.find(s => s.id === paymentStatus);
  }

  /**
   * List invoices
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${InvoiceService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get invoice
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${InvoiceService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save invoice
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(InvoiceService.URL, data, httpRequestHandler);
  }

  /**
   * Save Manager Authorize
   * @param httpRequestHandler HttpRequestHandler
   */
  managerAuthorize(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${InvoiceService.URL}/manager-authorize`, data, httpRequestHandler);
  }

  /**
   * Save Account Register
   * @param httpRequestHandler HttpRequestHandler
   */
  accountRegister(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${InvoiceService.URL}/account-register`, data, httpRequestHandler);
  }

  /**
   * Pay Invoice
   * @param httpRequestHandler HttpRequestHandler
   */
  pay(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${InvoiceService.URL}/invoice-pay`, data, httpRequestHandler);
  }

  /**
   * Delete invoice
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${InvoiceService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List invoices
   * @param httpRequestHandler HttpRequestHandler
   */
  list = () => {
    return this.http.observableGet(`${InvoiceService.URL}/list`, { mapFn: res => res.Invoices });
  };

  /**
   * Get invoice Details
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  getDetails(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${InvoiceService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Datos de seguimiento de Factura
   * @param id Id de factura
   */
  tracking(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${InvoiceService.URL}/tracking/${id}`, httpRequestHandler);
  }

  /**
   * Guardar observacion de factura
   */
  saveTracking(data, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${InvoiceService.URL}/tracking`, data, httpRequestHandler);
  }
}
