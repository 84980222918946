import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalComponent } from 'app/modules/shared/modal/modal.component';
import { NotificationBroadcast } from 'app/services/broadcast';
import { environment } from 'environments/environment';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject, Subscription } from 'rxjs';

import { MenuService, NotificationService, UserService } from '../../../services/api';
import { ErrorUtil, FunctionsService, MessageType, NotifyMessageService } from '../../../services/util';
import { SessionService } from '../../../services/util/session.service';
import { GC } from '../../shared';
import { CustomValidators } from '../../shared/form';
import { LocalDt } from '../../shared/local-dt';
import { ModalSize } from 'app/modules/shared';
import { StorageService } from '../../../services/util/storage.service';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
  permission?: string;
}

export interface MainMenuItems {
  state: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
  permission?: string;
}

export interface Menu {
  name: string;
  children: MainMenuItems[];
}

export interface ILanguageItem {
  lang: string;
  flagIcon: string;
  name: string;
}

export const languages: ILanguageItem[] = [
  {
    lang: 'es',
    name: 'Español',
    flagIcon: 'flag-co.png'
  },
  {
    lang: 'en',
    name: 'English',
    flagIcon: 'flag-us.png'
  }
];

@Component({
  selector: 'app-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('mobileMenuTop', [
      state('no-block, void', style({ overflow: 'hidden', height: '0px' })),
      state('yes-block', style({ height: AUTO_STYLE })),
      transition('no-block <=> yes-block', [animate('400ms ease-in-out')])
    ])
  ],
  providers: [MenuService, NotificationService, NotificationBroadcast]
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  deviceType = 'desktop';
  verticalNavType = 'expanded';
  verticalEffect = 'shrink';
  innerHeight: string;
  isScrolled = false;
  isCollapsedMobile = 'no-block';
  toggleOn = true;
  windowWidth: number;
  languages = languages;
  language: ILanguageItem;
  notificationsListHidden = false;

  verticalLayout = 'wide';
  navPcodedHeaderPosition = 'fixed';
  sidebarPcodedNavbarPosition = 'fixed';
  pcodedMainContainerStyle: any = { 'margin-top': '56px' };
  actionId: number;

  private subs = new Subscription();

  myData = {
    permissions: new LocalDt(),
    roles: []
  };

  changePassword = {
    form: null as FormGroup,
    loading: null as boolean,
    required: null as boolean
  };

  notifications = {
    page: 1,
    loading: false
  };

  loading = {
    user: new BehaviorSubject<boolean>(false),
    menu: new BehaviorSubject<boolean>(false)
  };
  mainMenu: Menu[];

  @ViewChild('searchFriends', { static: false }) search_friends: ElementRef;
  @ViewChild('toggleButton', { static: false }) toggle_button: ElementRef;
  @ViewChild('sideMenu', { static: false }) side_menu: ElementRef;
  @ViewChild('myPermissionsModal', { static: false }) myPermissionsModal: ModalComponent;
  @ViewChild('changePasswordModal', { static: false }) changePasswordModal: ModalComponent;

  ModalSize = ModalSize;
  env = environment;

  constructor(
    fb: FormBuilder,
    ngxPermisisonsService: NgxPermissionsService,
    public sessionService: SessionService,
    public notify: NotifyMessageService,
    public translate: TranslateService,
    private menuService: MenuService,
    private userService: UserService,
    private fn: FunctionsService,
    private notificationService: NotificationService,
    private router: Router,
    private storageService: StorageService,
    private notificationBroadcast: NotificationBroadcast
  ) {
    if (sessionService.boxLayout) {
      this.verticalLayout = 'box';
      this.navPcodedHeaderPosition = 'relative';
      this.sidebarPcodedNavbarPosition = 'absolute';
      this.pcodedMainContainerStyle = {};
    }
    const scrollHeight = window.screen.height - 150;
    this.innerHeight = scrollHeight + 'px';
    this.windowWidth = window.innerWidth;
    this.setMenuAttributs(this.windowWidth);

    let foundLang;
    const savedLang = storageService.get(GC.LS.LANG);

    if (savedLang) foundLang = this.languages.find(item => item.lang === savedLang);
    if (foundLang) {
      this.changeLang(foundLang);
    } else {
      this.changeLang(this.languages[0]);
    }

    this.changePassword.form = fb.group({
      current: [null, [Validators.required]],
      new: [null],
      confirmation: [null]
    });
    this.changePassword.form.controls.new.setValidators([
      Validators.required,
      Validators.minLength(8),
      CustomValidators.passwordStrength,
      CustomValidators.diffField('current')
    ]);
    this.changePassword.form.controls.confirmation.setValidators([CustomValidators.equalField('new')]);
    this.changePassword.form.customErrorMessages = {
      new: { diffField: 'auth.newPasswordDiffValidation' },
      confirmation: { equalField: 'auth.passwordConfirmationValidation' }
    };

    // SI hay sidebar (NO GUEST MODE)
    if (sessionService.sidebarMenu) {
      this.subs.add(
        sessionService.userReady.subscribe(user => {
          if (!user) return;

          notificationBroadcast.subscribe(user.id);

          this.subs.add(
            notificationBroadcast.onNew.subscribe(value => {
              sessionService.notifications.plusUnseen();
              sessionService.notifications.list.unshift(value);
              notify.info('notification.new', value.content);
            })
          );
        })
      );
      this.notificationScroll();
    }

    this.subs.add(
      ngxPermisisonsService.permissions$.subscribe(value => {
        if (Object.keys(value).length > 0) this.getMainMenu();
      })
    );
  }

  ngOnInit() {
    const checkRequiredChangePassword = () => {
      if (this.sessionService.user.changePassword) this.openChangePassword(true);
    };

    if (!this.sessionService.user) {
      this.loading.user.next(true);
      this.userService.current({
        success: res => {
          this.sessionService.setUser(res.user, res.permissions, res.actions);
          checkRequiredChangePassword();
        },
        always: () => this.loading.user.next(false)
      });
    } else {
      checkRequiredChangePassword();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    if (this.sessionService.sidebarMenu) this.notificationBroadcast.unsubscribe();
  }

  markAsSeen(notification: any) {
    notification.seen = true;
    this.sessionService.notifications.minusUnseen();
    this.notificationService.markAsSeen(notification.id, {
      error: () => {
        notification.seen = false;
        this.sessionService.notifications.plusUnseen();
      }
    });
  }

  markAsSeenClick(e: Event, notification: any) {
    e.stopPropagation();
    this.markAsSeen(notification);
  }

  notificationClick(notification: any) {
    this.notificationsListHidden = true;
    setTimeout(() => {
      this.notificationsListHidden = false;
    }, 200);

    if (!notification.seen) this.markAsSeen(notification);
    if (notification.url) this.router.navigateByUrl(notification.url);
  }

  // viewAllNotifications() {
  //   this.notificationsListHidden = true;
  //   setTimeout(() => {
  //     this.notificationsListHidden = false;
  //   }, 200);

  //   this.router.navigate(['/']);
  // }

  getMainMenu() {
    this.loading.menu.next(true);
    this.menuService.index({
      success: res => {
        const handleMenu = (depth: number, nodes: any[]) => {
          return nodes.map(node => {
            const mapped: any = {};
            mapped.name = node.name;

            if (depth === 1) mapped.icon = node.icon;

            if (depth >= 1) {
              mapped.state = node.url;

              if (node.children) {
                mapped.type = 'sub';
              } else {
                mapped.type = 'link';

                if (node.permissionName) {
                  mapped.status = this.sessionService.permissions.includes(node.permissionName);
                } else {
                  mapped.status = true;
                }
              }
            }

            if (node.children) {
              mapped.children = handleMenu(depth + 1, node.children);

              if (mapped.children.length === 0) {
                mapped.status = false;
              } else {
                mapped.status = !mapped.children.every(child => child.status === false);
              }
            }

            return mapped;
          });
        };
        let mainMenuTemp = handleMenu(0, res.menu);

        const handleStatus = (items: any[]) => {
          return items.filter(item => {
            if (item.children) item.children = handleStatus(item.children);
            return item.status;
          });
        };

        mainMenuTemp = handleStatus(mainMenuTemp);
        this.mainMenu = mainMenuTemp;
      },
      always: () => this.loading.menu.next(false)
    });
  }

  actionChange(e) {
    this.router.navigate([e.url]);

    setTimeout(() => {
      this.actionId = null;
    }, 0);
  }

  changeLang(item: ILanguageItem) {
    this.language = item;
    this.translate.use(item.lang);
    this.storageService.set(GC.LS.LANG, item.lang);
    this.fn.handleLanguage(item.lang);
  }

  logout() {
    this.userService.logout({});
    this.sessionService.logout();
  }

  myPermissions() {
    this.myData.permissions.loading = true;
    this.userService.myPermissions({
      success: res => {
        this.myData.permissions.rows = res.permissions;
        this.myData.roles = res.roles;
      },
      always: () => (this.myData.permissions.loading = false)
    });
    this.myPermissionsModal.open();
  }

  myPermissionsModalClosed() {
    this.myData.permissions.rows = [];
  }

  openChangePassword(required = false) {
    this.changePassword.required = required;
    this.changePassword.form.reset();
    setTimeout(() => this.changePasswordModal.open(), 0); // esperar para que el modal tenga el nuevo valor de [closable]
  }

  submitChangePassword() {
    if (!this.changePassword.form.validate()) return;

    this.changePassword.loading = true;
    this.userService.changePassword(this.changePassword.form.value, {
      success: res => {
        this.notify.success('general.saved', 'auth.changePasswordSuccess');
        this.sessionService.user.changePassword = false;
        this.changePasswordModal.close();
      },
      errorMsgType: MessageType.None,
      error: (res, err: ErrorUtil) => {
        if (res && res.type === 'invalid_current_password') {
          this.notify.error('general.error', 'auth.invalidCurrentPassword');
          this.changePassword.form.controls.current.setValue(null);
        } else {
          err.showNotifyError();
        }
      },
      always: () => (this.changePassword.loading = false)
    });
  }

  notificationScroll() {
    this.notifications.loading = true;
    this.notificationService.index(this.notifications.page, {
      success: res => {
        this.sessionService.notifications.list = this.sessionService.notifications.list.concat(res.notifications);
        this.sessionService.unseenCounter = res.unseenCount;
        this.notifications.page++;
      },
      always: () => (this.notifications.loading = false)
    });
  }

  // template functions ---------------------------------------------------------------------

  onClickedOutside(e: Event) {
    if (this.windowWidth < 768 && this.toggleOn && this.verticalNavType !== 'offcanvas') {
      this.toggleOn = true;
      this.verticalNavType = 'offcanvas';
    }
  }

  onResize(event) {
    this.innerHeight = event.target.innerHeight + 'px';
    /* menu responsive */
    this.windowWidth = event.target.innerWidth;
    this.setMenuAttributs(this.windowWidth);
  }

  setMenuAttributs(windowWidth) {
    if (windowWidth >= 768 && windowWidth <= 1024) {
      this.deviceType = 'tablet';
      this.verticalNavType = 'collapsed';
      this.verticalEffect = 'push';
    } else if (windowWidth < 768) {
      this.deviceType = 'mobile';
      this.verticalNavType = 'offcanvas';
      this.verticalEffect = 'overlay';
    } else {
      this.deviceType = 'desktop';
      this.verticalNavType = 'expanded';
      this.verticalEffect = 'shrink';
    }
  }

  searchFriendList(event) {
    const search = this.search_friends.nativeElement.value.toLowerCase();
    let search_input: string;
    let search_parent: any;
    const friendList = document.querySelectorAll('.userlist-box .media-body .chat-header');
    Array.prototype.forEach.call(friendList, function(elements, index) {
      search_input = elements.innerHTML.toLowerCase();
      search_parent = elements.parentNode.parentNode;
      if (search_input.indexOf(search) !== -1) {
        search_parent.classList.add('show');
        search_parent.classList.remove('hide');
      } else {
        search_parent.classList.add('hide');
        search_parent.classList.remove('show');
      }
    });
  }

  toggleOpened() {
    if (this.windowWidth < 768) {
      this.toggleOn = this.verticalNavType === 'offcanvas' ? true : this.toggleOn;
      this.verticalNavType = this.verticalNavType === 'expanded' ? 'offcanvas' : 'expanded';
    } else {
      this.verticalNavType = this.verticalNavType === 'expanded' ? 'collapsed' : 'expanded';
    }
  }

  onMobileMenu() {
    this.isCollapsedMobile = this.isCollapsedMobile === 'yes-block' ? 'no-block' : 'yes-block';
  }

  onScroll(event) {
    this.isScrolled = false;
  }
}
