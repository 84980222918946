import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectLookupFn } from 'app/modules/shared/select';
import { environment } from 'environments/environment';

import { HttpRequestHandler, HttpService } from '../../util';

export enum LibraryItemCopyStatus {
  Inactive,
  Enabled,
  Borrowed,
  Reserved,
}

@Injectable()
export class LibraryItemCopyService {
  public static URL = environment.URL.API2 + 'library-item-copy';

  itemCopyStatuses = [
    { id: LibraryItemCopyStatus.Enabled, name: '', key: 'general.enabled', inForm: true, class: 'label label-primary' },
    { id: LibraryItemCopyStatus.Inactive, name: '', key: 'general.disabled', inForm: true, class: 'label label-default' },
    { id: LibraryItemCopyStatus.Borrowed, name: '', key: 'libraryItemCopy.borrowed', inForm: false, class: 'label label-warning' },
    { id: LibraryItemCopyStatus.Reserved, name: '', key: 'libraryItemCopy.reserved', inForm: false, class: 'label label-warning' },
  ];

  /**
   * Empty constructor
   * @param http HttpService
   */
  constructor(private http: HttpService, translate: TranslateService) {
    const udpateLangValues = () => {
      this.itemCopyStatuses.forEach(item => (item.name = translate.instant(item.key)));
      this.itemCopyStatuses = [...this.itemCopyStatuses];
    };
    udpateLangValues();
    translate.onLangChange.subscribe(value => udpateLangValues());
  }

  /**
   * List library item copies
   *
   * @param params string
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  index(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${LibraryItemCopyService.URL}/index/${id}`, httpRequestHandler);
  }

  /**
   * Save library item copy
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(LibraryItemCopyService.URL, data, httpRequestHandler);
  }

  /**
   * Delete library item copy
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${LibraryItemCopyService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get library item copy
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${LibraryItemCopyService.URL}/${id}`, httpRequestHandler);
  }
  /**
   * Save library item copy
   */
  saveFastMode(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${LibraryItemCopyService.URL}/fast-mode`, data, httpRequestHandler);
  }

  /**
   * Library item copy massive creation
   */
  saveMassive(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${LibraryItemCopyService.URL}/massive`, data, httpRequestHandler);
  }

  /**
   * Select autocomplete item copy
   */
  list: SelectLookupFn = data => {
    return this.http.observablePost(LibraryItemCopyService.URL + '/list', data, { mapFn: res => res.copies });
  };

  /**
   * Get the copies only with enabled status to be disabled
   */
  getDisabled(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${LibraryItemCopyService.URL}/disabled/${id}`, httpRequestHandler);
  }

  /**
   * Save the copies disabled massively
   */
  saveDisabledMassive(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${LibraryItemCopyService.URL}/disabled-massive`, data, httpRequestHandler);
  }
}
