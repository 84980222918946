import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum Day {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7,
}

@Injectable()
export class BlGroupService {
  public static URL = environment.URL.API2 + 'bl-group';

  constructor(private http: HttpService) {}

  /**
   * Index Baby Love group
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${BlGroupService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Delete Baby Love group
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${BlGroupService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get Baby Love group
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${BlGroupService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Baby Love group
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(BlGroupService.URL, data, httpRequestHandler);
  }

  list = () => {
    return this.http.observableGet(BlGroupService.URL + '/list/', { mapFn: res => res.blDiscount });
  };

  /**
   * Get Baby Love group
   */
  getSchedule(httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${BlGroupService.URL}/schedule-group-list/`, httpRequestHandler);
  }
}
