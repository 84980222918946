import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectLookupFn } from 'app/modules/shared/select';

import { environment } from '../../../../environments/environment';
import { AutocompleteTypeahead, FunctionsService, HttpRequestHandler, HttpService } from '../../util';

export enum AttendantFormerStudent {
  Yes = 1,
  No = 2,
  NotGraduated = 3,
}

@Injectable()
export class AttendantService {
  public static URL = environment.URL.API + 'attendant';

  formerStudents = [
    { id: AttendantFormerStudent.Yes, key: 'general.yes', name: '' },
    { id: AttendantFormerStudent.No, key: 'general.no', name: '' },
    { id: AttendantFormerStudent.NotGraduated, key: 'attendant.notGraduated', name: '' },
  ];

  constructor(private http: HttpService, private fn: FunctionsService, translate: TranslateService) {
    const lists = {
      formerStudents: this.formerStudents,
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * Mostrar listado de acudientes
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(AttendantService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Datos de acudiente
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AttendantService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Guardar datos de acudiente
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AttendantService.URL}/save`, data, httpRequestHandler);
  }

  /**
   * Listar acudientes y representantes financieros
   */
  listAttendantFinancialTp: SelectLookupFn = data => {
    return this.http.observablePost(`${AttendantService.URL}/list-attendant-financial-tp`, data, { mapFn: res => res.data });
  };

  /**
   * Autocompletado de cargo
   */
  autocompletePositions: AutocompleteTypeahead = (query, data) => {
    return this.http.observableGet(AttendantService.URL + '/positions?' + this.fn.objectToQueryParams({ q: query }), {
      mapFn: ({ positions }) => positions,
    });
  };
}
