import { AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { Observable, timer as observableTimer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export type CustomAsyncValidation<T> = (id: number, name: string) => Observable<T>;
export type CustomReturnValue = () => any;

export class CustomValidators {
  static asyncMapFn = res => (res.exists ? { async: true } : null);

  /**
   * Validator that performs numeric validation
   */
  static numeric(c: FormControl): ValidationErrors {
    const params = {
      numeric: {},
    };
    return c.value === '' || c.value == null || (!isNaN(parseFloat(c.value)) && isFinite(c.value)) ? null : params;
  }

  /**
   * Validator that performs integer validation. Null, undefined or empty string pass the validation
   */
  static integer(c: FormControl): ValidationErrors {
    const params = {
      integer: {},
    };
    return c.value === '' || c.value == null || (!isNaN(parseFloat(c.value)) && isFinite(c.value) && c.value % 1 === 0) ? null : params;
  }

  /**
   * Validator that performs alphabetical validation. Null, undefined or empty string pass the validation
   */
  static alpha(c: FormControl): ValidationErrors {
    const params = {
      alpha: {},
    };
    return c.value === '' || c.value == null || /^[a-zA-Z]+$/i.test(c.value) ? null : params;
  }

  /**
   * Validator that performs alphanumerical validation. Null, undefined or empty string pass the validation
   */
  static alphanumeric(c: FormControl): ValidationErrors {
    const params = {
      alphanumeric: {},
    };
    return c.value === '' || c.value == null || /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\s]+$/.test(c.value) ? null : params;
  }

  /**
   * Validator that performs email validation, for no email the validation passes
   */
  static email(c: FormControl): ValidationErrors {
    const params = {
      email: {},
    };
    return !c.value ||
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        c.value
      )
      ? null
      : params;
  }

  /**
   * Validator that performs password strength validation
   */
  static passwordStrength(c: FormControl): ValidationErrors {
    const params = {
      passwordStrength: {},
    };
    const value: string = c.value == null ? '' : c.value;
    // Regular expression, minimum one capital letter, one lowercase letter and one number
    const exp = /^(?=(?:.*\d){1})(?=(?:.*[A-Z]){1})(?=(?:.*[a-z]){1})\S{8,}$/;
    return exp.test(value) ? null : params;
  }

  /**
   * Validator that performs email (xxxxxxxxxxx@bennett.edu.co) validation, for no email the validation passes
   */
  static bennettEmail(c: FormControl): ValidationErrors {
    const params = {
      bennettEmail: {
        domain: 'bennett.edu.co',
      },
    };
    return !c.value || c.value.endsWith('@bennett.edu.co') ? null : params;
  }

  /**
   * Validator that performs email (xxxxxxxxxxx@colegiobennett.edu.co) validation, for no email the validation passes
   */
  static bennettSchoolEmail(c: FormControl): ValidationErrors {
    const params = {
      bennettEmail: {
        domain: 'colegiobennett.edu.co',
      },
    };
    return !c.value || c.value.endsWith('@colegiobennett.edu.co') ? null : params;
  }

  /**
   * Validator that performs no email (xxxxxxxxxxx@bennett.edu.co | xxxxxxxxxxx@colegiobennett.edu.co) validation, for no email the validation passes
   */
  static notBennettEmail(c: FormControl): ValidationErrors {
    const params = {
      notBennettEmail: {
        domain1: 'colegiobennett.edu.co',
        domain2: 'bennett.edu.co',
      },
    };
    return !c.value || (!c.value.endsWith(`@${params.notBennettEmail.domain1}`) && !c.value.endsWith(`@${params.notBennettEmail.domain2}`))
      ? null
      : params;
  }

  /**
   * Validator that performs money validation. Null, undefined or empty string pass the validation
   */
  static money(c: FormControl): ValidationErrors {
    const params = {
      money: {},
    };
    const val = c.value;
    if (c.value) return !c.value || /^\$?\d+(.\d{3})*(\,\d*)?$/.test(c.value) ? null : params;
  }

  /**
   * Validator that performs ISO date validation
   */
  static date(c: FormControl): ValidationErrors {
    const message = {
      date: {},
    };
    return c.value === '' || c.value == null || /^(\d{4})-(\d{1,2})-(\d{1,2})(?:\s([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9])?$/.test(c.value)
      ? null
      : message;
  }

  /**
   * Validator that performs greather than date validation against another field
   * @param form? FormGroup where the field is gonna be validated
   */
  static dateGreaterThan(field: string, form?: FormGroup, orEqual: boolean = false): ValidatorFn {
    return (c: FormControl): ValidationErrors => {
      const f = form || c.parent;

      if (f.controls[field].invalid) return null;

      const value = f.controls[field].value;
      const params = {
        dateGreaterThan: { date: value },
      };

      const date = moment(c.value, 'YYYY-MM-DD').toDate();
      const date2 = moment(value, 'YYYY-MM-DD').toDate();

      return c.value == null || (orEqual ? date >= date2 : date > date2) ? null : params;
    };
  }

  /**
   * Validator that performs greather than date validation against another field
   * @param form? FormGroup where the field is gonna be validated
   */
  static timeGreaterThan(field: string, form?: FormGroup): ValidatorFn {
    return (c: FormControl): ValidationErrors => {
      const f = form || c.parent;

      if (f.controls[field].invalid || c.invalid || !f.controls[field].value || !c.value) return null;

      const value = moment().hour(f.controls[field].value.hour).minute(f.controls[field].value.minute);

      const params = {
        timeGreaterThan: { time: value.format('hh:mm a') },
      };

      const date = moment().hour(c.value.hour).minute(c.value.minute).toDate();
      const date2 = value.toDate();

      return c.value == null || date > date2 ? null : params;
    };
  }

  /**
   * Validator that performs greather than value validation against another field
   * @param field String field name
   * @param orEqual boolean Change validation to greather or equal than
   * @param form? FormGroup where the field is gonna be validated
   */
  static numberGreaterThan(field: string, orEqual = false, form?: FormGroup): ValidatorFn {
    return (c: FormControl): ValidationErrors => {
      const f = form || c.parent;

      if (f.controls[field].invalid || c.invalid) return null;

      const value = f.controls[field].value;
      const params: any = {};
      if (orEqual) params.numberGreaterOrEqualThan = { number: value };
      else params.numberGreaterThan = { number: value };

      return c.value == null || (orEqual ? +c.value >= +value : +c.value > +value) ? null : params;
    };
  }

  /**
   * Validator that performs lower than value validation against another field
   * @param field String field name
   * @param orEqual boolean Change validation to lower or equal than
   * @param form? FormGroup where the field is gonna be validated
   */
  static numberLowerThan(field: string, orEqual = false, form?: FormGroup): ValidatorFn {
    return (c: FormControl): ValidationErrors => {
      const f = form || c.parent;

      if (f.controls[field].invalid || c.invalid) return null;

      const value = f.controls[field].value;
      const params: any = {};
      if (orEqual) params.numberLowerOrEqualThan = { number: value };
      else params.numberLowerThan = { number: value };

      return c.value == null || (orEqual ? +c.value <= +value : +c.value < +value) ? null : params;
    };
  }

  /**
   * Validator that performs minimun validation against another field
   */
  static minField(field: string): ValidatorFn {
    return (c: FormControl): ValidationErrors => {
      const sub = c.parent.controls[field].statusChanges.subscribe(val => {
        c.updateValueAndValidity();
        sub.unsubscribe();
      });

      const value = c.parent.controls[field].value;
      const params = {
        minField: {
          min: value,
        },
      };
      const parsed = parseFloat(c.value);

      return c.value === '' || (!isNaN(parsed) && isFinite(parsed) && parsed >= parseFloat(value)) ? null : params;
    };
  }

  /**
   * Validator that performs async validation
   */
  static async(value: number | Function, fn: CustomAsyncValidation<any>): AsyncValidatorFn {
    return (c: FormControl) => {
      return observableTimer(250).pipe(
        switchMap(() => {
          const val = value instanceof Function ? value.call(this) : value;
          return fn(val, c.value);
        })
      );
    };
  }

  /**
   * Validator that performs equal validation against another field
   */
  static equalField(field: string): ValidatorFn {
    return (c: FormControl): ValidationErrors => {
      const sub = c.parent.controls[field].statusChanges.subscribe(val => {
        c.updateValueAndValidity();
        sub.unsubscribe();
      });

      const value = c.parent.controls[field].value;
      const params = {
        equalField: {
          equal: value,
        },
      };

      return c.value === value ? null : params;
    };
  }

  /**
   * Validator that performs different validation against another field
   */
  static diffField(field: string): ValidatorFn {
    return (c: FormControl): ValidationErrors => {
      const sub = c.parent.controls[field].statusChanges.subscribe(val => {
        c.updateValueAndValidity();
        sub.unsubscribe();
      });

      const value = c.parent.controls[field].value;
      const params = {
        diffField: {
          diff: value,
        },
      };

      return c.value !== value ? null : params;
    };
  }

  /**
   * Validator that performs a minimum date validation
   * @param value date in ISO format
   * @param orEqual boolean
   */
  static dateGreatherThanValue(value: string, orEqual: boolean = false): ValidatorFn {
    return (c: FormControl): ValidationErrors => {
      if (c.invalid) return null;
      const params: any = {};

      if (orEqual) params.minOrEqualDate = { min: value };
      else params.minDate = { min: value };

      const date = moment(c.value, 'YYYY-MM-DD').toDate();
      const date2 = moment(value, 'YYYY-MM-DD').toDate();

      return c.value == null || (orEqual ? date >= date2 : date > date2) ? null : params;
    };
  }

  /**
   * Validator that performs a maximum date validation
   * @param value date in ISO format
   * @param orEqual boolean
   */
  static dateLowerThanValue(value: string, orEqual: boolean = false): ValidatorFn {
    return (c: FormControl): ValidationErrors => {
      if (c.invalid) return null;
      const params: any = {};
      if (orEqual) params.maxOrEqualDate = { max: value };
      else params.maxDate = { max: value };

      const date = moment(c.value, 'YYYY-MM-DD').toDate();
      const date2 = moment(value, 'YYYY-MM-DD').toDate();

      return c.value == null || (orEqual ? date <= date2 : date < date2) ? null : params;
    };
  }

  /**
   * Validator that performs regex validation with custom message
   * @param value pattern value
   * @param message new
   */
  static pattern(value: string, message: string): ValidatorFn {
    return (c: FormControl): ValidationErrors => {
      if (c.invalid) return null;
      const params: any = {};
      const regex = new RegExp(value);
      params.pattern = { value: message };
      return regex.test(c.value) ? null : params;
    };
  }
}
