import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class ProportionalEnrollService {
  public static URL = environment.URL.API + 'proportional-enroll';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de matriculas proporcionales
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(ProportionalEnrollService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Guardar matricula proporcional
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(ProportionalEnrollService.URL, data, httpRequestHandler);
  }

  /**
   * Eliminar matricula proporcional
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ProportionalEnrollService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Editar matricula proporcional
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ProportionalEnrollService.URL}/${id}`, httpRequestHandler);
  }
}
