
import {debounceTime} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { IPagerAddon, PagerService } from '../../../../services/util';

@Component({
  selector: 'app-pager-filter',
  templateUrl: './pager-filter.component.html'
})
export class PagerFilterComponent implements OnInit, IPagerAddon {
  value: Subject<any> = new Subject();

  filter: string;
  changeSubject: Subject<string> = new Subject();

  constructor(private pager: PagerService) {
    this.changeSubject.pipe(debounceTime(250)).subscribe(value => {
      this.value.next(value);
    });
  }

  ngOnInit() {
    this.pager.generalFilterInitiated(this);
  }

  valueReady(value) {
    this.filter = value;
  }
}
