import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IDashboardItem } from 'app/modules/shared/dashboard/dashboard.component';
import { ActionType } from 'app/services/api/security';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject } from 'rxjs';

import { GC } from '../../modules/shared';
import { StorageService } from './storage.service';

interface IUser {
  id: number;
  firstName: string;
  middleName: string;
  firstSurname: string;
  secondSurname: string;
  fullName: string;
  document: string;
  initials: string;
  changePassword: boolean;
  yearId: number;
  academicYearId: number;
  lastYearId: number;
  families: number[];
  isTeacher: boolean;
  sendAllGeneralMail: boolean;
}

@Injectable()
export class SessionService {
  public get token(): string {
    return this.storageService.get(GC.LS.TOKEN) || '';
  }

  actions = new BehaviorSubject<any[]>([]);

  user: IUser;
  permissions: string[];
  notifications = {
    list: [],
    plusUnseen: () => this.addOneUnseenNotification.call(this),
    minusUnseen: () => this.removeOneUnseenNotification.call(this)
  };
  boxLayout = false;
  sidebarMenu = true;
  userReady = new BehaviorSubject<IUser>(null);
  guest = {
    type: null as string,
    data: null
  };

  private _unseenCounter = 0;

  get unseenCounter() {
    return this._unseenCounter;
  }

  set unseenCounter(value: number) {
    this._unseenCounter = value;
    this.setTitleCounter();
  }

  constructor(
    private router: Router,
    private ngxPermissionsService: NgxPermissionsService,
    private titleService: Title,
    private storageService: StorageService,
    private translate: TranslateService
  ) {
    this.isGuest();

    translate.onLangChange.subscribe(() => {
      this.refreshActionsNames();
    });

    this.actions.subscribe(() => {
      this.refreshActionsNames();
    });
  }

  refreshActionsNames() {
    this.actions.value.forEach(a => {
      a.transName = this.translate.instant(a.name);
      a.transMenuName = this.translate.instant(a.menuName);
    });
  }

  addOneUnseenNotification() {
    this._unseenCounter++;
    this.setTitleCounter();
  }

  removeOneUnseenNotification() {
    this._unseenCounter--;
    this.setTitleCounter();
  }

  private setTitleCounter() {
    let title = this.titleService.getTitle();
    if (title.startsWith('(')) title = title.substr(title.indexOf(') ') + 2);

    const counterTitle = this._unseenCounter ? '(' + this._unseenCounter + ') ' : '';

    this.titleService.setTitle(counterTitle + title);
  }

  getActions(menuUrl: string, type?: ActionType): IDashboardItem[] {
    return this.actions.value
      .filter(a => {
        return a.menuUrl === menuUrl && (type === null || a.type === type);
      })
      .map(a => {
        return {
          icon: a.icon,
          title: a.name,
          desc: a.desc,
          url: a.url,
          color: a.color,
          queryParams: a.queryParams
        };
      });
  }

  /**
   * logout the current user
   */
  logout(navigate = true) {
    if (this.storageService.get(GC.LS.GUEST)) {
      this.storageService.set(GC.LS.GUEST_OLD, this.storageService.get(GC.LS.GUEST));
    }

    this.storageService.set(GC.LS.TOKEN, '');
    this.storageService.set(GC.LS.GUEST, '');
    this.notifications.list = [];
    this.unseenCounter = 0;
    this.user = undefined;
    this.guest.data = null;
    this.guest.type = null;
    this.ngxPermissionsService.flushPermissions();
    if (navigate) this.router.navigate(['/auth', 'login']);
  }

  /**
   * sets the current token
   */
  setToken(token: string, timestamp: number) {
    this.storageService.set(GC.LS.TOKEN, token);
  }

  /**
   * sets the current user and permissions
   */
  setUser(user: IUser, permissions: string[], actions: any[] = []) {
    user.initials = user.firstName.substr(0, 1) + user.firstSurname.substr(0, 1);
    user.changePassword = user.changePassword ? true : false;
    this.user = user;
    this.permissions = permissions;
    actions.forEach(a => {
      if (a.queryParams) {
        a.queryParams = JSON.parse(a.queryParams);
      }
    });
    this.actions.next(actions);
    this.ngxPermissionsService.loadPermissions(this.permissions);
    this.userReady.next(user);
  }

  /**
   * checks if there is a token stored
   */
  isGuest() {
    const guestData: any = this.storageService.get(GC.LS.GUEST);
    if (guestData) {
      this.guest = guestData;
      return true;
    }
    return false;
  }

  setGuestData(type: string, data: any) {
    this.guest.type = type;
    this.guest.data = data;
    this.storageService.set(GC.LS.GUEST, this.guest);
  }

  navigateToGuestPage() {
    switch (this.guest.type) {
      case 'admission_rating':
        this.router.navigate(['/g/rating/']);
        break;
      case 'admission_request':
        this.router.navigate(['/g/request/']);
        break;
      case 'admission_risk_authorization':
        this.router.navigate(['/g/risk-authorization/']);
        break;
      case 'admission_data_authorization':
        this.router.navigate(['/g/data-authorization/']);
        break;
    }
  }
}
