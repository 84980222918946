import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Host,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  SkipSelf,
  ViewChild
} from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FunctionsService } from 'app/services/util';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

@Component({
  selector: 'app-input-money',
  templateUrl: './input-money.component.html',
  styleUrls: ['./input-money.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputMoneyComponent)
    }
  ]
})
export class InputMoneyComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() addon = true;
  @Input() prefix = false;
  @Input() formControlName: string;
  @Input() readonly = false;
  @Input() decimals = false;
  @Input() negative = false;
  @Input() isDisabled = false;

  @Output() valueChange = new EventEmitter<number>();
  @Output() blur = new EventEmitter();

  config = {
    prefix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    decimalSymbol: ',',
    allowDecimal: this.decimals,
    allowNegative: false,
    allowLeadingZeroes: false
  };

  moneyMask: any;
  input: ElementRef;
  value: any;
  disabled: boolean;
  private control: AbstractControl;
  private propagateChange = (val: any) => {
  };
  private touchChange = () => {
  };

  @ViewChild('input', { static: false })
  set contentInput(value: ElementRef) {
    this.input = value;
  }

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
    private fn: FunctionsService
  ) {
    this.moneyMask = {
      mask: createNumberMask(this.config),
      modelClean: true
    };
  }

  ngOnInit() {
    this.config.allowDecimal = this.decimals;
    this.config.allowNegative = this.negative;
    this.moneyMask = {
      mask: createNumberMask(this.config),
      modelClean: true
    };
    if (this.formControlName) this.control = this.controlContainer.control.get(this.formControlName);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.prefix) {
      this.config.prefix = this.prefix ? '$ ' : '';
      this.moneyMask.mask = createNumberMask(this.config);
    }
  }

  writeValue(value: any) {
    if (value != null) {
      // value = value.replaceAll('.', ',');
      if (this.decimals) {
        value = this.fn.formatNumber(value);
      } else {
        value = this.fn.formatNumber(Math.trunc(value));
      }
    }
    this.value = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.touchChange = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  focus() {
    this.input.nativeElement.focus();
  }

  valueChanged(value: string) {
    if (value) {
      value = value.replace('$ ', '').replaceAll('.', '').replaceAll(',', '.');

      const parsed = parseFloat(value);
      this.propagateChange(parsed);
      this.valueChange.emit(parsed);
      return;
    }
    this.propagateChange(null);
    this.valueChange.emit(null);
  }

  onBlur() {
    this.touchChange();
    if (this.decimals) this.normalizeValue();
    if (this.control) this.control.updateValueAndValidity();
    this.blur.emit();
  }

  handleKeyboardEvent(event: KeyboardEvent) {
    const target = <any>event.target;
    if (event.key === '.') {
      if (target.selectionStart === 0 && target.value === '') {
        target.value = '0,';
      } else {
        const temp = target.selectionStart;
        target.value = target.value.slice(0, temp) + ',' + target.value.slice(temp);
      }
    }
  }

  normalizeValue() {
    if (!this.value) return;
    if (this.value.toString().indexOf(',') === this.value.length - 1) this.value += '00';
  }
}
