import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GC } from 'app/modules/shared';
import { FunctionsService, NotifyMessageService } from 'app/services/util';
import { environment } from 'environments/environment';

import { ILanguageItem, languages } from '../admin/admin-layout.component';
import { StorageService } from '../../../services/util/storage.service';

@Component({
  selector: 'app-noauth-layout',
  templateUrl: './noauth-layout.component.html',
  styleUrls: ['./noauth-layout.component.scss'],
  animations: [
    trigger('mobileMenuTop', [
      state(
        'no-block, void',
        style({
          overflow: 'hidden',
          height: '0px'
        })
      ),
      state(
        'yes-block',
        style({
          height: AUTO_STYLE
        })
      ),
      transition('no-block <=> yes-block', [animate('400ms ease-in-out')])
    ])
  ]
})
export class NoauthLayoutComponent implements OnInit {
  deviceType = 'desktop';

  innerHeight: string;
  isScrolled = false;
  isCollapsedMobile = 'no-block';
  windowWidth: number;

  languages = languages;
  language: ILanguageItem;

  env = environment;

  constructor(
    private storageService: StorageService,
    public translate: TranslateService,
    private fn: FunctionsService,
    public notify: NotifyMessageService
  ) {
    const scrollHeight = window.screen.height - 150;
    this.innerHeight = scrollHeight + 'px';
    this.windowWidth = window.innerWidth;
    this.setMenuAttributs(this.windowWidth);

    let foundLang;
    const savedLang = storageService.get(GC.LS.LANG);

    if (savedLang) foundLang = this.languages.find(item => item.lang === savedLang);
    if (foundLang) {
      this.changeLang(foundLang);
    } else {
      this.changeLang(this.languages[0]);
    }
  }

  ngOnInit() {
  }

  changeLang(item: ILanguageItem) {
    this.language = item;
    this.translate.use(item.lang);
    this.storageService.set(GC.LS.LANG, item.lang);
    this.fn.handleLanguage(item.lang);
  }

  // template functions ---------------------------------------------------------------------

  onResize(event) {
    this.innerHeight = event.target.innerHeight + 'px';
    /* menu responsive */
    this.windowWidth = event.target.innerWidth;
    this.setMenuAttributs(this.windowWidth);
  }

  setMenuAttributs(windowWidth) {
    if (windowWidth >= 768 && windowWidth <= 1024) {
      this.deviceType = 'tablet';
    } else if (windowWidth < 768) {
      this.deviceType = 'mobile';
    } else {
      this.deviceType = 'desktop';
    }
  }

  onScroll(event) {
    this.isScrolled = false;
  }

  onMobileMenu() {
    this.isCollapsedMobile = this.isCollapsedMobile === 'yes-block' ? 'no-block' : 'yes-block';
  }
}
