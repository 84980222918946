import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class GenderService {
  public static BASE_URL_API = environment.URL.API + 'gender';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de generos
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(GenderService.BASE_URL_API + '?' + params, httpRequestHandler);
  }

  /**
   * Agregar genero
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(GenderService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Eliminar genero
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${GenderService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Obtener genero
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${GenderService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar genero
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${GenderService.BASE_URL_API}/${id}`, data, httpRequestHandler);
  }

  list = () => {
    return this.http.observableGet(`${GenderService.BASE_URL_API}/list`, { mapFn: res => res.genders });
  };
}
