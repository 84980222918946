import { Pipe, PipeTransform } from '@angular/core';

import { FunctionsService } from '../services/util';

@Pipe({ name: 'formatNumber' })
export class FormatNumberPipe implements PipeTransform {
  constructor(private fn: FunctionsService) {}

  /**
   *
   * @param value Valor a convertir
   * @param trunc Cortar los decimales
   */
  transform(value: number, trunc: number | boolean = true): string {
    if (value == null) return null;
    if (trunc === true) value = Math.trunc(value);

    const values = value.toString().split('.');
    if (values.length > 1 && +values[1] === 0) values.pop();

    if (typeof trunc === 'number') {
      if (values.length === 1) values.push('');
      values[1] = values[1].substr(0, trunc).padEnd(trunc, '0');
    }

    values[0] = this.fn.formatNumber(+values[0]);

    return values.join(',');
  }
}
