import { ChangeDetectorRef, Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';

import { FunctionsService, IDtMessages } from '../../../services/util';
import { LocalDt } from './local-dt';

@Directive({
  selector: 'ngx-datatable[appLocalDt]'
})
export class LocalDatatableDirective implements OnInit {
  @Input() appLocalDt: LocalDt;
  @Input() limit = 10;
  @Input() headerHeight = 40;
  @Input() footerHeight = 40;
  @Input() columnMode = ColumnMode.flex;
  @Input() dtMessages: IDtMessages = this.fn.dtMessages;
  @Input() scrollbarH = false;

  /**
   * Evento que se dispara cada vez que se han cambiado el estado de la tabla (filtros, tamaño)
   */
  @Output() onDt = new EventEmitter();

  constructor(private host: DatatableComponent, private el: ElementRef, private fn: FunctionsService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.host.messages = this.dtMessages;
    this.host.footerHeight = this.footerHeight;
    this.host.headerHeight = this.headerHeight;
    this.host.rowHeight = -1; // 'auto'
    this.host.columnMode = this.columnMode;
    this.host.scrollbarH = this.scrollbarH;
    this.el.nativeElement.classList.add('data-table');

    this.appLocalDt.init(this.host, this.cd, this.limit, this.fn);
  }
}
