import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum AccountingDocReportType {
  Interface = 1,
  BilledPerSection = 2,
}

@Injectable()
export class AccountingDocService {
  public static URL = environment.URL.API + 'accounting-doc';

  reportTypes = [
    { id: AccountingDocReportType.Interface, key: 'accountingDoc.interface', name: '', permission: 'accounting_interface' },
    { id: AccountingDocReportType.BilledPerSection, key: 'accountingDoc.billedPerSection', name: '' },
  ];

  constructor(private http: HttpService, fn: FunctionsService, translate: TranslateService) {
    fn.refreshListPermissions(this.reportTypes, array => (this.reportTypes = array));

    const lists = { reportTypes: this.reportTypes };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * List accounting docs
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AccountingDocService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Download reports
   *
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  downloadReport(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AccountingDocService.URL}/reports`, data, httpRequestHandler);
  }

  /**
   * Detalle de documento contable
   */
  details(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AccountingDocService.URL}/${id}/details`, httpRequestHandler);
  }

  /**
   * Guardar documento contable
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(AccountingDocService.URL, data, httpRequestHandler);
  }

  /**
   * Datos de documento contable
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AccountingDocService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Eliminar documento contable
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${AccountingDocService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Print accounting doc
   *
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  print(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AccountingDocService.URL}/${id}/print`, httpRequestHandler);
  }

  /**
   * Listado de documentos a referenciar en documento contable
   */
  getDocsForReference(ftpId: number, accDocTypeId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AccountingDocService.URL}/ftp/${ftpId}/reference-docs/${accDocTypeId}`, httpRequestHandler);
  }
}
