import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class MeetingService {
  public static URL = environment.URL.API + 'meeting';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de reuniones
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(MeetingService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Detalle de reunión
   */
  detail(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${MeetingService.URL}/${id}/detail`, httpRequestHandler);
  }

  /**
   * Detalle de reunion de grupo
   */
  groupDetail(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${MeetingService.URL}/${id}/group-detail`, httpRequestHandler);
  }

  /**
   * Estudiantes del grupo de reunión seleccionada
   */
  students(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${MeetingService.URL}/${id}/students`, httpRequestHandler);
  }

  /**
   * Guardar selección de turno
   */
  selectSession(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${MeetingService.URL}/select-session`, data, httpRequestHandler);
  }

  /**
   * Liberar turno
   */
  freeSession(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${MeetingService.URL}/free-session`, data, httpRequestHandler);
  }

  /**
   * Generar correos para notificar a los padres de la reunión
   */
  generateMails(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${MeetingService.URL}/generate/${id}`, httpRequestHandler);
  }

  /**
   * Envia correos a los padres de la reunión
   */
  sendMails(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${MeetingService.URL}/send/${id}`, httpRequestHandler);
  }

  /**
   * Consulta los turnos bloqueados masivamente.
   */
  getMassiveBlockade(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${MeetingService.URL}/${id}/massive-blockade`, httpRequestHandler);
  }

  /**
   * Guardar turnos bloqueados de manera masiva.
   */
  saveMassiveBlockade(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${MeetingService.URL}/${id}/save-blockades`, data, httpRequestHandler)
  }

  /**
   * Guarda la reunión y su configuración.
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${MeetingService.URL}`, data, httpRequestHandler);
  }
}
