import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class ExpenseTypeService {
  public static BASE_URL_API = environment.URL.API + 'expense-type';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de Tipos de Egresos
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(ExpenseTypeService.BASE_URL_API + '?' + params, httpRequestHandler);
  }

  /**
   * Agregar Tipo de Egreso
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(ExpenseTypeService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Eliminar Tipo de Egreso
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ExpenseTypeService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Editar Tipo de Egreso
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExpenseTypeService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar Tipo de Egreso
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${ExpenseTypeService.BASE_URL_API}/${id}`, data, httpRequestHandler);
  }

  /**
   * Listado para combo de Tipos de Egreso
   */
  list(httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExpenseTypeService.BASE_URL_API}/list`, httpRequestHandler);
  }
}
