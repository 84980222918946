import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class ExtracurricularActivityService {
  public static URL = environment.URL.API2 + 'extracurricular-activity';

  constructor(private http: HttpService) {
  }

  /**
   * List Extracurricular Activities
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtracurricularActivityService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get Extracurricular Activity
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtracurricularActivityService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Extracurricular Activity
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(ExtracurricularActivityService.URL, data, httpRequestHandler);
  }

  /**
   * Delete Extracurricular Activity
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ExtracurricularActivityService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List Extracurricular Activities for select component
   */
  list = () => {
    return this.http.observableGet(`${ExtracurricularActivityService.URL}/list`, { mapFn: res => res.activities });
  };

  /**
   * List Extracurricular Activities from year
   */
  fromYear = data => {
    return this.http.observableGet(`${ExtracurricularActivityService.URL}/list/year/${data.yearId}`, { mapFn: res => res.activities });
  };
}
