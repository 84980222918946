import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class SectionService {
  public static URL = environment.URL.API + 'section';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de Secciones
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(SectionService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Eliminar Sección
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${SectionService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Editar Sección
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${SectionService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar Sección
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${SectionService.URL}/${id}`, data, httpRequestHandler);
  }

  /**
   * Guardar Sección
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${SectionService.URL}`, data, httpRequestHandler);
  }

  /**
   * Listado de secciones para combo.
   */
  list = () => {
    return this.http.observableGet(`${SectionService.URL}/list`, { mapFn: res => res.sections });
  };

  /**
   * Listado de secciones asociadas a un año lectivo
   * @param yearId ID de año lectivo
   */
  fromYear = data => {
    return this.http.observableGet(`${SectionService.URL}/list/year/${data.yearId}`, { mapFn: res => res.sections });
  };

  /**
   * Listado de secciones asociados a un año lectivo validando secciones del usuario
   * @param yearId ID de año lectivo
   */
  fromYearAcademic = data => {
    return this.http.observableGet(`${SectionService.URL}/list/year/${data.yearId}/academic`, { mapFn: res => res.sections });
  };

  /**
   * Listado de Secciones asociadas a un año lectivo con sus grados y grupos respectivos
   */
  fromYearGradesGroups(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${SectionService.URL}/list/year/${id}/grades-groups`, httpRequestHandler);
  }
}
