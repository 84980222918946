import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class PerformanceReportService {
  public static URL = environment.URL.API + 'performance-report';

  constructor(private http: HttpService) {}

  /**
   * List performance reports
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PerformanceReportService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * List performance reports tracking
   */
  trackingIndex(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PerformanceReportService.URL}/tracking?${params}`, httpRequestHandler);
  }

  /**
   * Get performance report
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PerformanceReportService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get performance report
   */
  getStudentData(enrollmentId: number, groupId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PerformanceReportService.URL}/${enrollmentId}/student/${groupId}`, httpRequestHandler);
  }

  /**
   * Store performance report
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(PerformanceReportService.URL, data, httpRequestHandler);
  }

  /**
   * List groups
   */
  getGroups(yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PerformanceReportService.URL}/${yearId}/groups`, httpRequestHandler);
  }

  /**
   * List groups
   */
  getStudents(groupId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PerformanceReportService.URL}/${groupId}/students`, httpRequestHandler);
  }
  /**
   * Notify performance report
   */
  notify(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PerformanceReportService.URL}/${id}/notify`, httpRequestHandler);
  }
}
