import { Component, EventEmitter, forwardRef, Host, Injectable, Input, OnInit, Optional, Output, SkipSelf, ViewChild } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { DaysOfWeek } from 'app/services/util';
import * as moment from 'moment';

/**
 * Clase para dar formato 'DD-MM-YYYY' a la fecha que se muestra en el input
 */
@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (value) {
      const m = moment(value, 'DD-MM-YYYY');
      return { day: m.date(), month: m.month() + 1, year: m.year() };
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    if (date) {
      return moment()
        .year(date.year)
        .month(date.month - 1)
        .date(date.day)
        .format('DD-MM-YYYY');
    }
    return '';
  }
}

/**
 * Clase para dar formato ISO a la fecha que se usa en los FormControl
 */
@Injectable()
export class NgbDateISOAdapter extends NgbDateAdapter<string> {
  /**
   * Convierte un valor string en formato ISO a un valor NgbDateStruct
   */
  fromModel(date: string): NgbDateStruct {
    if (date) {
      const m = moment(date, 'YYYY-MM-DD');
      return { day: m.date(), month: m.month() + 1, year: m.year() };
    }
    return null;
  }

  /**
   * Convierte un valor NgbDateStruct a un valor string en formato ISO
   */
  toModel(date: NgbDateStruct): string {
    if (date) {
      return moment()
        .year(date.year)
        .month(date.month - 1)
        .date(date.day)
        .format('YYYY-MM-DD');
    }
    return '';
  }
}

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    { provide: NgbDateAdapter, useClass: NgbDateISOAdapter },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DatePickerComponent)
    }
  ]
})
export class DatePickerComponent implements OnInit, ControlValueAccessor {
  @Input() minDate: NgbDateStruct = { year: 1918, month: 1, day: 1 };
  @Input() maxDate: NgbDateStruct;
  @Input() formControlName: string;
  @Input() isDisabled: boolean;
  @Input() disabledDays: DaysOfWeek[];

  @Output() dateChange = new EventEmitter<any>();

  get disabled() {
    return this.isDisabled || this.disabledByForm;
  }

  date: NgbDateStruct;
  control: AbstractControl;
  disabledByForm: boolean;

  @ViewChild('dp', { static: false }) datePicker: NgbInputDatepicker;

  validDay = (date: NgbDate, current: { month: number }) => {
    if (this.disabledDays) {
      let flag = false;
      this.disabledDays.forEach(d => {
        if (this.calendar.getWeekday(date) === d) flag = true;
      });
      return flag;
    } else {
      return false;
    }
  };

  propagateChange = (val: any) => {
  };
  touchChange = () => {
  };

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
    private calendar: NgbCalendar
  ) {
  }

  ngOnInit() {
    if (this.formControlName) this.control = this.controlContainer.control.get(this.formControlName);
  }

  writeValue(value: any) {
    this.date = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.touchChange = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabledByForm = isDisabled;
  }

  toggle() {
    if (!this.disabled) this.datePicker.toggle();
  }

  blur() {
    if (this.control) this.control.updateValueAndValidity();
  }

  valueChange(date) {
    this.propagateChange(date);
    this.dateChange.emit(date);
  }
}
