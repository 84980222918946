import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';

@Injectable()
export class StorageService {
  private readonly storage: Storage;

  constructor() {
    this.storage = localStorage;
  }

  get<T>(name: string): T | undefined {
    const decryptedData = this.decrypt(this.storage.getItem(name));
    return decryptedData ? (JSON.parse(decryptedData) as T) : undefined;
  }

  set(key: string, value: any) {
    const encryptedData = this.encrypt(JSON.stringify(value));
    this.storage.setItem(key, encryptedData);
  }

  remove(key: string) {
    this.storage.removeItem(key);
  }

  clear() {
    this.storage.clear();
  }

  private encrypt(value: string): string {
    return CryptoJS.AES.encrypt(value, environment.STORAGE_KEY).toString();
  }

  private decrypt(value: string | null): string | undefined {
    try {
      return CryptoJS.AES.decrypt(value, environment.STORAGE_KEY).toString(CryptoJS.enc.Utf8);
    } catch (error) {
    }
  }
}
