import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class AuditLogService {
  public static BASE_URL_API = environment.URL.API + 'audit-log';
  private listTimeout;

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de auditoria
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(AuditLogService.BASE_URL_API + '?' + params, httpRequestHandler);
  }

  /**
   * Obtener registro de auditoria
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AuditLogService.BASE_URL_API}/${id}`, httpRequestHandler);
  }
}
