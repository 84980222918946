import { Component, Input, OnInit } from '@angular/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ILocalFilter, ILocalFilterType, LocalDt } from '../local-dt';

@Component({
  selector: 'app-local-input-column-filter',
  templateUrl: './local-input-column-filter.component.html',
})
export class LocalInputColumnFilterComponent implements OnInit, ILocalFilter {
  @Input() localDt: LocalDt;
  @Input() placeholder = 'general.search';
  @Input() col: TableColumn;

  filter: string;
  value: Subject<any> = new Subject();
  type: ILocalFilterType = ILocalFilterType.Input;
  public name: string;
  private changeSubject: Subject<string> = new Subject();

  constructor() {
    this.changeSubject.pipe(debounceTime(150)).subscribe(value => {
      this.value.next(value);
    });
  }

  ngOnInit() {
    this.name = this.col.prop.toString();
    this.localDt.filterInitiated(this, true);
  }

  valueReady(value: string) {
    this.filter = value;
  }

  next(e) {
    this.changeSubject.next(e);
  }
}
