import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

enum TransportChangeType {
  Pickup = 1,
  Destination = 2
}

enum StudentChangeReportType {
  RoutedChanges = 1,
  UnroutedChanges = 2
}

@Injectable()
export class StudentChangeService {
  public static URL = environment.URL.API + 'student-change';

  transportChangeTypes = [
    { id: TransportChangeType.Pickup, key: 'studentChanges.pickup', name: '' },
    { id: TransportChangeType.Destination, key: 'studentChanges.destination', name: '' }
  ];

  reportTypes = [
    { id: StudentChangeReportType.RoutedChanges, key: 'studentChanges.routed', name: '' },
    { id: StudentChangeReportType.UnroutedChanges, key: 'studentChanges.unrouted', name: '' }
  ];

  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    const lists = {
      transportChangeTypes: this.transportChangeTypes,
      reportTypes: this.reportTypes
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  getTransportChangeType(id: number) {
    return this.transportChangeTypes.find(tct => tct.id === id);
  }

  /**
   * List unrouted changes
   */
  getUnroutedChanges(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${StudentChangeService.URL}/unrouted?${params}`, httpRequestHandler);
  }

  /**
   * List routed changes
   */
  getRoutedChanges(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${StudentChangeService.URL}/routed?${params}`, httpRequestHandler);
  }

  /**
   * Save change route
   */
  saveChangeRoute(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${StudentChangeService.URL}`, data, httpRequestHandler);
  }

  /**
   * List routed changes
   */
  unrouteChange(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${StudentChangeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Generate report
   */
  generateReport(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${StudentChangeService.URL}/reports`, data, httpRequestHandler);
  }
}
