import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class PettyCashInvoiceService {
  public static URL = environment.URL.API2 + 'petty-cash-invoice';

  constructor(private http: HttpService, private fn: FunctionsService, translate: TranslateService) {}

  /**
   * List Petty Cash invoices
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, paramsRoute: any, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PettyCashInvoiceService.URL}/${paramsRoute.pettyCashId}/${paramsRoute.history}?${params}`, httpRequestHandler);
  }

  /**
   * Get Petty Cash invoice
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PettyCashInvoiceService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Petty Cash invoice
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(PettyCashInvoiceService.URL, data, httpRequestHandler);
  }

  /**
   * Delete Petty Cash invoice
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${PettyCashInvoiceService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List Petty Cash invoices
   * @param httpRequestHandler HttpRequestHandler
   */
  list = () => {
    return this.http.observableGet(`${PettyCashInvoiceService.URL}/list`, { mapFn: res => res.Invoices });
  };

  /**
   * Add Petty Cash invoice to current refund
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  add(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(PettyCashInvoiceService.URL + '/add', data, httpRequestHandler);
  }

  /**
   * List Petty Cash refund invoices
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  getInvoices(refundId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PettyCashInvoiceService.URL + '/refund'}/${refundId}`, httpRequestHandler);
  }
}
