import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class MailNotificationCategoryService {
  public static BASE_URL_API = environment.URL.API + 'mail-notification-category';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de Categorias de Notificaciones de Correo
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(MailNotificationCategoryService.BASE_URL_API + '?' + params, httpRequestHandler);
  }

  /**
   * Agregar Categoria de Notificación de Correo
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(MailNotificationCategoryService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Eliminar Categoria de Notificación de Correo
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${MailNotificationCategoryService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Editar Categoria de Notificación de Correo
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${MailNotificationCategoryService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar Categoria de Notificación de Correo
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${MailNotificationCategoryService.BASE_URL_API}/${id}`, data, httpRequestHandler);
  }

  /**
   * Listado de Categorias de Notificaciones de Correo para combo.
   */
  list = () => {
    return this.http.observableGet(`${MailNotificationCategoryService.BASE_URL_API}/list`, { mapFn: res => res.mailNotificationCategories });
  };
}
