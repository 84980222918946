import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpRequestStream, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum AttendanceReportType {
  Diary = 1,
  RelationByStudent = 2,
  StudentAttendance = 3,
  RelationByStudentDetailed = 4,
}

@Injectable()
export class AttendanceService {
  public static URL = environment.URL.API + 'attendance';

  reportTypes = [
    { id: AttendanceReportType.Diary, key: 'attendance.diaryReport', name: '', canTeacher: true },
    { id: AttendanceReportType.RelationByStudent, key: 'attendance.relationByStudent', name: '', canTeacher: true },
    { id: AttendanceReportType.RelationByStudentDetailed, key: 'attendance.relationByStudentDetailed', name: '', canTeacher: true },
    { id: AttendanceReportType.StudentAttendance, key: 'attendance.studentAttendance', name: '', canTeacher: false },
  ];

  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    const lists = { reportTypes: this.reportTypes };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * List students
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(AttendanceService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Store attendance
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(AttendanceService.URL, data, httpRequestHandler);
  }

  /**
   * Delete attendance
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${AttendanceService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get attendance
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AttendanceService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Download interface
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  generateReport(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AttendanceService.URL}/reports`, data, httpRequestHandler);
  }

  /**
   * Get notification data
   */
  getNotification(date: string, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AttendanceService.URL}/notification`, { date: date }, httpRequestHandler);
  }

  /**
   * Mailing
   */
  mailing(date: string, opts: HttpRequestStream) {
    this.http.stream(`${AttendanceService.URL}/${date}/mailing`, opts);
  }

  /**
   * Save observation
   */
  saveObservation(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AttendanceService.URL}/observation`, data, httpRequestHandler);
  }

  /**
   * Get programmed attendances
   */
  getProgrammedAttendances(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AttendanceService.URL}/programmed/${id}`, httpRequestHandler);
  }

  /**
   * Delete programmed attendance
   */
  deleteProgrammed(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${AttendanceService.URL}/programmed/${id}`, httpRequestHandler);
  }

  /**
   * Save programmed attendance
   */
  saveProgrammed(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AttendanceService.URL}/programmed`, data, httpRequestHandler);
  }
}
