import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class AreaService {
  public static URL = environment.URL.API + 'area';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de Áreas
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(AreaService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Eliminar Área
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${AreaService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Editar Área
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AreaService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar Área
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${AreaService.URL}/${id}`, data, httpRequestHandler);
  }

  /**
   * Guardar Área
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AreaService.URL}`, data, httpRequestHandler);
  }

  /**
   * Listado de Áreas para combo.
   */
  list = () => {
    return this.http.observableGet(`${AreaService.URL}/list`, { mapFn: res => res.areas });
  };

  /**
   * Listado de Áreas asociadas a un año lectivo
   * @param yearId ID de año lectivo
   */
  fromYear = data => {
    return this.http.observableGet(`${AreaService.URL}/list/year/${data.yearId}`, { mapFn: res => res.areas });
  };
}
