import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum TicketAuthorizationTab {
  Pending = 'pending',
  Authorized = 'authorized',
  NotAuthorized = 'not-authorized',
}

@Injectable()
export class TicketAuthorizationService {
  public static URL = environment.URL.API + 'ticket-authorization';

  constructor(private http: HttpService) {}

  /**
   * List tickets for authorization
   *
   * @param params string
   * @param tab TicketAuthorizationTab
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, tab: TicketAuthorizationTab, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${TicketAuthorizationService.URL}/${tab}?${params}`, httpRequestHandler);
  }

  /**
   * Save ticket authorization
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  saveAuthorization(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${TicketAuthorizationService.URL}/authorize`, data, httpRequestHandler);
  }
}
