import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class EconomicSectorService {
  public static BASE_URL_API = environment.URL.API + 'economic-sector';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de Sectores Económicos
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(EconomicSectorService.BASE_URL_API + '?' + params, httpRequestHandler);
  }

  /**
   * Agregar Sector Económico
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(EconomicSectorService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Eliminar Sector Económico
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${EconomicSectorService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Editar Sector Económico
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${EconomicSectorService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar Sector Económico
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${EconomicSectorService.BASE_URL_API}/${id}`, data, httpRequestHandler);
  }

  /**
   * Listado de Sectores Económicos para combo.
   */
  list = () => {
    return this.http.observableGet(`${EconomicSectorService.BASE_URL_API}/list`, { mapFn: res => res.economicSectors });
  };
}
