import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';
import { ContractFormFrom } from './contract.service';

export enum BigpassStatus {
  Generated = 1,
  Pending = 2,
  Approved = 3,
  Notified = 4,
  Rejected = 5,
  Recalculated = 6,
  Comment = 7,
  CustomValue = 8
}

export enum BigpassFortnight {
  First = 1,
  Second = 2,
}

export enum BigpassCustomValue {
  Yes = 1,
  No = 2,
}

@Injectable()
export class BigpassService {
  public static URL = environment.URL.API2 + 'bigpass';

  statuses = [
    { id: BigpassStatus.Generated, name: '', key: 'bigpass.statuses.generated', inForm: true, class: 'label label-default' },
    { id: BigpassStatus.Pending, name: '', key: 'bigpass.statuses.pending', class: 'label bg-purple' },
    { id: BigpassStatus.Approved, name: '', key: 'bigpass.statuses.approved', class: 'label label-primary' },
    { id: BigpassStatus.Notified, name: '', key: 'bigpass.statuses.notified', class: 'label label-success' },
    { id: BigpassStatus.Rejected, name: '', key: 'bigpass.statuses.rejected', class: 'label label-danger' }
  ];

  fortnights = [
    { id: BigpassFortnight.First, name: '', key: 'bigpass.fortnights.first' },
    { id: BigpassFortnight.Second, name: '', key: 'bigpass.fortnights.second' }
  ];

  customValue = [
    { id: BigpassCustomValue.Yes, name: '', key: 'bigpass.customValue.yes' },
    { id: BigpassCustomValue.No, name: '', key: 'bigpass.customValue.no' }
  ];

  bigpassLogTypes = [
    { id: BigpassStatus.Generated, name: '', key: 'bigpass.tracingStatus.generated', inForm: true, class: 'bg-secondary-light' },
    { id: BigpassStatus.Pending, name: '', key: 'bigpass.tracingStatus.pending', class: 'bg-primary-light2' },
    { id: BigpassStatus.Approved, name: '', key: 'bigpass.tracingStatus.approved', class: 'bg-primary-light' },
    { id: BigpassStatus.Notified, name: '', key: 'bigpass.tracingStatus.notified', class: 'bg-success-light' },
    { id: BigpassStatus.Rejected, name: '', key: 'bigpass.tracingStatus.rejected', class: 'bg-danger-light' },
    { id: BigpassStatus.Recalculated, name: '', key: 'bigpass.tracingStatus.recalculated', class: 'bg-secondary-light' },
    { id: BigpassStatus.Comment, name: '', key: 'bigpass.tracingStatus.comment', class: 'bg-secondary-light' },
    { id: BigpassStatus.CustomValue, name: '', key: 'bigpass.tracingStatus.customValue', class: 'bg-secondary-light' }
  ];

  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    const lists = {
      statuses: this.statuses,
      fortnights: this.fortnights,
      customValue: this.customValue,
      bigpassLogTypes: this.bigpassLogTypes
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * Index Bigpass
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${BigpassService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get Bigpass Details
   */
  get(id: number, params: string, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${BigpassService.URL}/${id}?${params}`, httpRequestHandler);
  }

  /**
   * Contract Bigpass Generate
   */
  generate(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(BigpassService.URL, data, httpRequestHandler);
  }

  /**
   * Set Contract-Bigpass Custom Value
   */
  setValue(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${BigpassService.URL}/value`, data, httpRequestHandler);
  }

  /**
   * Set PreApprove Bigpass Status and notify Bigpass Status
   */
  preApprove(id: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${BigpassService.URL}/pre-approve/${id}`, httpRequestHandler);
  }

  /**
   * Set Approve Bigpass Status and notify Bigpass Status
   */
  approve(id: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${BigpassService.URL}/approve/${id}`, httpRequestHandler);
  }

  /**
   * Set Status notify Bigpass and notify
   */
  notify(id: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${BigpassService.URL}/notify/${id}`, httpRequestHandler);
  }

  /**
   * export list Bigpass
   */
  export(id: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${BigpassService.URL}/export/${id}`, httpRequestHandler);
  }

  /**
   * Set Status Reject Bigpass
   */
  reject(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${BigpassService.URL}/reject`, data, httpRequestHandler);
  }

  /**
   * Set Status Reject Bigpass
   */
  comment(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${BigpassService.URL}/comment`, data, httpRequestHandler);
  }

  /**
   * bigpass tracking
   * @param id Id de bigpass
   */
  tracking(id: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${BigpassService.URL}/tracking/${id}`, httpRequestHandler);
  }

  /**
   * bigpass tracking
   * @param id Id de bigpass
   */
  trackingContract(id: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${BigpassService.URL}/tracking-contract/${id}`, httpRequestHandler);
  }

  /**
   * Get Contract Bigpass Details
   */
  getContract(id: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${BigpassService.URL}/contract-bigpass/${id}`, httpRequestHandler);
  }

  /**
   * Delete Bigpass
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${BigpassService.URL}/${id}`, httpRequestHandler);
  }

  /*Employee view in my contract*/
  /**
   * Index bigpass in contract
   */
  indexContract(params: string, id: number, from: ContractFormFrom, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${BigpassService.URL}/contract/${id}/${from}?${params}`, httpRequestHandler);
  }
}
