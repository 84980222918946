import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class ProviderPaymentService {
  public static URL = environment.URL.API2 + 'provider-payment';

  constructor(private http: HttpService) {}

  /**
   * Index Provider Payment
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ProviderPaymentService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Delete Provider Payment
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ProviderPaymentService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get Provider Payment
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ProviderPaymentService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Provider Payment
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(ProviderPaymentService.URL, data, httpRequestHandler);
  }

  list = ({ providerId }) => {
    return this.http.observableGet(`${ProviderPaymentService.URL}/list/${providerId}`, { mapFn: res => res.providerPayment });
  };

  /**
   * Get Provider Payment
   */
  getActivePayments(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ProviderPaymentService.URL}/active-payments/${id}`, httpRequestHandler);
  }
}
