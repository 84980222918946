import { Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, ValidatorFn } from '@angular/forms';
import { FunctionsService } from 'app/services/util';
import { KeyCode } from 'app/modules/shared';

import { InputMoneyComponent } from '../input-money/input-money.component';
import { InputPercentageComponent } from '../input-percentage/input-percentage.component';

export interface IInlineEditorEvent {
  value: string;

  commit(): () => void;

  rollback(): () => void;
}

type InlineEditorType = 'text' | 'money' | 'percentage';

@Component({
  selector: 'app-inline-editor',
  templateUrl: './inline-editor.component.html',
  styleUrls: ['./inline-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InlineEditorComponent)
    }
  ]
})
export class InlineEditorComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() validators: ValidatorFn | ValidatorFn[];
  @Input() type: InlineEditorType = 'text';
  @Input() disabled = false;
  @Input() fullSpan = false;
  @Input() nullValue = '--';
  @Input() border = true;

  @Output() changingValue = new EventEmitter<IInlineEditorEvent>();

  form: FormGroup;
  editing = false;
  loading = false;
  input: ElementRef;
  inputMoney: InputMoneyComponent;
  inputPercentage: InputPercentageComponent;
  private clickOutsideFlag = true;

  private propagateChange = (val: any) => {
  };
  private touchChange = () => {
  };

  @ViewChild('input', { static: false })
  set contentInput(value: ElementRef) {
    this.input = value;
  }

  @ViewChild('inputMoney', { static: false })
  set contentInputMoney(value: InputMoneyComponent) {
    this.inputMoney = value;
  }

  @ViewChild('inputPercentage', { static: false })
  set contentInputPercentage(value: InputPercentageComponent) {
    this.inputPercentage = value;
  }

  constructor(fb: FormBuilder, private fn: FunctionsService) {
    this.form = fb.group({ actual: [null], temp: [null] });
  }

  writeValue(value: any) {
    this.form.controls.actual.setValue(value);
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.touchChange = fn;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.validators) {
      this.form.controls.temp.setValidators(changes.validators.currentValue);
    }
  }

  edit() {
    if (this.disabled) return;

    this.form.controls.temp.setValue(this.form.controls.actual.value);
    this.editing = true;
    this.clickOutsideFlag = false;
    setTimeout(() => (this.clickOutsideFlag = true), 100);
    setTimeout(() => this.focusInput(), 0);
  }

  cancel() {
    if (!this.loading) this.editing = false;
  }

  clickOutside(e) {
    if (!this.clickOutsideFlag || !this.editing) return;
    this.ok();
  }

  keydown(e: KeyboardEvent) {
    switch (e.keyCode) {
      case KeyCode.Escape:
        this.cancel();
        break;
      case KeyCode.Enter:
        this.ok();
        break;
    }
  }

  ok() {
    setTimeout(() => {
      if (!this.form.validate()) {
        this.focusInput();
        return;
      }

      const data = this.form.value;

      if (data.temp === data.actual) {
        this.cancel();
        return;
      }

      this.loading = true;
      this.changingValue.emit({
        value: data.temp,
        commit: this.commit.bind(this),
        rollback: this.rollback.bind(this)
      });
    }, 0);
  }

  commit() {
    const value = this.form.controls.temp.value;
    this.propagateChange(value);
    this.writeValue(value);
    this.loading = false;
    this.cancel();
  }

  rollback() {
    this.loading = false;
    this.focusInput();
  }

  focusInput() {
    switch (this.type) {
      case 'text':
        this.input.nativeElement.focus();
        break;
      case 'money':
        this.inputMoney.focus();
        break;
      case 'percentage':
        this.inputPercentage.focus();
        break;
    }
  }
}
