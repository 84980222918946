import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dimmer',
  templateUrl: './dimmer.component.html',
  styleUrls: ['./dimmer.component.scss']
})
export class DimmerComponent implements OnInit {
  @Input() loading = false;
  @Input() size = 2;

  constructor() {
  }

  ngOnInit() {
  }
}
