import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ModalSize } from './modal.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() title: string;
  @Input() plainTitle: string;
  @Input() closable = true;
  @Input() size: ModalSize;
  @Input() headerClass: '';

  @Output() closed: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('modalTmp', { static: true }) private modalTpl: TemplateRef<any>;

  private modalReference?: NgbModalRef;

  constructor(private modalService: NgbModal) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngAfterViewInit(): void {
  }

  open(onOpened?: () => void) {
    this.modalReference = this.modalService.open(this.modalTpl, {
      size: this.size,
      centered: true,
      backdrop: 'static',
      scrollable: true,
      keyboard: false
    });

    if (onOpened) setTimeout(() => onOpened(), 0);
  }

  close() {
    if (this.modalReference) {
      this.modalReference.close();
      this.closed.emit();
    }
  }
}
