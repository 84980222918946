import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class AssessmentService {
  public static URL = environment.URL.API + 'assessment';

  constructor(private http: HttpService) {}

  /**
   * Listado de grupos
   */
  groups(yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AssessmentService.URL}/${yearId}/groups`, httpRequestHandler);
  }

  /**
   * Listado de estudiantes de grupo de evaluación
   */
  students(groupId: number, assessmentId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AssessmentService.URL}/${groupId}/students/${assessmentId}`, httpRequestHandler);
  }

  /**
   * Datos de evaluación de estudiante
   */
  get(assessmentId: number, ggStudentId: number, periodId: number, employeeId: any, httpRequestHandler: HttpRequestHandler) {
    this.http.get(
      `${AssessmentService.URL}?assessmentId=${assessmentId}&ggStudentId=${ggStudentId}&periodId=${periodId}&employeeId=${employeeId}`,
      httpRequestHandler
    );
  }

  /**
   * Guardar evaluacion de estudiante
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AssessmentService.URL}`, data, httpRequestHandler);
  }

  /**
   * Imprimir evaluación de estudiante
   */
  print(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AssessmentService.URL}/print/${id}`, httpRequestHandler);
  }

  /**
   * Listado de Grados asociados a un año lectivo validando secciones del usuario
   */
  grades(yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AssessmentService.URL}/${yearId}/grades`, httpRequestHandler);
  }

  /**
   * Listado de estudiantes de grupo de evaluación
   */
  trackingStudents(groupId: number, assessmentId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AssessmentService.URL}/tracking/${groupId}/students/${assessmentId}`, httpRequestHandler);
  }
}
