import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct, NgbInputDatepicker, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Subject } from 'rxjs';

import { IPagerFilter, LabelPosition, PagerService } from '../../../../services/util';

declare const $;

export class NgbDatePlainAdapter extends NgbDateAdapter<string> {
  /**
   * Convierte un valor string en format 'DD-MM-YYYY' a un valor NgbDateStruct
   */
  fromModel(date: string): NgbDateStruct {
    if (date) {
      const m = moment(date, 'DD-MM-YYYY');
      return { day: m.date(), month: m.month() + 1, year: m.year() };
    }
    return null;
  }

  /**
   * Convierte un valor NgbDateStruct a un valor string en format 'DD-MM-YYYY'
   */
  toModel(date: NgbDateStruct): string {
    if (date) {
      return moment()
        .year(date.year)
        .month(date.month - 1)
        .date(date.day)
        .format('DD-MM-YYYY');
    }
    return '';
  }
}

@Component({
  selector: 'app-pager-date-column-filter',
  templateUrl: './pager-date-column-filter.component.html',
  styleUrls: ['./pager-date-column-filter.component.scss'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDatePlainAdapter }],
})
export class PagerDateColumnFilterComponent implements OnInit, IPagerFilter {
  @Input() name: string;
  @Input() default: string;
  @Input() label: string;
  @Input() labelPos: LabelPosition = 'left';
  @Input() clearable = true;

  date = '';
  private canClosePopover: boolean;
  value: Subject<any> = new Subject();

  datePicker: NgbInputDatepicker;
  @ViewChild('p', { static: false }) popover: NgbPopover;

  constructor(private pager: PagerService) {}

  ngOnInit() {
    this.pager.filterInitiated(this);

    if (this.date == null) this.date = '';

    setTimeout(() => {
      this.popover.shown.subscribe(() => {
        $('datatable-body').css('z-index', '-1');
      });
      this.popover.hidden.subscribe(() => {
        $('datatable-body').css('z-index', 'inherit');
      });
    }, 100);
  }

  valueReady(value) {
    this.date = value == null ? '' : value;
    if (this.date !== '') this.date = moment(this.date, ['DD-MM-YYYY', 'YYYY-MM-DD']).format('DD-MM-YYYY');
  }

  open() {
    setTimeout(() => {
      this.popover.open();
      this.canClosePopover = false;
      setTimeout(() => {
        this.canClosePopover = true;
      }, 100);
    }, 100);
  }

  close() {
    if (this.canClosePopover && this.popover.isOpen()) this.popover.close();
  }

  select() {
    this.close();
    this.emit();
  }

  clear() {
    this.date = '';
    this.emit();
  }

  emit() {
    this.value.next(this.date);
  }
}
