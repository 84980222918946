import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { SessionService } from '../services/util';

@Injectable()
export class BoxLayoutGuard implements CanActivate {
  constructor(private sessionService: SessionService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.sessionService.boxLayout = next.data.box;
    this.sessionService.sidebarMenu = !next.data.guest;
    return true;
  }
}
