import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class ExtendedHoursPlanService {
  public static BASE_URL_API = environment.URL.API + 'extended-hours-plan';

  constructor(private http: HttpService) {}

  /**
   * List extended hours plan
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtendedHoursPlanService.BASE_URL_API}?${params}`, httpRequestHandler);
  }

  /**
   * Get extended hours plan
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtendedHoursPlanService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Save extended hours plan
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(ExtendedHoursPlanService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Delete extended hours plan
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ExtendedHoursPlanService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * List extended hours plans
   */
  list = () => {
    return this.http.observableGet(`${ExtendedHoursPlanService.BASE_URL_API}/list`, { mapFn: res => res.extendedHoursPlans });
  };
}
