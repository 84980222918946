import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class MyTicketService {
  public static URL = environment.URL.API + 'my-ticket';

  constructor(private http: HttpService) {}

  /**
   * List my tickets
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${MyTicketService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Add ticket attachment
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  addAttachment(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${MyTicketService.URL}/attachment`, data, httpRequestHandler);
  }

  /**
   * Delete ticket attachment
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  deleteAttachment(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${MyTicketService.URL}/${id}/attachment`, httpRequestHandler);
  }
}
