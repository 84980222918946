import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class ConsultationReasonService {
  public static URL = environment.URL.API + 'consultation-reason';

  constructor(private http: HttpService) {}

  /**
   * List consultation reason
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ConsultationReasonService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get consultation reason
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ConsultationReasonService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save consultation reason
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(ConsultationReasonService.URL, data, httpRequestHandler);
  }

  /**
   * Delete consultation reason
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ConsultationReasonService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List consultation reason
   * @param httpRequestHandler HttpRequestHandler
   */
  list = () => {
    return this.http.observableGet(`${ConsultationReasonService.URL}/list`, { mapFn: res => res.consultationReasons });
  };
}
