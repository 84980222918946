import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class BankService {
  public static URL = environment.URL.API + 'bank';

  constructor(private http: HttpService) {}

  /**
   * List banks
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${BankService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get bank
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${BankService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save bank
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(BankService.URL, data, httpRequestHandler);
  }

  /**
   * Delete bank
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${BankService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List banks
   * @param httpRequestHandler HttpRequestHandler
   */
  list = () => {
    return this.http.observableGet(`${BankService.URL}/list`, { mapFn: res => res.banks });
  };
}
