import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum AutoReportColumnType {
  Numeric = 1,
  Money = 2,
  Date = 3,
  Time = 4
}

@Injectable()
export class AutomaticReportService {
  public static URL = environment.URL.API + 'automatic-report';

  constructor(private http: HttpService) {}

  /**
   * List automatic reports
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AutomaticReportService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get automatic report
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AutomaticReportService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Generar reporte
   */
  export(id: any, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AutomaticReportService.URL}/${id}/export`, httpRequestHandler);
  }

  /**
   * List automatic report types
   * @param httpRequestHandler HttpRequestHandler
   */
  listTypes = () => {
    return this.http.observableGet(`${AutomaticReportService.URL}/list-types`, { mapFn: res => res.types });
  };
}
