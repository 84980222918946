import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class DepartureTimeService {
  public static BASE_URL_API = environment.URL.API + 'departure-time';

  constructor(private http: HttpService) {}

  /**
   * List departure times
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${DepartureTimeService.BASE_URL_API}?${params}`, httpRequestHandler);
  }

  /**
   * List departure times
   */
  list = () => {
    return this.http.observableGet(`${DepartureTimeService.BASE_URL_API}/list`, { mapFn: res => res.departureTimes });
  };

  /**
   * Store departure time
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(DepartureTimeService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Delete departure time
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${DepartureTimeService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Get departure time
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${DepartureTimeService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  fromYear = ({ yearId }) => {
    return this.http.observableGet(`${DepartureTimeService.BASE_URL_API}/list/year/${yearId}`, { mapFn: res => res.departureTimes });
  };
}
