import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class PrepaidService {
  public static URL = environment.URL.API + 'prepaid';

  constructor(private http: HttpService) {}

  /**
   * Listado paginador de anticipos
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PrepaidService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Detalle de anticipo
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PrepaidService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Aplicar anticipo a documento
   */
  apply(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PrepaidService.URL}/apply`, data, httpRequestHandler);
  }
}
