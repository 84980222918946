import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum BlockType {
  Academic = 1,
  Disciplinary = 2,
  Other = 3,
  Financial = 4,
  Transport = 5,
  Extracurricular = 6,
}

@Injectable()
export class BlockService {
  public static BASE_URL_API = environment.URL.API + 'academic-block';

  blockTypes = [
    { id: BlockType.Academic, name: '', key: 'menu.main.academic', display: true },
    { id: BlockType.Disciplinary, name: '', key: 'general.disciplinary', display: true },
    { id: BlockType.Other, name: '', key: 'general.other', display: true },
    { id: BlockType.Financial, name: '', key: 'enroll.status.financial', display: false },
    { id: BlockType.Extracurricular, name: '', key: 'enroll.status.financial', display: false }
  ];

  constructor(private http: HttpService, private translate: TranslateService) {
    const udpateLangValues = () => {
      this.blockTypes.forEach(item => (item.name = translate.instant(item.key)));
      this.blockTypes = [...this.blockTypes];
    };
    udpateLangValues();
    translate.onLangChange.subscribe(value => udpateLangValues());
  }

  getBlockType(id: BlockType) {
    return this.blockTypes.find(b => b.id === id);
  }

  /**
   * List academic/disciplinary
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${BlockService.BASE_URL_API}?${params}`, httpRequestHandler);
  }

  /**
   * Block academic/disciplinary
   */
  block(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(BlockService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Unblock academic/disciplinary
   */
  unblock(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${BlockService.BASE_URL_API}/unblock`, data, httpRequestHandler);
  }
}
