/** Constantes globales */
export const GC = {
  ERROR: {
    TOKEN: {
      INVALID: 'token_invalid',
      MISSING: 'token_missing',
      EXPIRED: 'token_expired',
    },
    NO_USER: 'no_user',
    QUERY: 'query',
    MODEL_NOT_FOUND: 'model_not_found',
    FAILED_VALIDATION: 'failed_validation',
    USER_BLOCKED: 'user_blocked'
  },
  QUERY_CODE: {
    REFERENCED: 1451
  },
  MAXIMUM: {
    TINYINT: 255,
    SMALLINT: 65535,
    MEDIUMINT: 16777215,
    INT: 4294967295,
    MONEY: 999999999999999.99
  },
  /**
   * Llaves para usar en el localStorage
   */
  LS: {
    LANG: 'lang',
    SWITCHBACK: 'switchBack',
    TOKEN: 'token',
    LOGINS_FAILED: 'loginsFailed',
    GUEST: 'guest',
    GUEST_OLD: 'guest_old'
  }
};
