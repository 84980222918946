<div class="border-checkbox-section">
  <div class="border-checkbox-group border-checkbox-group-primary">
    <input
      class="border-checkbox"
      [id]="inputId"
      type="checkbox"
      [(ngModel)]="value"
      (ngModelChange)="change($event)"
      [disabled]="isDisabled"
      (blur)="blur()"
    />
    <label class="border-checkbox-label checkbox-label-table" [for]="inputId">{{ label | translate }} </label>
  </div>
</div>
