import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { PagerService } from '../../../../services/util';

@Component({
  selector: 'app-pager-button',
  templateUrl: './pager-button.component.html',
  styleUrls: ['./pager-button.component.scss']
})
export class PagerButtonComponent implements OnInit {
  @Input() tooltip: string;
  @Input() permission: string;
  @Input() color = 'btn-primary';

  /**
   * Ruta a redireccionar
   */
  @Input() link: any[];

  /**
   * Determina si el boton se encuentra deshabilitado
   * @default false
   */
  @Input() disabled = false;

  /**
   * Usar este evento en vez del click generico de angular cuando se necesite validar el estado del botón [disabled]
   * Este evento no se dispara si se ha pasado un valor en el parametro [link]
   */
  @Output() onClick = new EventEmitter();

  constructor(public pager: PagerService) {}

  ngOnInit() {}
}
