<ng-container *ngIf="label; else datepickerTemplate" [ngSwitch]="labelPos">
  <div class="d-table" *ngSwitchCase="'left'">
    <div class="d-table-cell text-nowrap pr-1">
      <label>{{ label | translate }}:</label>
    </div>
    <div class="d-table-cell w-100"><ng-container *ngTemplateOutlet="datepickerTemplate"></ng-container></div>
  </div>
  <div *ngSwitchCase="'top'">
    <label>{{ label | translate }}:</label>
    <ng-container *ngTemplateOutlet="datepickerTemplate"></ng-container>
  </div>
</ng-container>

<ng-template #datepickerTemplate>
  <div class="pos-relative" [ngbPopover]="popContent" triggers="manual" placement="bottom" #p="ngbPopover" popoverClass="p-0" [autoClose]="false">
    <div class="input-group input-group-sm w-auto" [ngClass]="{ 'mx-2': !label }">
      <input class="form-control" placeholder="DD-MM-YYYY" (focus)="open()" [value]="date" [ngClass]="{ 'has-filter': !!date }" readonly />
      <div class="input-group-append">
        <button class="btn btn-primary" (click)="open()" type="button"><i class="fa fa-calendar mr-0"></i></button>
      </div>
    </div>
    <i class="fa fa-remove clear-date hover-bg" [ngClass]="{ 'with-label': !!label }" (click)="clear()" *ngIf="date && clearable"></i>
  </div>
</ng-template>

<ng-template #popContent>
  <app-pager-date-column-filter-datepicker (clickOutside)="close()" (select)="select()" [(ngModel)]="date"></app-pager-date-column-filter-datepicker>
</ng-template>
