import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'findBy' })
export class FindByPipe implements PipeTransform {
  constructor() {}

  /**
   */
  transform(value: any[], key: string, id: any): any {
    return value.find(i => i[key] === id);
  }
}
