export const environment = {
  production: true,
  testing: true,
  STORAGE_KEY: '41f6193d-f1ac-4ade-a5b6-fa792746bc32',
  URL: {
    WEB: 'https://back.test.colegiobennett.edu.co/',
    API: 'https://back.test.colegiobennett.edu.co/api/',
    UPLOADS: 'https://bs2-dev-public.s3.us-east-2.amazonaws.com/',
    WEB2: 'https://dotnet.test.colegiobennett.edu.co:4000/',
    API2: 'https://dotnet.test.colegiobennett.edu.co:4000/api/'
  },
  GOOGLE: {
    CLIENT_ID: '772653512132-peajab5u5ku340icbiv20puet1p6o4u5.apps.googleusercontent.com',
    CALENDAR_API_KEY: 'AIzaSyDPQ35UgTeq68t7ds-CZoiATolB0InCiVk',
    RECAPTCHA: '6LcDzWwUAAAAAPuBtWhc2yoDkhqBrdNxbI6VkqkV',
    MAPS: 'AIzaSyBJ3PqjpfS2qapVGHry44ncxu0W_aFiRAU'
  }
};
