import { Component, Input, OnInit } from '@angular/core';

import { LocalDt } from '../local-dt';

@Component({
  selector: 'app-local-size',
  templateUrl: './local-size.component.html'
})
export class LocalFilterSizeComponent implements OnInit {
  @Input()
  localDt: LocalDt;

  constructor() {}

  ngOnInit() {}
}
