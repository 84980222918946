import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class PublicationDateTypeService {
  public static BASE_URL_API = environment.URL.API + 'publication-date-type';

  constructor(private http: HttpService) {}

  /**
   * List publication date types
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PublicationDateTypeService.BASE_URL_API}?${params}`, httpRequestHandler);
  }

  /**
   * List publication date types
   */
  list = () => {
    return this.http.observableGet(`${PublicationDateTypeService.BASE_URL_API}/list`, { mapFn: res => res.publicationDateTypes });
  };

  /**
   * Store publication date type
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(PublicationDateTypeService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Delete publication date type
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${PublicationDateTypeService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Get publication date type
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PublicationDateTypeService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Update publication date type
   */
  update(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${PublicationDateTypeService.BASE_URL_API}`, data, httpRequestHandler);
  }
}
