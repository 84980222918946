import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum PaymentSessionStatus {
  Pending = 1,
  Approved = 2,
  Rejected = 3,
  Failed = 4
}

@Injectable()
export class PlaceToPayService {
  public static BASE_URL_API = environment.URL.API + 'place-to-pay';

  paymentSessionStatuses = [
    { id: PaymentSessionStatus.Pending, name: '', key: 'electronicPayment.status.pending', class: 'label label-default' },
    { id: PaymentSessionStatus.Approved, name: '', key: 'electronicPayment.status.approved', class: 'label label-success' },
    { id: PaymentSessionStatus.Rejected, name: '', key: 'electronicPayment.status.rejected', class: 'label label-danger' },
    { id: PaymentSessionStatus.Failed, name: '', key: 'electronicPayment.status.failed', class: 'label label-danger' }
  ];

  /**
   * Service constructor
   * @param http HttpService
   * @param translate TranslateService
   */
  constructor(public http: HttpService, translate: TranslateService) {
    const udpateLangValues = () => {
      this.paymentSessionStatuses.forEach(item => (item.name = translate.instant(item.key)));
      this.paymentSessionStatuses = [...this.paymentSessionStatuses];
    };
    udpateLangValues();
    translate.onLangChange.subscribe(value => udpateLangValues());
  }

  /**
   * Get payment session status
   * @param status any
   */
  getPaymentSessionStatus(status: PaymentSessionStatus) {
    return this.paymentSessionStatuses.find(sta => sta.id === status);
  }

  /**
   * Generate receipt session in Place to Pay
   * @param httpRequestHandler HttpRequestHandler
   */
  generateReceiptSession(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PlaceToPayService.BASE_URL_API}/receipt-session`, data, httpRequestHandler);
  }

  /**
   * Generate receipt session in Place to Pay
   * @param httpRequestHandler HttpRequestHandler
   */
  generateAccDocSession(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PlaceToPayService.BASE_URL_API}/acc-doc-session`, data, httpRequestHandler);
  }

  /**
   * Get payment sessions
   * @param httpRequestHandler HttpRequestHandler
   */
  getPaymentSessions(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PlaceToPayService.BASE_URL_API}/payment-sessions?${params}`, httpRequestHandler);
  }

  /**
   * Generate external session in Place to Pay
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  generateExternalSession(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PlaceToPayService.BASE_URL_API}/external-session`, data, httpRequestHandler);
  }

  /**
   * Validate external session in Place to Pay
   * @param code string
   * @param httpRequestHandler HttpRequestHandler
   */
  validateExternalSession(code: string, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PlaceToPayService.BASE_URL_API}/external-session/validate`, { code: code }, httpRequestHandler);
  }

  /**
   * Get payment external types
   */
  listTypes = () => {
    return this.http.observableGet(`${PlaceToPayService.BASE_URL_API}/external-session-types`, { mapFn: res => res.types });
  };
}
