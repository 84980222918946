import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class ScheduleService {
  public static BASE_URL_API = environment.URL.API + 'schedule';

  constructor(private http: HttpService) {}

  /**
   * List schedules
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ScheduleService.BASE_URL_API}?${params}`, httpRequestHandler);
  }

  /**
   * List schedules
   */
  list(httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ScheduleService.BASE_URL_API}/list`, httpRequestHandler);
  }

  /**
   * Store schedule
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(ScheduleService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Delete schedule
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ScheduleService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Get schedule
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ScheduleService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Update schedule
   */
  update(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${ScheduleService.BASE_URL_API}`, data, httpRequestHandler);
  }
}
