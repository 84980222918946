import { EventEmitter, Injectable } from '@angular/core';

import { SignalRService } from '../util';
import { BaseBroadcast } from './base-broadcast';

@Injectable()
export class AccountingDocIssueBroadcast extends BaseBroadcast {
  onInit = new EventEmitter<any>();
  onSent = new EventEmitter<any>();
  onError = new EventEmitter<any>();

  private _onInit = data => this.onInit.emit(data);
  private _onSent = data => this.onSent.emit(data);
  private _onError = data => this.onError.emit(data);

  constructor(signalRService: SignalRService) {
    super(signalRService, 'accounting-doc-issue');

    this.setEvents({
      init: this._onInit,
      sent: this._onSent,
      error: this._onError,
    });
  }
}
