<div class="page-header">
  <div class="page-header-title">
    <span *ngFor="let breadcrumb of breadcrumbs; let last = last">
      <h4 *ngIf="last">{{ (breadcrumb.title || breadcrumb.label) | translate }}</h4>
    </span>
  </div>
  <div class="page-header-breadcrumb">
    <ul class="breadcrumb-title">
      <li class="breadcrumb-item">
        <a [routerLink]="'/'">
          <i class="icofont icofont-home"></i>
        </a>
      </li>
      <ng-container *ngFor="let breadcrumb of breadcrumbs">
        <li class="breadcrumb-item">
          <a [ngClass]="breadcrumb.status === false ? 'not-active': ''" [routerLink]="breadcrumb.url">
            {{ breadcrumb.label | translate }}
          </a>
        </li>
      </ng-container>

      <li class="breadcrumb-item cursor-pointer" *ngIf="last?.help" (click)="showHelp()">
        <i class="help fa" [ngClass]="loading ? 'fa-spinner fa-pulse' : 'fa-question pulse'" [ngbTooltip]="'general.help' | translate"></i>
      </li>
    </ul>
  </div>
</div>
