import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class OtherFinancialTpService {
  public static URL = environment.URL.API + 'other-financial-tp';

  /**
   * Empty constructor
   * @param http HttpService
   */
  constructor(private http: HttpService) {}

  /**
   * List other financial tps
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${OtherFinancialTpService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get other financial tp
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${OtherFinancialTpService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save other financial tp
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(OtherFinancialTpService.URL, data, httpRequestHandler);
  }

  /**
   * Delete other financial tp
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${OtherFinancialTpService.URL}/${id}`, httpRequestHandler);
  }
}
