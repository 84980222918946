import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class SchoolDiaryService {
  public static URL = environment.URL.API2 + 'school-diary';

  constructor(private http: HttpService) {}

  /**
   * List of school diaries
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(id: number, params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${SchoolDiaryService.URL}/group/${id}?${params}`, httpRequestHandler);
  }

  /**
   * Get the school diary
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${SchoolDiaryService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get students for school diary
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  getStudents(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${SchoolDiaryService.URL}/group/${id}/students`, httpRequestHandler);
  }

  /**
   * Save the school diary
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(SchoolDiaryService.URL, data, httpRequestHandler);
  }

  /**
   * Delete the school diary
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${SchoolDiaryService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get data to copy the school diary
   * @param id
   * @param httpRequestHandler
   */
  copy(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${SchoolDiaryService.URL}/copy/${id}`, httpRequestHandler);
  }

  /**
   * Save the copy from the school diary
   * @param data
   * @param httpRequestHandler
   */
  saveCopy(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${SchoolDiaryService.URL}/copy`, data, httpRequestHandler);
  }

  /**
   * Save the feedback observation of the student
   * @param data
   * @param httpRequestHandler
   */
  saveFeedbackObservation(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${SchoolDiaryService.URL}/feedback-observation`, data, httpRequestHandler);
  }
}
