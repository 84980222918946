import { Injectable } from '@angular/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum EnrollmentDocType {
  EnrollmentContract = 1,
  EnrollmentSettlement = 2,
  EnrollmentPromissoryNote = 3,
  EnrollmentRecord = 4,
  TransportContract = 11,
  TransportSettlement = 12,
  TransportPromissoryNote = 13,
}

export enum EnrollmentDocStatus {
  Pending = 1,
  Signed = 2,
  Cancelled = 3,
}

@Injectable()
export class EnrollDocumentService {
  public static URL = environment.URL.API + 'enroll-document';
  public static URL2 = environment.URL.API2 + 'enroll-document';

  readonly uploadDocuments = {
    validTypes: ['image/jpeg', 'image/png', 'image/bmp', 'application/pdf'],
    maxSize: 15
  };

  constructor(private http: HttpService, private fn: FunctionsService) {
  }

  getEnrollmentDocTypeName(type: number) {
    switch (type) {
      case EnrollmentDocType.EnrollmentContract:
        return 'yearContract.enrollmentContract';
      case EnrollmentDocType.EnrollmentSettlement:
        return 'enroll.enrollmentSettlement';
      case EnrollmentDocType.EnrollmentPromissoryNote:
        return 'yearContract.enrollmentPromissoryNote';
      case EnrollmentDocType.EnrollmentRecord:
        return 'enroll.enrollmentRecord';
      case EnrollmentDocType.TransportContract:
        return 'yearContract.transportContract';
      case EnrollmentDocType.TransportSettlement:
        return 'enroll.transportEnrollmentSettlement';
      case EnrollmentDocType.TransportPromissoryNote:
        return 'yearContract.transportPromissoryNote';
      default:
        return '';
    }
  }

  getEnrollmentDocOrderName(order: number, type: number) {
    if (type === EnrollmentDocType.EnrollmentRecord) {
      return 'electronicSignature.signer';
    } else {
      return order === 1 ? 'electronicSignature.debtor' : 'electronicSignature.codebtor';
    }
  }

  getEnrollmentDocStatusName(status: EnrollmentDocStatus) {
    switch (status) {
      case EnrollmentDocStatus.Pending:
        return 'documentVerification.status.pending';
      case EnrollmentDocStatus.Signed:
        return 'documentVerification.status.signed';
      case EnrollmentDocStatus.Cancelled:
        return 'documentVerification.status.cancelled';
      default:
        return '';
    }
  }

  /**
   * Get student documents
   * @param studentId number
   * @param yearId number
   * @param httpRequestHandler HttpRequestHandler
   */
  getEnrolledStudentDocuments(studentId: number, yearId: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${EnrollDocumentService.URL}/${studentId}/enrolled-student-document/${yearId}`, httpRequestHandler);
  }

  /**
   * Download electronic document for no auth
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  downloadElectronicDocumentNoAuth(token: string, data: any, httpRequestHandler: HttpRequestHandler) {
    data._token = token;
    httpRequestHandler.includeToken = false;
    return this.http.post(`${EnrollDocumentService.URL}-no-auth/download-electronic-document`, data, httpRequestHandler);
  }

  /**
   * Download electronic document
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  downloadElectronicDocument(data: any, httpRequestHandler: HttpRequestHandler) {
    return this.http.post(`${EnrollDocumentService.URL}/download-electronic-document`, data, httpRequestHandler);
  }

  /**
   * Get student documents
   * @param enrollmentId number
   * @param httpRequestHandler HttpRequestHandler
   */
  getPendingSignatures(enrollmentId: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${EnrollDocumentService.URL}/pending-signatures/${enrollmentId}`, httpRequestHandler);
  }

  /**
   * Re notify token
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  reNotifyToken(data: any, httpRequestHandler: HttpRequestHandler) {
    return this.http.post(`${EnrollDocumentService.URL}/re-notify-token`, data, httpRequestHandler);
  }

  /**
   * Guardar documento de estudiante de matricula
   */
  saveStudentDocument(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollDocumentService.URL}/upload/student-document`, data, httpRequestHandler);
  }

  /**
   * Eliminar documento de estudiante de matricula
   */
  deleteStudentDocument(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${EnrollDocumentService.URL}/student-document/${id}`, httpRequestHandler);
  }

  /**
   * Guardar documento de acudiente de matricula
   */
  saveAttendantDocument(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollDocumentService.URL}/upload/attendant-document`, data, httpRequestHandler);
  }

  /**
   * Eliminar documento de acudiente de matricula
   */
  deleteAttendantDocument(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${EnrollDocumentService.URL}/attendant-document/${id}`, httpRequestHandler);
  }

  /**
   * Guardar documento de otro representante financiero de matricula
   */
  saveOtherFrDocument(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollDocumentService.URL}/upload/other-fr-document`, data, httpRequestHandler);
  }

  /**
   * Eliminar documento de otro representante financiero de matricula
   */
  deleteOtherFrDocument(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${EnrollDocumentService.URL}/other-fr-document/${id}`, httpRequestHandler);
  }

  /**
   * Enviar matricula a revision de documentos
   */
  sendForReview(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollDocumentService.URL}/send-for-review`, { id }, httpRequestHandler);
  }

  /**
   * Datos de revision de documentos
   */
  documentReview(yearId: number, familyId: number, studentId: number, httpRequestHandler: HttpRequestHandler) {
    const data: any = {};
    if (familyId) {
      data.familyId = familyId;
    } else if (studentId) data.studentId = studentId;

    this.http.get(`${EnrollDocumentService.URL}/document-review/${yearId}?${this.fn.objectToQueryParams(data)}`, httpRequestHandler);
  }

  /**
   * Guardar estado de revision de documento
   */
  setDocumentReview(data, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollDocumentService.URL}/document-review`, data, httpRequestHandler);
  }

  /**
   * Guardar estado de aplicación de revisión de documentos de acudiente
   */
  setDocumentReviewApply(data, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollDocumentService.URL}/document-review-apply`, data, httpRequestHandler);
  }

  /**
   * Cambiar de estado de matricula a "Matricula Financiera"
   * @param id Id de matricula
   */
  sendToFinancial(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollDocumentService.URL}/send-to-financial`, { id }, httpRequestHandler);
  }

  /**
   * Cambiar de estado de matricula a "Carga de Documentos"
   * @param id Id de matricula
   */
  sendToDocumentUpload(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollDocumentService.URL}/send-to-document-upload`, { id }, httpRequestHandler);
  }

  /**
   * Guardar estado de revision de firma de documento
   */
  setDocumentSignatureReview(data, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollDocumentService.URL}/document-signature-review`, data, httpRequestHandler);
  }

  /**
   * Datos para verificacion de validez de documento
   */
  documentVerification(guid: string, httpRequestHandler: HttpRequestHandler) {
    httpRequestHandler.includeToken = false;
    this.http.get(`${environment.URL.API}enroll-document-verification/${guid}`, httpRequestHandler);
  }

  /**
   * Cambiar mensaje de estado a documento en revisión
   */
  udpateStatusMsg(data, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollDocumentService.URL}/document-status-msg`, data, httpRequestHandler);
  }

  /**
   * Cambiar tipo de pago 50/50 a matricula
   */
  fiftyFiftyPayment(data, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollDocumentService.URL}/fifty-fifty-payment`, data, httpRequestHandler);
  }

  /**
   * Descargar documento de estudiante de años anteriores
   */
  downloadLastStuDoc(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${EnrollDocumentService.URL}/download-last-stu-doc/${id}`, httpRequestHandler);
  }

  /**
   * Descargar documento de acudiente de años anteriores
   */
  downloadLastAttDoc(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${EnrollDocumentService.URL}/download-last-att-doc/${id}`, httpRequestHandler);
  }

  /**
   * Descargar documento de rep. financiero de años anteriores
   */
  downloadLastFrDoc(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${EnrollDocumentService.URL}/download-last-fr-doc/${id}`, httpRequestHandler);
  }

  /**
   * Notificar documentos pendientes del estudiante
   * @param enrollmentId
   * @param httpRequestHandler
   */
  notifyPendingDocuments(enrollmentId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${EnrollDocumentService.URL2}/notify-pending-documents/${enrollmentId}`, httpRequestHandler);
  }
}
