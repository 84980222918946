import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { CustomAsyncValidation, CustomValidators } from '../../../modules/shared/form';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class NotificationTypeService {
  public static BASE_URL_API = environment.URL.API + 'notification-type';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado Tipos de Notificación.
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(NotificationTypeService.BASE_URL_API + '?' + params, httpRequestHandler);
  }

  /**
   * Crear Tipo de Notificación.
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(NotificationTypeService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Eliminar Tipo de Notificación.
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${NotificationTypeService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Editar Tipo de Notificación.
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${NotificationTypeService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar Tipo de Notificación.
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${NotificationTypeService.BASE_URL_API}/${id}`, data, httpRequestHandler);
  }

  /**
   * Listado de Tipos de Notificación.
   */
  list(httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${NotificationTypeService.BASE_URL_API}/list`, httpRequestHandler);
  }

  /**
   * Validacion de existencia de nombre de Tipo de Notificación.
   */
  exists: CustomAsyncValidation<any> = (id, name) => {
    return this.http.observableGet(`${NotificationTypeService.BASE_URL_API}/exists?name=${encodeURIComponent(name)}&id=${id}`, {
      mapFn: CustomValidators.asyncMapFn
    });
  };
}
