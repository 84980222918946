import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class ExtracurricularPriceService {
  public static URL = environment.URL.API2 + 'extracurricular-price';

  constructor(private http: HttpService) {
  }

  /**
   * List Extracurricular Prices
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtracurricularPriceService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get Extracurricular Price
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtracurricularPriceService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Extracurricular Price
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(ExtracurricularPriceService.URL, data, httpRequestHandler);
  }

  /**
   * Delete Extracurricular Price
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ExtracurricularPriceService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List Extracurricular Prices for select component
   */
  list = () => {
    return this.http.observableGet(`${ExtracurricularPriceService.URL}/list`, { mapFn: res => res.extracurricularPrices });
  };

  /**
   * Get Extracurricular Activities from an Extracurricular Plan General with their prices
   */
  getExtracurricularActivities(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtracurricularPriceService.URL}/${data.yearId}/${data.daysQty}`, httpRequestHandler);
  }
}
