import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum TicketStatus {
  Pending = 1,
  Authorized = 2,
  Requested = 3,
  Viewed = 4,
  InProcess = 5,
  Finished = 6,
  Closed = 7,
  Rated = 8,
  Cancelled = 9,
  NotAuthorized = 10,
}

export enum TicketObservationType {
  NotAuthorized = 1,
  Authorized = 2,
  Canceled = 3,
  Rated = 4,
  Closed = 5,
}

export enum TicketFrom {
  MyTickets = 1,
  Authorization = 2,
  Tracking = 3,
  Assigned = 4,
}

export enum TicketAssigneeType {
  Employee = 1,
  Provider = 2,
}

@Injectable()
export class TicketService {
  public static URL = environment.URL.API + 'ticket';

  statuses = [
    { id: TicketStatus.Pending, key: 'ticket.status.pending', name: '', class: 'label label-default' },
    { id: TicketStatus.Authorized, key: 'ticket.status.authorized', name: '', class: 'label label-primary' },
    { id: TicketStatus.Requested, key: 'ticket.status.requested', name: '', class: 'label label-primary' },
    { id: TicketStatus.Viewed, key: 'ticket.status.viewed', name: '', class: 'label label-info' },
    { id: TicketStatus.InProcess, key: 'ticket.status.inProcess', name: '', class: 'label label-warning' },
    { id: TicketStatus.Finished, key: 'ticket.status.finished', name: '', class: 'label label-success' },
    { id: TicketStatus.Closed, key: 'ticket.status.closed', name: '', class: 'label label-success' },
    { id: TicketStatus.Rated, key: 'ticket.status.evaluated', name: '', class: 'label label-success' },
    { id: TicketStatus.Cancelled, key: 'ticket.status.cancelled', name: '', class: 'label label-danger' },
    { id: TicketStatus.NotAuthorized, key: 'ticket.status.notAuthorized', name: '', class: 'label label-danger' },
  ];

  observationTypes = [
    { id: TicketObservationType.NotAuthorized, key: 'ticket.observationType.notAuthorized', name: '', class: 'label label-danger' },
    { id: TicketObservationType.Authorized, key: 'ticket.observationType.authorized', name: '', class: 'label label-primary' },
    { id: TicketObservationType.Canceled, key: 'ticket.observationType.cancelled', name: '', class: 'label label-danger' },
    { id: TicketObservationType.Rated, key: 'ticket.observationType.rated', name: '', class: 'label label-success' },
    { id: TicketObservationType.Closed, key: 'ticket.observationType.closed', name: '', class: 'label label-success' },
  ];

  assigneeTypes = [
    { id: TicketAssigneeType.Employee, key: 'employee.msgTitle', name: '' },
    { id: TicketAssigneeType.Provider, key: 'provider.msgTitle', name: '' },
  ];

  constructor(private http: HttpService, fn: FunctionsService, translate: TranslateService) {
    const lists = {
      statuses: this.statuses,
      observationTypes: this.observationTypes,
      assigneeTypes: this.assigneeTypes,
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * Get ticket status
   * @param status MyTicketStatus
   */
  getStatus(status: TicketStatus) {
    return this.statuses.find(s => s.id === status);
  }

  /**
   * Get ticket observation type
   * @param type TicketObservationType
   */
  getObservationType(type: TicketObservationType) {
    return this.observationTypes.find(o => o.id === type);
  }

  /**
   * Get ticket assignee type
   * @param person any
   */
  getType(person: any) {
    const type = person.employeeId ? TicketAssigneeType.Employee : TicketAssigneeType.Provider;
    return this.assigneeTypes.find(t => t.id === type);
  }

  /**
   * Create ticket
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  create(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${TicketService.URL}/create`, data, httpRequestHandler);
  }

  /**
   * Get ticket info
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${TicketService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save ticket observation
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  saveObservation(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${TicketService.URL}/observation`, data, httpRequestHandler);
  }

  /**
   * Save tracking info
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  saveTrackingInfo(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${TicketService.URL}/tracking-info`, data, httpRequestHandler);
  }

  /**
   * Close ticket
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  close(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${TicketService.URL}/close`, data, httpRequestHandler);
  }

  /**
   * Finish ticket
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  finish(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${TicketService.URL}/${id}/finish`, httpRequestHandler);
  }

  /**
   * Rate ticket
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  rate(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${TicketService.URL}/rate`, data, httpRequestHandler);
  }
}
