import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum YearFileTypes {
  PromissoryNote = 1,
  Uniforms = 2
}

@Injectable()
export class YearFileService {
  public static URL = environment.URL.API + 'year-file';

  constructor(private http: HttpService) {}

  /**
   * Obtener archivos por año
   * @param yearId number
   * @param httpRequestHandler HttpRequestHandler
   */
  index(yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${YearFileService.URL}/${yearId}`, httpRequestHandler);
  }

  /**
   * Obtener reglas
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${YearFileService.URL}/${id}/rules`, httpRequestHandler);
  }

  /**
   * Save file
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  saveRule(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${YearFileService.URL}/save-rule`, data, httpRequestHandler);
  }

  /**
   * Save file
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  saveYearFile(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${YearFileService.URL}/save-year-file`, data, httpRequestHandler);
  }

  /**
   * Eliminar archivo por año
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${YearFileService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Eliminar archivo por año
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  deleteRule(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${YearFileService.URL}/${id}/rule`, httpRequestHandler);
  }
}
