import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectLookupFn } from 'app/modules/shared/select';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';

export enum FtpReportType {
  Balance = 1,
  BalancePerStudent = 2,
  TransportDefaulters = 3,
  PortfolioByAges = 4,
  PortfolioReconciliation = 5,
  AdvanceByTp = 6,
  RiskCentrals = 7,
}

export enum FtpDetailType {
  DetailedByDocument = 1,
  SummarizedByTp = 2,
}

export enum FtpRiskCentralsType {
  QuestionablePayment = 1,
  PortfolioPunished = 2,
  RecoveredPortfolio = 3,
  Ignore = 4,
}

@Injectable()
export class FinancialTpService {
  public static URL = environment.URL.API + 'financial-tp';
  public static URL2 = environment.URL.API2 + 'financial-tp';

  reportTypes = [
    { id: FtpReportType.Balance, key: 'financialTp.balanceReport', name: '', permission: 'financial_tp_balance_report' },
    { id: FtpReportType.BalancePerStudent, key: 'financialTp.balanceReportPerStudent', name: '', permission: 'financial_tp_balance_report' },
    { id: FtpReportType.TransportDefaulters, key: 'financialTp.transportDefaultersReport', name: '', permission: 'financial_tp_balance_report' },
    { id: FtpReportType.PortfolioByAges, key: 'financialTp.portfolioByAges', name: '' },
    { id: FtpReportType.RiskCentrals, key: 'financialTp.riskCentrals', name: '' },
    { id: FtpReportType.PortfolioReconciliation, key: 'financialTp.portfolioReconciliation', name: '' },
    { id: FtpReportType.AdvanceByTp, key: 'financialTp.advanceByTp', name: '' }
  ];

  detailTypes = [
    { id: FtpDetailType.DetailedByDocument, key: 'financialTp.detailedByDocument', name: '' },
    { id: FtpDetailType.SummarizedByTp, key: 'financialTp.summarizedByTp', name: '' }
  ];

  riskCentralsTypes = [
    { id: FtpRiskCentralsType.QuestionablePayment, key: 'riskCentralsReport.types.questionablePayment', name: '' },
    { id: FtpRiskCentralsType.PortfolioPunished, key: 'riskCentralsReport.types.portfolioPunished', name: '' },
    { id: FtpRiskCentralsType.RecoveredPortfolio, key: 'riskCentralsReport.types.portfolioRecovered', name: '' },
    { id: FtpRiskCentralsType.Ignore, key: 'riskCentralsReport.types.ignore', name: '' }
  ];

  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    fn.refreshListPermissions(this.reportTypes, array => (this.reportTypes = array));

    const lists = {
      reportTypes: this.reportTypes,
      detailTypes: this.detailTypes,
      riskCentralsTypes: this.riskCentralsTypes
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * Listado paginador de terceros financieros
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${FinancialTpService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Datos para carta de representantes financieros
   * @param id Person Id
   */
  getCard(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${FinancialTpService.URL}/${id}/card`, httpRequestHandler);
  }

  /**
   * Listar todos los representantes financieros
   */
  list: SelectLookupFn = data => {
    return this.http.observablePost(FinancialTpService.URL + '/list', data, { mapFn: res => res.data });
  };

  /**
   * Save custom due day
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  saveCustomDueDay(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(FinancialTpService.URL, data, httpRequestHandler);
  }

  /**
   * Consulta de cartera
   */
  getStatement(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${FinancialTpService.URL}/statement`, data, httpRequestHandler);
  }

  /**
   * Listado de estudiantes de representante financiero
   */
  getCharges(id: number, yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${FinancialTpService.URL}/${id}/charges/${yearId}`, httpRequestHandler);
  }

  /**
   * Listado paginador de cobros de representante financiero
   */
  getFrCharges(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${FinancialTpService.URL}/charges?${params}`, httpRequestHandler);
  }

  /**
   * Guardar cobro
   */
  saveCharge(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${FinancialTpService.URL}/charges`, data, httpRequestHandler);
  }

  /**
   * Obtener datos de cobro
   */
  getCharge(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${FinancialTpService.URL}/charges/${id}`, httpRequestHandler);
  }

  /**
   * Eliminar cobro
   */
  deleteCharge(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${FinancialTpService.URL}/charges/${id}`, httpRequestHandler);
  }

  /**
   * Generar reporte
   */
  generateReport(data: any, httpRequestHandler: HttpRequestHandler) {
    if (data.type === FtpReportType.RiskCentrals) {
      this.http.post(`${FinancialTpService.URL}/reports`, data, httpRequestHandler);
    } else {
      this.http.post(`${FinancialTpService.URL}/reports`, data, httpRequestHandler);
    }
  }

  /**
   * Obtener datos de cobro
   */
  getPortfolioByAgePeriods(httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${FinancialTpService.URL}/portfolio-by-ages-periods`, httpRequestHandler);
  }

  /**
   * Generate payment certificate
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  generatePaymentCertificate(data: any, httpRequestHandler: HttpRequestHandler) {
    return this.http.post(`${FinancialTpService.URL}/payment-certificate`, data, httpRequestHandler);
  }

  /**
   * Get payment certificate
   */
  getPaymentCertificate(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${FinancialTpService.URL}/payment-certificate/${id}`, httpRequestHandler);
  }

  /**
   * Get certificate history
   */
  getCertificateHistory(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${FinancialTpService.URL}/certificate-history/${id}`, httpRequestHandler);
  }

  /**
   * Validate payment certificate
   */
  validatePaymentCertificate(guid: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${FinancialTpService.URL}/payment-certificate-verification/${guid}`, httpRequestHandler);
  }

  /**
   * Save risk centrals type
   */
  saveRiskCentralsType(data: any, httpRequestHandler: HttpRequestHandler): Subscription {
    return this.http.post(`${FinancialTpService.URL2}/risk-central-type`, data, httpRequestHandler);
  }

  getRiskCentralsType(riskCentralType?: FtpRiskCentralsType): any {
    return this.riskCentralsTypes.find(x => x.id === riskCentralType);
  }
}
