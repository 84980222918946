import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { FormControlErrorModule } from '../form-control-error';
import { ModalModule } from '../modal/modal.module';
import { SelectModule } from '../select/select.module';
import { AddressGeneratorComponent } from './address-generator.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    SelectModule,
    FormControlErrorModule,
    NgbTooltipModule,
    ModalModule,
    NgbDropdownModule,
  ],
  declarations: [AddressGeneratorComponent],
  exports: [AddressGeneratorComponent],
})
export class AddressGeneratorModule {}
