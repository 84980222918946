import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { FunctionsService, HttpRequestHandler, HttpService } from '../../util';

export enum GradingType {
  None = 0,
  Quantitative = 1,
  Qualitative = 2,
}

export enum PromotionCriteria {
  None = 0,
  Area = 1,
  Subject = 2,
}

export enum AcademicReportType {
  Type1 = 1,
  Type2 = 2,
  Type3 = 3,
  Type4 = 4,
  Type5 = 5,
}

@Injectable()
export class ClassGradingService {
  public static URL = environment.URL.API + 'class-grading';

  gradingTypes = [
    { id: GradingType.None, key: 'general.notApplicable', name: '' },
    { id: GradingType.Quantitative, key: 'gradeConfiguration.gradingTypes.Quantitative', name: '' },
    { id: GradingType.Qualitative, key: 'gradeConfiguration.gradingTypes.Qualitative', name: '' }
  ];

  promotionCriteria = [
    { id: PromotionCriteria.None, key: 'general.notApplicable', name: '' },
    { id: PromotionCriteria.Area, key: 'gradeConfiguration.promotionCriteriaType.byArea', name: '' },
    { id: PromotionCriteria.Subject, key: 'gradeConfiguration.promotionCriteriaType.bySubject', name: '' }
  ];

  academicReportTypes = [
    { id: AcademicReportType.Type1, key: 'gradeConfiguration.academicReportType.type1', name: '' },
    { id: AcademicReportType.Type2, key: 'gradeConfiguration.academicReportType.type2', name: '' },
    { id: AcademicReportType.Type3, key: 'gradeConfiguration.academicReportType.type3', name: '' },
    { id: AcademicReportType.Type4, key: 'gradeConfiguration.academicReportType.type4', name: '' },
    { id: AcademicReportType.Type5, key: 'gradeConfiguration.academicReportType.type5', name: '' }
  ];

  constructor(private http: HttpService, fn: FunctionsService) {
    const lists = {
      gradingTypes: this.gradingTypes,
      promotionCriteria: this.promotionCriteria,
      academicReportTypes: this.academicReportTypes
    };
    fn.handleListTranslation(lists, this);
  }

  /**
   * Mostrar listado de Notas de grupo
   */
  index(id: number, params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ClassGradingService.URL}/group/${id}?${params}`, httpRequestHandler);
  }

  /**
   * Mostrar listado de Notas de grupo
   */
  students(groupId: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${ClassGradingService.URL}/group/${groupId}/students`, httpRequestHandler);
  }

  /**
   * Eliminar Nota
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ClassGradingService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Editar Nota
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ClassGradingService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Datos de Nota
   */
  info(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ClassGradingService.URL}/${id}/info`, httpRequestHandler);
  }

  /**
   * Listado de grupos asignaturas
   */
  groups(yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ClassGradingService.URL}/groups/${yearId}`, httpRequestHandler);
  }

  /**
   * Guardar Nota
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ClassGradingService.URL}`, data, httpRequestHandler);
  }

  /**
   * Listado de notas de estudiantes
   */
  gradings(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ClassGradingService.URL}/${id}/gradings`, httpRequestHandler);
  }

  /**
   * Guardar Notas de estudiantes
   */
  saveGradings(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ClassGradingService.URL}/gradings`, data, httpRequestHandler);
  }

  /**
   * Datos para copiar nota
   */
  copy(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ClassGradingService.URL}/copy/${id}`, httpRequestHandler);
  }

  /**
   * Copiar nota
   */
  saveCopy(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ClassGradingService.URL}/copy`, data, httpRequestHandler);
  }

  /**
   * Planilla de notas de clase grupo
   */
  worksheet(id: number, periodId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ClassGradingService.URL}/worksheet/${id}/period/${periodId}`, httpRequestHandler);
  }

  /**
   * Exportar planilla de notas de clase grupo
   */
  exportWorksheet(id: number, periodId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ClassGradingService.URL}/worksheet/${id}/period/${periodId}/export`, httpRequestHandler);
  }

  /**
   * Planilla de notas de clase padre
   */
  parentWorksheet(classId: number, groupId: number, periodId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ClassGradingService.URL}/parent-worksheet/${classId}/group/${groupId}/period/${periodId}`, httpRequestHandler);
  }

  /**
   * Planilla de notas de clase deporte
   */
  sportWorksheet(classId: number, groupId: number, periodId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ClassGradingService.URL}/sport-worksheet/${classId}/group/${groupId}/period/${periodId}`, httpRequestHandler);
  }

  /**
   * Guardar valoracion de nota en planilla
   */
  saveGrading(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ClassGradingService.URL}/worksheet/grading`, data, httpRequestHandler);
  }

  /**
   * Guardar valoracion definitiva de clase
   */
  saveCustomGrading(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ClassGradingService.URL}/custom-grading`, data, httpRequestHandler);
  }

  /**
   * Datos de notas cualitativa de estudiante por periodo
   */
  qualitativeGradings(studentId: number, periodId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ClassGradingService.URL}/qualitative/${studentId}/period/${periodId}`, httpRequestHandler);
  }

  /**
   * Guardar valoracion cualitativa de estuadiante
   */
  saveQualitativeGrading(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ClassGradingService.URL}/qualitative/grading`, data, httpRequestHandler);
  }

  /**
   * Datos del estudiante por periodo
   */
  studentPeriod(studentId: number, periodId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ClassGradingService.URL}/student/${studentId}/period/${periodId}`, httpRequestHandler);
  }

  /**
   * Datos del estudiante finales
   */
  studentFinal(studentId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ClassGradingService.URL}/student/${studentId}/final`, httpRequestHandler);
  }

  /**
   * Cambia de manera manual el valor de la promoción del estudiante
   */
  changePromotion(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ClassGradingService.URL}/change-promotion`, data, httpRequestHandler);
  }

  /**
   * Guardar observacion de estudiante por periodo
   */
  saveStudentPeriod(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ClassGradingService.URL}/student-period`, data, httpRequestHandler);
  }

  /**
   * Informe de periodo de estudiante
   */
  studentPeriodReport(studentId: number, periodId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ClassGradingService.URL}/student/${studentId}/period/${periodId}/report`, httpRequestHandler);
  }

  /**
   * Informe de periodo de estudiante
   */
  studentFinalReport(studentId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ClassGradingService.URL}/student/${studentId}/final-report`, httpRequestHandler);
  }

  /**
   * Informe de periodo de estudiantes de grupo
   */
  groupPeriodReport(groupId: number, periodId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ClassGradingService.URL}/group/${groupId}/period/${periodId}/report`, httpRequestHandler);
  }

  /**
   * Informe final de estudiantes de grupo
   */
  groupFinalReport(groupId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ClassGradingService.URL}/group/${groupId}/final-report`, httpRequestHandler);
  }

  /**
   * Informe de periodo de estudiante (valoración cualitativa)
   */
  studentPeriodQualitativeReport(studentId: number, periodId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ClassGradingService.URL}/student/${studentId}/period/${periodId}/qualitative-report`, httpRequestHandler);
  }

  /**
   * Listado de classes de grupo
   */
  classes(groupId: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${ClassGradingService.URL}/group/${groupId}/classes`, httpRequestHandler);
  }

  /**
   * Listado de clases de deportes por año lectivo
   */
  sportClasses(yearId: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${ClassGradingService.URL}/sport-classes/${yearId}`, httpRequestHandler);
  }

  /**
   * Listado de Grados asociados a un año lectivo validando secciones del usuario
   */
  grades(yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ClassGradingService.URL}/grades/${yearId}`, httpRequestHandler);
  }

  /**
   * Datos básicos de familia de estudiante
   */
  familyInfo(studentId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ClassGradingService.URL}/family-info/${studentId}`, httpRequestHandler);
  }

  /**
   * Consultar notas de un estudiante en un periodo para una asignatura en específico
   */
  getGradings(enrollmentId: number, periodId: number, classId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ClassGradingService.URL}/${enrollmentId}/${periodId}/${classId}/gradings`, httpRequestHandler);
  }

  /**
   * Informe de libros oficiales
   */
  officialBooks(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ClassGradingService.URL}/official-books`, data, httpRequestHandler);
  }
}
