import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ReturnPagerService } from '../../../services/util';
import { GC } from '../global-consts';
import { StorageService } from '../../../services/util/storage.service';

@Component({
  selector: 'app-return-switch',
  templateUrl: './return-switch.component.html',
  styleUrls: ['./return-switch.component.css']
})
export class ReturnSwitchComponent implements OnInit {
  checked = false;

  constructor(private router: Router, private returnPager: ReturnPagerService, private storageService: StorageService) {
  }

  ngOnInit() {
    this.checked = !!this.storageService.get(GC.LS.SWITCHBACK);
  }

  registerOnChanged(value: boolean) {
    this.storageService.set(GC.LS.SWITCHBACK, value);
  }

  handleRedir() {
    if (this.returnPager && this.checked) {
      this.router.navigate(this.returnPager.path, { queryParams: this.returnPager.queryParams });
      return true;
    }
    return false;
  }
}
