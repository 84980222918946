import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoadingContentComponent } from './loading-content.component';

@NgModule({
  imports: [CommonModule],
  declarations: [LoadingContentComponent],
  exports: [LoadingContentComponent],
})
export class LoadingContentModule {}
