import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpRequestStream, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum DefaulterMailTypes {
  General = 1,
  Transport = 2,
  PaymentAgreement = 3,
  TransportCutOff = 4
}

export enum DefaulterNotifyTo {
  Everyone = 1,
  Section = 2,
  Grade = 3
}

export enum MailDefaulterStatus {
  Created = 1,
  PartiallySent = 2,
  Sent = 3
}

@Injectable()
export class DefaulterMailService {
  public static URL = environment.URL.API + 'defaulter-mail';

  defaulterMailTypes = [
    { id: DefaulterMailTypes.General, key: 'general.general', name: '' },
    { id: DefaulterMailTypes.Transport, key: 'menu.main.transport', name: '' },
    { id: DefaulterMailTypes.PaymentAgreement, key: 'paymentAgreement.title', name: '' },
    { id: DefaulterMailTypes.TransportCutOff, key: 'defaulterMail.cutOff', name: '' }
  ];

  defaulterNotifyTo = [
    { id: DefaulterNotifyTo.Everyone, key: 'general.everyone', name: '' },
    { id: DefaulterNotifyTo.Section, key: 'section.msgTitle', name: '' },
    { id: DefaulterNotifyTo.Grade, key: 'grade.msgTitle', name: '' }
  ];

  statuses = [
    { id: MailDefaulterStatus.Created, name: '', key: 'general.created', class: 'label label-default' },
    { id: MailDefaulterStatus.PartiallySent, name: '', key: 'mail.partiallySent', class: 'label label-primary' },
    { id: MailDefaulterStatus.Sent, name: '', key: 'general.sent', class: 'label label-success' }
  ];

  constructor(private http: HttpService, fn: FunctionsService, translate: TranslateService) {
    const lists = {
      defaulterMailTypes: this.defaulterMailTypes,
      defaulterNotifyTo: this.defaulterNotifyTo,
      statuses: this.statuses
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  getType(type: DefaulterMailTypes) {
    return this.defaulterMailTypes.find(t => t.id === type);
  }

  getStatus(status: MailDefaulterStatus) {
    return this.statuses.find(s => s.id === status);
  }

  index(params: string, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${DefaulterMailService.URL}?${params}`, httpRequestHandler);
  }

  listFtps(id: number, params: string, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${DefaulterMailService.URL}/${id}/ftps?${params}`, httpRequestHandler);
  }

  get(id: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${DefaulterMailService.URL}/${id}`, httpRequestHandler);
  }

  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.delete(`${DefaulterMailService.URL}/${id}`, httpRequestHandler);
  }

  save(data: any, httpRequestHandler: HttpRequestHandler) {
    return this.http.post(`${DefaulterMailService.URL}`, data, httpRequestHandler);
  }

  createCutOffMail(data: any, httpRequestHandler: HttpRequestHandler) {
    return this.http.post(`${DefaulterMailService.URL}/cut-off`, data, httpRequestHandler);
  }

  notify(id: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${DefaulterMailService.URL}/${id}/notify`, httpRequestHandler);
  }

  export(id: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${DefaulterMailService.URL}/${id}/export`, httpRequestHandler);
  }

  /**
   * Mailing
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  mailing(id: number, opts: HttpRequestStream) {
    this.http.stream(`${DefaulterMailService.URL}/${id}/mailing`, opts);
  }
}
