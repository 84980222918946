import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class AdditionalReportService {
  public static URL = environment.URL.API + 'additional-report';

  constructor(private http: HttpService) {}

  /**
   * Get additional report
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AdditionalReportService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save additional report
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdditionalReportService.URL}`, data, httpRequestHandler);
  }

  /**
   * List groups
   */
  getGroups(yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AdditionalReportService.URL}/${yearId}/groups`, httpRequestHandler);
  }

  /**
   * List students
   */
  getStudents(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdditionalReportService.URL}/students`, data, httpRequestHandler);
  }

  /**
   * Print report
   */
  print(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AdditionalReportService.URL}/${id}/print`, httpRequestHandler);
  }
}
