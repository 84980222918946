<section class="offline-404 d-flex text-center">
  <!-- Container-fluid starts -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 mt-5">
        <!-- auth body start -->
        <div>
          <h1>
            <i class="fa fa-chain-broken text-danger"></i> {{ 'notFound.title' | translate }}</h1>
          <h2>{{ 'notFound.description' | translate }}</h2>
          <p>
            <strong>{{ 'forbidden.requestedURL' | translate }}:</strong> {{ contentURL }}
          </p>
        </div>
        <!-- auth body end -->
      </div>
    </div>
  </div>
</section>
