import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum TutoringPriceDetailType {
  Online = 1,
  OnSite = 2,
}

@Injectable()
export class TutoringPriceService {
  public static URL = environment.URL.API + 'tutoring-price';

  constructor(private http: HttpService) {}

  /**
   * Index tutoring prices
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(TutoringPriceService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Delete tutoring price
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${TutoringPriceService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get tutoring price
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${TutoringPriceService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save tutoring price
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${TutoringPriceService.URL}`, data, httpRequestHandler);
  }

  /**
   * List tutoring price
   */
  list = () => {
    return this.http.observableGet(`${TutoringPriceService.URL}/list`, { mapFn: res => res.tutoringPrices });
  };
}
