import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class SportsPlaceService {
  public static BASE_URL_API = environment.URL.API + 'sports-place';

  constructor(private http: HttpService) {}

  /**
   * List sport places
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${SportsPlaceService.BASE_URL_API}?${params}`, httpRequestHandler);
  }

  /**
   * List sport places
   */
  list = () => {
    return this.http.observableGet(`${SportsPlaceService.BASE_URL_API}/list`, { mapFn: res => res.places });
  };

  /**
   * Store sport places
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(SportsPlaceService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Delete sport place
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${SportsPlaceService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Get sport place after update
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${SportsPlaceService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Update sport place
   */
  update(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${SportsPlaceService.BASE_URL_API}`, data, httpRequestHandler);
  }
}
