import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../../environments/environment';
import { FunctionsService, HttpRequestHandler, HttpService } from '../../util';

export enum ElectionStatus {
  Created = 1,
  PartialNotified = 2,
  Notified = 3
}

export enum ElectionType {
  Attendants = 1,
  Students = 2,
  StudentsRepresentative = 3
}

export enum ElectionCandidateType {
  Normal = 1,
  Compound = 2,
  Blank = 3
}

@Injectable()
export class ElectionService {
  public static URL = environment.URL.API + 'election';

  statuses = [
    { id: ElectionStatus.Created, key: 'election.status.created', name: '', class: 'label label-default' },
    { id: ElectionStatus.PartialNotified, key: 'election.status.partialNotified', name: '', class: 'label label-primary' },
    { id: ElectionStatus.Notified, key: 'election.status.notified', name: '', class: 'label label-success' }
  ];

  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    const lists = {
      statuses: this.statuses
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * Listado paginador de elecciones
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ElectionService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Reiniciar correos de notificacion de eleccion
   */
  resetNotification(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ElectionService.URL}/${id}/reset-notification`, null, httpRequestHandler);
  }

  /**
   * Notificar a correos de notificacion de eleccion
   */
  notify(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ElectionService.URL}/${id}/notify`, null, httpRequestHandler);
  }

  /**
   * REsultados de elección
   */
  results(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ElectionService.URL}/${id}/results`, httpRequestHandler);
  }

  /**
   * Datos de elección
   */
  getInfo(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ElectionService.URL}/${id}/info`, httpRequestHandler);
  }

  /**
   * Datos de elección de grado
   */
  getGradeInfo(id: number, gradeId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ElectionService.URL}/${id}/info/${gradeId}`, httpRequestHandler);
  }

  /**
   * Datos de eleccion para votacion de tipo personero
   */
  getStudentRepreInfo(id: number, studentId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ElectionService.URL}/${id}/student-repre-info/${studentId}`, httpRequestHandler);
  }

  /**
   * Votar por candidato
   */
  vote(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ElectionService.URL}/vote`, data, httpRequestHandler);
  }

  /**
   * Datos de eleccion para votaciones por direccion de grupo
   */
  votingInfo(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ElectionService.URL}/${id}/voting-info`, httpRequestHandler);
  }

  /**
   * Listado de elecciones activas para votar
   */
  mine(yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ElectionService.URL}/mine/${yearId}`, httpRequestHandler);
  }
}
