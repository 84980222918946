import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class SportItemService {
  public static BASE_URL_API = environment.URL.API + 'sport-item';

  constructor(private http: HttpService) {}

  /**
   * List sport items
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(SportItemService.BASE_URL_API + '?' + params, httpRequestHandler);
  }

  /**
   * Save sport item
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(SportItemService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Delete sport item
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${SportItemService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Get sport item
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${SportItemService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Update sport item
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  update(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${SportItemService.BASE_URL_API}`, data, httpRequestHandler);
  }

  /**
   * List sport items
   */
  list = () => {
    return this.http.observableGet(`${SportItemService.BASE_URL_API}/list`, { mapFn: res => res.items });
  };

  /**
   * Cambiar cantidad en stock para un item de deporte
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  changeQuantity(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${SportItemService.BASE_URL_API}/change-qty`, data, httpRequestHandler);
  }
}
