import { Component, EventEmitter, forwardRef, Host, OnInit, Optional, Output, SkipSelf, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { FunctionsService } from 'app/services/util';
import * as moment from 'moment';

@Component({
  selector: 'app-pager-date-column-filter-datepicker',
  templateUrl: './pager-date-column-filter-datepicker.component.html',
  styleUrls: ['./pager-date-column-filter-datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PagerDateColumnFilterDatepickerComponent),
    },
  ],
})
export class PagerDateColumnFilterDatepickerComponent implements OnInit, ControlValueAccessor {
  value: any;
  private propagateChange = (val: any) => {};
  private touchChange = () => {};

  @Output() select = new EventEmitter<any>();
  @Output() clickOutside = new EventEmitter<any>();

  datePicker: NgbInputDatepicker;
  @ViewChild('dp', { static: false }) set setDp(value: NgbInputDatepicker) {
    this.datePicker = value;
  }

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private cc: ControlContainer,
    private fn: FunctionsService
  ) {}

  ngOnInit() {}

  writeValue(value: any) {
    this.value = value;
    if (this.value) {
      const m = moment(this.value, 'DD-MM-YYYY');
      this.datePicker.navigateTo({ month: m.month() + 1, year: m.year() });
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.touchChange = fn;
  }

  setDisabledState(isDisabled: boolean) {}

  dpSelect(e) {
    const date = e ? this.fn.ngbDateStructToMoment(e).format('DD-MM-YYYY') : null;
    this.propagateChange(date);
    this.select.emit();
  }

  dpClickOutside() {
    this.clickOutside.emit();
  }
}
