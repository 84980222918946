import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

import { ContractFormFrom } from '..';

@Injectable()
export class VoucherService {
  public static URL = environment.URL.API2 + 'voucher';

  constructor(private http: HttpService) {}

  /**
   * Index vouchers
   */
  index(params: string, id: number, from: ContractFormFrom, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${VoucherService.URL}/${id}/${from}?${params}`, httpRequestHandler);
  }

  /**
   * Get voucher
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${VoucherService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Print voucher
   */
  print(id: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${VoucherService.URL}/print/${id}`, httpRequestHandler);
  }

  /**
   * Mark as seen voucher
   */
  markAsSeen(id: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${VoucherService.URL}/mark-as-seen/${id}`, httpRequestHandler);
  }
}
