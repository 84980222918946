import { Component, Input, OnInit } from '@angular/core';

export interface IDashboardItem {
  icon: string;
  title: string;
  desc: string;
  url: string;
  color?: number;
  permission?: string;
  queryParams?: any;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  @Input() items: IDashboardItem[];

  constructor() {}

  ngOnInit() {}
}
