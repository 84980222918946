import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { SessionService } from '../services/util';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(private sessionService: SessionService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (state.url.startsWith('/auth/guest')) this.sessionService.logout(false);
    if (!this.sessionService.token) return true;

    this.router.navigate(['/']);
    return false;
  }
}
