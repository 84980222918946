import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class BlPackageService {
  public static URL = environment.URL.API2 + 'bl-package';

  constructor(private http: HttpService) {}

  /**
   * Index Baby Love Package
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${BlPackageService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Delete Baby Love Package
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${BlPackageService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get Baby Love Package
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${BlPackageService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Baby Love Package
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(BlPackageService.URL, data, httpRequestHandler);
  }

  list = () => {
    return this.http.observableGet(BlPackageService.URL + '/list/', { mapFn: res => res.blDiscount });
  };
}
