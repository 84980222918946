import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../../environments/environment';
import { FunctionsService, HttpRequestHandler, HttpService } from '../../util';

export enum MailLogType {
  Normal = 1,
  Test = 2,
}
@Injectable()
export class MailLogService {
  public static URL = environment.URL.API2 + 'mail-log';

  mailLogtypes = [
    { id: MailLogType.Normal, key: 'mailLog.type.normal', name: '' },
    { id: MailLogType.Test, key: 'mailLog.type.test', name: '' },
  ];

  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    const lists = { mailLogtypes: this.mailLogtypes };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * Index data
   * @param params string
   * @param httpRequestHandler  HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${MailLogService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get log details
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  getLogDetails(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${MailLogService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get chart data
   * @param type number
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  getChartData(type: number, params: string, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${MailLogService.URL}/chart/${type}?${params}`, httpRequestHandler);
  }
}
