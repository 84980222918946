import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class TicketCategoryService {
  public static URL = environment.URL.API + 'ticket-category';

  constructor(private http: HttpService) {}

  /**
   * List Ticket Category
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${TicketCategoryService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get Ticket Category
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${TicketCategoryService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Ticket Category
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(TicketCategoryService.URL, data, httpRequestHandler);
  }

  /**
   * Delete Ticket Category
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${TicketCategoryService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List Ticket Category
   * @param httpRequestHandler HttpRequestHandler
   */
  list = data => {
    return this.http.observablePost(`${TicketCategoryService.URL}/list`, data, { mapFn: res => res.categories });
  };
}
