import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class ExpenseCategoryService {
  public static URL = environment.URL.API2 + 'expense-category';

  constructor(private http: HttpService) {}

  /**
   * Index Expense Category
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExpenseCategoryService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Delete Expense Category
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ExpenseCategoryService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get Expense Category
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExpenseCategoryService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Expense Category
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(ExpenseCategoryService.URL, data, httpRequestHandler);
  }

  list = ({ providerId }) => {
    return this.http.observableGet(`${ExpenseCategoryService.URL}/list/${providerId}`, { mapFn: res => res.expenseCategory });
  };
}
