<div [ngClass]="{ 'p-l-10 p-r-10': !withLabel }">
  <div class="row" *ngIf="withLabel; else noLabelTemplate">
    <div class="col-md-6 pr-1">
      <label class="pull-right mt-1">{{ 'academicYear.msgTitle' | translate }}:</label>
    </div>
    <div class="col-md-6 pl-0" style="width: 100%"><ng-container *ngTemplateOutlet="selectTemplate"></ng-container></div>
  </div>
  <ng-template #noLabelTemplate> <ng-container *ngTemplateOutlet="selectTemplate"></ng-container> </ng-template>
</div>
<ng-template #selectTemplate>
  <app-select
    [selectOptions]="items"
    [(ngModel)]="selected"
    (itemChange)="value.next($event?.id)"
    [searchable]="false"
    [appendTo]="withLabel ? null : 'body'"
    [clearable]="clearable"
    [ngClass]="{ 'has-filter': !!selected }"
    (itemChange)="itemChange()"
  ></app-select>
</ng-template>
