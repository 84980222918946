import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../../environments/environment';
import { FunctionsService, HttpRequestHandler, HttpService } from '../../util';

export enum GradeGroupType {
  Normal = 1,
  English = 2,
  Artistic = 3,
}

export enum GradeGroupReportType {
  StudentsList = 1,
}

@Injectable()
export class GradeGroupService {
  public static URL = environment.URL.API + 'grade-group';

  groupList = [
    { id: 'A', name: 'A' },
    { id: 'B', name: 'B' },
    { id: 'C', name: 'C' },
    { id: 'D', name: 'D' },
    { id: '1', name: '1' },
    { id: '2', name: '2' },
    { id: '3', name: '3' },
    { id: '4', name: '4' },
    { id: 'I', name: 'I' },
    { id: 'II', name: 'II' },
    { id: 'III', name: 'III' },
    { id: 'IV', name: 'IV' },
  ];

  types = [
    { id: GradeGroupType.Normal, key: 'gradeGroup.type.normal', name: '' },
    { id: GradeGroupType.English, key: 'gradeGroup.type.english', name: '' },
    { id: GradeGroupType.Artistic, key: 'gradeGroup.type.artistic', name: '' },
  ];

  reportTypes = [{ id: GradeGroupReportType.StudentsList, key: 'gradeGroup.reportType.studentsList', name: '' }];

  constructor(private http: HttpService, private fn: FunctionsService, translate: TranslateService) {
    const lists = {
      types: this.types,
      reportTypes: this.reportTypes,
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  getType(type: GradeGroupType) {
    return this.types.find(t => t.id === type);
  }

  /**
   * Mostrar listado de Grupos
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(GradeGroupService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Eliminar Grupo
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${GradeGroupService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Editar Grupo
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${GradeGroupService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar Grupo
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${GradeGroupService.URL}/${id}`, data, httpRequestHandler);
  }

  /**
   * Guardar Grupo
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${GradeGroupService.URL}`, data, httpRequestHandler);
  }

  /**
   * Listado de Grupos asociados a un año lectivo.
   * @param yearId ID de año lectivo
   */
  fromYear = data => {
    return this.http.observableGet(`${GradeGroupService.URL}/list/year/${data.yearId}`, { mapFn: res => res.gradeGroups });
  };

  /**
   * Consulta por año y tipo de grupo
   */
  getByYearAndType(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${GradeGroupService.URL}/year-type`, data, httpRequestHandler);
  }

  /**
   * Listado de Grupos asociadas a un grado.
   * @param yearGradeId
   */
  fromGrade = data => {
    return this.http.observableGet(`${GradeGroupService.URL}/list/grade/${data.yearGradeId}`, { mapFn: res => res.gradeGroups });
  };

  /**
   * Listados de Estudiantes asociados y NO asociados a un grupo.
   * @param yearId ID de año lectivo
   */
  students(id: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${GradeGroupService.URL}/students/${id}`, httpRequestHandler);
  }

  /**
   * Asociar un estududiante al grupo.
   */
  addStudent(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${GradeGroupService.URL}/add-student`, data, httpRequestHandler);
  }

  /**
   * Desasociar un Estududiante del grupo.
   */
  removeStudent(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${GradeGroupService.URL}/remove-student/${id}`, httpRequestHandler);
  }

  /*
   * Generate grade group report
   */
  generateReport(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${GradeGroupService.URL}/reports`, data, httpRequestHandler);
  }
}
