import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'environments/environment';
import { RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPermissionsModule } from 'ngx-permissions';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { AuthGuard, GuestGuard, LoginGuard, PermissionGuard } from './guards';
import { BoxLayoutGuard } from './guards/box-layout.guard';
import { FamilyGuard } from './guards/family.guard';
import { ForbiddenComponent } from './modules/error/forbidden/forbidden.component';
import { NotFoundComponent } from './modules/error/not-found/not-found.component';
import { PlaceToPayFaqComponent } from './modules/error/place-to-pay-faq/place-to-pay-faq.component';
import { AdminLayoutComponent } from './modules/layouts/admin/admin-layout.component';
import { BreadcrumbsComponent } from './modules/layouts/admin/breadcrumbs/breadcrumbs.component';
import { TitleComponent } from './modules/layouts/admin/title/title.component';
import { AuthLayoutComponent } from './modules/layouts/auth/auth-layout.component';
import { NoauthLayoutComponent } from './modules/layouts/noauth/noauth-layout.component';
import { ScrollModule } from './modules/scroll/scroll.module';
import { SharedModule } from './modules/shared/shared.module';
import { UserService } from './services/api';
import {
  CacheInterceptor,
  FunctionsService,
  HttpService,
  NotifyMessageService,
  SessionService,
  SignalRService,
  SwalMessageService
} from './services/util';
import { StorageService } from './services/util/storage.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const recaptchaSettings: RecaptchaSettings = { siteKey: environment.GOOGLE.RECAPTCHA };

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    SharedModule,
    RouterModule.forRoot(AppRoutes),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    RecaptchaModule,
    InfiniteScrollModule,
    ScrollModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    NoauthLayoutComponent,
    BreadcrumbsComponent,
    TitleComponent,
    ForbiddenComponent,
    NotFoundComponent,
    PlaceToPayFaqComponent
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: RECAPTCHA_SETTINGS, useValue: recaptchaSettings },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    HttpService,
    NotifyMessageService,
    SwalMessageService,
    FunctionsService,
    UserService,
    SessionService,
    StorageService,
    AuthGuard,
    PermissionGuard,
    LoginGuard,
    BoxLayoutGuard,
    GuestGuard,
    FamilyGuard,
    SignalRService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
