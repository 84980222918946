import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

export enum AvailableFor {
  GreaterGrade = 1,
  LowerGrade = 2,
  Everyone = 3
}

export enum DiscountType {
  Student = 1,
  Family = 2,
  Custom = 3,
  Automatic = 4
}

@Injectable()
export class DiscountService {
  public static URL = environment.URL.API + 'discount';

  availableFor = [
    { id: AvailableFor.GreaterGrade, key: 'discount.availableFor.greaterGrade', name: '' },
    { id: AvailableFor.LowerGrade, key: 'discount.availableFor.lowerGrade', name: '' },
    { id: AvailableFor.Everyone, key: 'discount.availableFor.everyone', name: '' }
  ];

  constructor(private http: HttpService, translate: TranslateService) {
    const udpateLangValues = () => {
      this.availableFor.forEach(item => (item.name = translate.instant(item.key)));
      this.availableFor = [...this.availableFor];
    };
    udpateLangValues();
    translate.onLangChange.subscribe(value => udpateLangValues());
  }

  /**
   * Mostrar listado de descuentos
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(DiscountService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Guardar descuento
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(DiscountService.URL, data, httpRequestHandler);
  }

  /**
   * Eliminar descuento
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${DiscountService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Editar descuento
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${DiscountService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Listado de descuentos para combo
   */
  list = () => {
    return this.http.observableGet(`${DiscountService.URL}/list`, { mapFn: res => res.discounts });
  };

  /**
   * Revalidación de descuentos
   * @param yearId number
   * @param httpRequestHandler HttpRequestHandler
   */
  discountRevalidation(yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${DiscountService.URL}/${yearId}/revalidation`, httpRequestHandler);
  }
}
