import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class SubjectService {
  public static URL = environment.URL.API + 'subject';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de Asignaturas
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(SubjectService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Eliminar Asignatura
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${SubjectService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Editar Asignatura
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${SubjectService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar Asignatura
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${SubjectService.URL}/${id}`, data, httpRequestHandler);
  }

  /**
   * Guardar Asignatura
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${SubjectService.URL}`, data, httpRequestHandler);
  }

  /**
   * Listado de Asignaturas para combo.
   */
  list = () => {
    return this.http.observableGet(`${SubjectService.URL}/list`, { mapFn: res => res.subjects });
  };

  /**
   * Listado de Grados asociados a un año lectivo validando secciones del usuario
   * @param yearId ID de año lectivo
   */
  fromYearAcademic = data => {
    return this.http.observableGet(`${SubjectService.URL}/list/year/${data.yearId}/academic`, { mapFn: res => res.subjects });
  };

  /**
   * Listado de Asignaturas Padres
   * @param areaId ID de año lectivo
   */
  parent = data => {
    return this.http.observableGet(`${SubjectService.URL}/list/parent/${data.areaId}`, { mapFn: res => res.subjects });
  };
}
