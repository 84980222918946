import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class DepartmentService {
  public static URL = environment.URL.API + 'department';

  constructor(private http: HttpService) {}

  /**
   * List Departments
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  /*   index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(DepartmentService.URL + '?' + params, httpRequestHandler);
  } */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${DepartmentService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Save Department
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(DepartmentService.URL, data, httpRequestHandler);
  }

  /**
   * Delete Department
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${DepartmentService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get Department
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${DepartmentService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List Departments
   * @param httpRequestHandler HttpRequestHandler
   */
  list = () => {
    return this.http.observableGet(`${DepartmentService.URL}/list`, { mapFn: res => res.departments });
  };
}
