import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'implode' })
export class ImplodePipe implements PipeTransform {
  constructor() {}

  transform(value: string[], separator: string = ','): string {
    return value.join(separator);
  }
}
