import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';

import { IPagerFilter, LabelPosition, PagerService } from '../../../../services/util';
import { SelectOptions } from '../../select';

@Component({
  selector: 'app-pager-select-column-filter',
  templateUrl: './pager-select-column-filter.component.html',
})
export class PagerSelectColumnFilterComponent implements OnInit, IPagerFilter {
  @Input() items: any[];
  @Input() valueField = 'id';
  @Input() displayField = 'name';
  @Input() name: string;
  @Input() label: string;
  @Input() labelPos: LabelPosition = 'left';
  @Input() default: any;
  @Input() searchable = true;
  @Input() clearable = true;
  @Input() selectOptions: SelectOptions;
  @Input() multi = false;

  @ContentChild(TemplateRef, { static: false }) templateRef: TemplateRef<any>;

  selected: any;
  value: Subject<any> = new Subject();

  constructor(private pager: PagerService) {}

  ngOnInit() {
    this.pager.filterInitiated(this);
  }

  valueReady(value) {
    if (value) {
      if (this.multi) this.selected = value.split('|').map(v => +v);
      else this.selected = +value;
    }
  }

  onChange(e) {
    if (!e) this.value.next(null);
    else {
      if (this.multi) {
        const val: any[] = e.map(i => i[this.valueField]);
        this.value.next(val.length ? val.join('|') : null);
      } else {
        this.value.next(e[this.valueField]);
      }
    }
  }

  select(e) {
    this.value.next(e);
    this.selected = +e;
  }
}
