import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum PollItemType {
  Employee = 1,
  Student = 2,
}

@Injectable()
export class PollItemService {
  public static URL = environment.URL.API2 + 'poll-item';

  types = [
    { id: PollItemType.Employee, key: 'employee.msgTitle', name: '' },
    { id: PollItemType.Student, key: 'student.msgTitle', name: '' },
  ];

  constructor(private http: HttpService, private fn: FunctionsService, translate: TranslateService) {
    const lists = { types: this.types };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  getType(type: PollItemType) {
    return this.types.find(t => t.id === type);
  }

  /**
   * List poll items
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PollItemService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get poll item
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PollItemService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save poll item
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(PollItemService.URL, data, httpRequestHandler);
  }

  /**
   * Delete poll item
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${PollItemService.URL}/${id}`, httpRequestHandler);
  }
}
