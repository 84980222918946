import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pager-button-delete',
  templateUrl: './pager-button-delete.component.html',
  styleUrls: ['./pager-button-delete.component.scss'],
})
export class PagerButtonDeleteComponent implements OnInit {
  @Input()
  permission: string;

  @Input()
  disabled = false;

  constructor() {}

  ngOnInit() {}
}
