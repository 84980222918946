import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PermissionService } from '../../../services/api';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.css'],
  providers: [PermissionService]
})
export class ForbiddenComponent implements OnInit {
  contentURL: string;
  permission: any;

  constructor(activated: ActivatedRoute, private permissionService: PermissionService) {
    activated.queryParams.subscribe(params => {
      this.contentURL = decodeURIComponent(params.url);

      if (params.permission) this.permissionService.getByName(params.permission, { success: res => (this.permission = res.permission) });
    });
  }

  ngOnInit() {}
}
