import { Injectable } from '@angular/core';
import { SelectLookupFn } from 'app/modules/shared/select';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class NeighborhoodService {
  public static URL = environment.URL.API + 'neighborhood';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de barrio
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(NeighborhoodService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Agregar barrio
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(NeighborhoodService.URL, data, httpRequestHandler);
  }

  /**
   * Eliminar barrio
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${NeighborhoodService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Obtener barrio
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${NeighborhoodService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar barrio
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${NeighborhoodService.URL}/${id}`, data, httpRequestHandler);
  }

  /**
   * listado async de barrios para select
   */
  list: SelectLookupFn = data => {
    return this.http.observablePost(`${NeighborhoodService.URL}/list`, data, { mapFn: res => res.neighborhoods });
  };

  /**
   * Listado de barrios con sector para año lectivo
   */
  fromYear = ({ yearId }) => {
    return this.http.observableGet(`${NeighborhoodService.URL}/list/year/${yearId}`, { mapFn: res => res.neighborhoods });
  };

  getByYear(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${NeighborhoodService.URL}/list`, data, httpRequestHandler);
  }
}
