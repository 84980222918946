import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';

@Injectable()
export class PayrollProvisionService {

  public static URL = environment.URL.API2 + 'payroll-provision';

  constructor(private http: HttpService) {
  }

  /**
   * Index Payroll Provision
   */
  index(params: string, httpRequestHandler: HttpRequestHandler): Subscription {
    return this.http.get(`${PayrollProvisionService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Validate Payroll Provision
   */
  validate(data: any, httpRequestHandler: HttpRequestHandler): Subscription {
    return this.http.post(`${PayrollProvisionService.URL}/validate`, data, httpRequestHandler);
  }

  /**
   * Process Payroll Provision
   */
  process(data: any, httpRequestHandler: HttpRequestHandler): Subscription {
    return this.http.post(PayrollProvisionService.URL, data, httpRequestHandler);
  }

  /**
   * Delete Payroll Provision
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler): Subscription {
    return this.http.delete(`${PayrollProvisionService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get Payroll Provision
   */
  get(id: number, params: string, httpRequestHandler: HttpRequestHandler): Subscription {
    return this.http.get(`${PayrollProvisionService.URL}/${id}?${params}`, httpRequestHandler);
  }

  /**
   * Download Payroll Provision File
   */
  downloadFile(id: number, httpRequestHandler: HttpRequestHandler): Subscription {
    return this.http.get(`${PayrollProvisionService.URL}/${id}/download`, httpRequestHandler);
  }
}
