import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Host,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  SkipSelf,
  ViewChild
} from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputNumberComponent)
    }
  ]
})
export class InputNumberComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() formControlName: string;
  @Input() readonly = false;
  @Input() decimals: number;
  @Input() negative = false;
  @Input() thousandsSeparator = true;
  @Input() size = 'sm';
  @Input() inputClass = '';

  @Output() valueChange = new EventEmitter<any>();
  @Output() blur = new EventEmitter();
  @Output() onFocus = new EventEmitter();
  @Output() ready = new EventEmitter<InputNumberComponent>();

  @ViewChild('input', { static: false }) input: ElementRef;

  numberMask: any;
  control: AbstractControl;
  value: any;
  disabled = false;
  inputId: string;
  private propagateChange = (val: any) => {
  };
  private touchChange = () => {
  };

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private cc: ControlContainer
  ) {
    this.numberMask = {
      mask: createNumberMask({
        prefix: '',
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: '.',
        allowDecimal: false,
        allowNegative: false,
        allowLeadingZeroes: false
      }),
      modelClean: true
    };
  }

  ngOnInit() {
    if (this.formControlName) this.control = this.cc.control.get(this.formControlName);
    this.ready.emit(this);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.decimals || changes.negative || changes.thousandsSeparator) {
      const maskData: any = {
        prefix: '',
        includeThousandsSeparator: this.thousandsSeparator,
        thousandsSeparatorSymbol: '.',
        allowNegative: this.negative,
        allowLeadingZeroes: false
      };

      if (this.decimals) {
        maskData.allowDecimal = true;
        maskData.decimalSymbol = ',';
        maskData.decimalLimit = this.decimals;
      }

      this.numberMask.mask = createNumberMask(maskData);
    }
  }

  writeValue(value: any) {
    this.value = value ? value.toString().replace('.', ',') : value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.touchChange = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  onBlur() {
    if (this.readonly) return;
    this.touchChange();
    if (this.control) this.control.updateValueAndValidity();
    this.blur.emit();
  }

  change(e) {
    let value = this.value;
    if (value == null || value === '') {
      value = null;
    } else {
      value = value.toString().replaceAll('.', '').replaceAll(',', '.');
      value = parseFloat(value);
    }
    this.propagateChange(value);
    this.valueChange.emit(value);
  }

  onKeypress(e: KeyboardEvent) {
    const target = <any>e.target;
    if (e.key === '.') {
      if (target.selectionStart === 0 && target.value === '') {
        target.value = '0,';
      } else {
        const temp = target.selectionStart;
        target.value = target.value.slice(0, temp) + ',' + target.value.slice(temp);
      }
    }
  }

  focus(select: boolean) {
    this.input.nativeElement.focus();
    setTimeout(() => {
      if (select) this.input.nativeElement.select();
    }, 0);
  }
}
