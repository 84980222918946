import { Component, Input, OnInit } from '@angular/core';

import { PagerService } from '../../../../services/util';

@Component({
  selector: 'app-pager-button-edit',
  templateUrl: './pager-button-edit.component.html',
  styleUrls: ['./pager-button-edit.component.scss']
})
export class PagerButtonEditComponent implements OnInit {
  @Input()
  link: any[];

  @Input()
  permission: string;

  constructor(public pager: PagerService) {}

  ngOnInit() {}
}
