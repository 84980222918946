<ngb-datepicker
  #dt
  class="no-margins"
  (select)="onDateSelection($event)"
  [dayTemplate]="t"
  [displayMonths]="months"
  [firstDayOfWeek]="7"
></ngb-datepicker>

<ng-template #t let-date="date" let-focused="focused">
  <span
    class="custom-day"
    [class.focused]="focused"
    [class.range]="isRange(date)"
    [class.faded]="isHovered(date) || isInside(date)"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null"
  >
    {{ date.day }}
  </span>
</ng-template>
