import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { CustomAsyncValidation, CustomValidators } from '../../../modules/shared/form';
import { FunctionsService, HttpRequestHandler, HttpService } from '../../util';

declare const $;

@Injectable()
export class RoleService {
  public static URL = environment.URL.API + 'role';

  constructor(private http: HttpService, private fn: FunctionsService) {}

  /**
   * Mostrar listado de roles
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(RoleService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Agregar rol
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(RoleService.URL, data, httpRequestHandler);
  }

  /**
   * Eliminar rol
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${RoleService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Obtener rol
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${RoleService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar rol
   */
  update(id: number, data: any, initialChildrenRoles: any[], httpRequestHandler: HttpRequestHandler) {
    const newData = $.extend(
      true,
      {
        childrenRoles: this.fn.arrayDifference(initialChildrenRoles, data.childrenRolesId),
      },
      data
    );
    delete newData.childrenRolesId;
    this.http.put(`${RoleService.URL}/${id}`, newData, httpRequestHandler);
  }

  /**
   * Listado de roles para select
   */
  list = () => {
    return this.http.observableGet(`${RoleService.URL}/list`, { mapFn: res => res.roles });
  };

  /**
   * Obtener permisos del rol
   */
  permissions(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${RoleService.URL}/${id}/permissions`, httpRequestHandler);
  }

  /**
   * Guardar permisos del rol
   */
  storePermissions(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${RoleService.URL}/${id}/permissions`, data, httpRequestHandler);
  }

  /**
   * Validacion de existencia de nombre de rol
   */
  exists: CustomAsyncValidation<any> = (id, name) => {
    return this.http.observableGet(`${RoleService.URL}/exists?name=${encodeURIComponent(name)}&id=${id}`, {
      mapFn: CustomValidators.asyncMapFn,
    });
  };
}
