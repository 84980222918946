import { Injectable } from '@angular/core';
import { SelectLookupFn } from 'app/modules/shared/select';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class StateService {
  public static BASE_URL_API = environment.URL.API + 'state';
  private listTimeout;

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de departamentos
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(StateService.BASE_URL_API + '?' + params, httpRequestHandler);
  }

  /**
   * Agregar departamentos
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(StateService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Eliminar departamentos
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${StateService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Obtener departamentos
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${StateService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Select automeplete departamentos
   */
  list: SelectLookupFn = data => {
    return this.http.observablePost(StateService.BASE_URL_API + '/list', data, { mapFn: res => res.states });
  };
}
