import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ɵa as CKEditorComponent } from 'ng2-ckeditor';

@Component({
  selector: 'app-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => HtmlEditorComponent)
    }
  ]
})
export class HtmlEditorComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() preview = false;
  @Input() maxHeight: string | number;
  @Input() pageBreak = false;
  @Input() isDisabled = false;
  @Output() onPreview = new EventEmitter();

  @ViewChild('ckeditor', { static: true }) ckeditor: CKEditorComponent;

  config: any = {
    extraPlugins: 'youtube,autogrow,divarea',
    removePlugins: '',
    removeButtons: 'Anchor',
    readOnly: this.isDisabled,
    toolbarGroups: [
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
      { name: 'links' },
      { name: 'forms' },
      { name: 'insert' },
      { name: 'customActions' },
      '/',
      { name: 'styles' },
      { name: 'colors' },
      { name: 'tools' },
      { name: 'others' },
      { name: 'about' },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'] }
    ],
    autoGrow_minHeight: 0,
    autoGrow_onStartup: true,
    resize_enabled: false
  };
  htmlValue: string;

  propagateChange = (val: any) => {
  };
  touchChange = () => {
  };

  constructor() {
  }

  ngOnInit() {
    this.setHeight();
    this.setPageBreak();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.height) this.setHeight();
    if (changes.pageBreak) this.setPageBreak();
    if (changes.isDisabled) this.config.readOnly = this.isDisabled;
  }

  writeValue(value: any) {
    this.htmlValue = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.touchChange = fn;
  }

  setHeight() {
    if (this.maxHeight != null) {
      if (!this.ckeditor) return;

      this.ckeditor.ready.subscribe(() => {
        const el: HTMLElement = this.ckeditor.host.nativeElement.parentNode.querySelector('.cke_contents');
        el.style.maxHeight = typeof this.maxHeight === 'number' ? this.maxHeight + 'px' : this.maxHeight;
        el.style.overflowY = 'scroll';
      });
    }
  }

  setPageBreak() {
    if (this.pageBreak) {
      this.config.removePlugins = 'sourcearea,language,save,newpage,preview,print,forms,flash,smiley,about,maximize,elementspath';
    } else {
      this.config.removePlugins = 'sourcearea,language,save,newpage,preview,print,forms,flash,smiley,pagebreak,about,maximize,elementspath';
    }
  }
}
