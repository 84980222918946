import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { FunctionsService, HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class AvailabilityService {
  public static BASE_URL_API = environment.URL.API + 'availability';

  constructor(private http: HttpService, private fn: FunctionsService) {}

  /**
   * Obtener Turnos
   */
  index(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.get(AvailabilityService.BASE_URL_API + '?' + this.fn.objectToQueryParams(data), httpRequestHandler);
  }

  /**
   * Agregar Turno
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(AvailabilityService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Eliminar Turno
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${AvailabilityService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar Turno
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${AvailabilityService.BASE_URL_API}/${id}`, data, httpRequestHandler);
  }
}
