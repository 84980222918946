import { filter } from 'rxjs/operators';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserManualService } from '../../../../services/api';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css'],
  providers: [UserManualService]
})
export class BreadcrumbsComponent {
  breadcrumbs: any[];
  last: any;
  loading!: boolean;

  constructor(private router: Router, private route: ActivatedRoute, private titleService: Title, private userManualService: UserManualService) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      this.breadcrumbs = [];
      let currentRoute = this.route.root,
        url = '';

      do {
        const childrenRoutes = currentRoute.children;
        currentRoute = null;
        childrenRoutes.forEach(r => {
          if (r.outlet === 'primary') {
            const routeSnapshot = r.snapshot;
            url += '/' + routeSnapshot.url.map(segment => segment.path).join('/');

            if (r.snapshot.data.breadcrumb !== undefined) {
              let status = true;
              if (r.snapshot.data.status !== undefined) {
                status = r.snapshot.data.status;
              }

              const found = this.breadcrumbs.findIndex(bc => {
                return bc.label === r.snapshot.data.breadcrumb && bc.title === r.snapshot.data.title;
              });

              if (found === -1) {
                this.breadcrumbs.push({
                  label: r.snapshot.data.breadcrumb,
                  title: r.snapshot.data.title,
                  status: status,
                  url: url,
                  help: r.snapshot.data.help
                });
              }
            }
            currentRoute = r;
          }
        });
      } while (currentRoute);

      this.last = this.breadcrumbs.length ? this.breadcrumbs[this.breadcrumbs.length - 1] : undefined;
    });
  }

  showHelp(): void {
    this.loading = true;
    this.userManualService.download(this.last.help, {
      success: ({ filename }) => {
        window.open(filename, '_blank');
      },
      always: () => (this.loading = false)
    });
  }
}
