import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationAnimationType, NotificationsService, Options } from 'angular2-notifications';

import { ITranslateString } from '.';

@Injectable()
export class NotifyMessageService {
  public options: Options = {
    timeOut: 2000,
    showProgressBar: false,
    pauseOnHover: true,
    lastOnBottom: true,
    clickToClose: true,
    animate: NotificationAnimationType.FromRight,
    position: ['top', 'right']
  };

  constructor(private notify: NotificationsService, private translate: TranslateService) {
  }

  success(title: string, msg: string | ITranslateString, opts?: any) {
    this.translate.get(title).subscribe(titleTrans => {
      this.notify.success(titleTrans, typeof msg === 'string' ? this.translate.instant(msg) : this.translate.instant(msg.key, msg.params), opts);
    });
  }

  warning(title: string, msg: string | ITranslateString, opts?: any) {
    this.translate.get(title).subscribe(titleTrans => {
      this.notify.warn(titleTrans, typeof msg === 'string' ? this.translate.instant(msg) : this.translate.instant(msg.key, msg.params), opts);
    });
  }

  info(title: string, msg: string, opts?: any) {
    this.translate.get([title, msg]).subscribe(trans => {
      this.notify.info(trans[title], trans[msg], opts);
    });
  }

  error(title: string, msg: string | ITranslateString, opts?: any) {
    this.translate.get(title).subscribe(titleTrans => {
      this.notify.error(titleTrans, typeof msg === 'string' ? this.translate.instant(msg) : this.translate.instant(msg.key, msg.params), opts);
    });
  }

  plainError(title: string, msg: string) {
    this.notify.error(title, msg);
  }
}
