import { Injectable } from '@angular/core';
import { CustomAsyncValidation, CustomValidators } from 'app/modules/shared/form';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';


@Injectable()
export class MailLogStateService {

  public static BASE_URL_API = environment.URL.API + 'mail-log-state';

  constructor(private http: HttpService) {}

  /**
   * List mail log states
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${MailLogStateService.BASE_URL_API}?${params}`, httpRequestHandler);
  }

  /**
   * List mail log states
   */
  list(httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${MailLogStateService.BASE_URL_API}/list`, httpRequestHandler);
  }

  /**
   * Add mail log state
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(MailLogStateService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Delete mail log state
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${MailLogStateService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Get mail log state after update
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${MailLogStateService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Update mail log state
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${MailLogStateService.BASE_URL_API}/${id}`, data, httpRequestHandler);
  }

  /**
   * Validate if given key already exist
   */
  exists: CustomAsyncValidation<any> = (id, key) => {
    return this.http.observableGet(`${MailLogStateService.BASE_URL_API}/exist?key=${encodeURIComponent(key)}&id=${id}`, {
      mapFn: CustomValidators.asyncMapFn
    });
  };

}
