import { Component, Input, OnInit } from '@angular/core';
import { TableColumn } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-datatable-header',
  templateUrl: './datatable-header.component.html',
  styleUrls: ['./datatable-header.component.scss']
})
export class DatatableHeaderComponent implements OnInit {
  @Input() sort: Function;
  @Input() col: TableColumn;
  @Input() center = false;
  @Input() translate = true;

  constructor() {}

  ngOnInit() {}
}
