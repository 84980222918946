import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';

import { HttpRequestHandler, HttpService } from '../../util';

export enum BorrowingStatus {
  NotReturned = 1,
  PartialReturned = 2,
  Returned = 3
}

export enum StatusClass {
  Returned = 'label label-success',
  PartialReturned = 'label label-primary',
  NotReturned = 'label label-default',
  Expired = 'label label-warning'
}

@Injectable()
export class SportBorrowingService {
  public static URL = environment.URL.API + 'sport-borrowing';

  statuses = [
    { id: BorrowingStatus.Returned, name: '', key: 'sportBorrowing.returned', class: StatusClass.Returned },
    { id: BorrowingStatus.PartialReturned, name: '', key: 'sportBorrowing.partialReturned', class: StatusClass.PartialReturned },
    { id: BorrowingStatus.NotReturned, name: '', key: 'sportBorrowing.notReturned', class: StatusClass.NotReturned }
  ];

  constructor(private http: HttpService, private translate: TranslateService) {
    const udpateLangValues = () => {
      this.statuses.forEach(item => (item.name = translate.instant(item.key)));
    };
    udpateLangValues();
    translate.onLangChange.subscribe(value => udpateLangValues());
  }

  getStatus(status: BorrowingStatus) {
    return this.statuses.find(sta => sta.id === status);
  }

  /**
   * List sport borrowings
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(SportBorrowingService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Get sport borrowing
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${SportBorrowingService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save sport borrowing
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(SportBorrowingService.URL, data, httpRequestHandler);
  }

  /**
   * Retiurn sport borrowing
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  return(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${SportBorrowingService.URL}/return`, data, httpRequestHandler);
  }

  /**
   * Print PDF borrowing
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  print(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${SportBorrowingService.URL}/${id}/print`, httpRequestHandler);
  }
}
