import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IFinancialRepresentative } from 'app/modules/enrollment/enroll/step/enroll-step.component';

import { environment } from '../../../../environments/environment';
import { FunctionsService, HttpRequestHandler, HttpRequestStream, HttpService, RuleType } from '../../util';
import { OtherChargeApplyIn, OtherChargeValueType } from './prices.service';
import { StepOptionValueType, StepRuleType } from './step.service';

export interface IReceiptItemDiscount {
  perc: number;
  from: string;
}

export class ReceiptItem {
  value: number = null;
  discount: number = null;
  count: number;
  substract = false;
  info: { text: string; params?: any };
  discounts: IReceiptItemDiscount[] = [];
  skipDiscounts = false;

  get final() {
    return (this.value || 0) - (this.discount || 0);
  }

  constructor(public concept: { id: number; name: string }) {
  }
}

export enum EnrollmentStatus {
  InProgress = 1,
  DocumentUpload = 5,
  DocumentReview = 2,
  Financial = 3,
  Finished = 4,
}

export enum AdvanceType {
  Number = 1,
  Value = 2,
}

export enum DocReviewStatus {
  Ok = 1,
  Missing = 2,
  NotApplicable = 3,
  Wrong = 4,
  PreOk = 5,
}

export enum EnrollmentPaymentType {
  Cash = 1,
  Agreement = 2,
  DebitCard = 3,
  CreditCard = 4,
  Check = 5,
  Deposit = 6,
  Prepaid = 7,
  Electronic = 8,
  Unused = 9,
  Cross = 10,
  Pending = 11,
}

export enum EnrollmentAvailability {
  Before,
  Available,
  After,
}

export enum FinancialRepresentativeType {
  Transport = 1,
  Monthly = 2,
}

export enum EnrollmentSignatureType {
  Electronic = 1,
  Manual = 2,
}

export enum EnrollmentDocSignatureStatus {
  PreOk = 1,
  Ok = 2,
  Missing = 3,
  Wrong = 4,
}

export enum EnrollmentLogType {
  Rollback = 1,
  Normal = 2,
  AcademicDone = 3,
  SentToDocumentReview = 4,
  SentToDocumentUpload = 5,
  SentToFinancial = 6,
  Finished = 7,
}

@Injectable()
export class EnrollService {
  public static URL = environment.URL.API + 'enroll';

  statuses = [
    { id: EnrollmentStatus.InProgress, key: 'enroll.status.inProgress', name: '', class: 'label label-default' },
    { id: EnrollmentStatus.DocumentUpload, key: 'enroll.status.documentUpload', name: '', class: 'label label-info' },
    { id: EnrollmentStatus.DocumentReview, key: 'enroll.status.documentReview', name: '', class: 'label label-info' },
    { id: EnrollmentStatus.Financial, key: 'enroll.status.financial', name: '', class: 'label label-primary' },
    { id: EnrollmentStatus.Finished, key: 'enroll.status.finished', name: '', class: 'label label-success' }
  ];

  advanceTypes = [
    { id: AdvanceType.Number, key: 'enroll.advanceType.number', name: '' },
    { id: AdvanceType.Value, key: 'enroll.advanceType.value', name: '' }
  ];

  transportAdvanceTypes = [
    { id: AdvanceType.Number, key: 'enroll.transportAdvanceType.number', name: '' },
    { id: AdvanceType.Value, key: 'enroll.transportAdvanceType.value', name: '' }
  ];

  paymentTypes = [
    { id: EnrollmentPaymentType.Cash, key: 'enroll.paymentType.cash', name: '' },
    { id: EnrollmentPaymentType.Agreement, key: 'enroll.paymentType.agreement', name: '' },
    { id: EnrollmentPaymentType.DebitCard, key: 'enroll.paymentType.debitCard', name: '' },
    { id: EnrollmentPaymentType.CreditCard, key: 'enroll.paymentType.creditCard', name: '' },
    { id: EnrollmentPaymentType.Check, key: 'enroll.paymentType.check', name: '' },
    { id: EnrollmentPaymentType.Deposit, key: 'enroll.paymentType.deposit', name: '' },
    { id: EnrollmentPaymentType.Prepaid, key: 'enroll.paymentType.prepaid', name: '' },
    { id: EnrollmentPaymentType.Electronic, key: 'enroll.paymentType.electronic', name: '' },
    { id: EnrollmentPaymentType.Unused, key: 'enroll.paymentType.unused', name: '' },
    { id: EnrollmentPaymentType.Cross, key: 'enroll.paymentType.cross', name: '' },
    { id: EnrollmentPaymentType.Pending, key: 'enroll.paymentType.pending', name: '' }
  ];

  financialRepresentativeTypes = [
    { id: FinancialRepresentativeType.Transport, key: 'menu.main.transport', name: '' },
    { id: FinancialRepresentativeType.Monthly, key: 'portfolioConfiguration.monthly', name: '' }
  ];

  enrollmentSignatureTypes = [
    { id: EnrollmentSignatureType.Electronic, key: 'enroll.signatureType.electronic', name: '' },
    { id: EnrollmentSignatureType.Manual, key: 'enroll.signatureType.manual', name: '' }
  ];

  enrollmentLogTypes = [
    { id: EnrollmentLogType.Rollback, key: 'enroll.tracingStatus.rollback', name: '', class: 'bg-secondary-light' },
    { id: EnrollmentLogType.Normal, key: 'enroll.tracingStatus.normal', name: '', class: '' },
    { id: EnrollmentLogType.AcademicDone, key: 'enroll.tracingStatus.academicDone', name: '', class: 'bg-primary-light' },
    { id: EnrollmentLogType.SentToDocumentReview, key: 'enroll.tracingStatus.sentToDocumentReview', name: '', class: 'bg-primary-light' },
    { id: EnrollmentLogType.SentToDocumentUpload, key: 'enroll.tracingStatus.sentToDocumentUpload', name: '', class: 'bg-danger-light' },
    { id: EnrollmentLogType.SentToFinancial, key: 'enroll.tracingStatus.sentToFinancial', name: '', class: 'bg-primary-light' },
    { id: EnrollmentLogType.Finished, key: 'enroll.tracingStatus.finished', name: '', class: 'bg-success-light' }
  ];

  constructor(private http: HttpService, private fn: FunctionsService, translate: TranslateService) {
    const lists = {
      statuses: this.statuses,
      advanceTypes: this.advanceTypes,
      transportAdvanceTypes: this.transportAdvanceTypes,
      paymentTypes: this.paymentTypes,
      financialRepresentativeTypes: this.financialRepresentativeTypes,
      enrollmentSignatureTypes: this.enrollmentSignatureTypes,
      enrollmentLogTypes: this.enrollmentLogTypes
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  getStatus(status: EnrollmentStatus) {
    return this.statuses.find(s => s.id === status);
  }

  getFinancialRepType(type: FinancialRepresentativeType) {
    return this.financialRepresentativeTypes.find(t => t.id === type);
  }

  /**
   * Retorna el contenido de un grupo de reglas según el estudiante
   * @param sectionId Year Section Id del estudiante
   * @param gradeId Year Grade Id del Estudiante
   */
  getRuleContent(baseRules: any[], type: StepRuleType, sectionId: number, gradeId: number) {
    const prop = type === StepRuleType.Options ? 'options' : 'content';
    const newBaseRules = baseRules.filter(r => r.type === type);

    let rules = newBaseRules.filter(r => r.ruleType === RuleType.Grade);
    for (let i = 0; i < rules.length; i++) {
      const r = rules[i];
      if (r.gradesId.includes(gradeId)) return r[prop];
    }

    rules = newBaseRules.filter(r => r.ruleType === RuleType.Section);
    for (let i = 0; i < rules.length; i++) {
      const r = rules[i];
      if (r.sectionsId.includes(sectionId)) return r[prop];
    }

    const rule = newBaseRules.find(r => r.ruleType === RuleType.General);
    if (rule) return rule[prop];

    return null;
  }

  /**
   * Valida si un estudiante es valido para aplicar un paso de matricula
   * @param sectionId Year Section Id del estudiante
   * @param gradeId Year Grade Id del Estudiante
   */
  isValidForStep(baseRules: any[], sectionId: number, gradeId: number) {
    const newBaseRules = baseRules.filter(r => r.type === StepRuleType.Main);

    const rule = newBaseRules.find(r => r.ruleType === RuleType.General);
    if (rule) return true;

    let rules = newBaseRules.filter(r => r.ruleType === RuleType.Section);
    for (let i = 0; i < rules.length; i++) {
      const r = rules[i];
      if (r.sectionsId.includes(sectionId)) return true;
    }

    rules = newBaseRules.filter(r => r.ruleType === RuleType.Grade);
    for (let i = 0; i < rules.length; i++) {
      const r = rules[i];
      if (r.gradesId.includes(gradeId)) return true;
    }
  }

  /**
   * Calculo de valor de opciones
   * @param sectionId Year Section Id del estudiante
   * @param gradeId Year Grade Id del Estudiante
   */
  getOptionsWithValue(baseRules: any[], sectionId: number, gradeId: number) {
    const options = this.getRuleContent(baseRules, StepRuleType.Options, sectionId, gradeId);

    return options.map(o => {
      const cloned = Object.assign({}, o);

      switchBlock: switch (o.valueType) {
        case StepOptionValueType.Direct:
          cloned.val = +o.value;
          break;
        case StepOptionValueType.Rules:
          let subRules = o.rules.filter(r => r.ruleType === RuleType.Grade);
          for (let i = 0; i < subRules.length; i++) {
            const r = subRules[i];
            if (r.gradesId.includes(gradeId)) {
              cloned.val = +r.value;
              break switchBlock;
            }
          }

          subRules = o.rules.filter(r => r.ruleType === RuleType.Section);
          for (let i = 0; i < subRules.length; i++) {
            const r = subRules[i];
            if (r.sectionsId.includes(sectionId)) {
              cloned.val = +r.value;
              break switchBlock;
            }
          }

          const generalRule = o.rules.find(r => r.ruleType === RuleType.General);
          if (generalRule) cloned.val = +generalRule.value;

          break;
      }
      return cloned;
    });
  }

  /**
   * Calcular conceptos de cobro de Otros cobros y descuentos
   * @param s data de estudiante
   * @param includeFixed bandera para identificar si se validan los otros cobros de tipo de valor fijo
   */
  processOtherChargesAndDiscounts(s, fr: IFinancialRepresentative, concepts: any[], includeFixed = true) {
    fr.monthlyItems = fr.monthlyItems.filter(sett => sett.count !== 0);

    for (const charge of s.charges) {
      if (!includeFixed && charge.valueType === OtherChargeValueType.Fixed) continue;

      const concept = new ReceiptItem(concepts.find(c => c.id === charge.conceptId));

      switch (charge.valueType) {
        case OtherChargeValueType.Fixed:
          concept.value = charge.value;
          break;
        case OtherChargeValueType.Calculated:
          let baseConcepts: ReceiptItem[];

          switch (charge.applyIn) {
            case OtherChargeApplyIn.Enrollment:
              baseConcepts = fr.enrollmentItems.filter(c => charge.concepts.includes(c.concept.id));
              break;
            case OtherChargeApplyIn.Monthly:
              baseConcepts = fr.monthlyItems.filter(c => charge.concepts.includes(c.concept.id));
              break;
          }

          let sum = 0;
          baseConcepts.forEach(c => (sum += c.value));
          concept.value = (sum * charge.value) / 100;
          break;
      }

      if (concept.value) {
        switch (charge.applyIn) {
          case OtherChargeApplyIn.Enrollment:
            fr.enrollmentItems.push(concept);
            break;
          case OtherChargeApplyIn.Monthly:
            concept.count = s.months;
            fr.monthlyItems.push(concept);
            break;
        }
      }
    }

    const discount = s.discount || s.discountCustom;

    fr.enrollmentItems.concat(fr.monthlyItems).forEach(ri => {
      if (ri.substract || ri.skipDiscounts) return;

      const c = discount ? discount.concepts.find(c => ri.concept.id === c.conceptId) : null;
      const c2 = s.compDiscount ? s.compDiscount.concepts.find(c => ri.concept.id === c.conceptId) : null;
      if (!c && !c2) return;

      let percentage = 0;

      if (c) {
        percentage += c.percentage;
        ri.discounts.push({ perc: c.percentage, from: discount.name });
      }
      if (c2) {
        percentage += c2.percentage;
        ri.discounts.push({ perc: c2.percentage, from: s.compDiscount.name });

        if (percentage > c2.maxPercentage) percentage = c2.maxPercentage;
      }

      ri.discount += (ri.value * percentage) / 100;

      if (ri.discount > ri.value) ri.discount = ri.value;
    });
  }

  /**
   * Redondear valores de conceptos y descuentos
   */
  roundValues(fr: IFinancialRepresentative) {
    fr.enrollmentItems.forEach(c => {
      c.value = Math.round(c.value);
      if (c.discount) c.discount = Math.round(c.discount);
    });

    fr.monthlyItems.forEach(c => {
      c.value = Math.round(c.value);
      if (c.discount) c.discount = Math.round(c.discount);
    });
  }

  /**
   * Paginador de matriculas por familia
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(EnrollService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Paginador de matriculas por estudiante
   */
  students(params: string, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(EnrollService.URL + '/students?' + params, httpRequestHandler);
  }

  /**
   * Paginador de matriculas financiera
   */
  financial(params: string, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(EnrollService.URL + '/financial?' + params, httpRequestHandler);
  }

  /**
   * Listado de las matriculas asociadas al usuario actual
   */
  myEnrollments(yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(EnrollService.URL + '/my-enrollments/' + yearId, httpRequestHandler);
  }

  /**
   * Listado de familias para simulador de precios
   */
  priceSimulator(yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${EnrollService.URL}/price-simulator/${yearId}`, httpRequestHandler);
  }

  /**
   * Listado de estudaintes de familia para simulador de precios
   */
  priceSimulatorFamily(yearId: number, familyId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${EnrollService.URL}/price-simulator/${yearId}/${familyId}`, httpRequestHandler);
  }

  /**
   * Enviar simulación de precios a correo electrónico
   */
  sendPriceSimulator(data, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollService.URL}/price-simulator/send`, data, httpRequestHandler);
  }

  /**
   * Secuencia de pasos para matricula
   */
  steps(yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${EnrollService.URL}/steps/${yearId}`, httpRequestHandler);
  }

  /**
   * Datos de paso
   */
  step(params, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${EnrollService.URL}/step?${this.fn.objectToQueryParams(params)}`, httpRequestHandler);
  }

  /**
   * Guardar datos para paso 'Selección de estudiante'
   */
  setStudent(data, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollService.URL}/student`, data, httpRequestHandler);
  }

  /**
   * Guardar datos para paso 'Representante de estudiante'
   */
  setRepresentative(data, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollService.URL}/representative`, data, httpRequestHandler);
  }

  /**
   * Guardar datos para paso dinamico por estudiante
   */
  setDynamicStudent(data, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollService.URL}/dynamic/student`, data, httpRequestHandler);
  }

  /**
   * Guardar datos para paso dinamico por familia
   */
  setDynamicFamily(data, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollService.URL}/dynamic/family`, data, httpRequestHandler);
  }

  /**
   * Guardar datos para paso 'Selección de deporte'
   */
  setSport(data, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollService.URL}/sport`, data, httpRequestHandler);
  }

  /**
   * Guardar datos para paso 'Selección de actividad extracurricular'
   */
  setExtracurricular(data, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollService.URL}/extracurricular`, data, httpRequestHandler);
  }

  /**
   * Guardar datos para paso 'Transporte'
   */
  setTransport(data, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollService.URL}/transport`, data, httpRequestHandler);
  }

  /**
   * Guardar datos para paso 'Liquidación'
   */
  setSettlement(data, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollService.URL}/settlement`, data, httpRequestHandler);
  }

  /**
   * Guardar datos para paso 'Resumen'
   */
  setSummary(data, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollService.URL}/summary`, data, httpRequestHandler);
  }

  /**
   * Guardar datos para paso 'Notificacion Firma Electronica'
   */
  setSignatures(data, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollService.URL}/signatures`, data, httpRequestHandler);
  }

  /**
   * Datos de matricula
   * @param id Id de matricula
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${EnrollService.URL}/get/${id}`, httpRequestHandler);
  }

  /**
   * Datos de seguimiento de matricula
   * @param id Id de matricula
   */
  tracing(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${EnrollService.URL}/tracing/${id}`, httpRequestHandler);
  }

  /**
   * Cambia de estado una matricula a "En Progreso"
   */
  rollback(data, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollService.URL}/rollback`, data, httpRequestHandler);
  }

  /**
   * Guardar observacion de matricula
   */
  saveTracing(data, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollService.URL}/tracing`, data, httpRequestHandler);
  }

  /**
   * Cambiar tipo de firma a matricula
   */
  changeSignatureType(data, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollService.URL}/change-signature-type`, data, httpRequestHandler);
  }

  /**
   * Verifica si la familia ya ha actualizado los datos segun el año
   */
  checkFamily(yearId: number, familyId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${EnrollService.URL}/check-family/${yearId}/${familyId}`, httpRequestHandler);
  }

  /**
   * Obtener datos de fecha de pago de matricula
   * @param id Id de matricula
   */
  getPaymentDate(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${EnrollService.URL}/payment-date/${id}`, httpRequestHandler);
  }

  /**
   * Guardar fecha de pago de matricula
   */
  setPaymentDate(data, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollService.URL}/payment-date`, data, httpRequestHandler);
  }

  /**
   * Obtener datos para finalización de matricula financiera
   * @param id Id de matricula
   */
  getFinish(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${EnrollService.URL}/finish/${id}`, httpRequestHandler);
  }

  /**
   * Finalizar matricula financiera
   * @param id Id de matricula
   */
  finish(data, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollService.URL}/finish`, data, httpRequestHandler);
  }

  /**
   * Obtener estudiantes matriculados
   * @param id Id de la familia
   * @param httpRequestHandler HttpRequestHandler
   */
  getEnrolledStudents(familyId: number, yearId: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${EnrollService.URL}/enrolled-students/${familyId}/${yearId}`, httpRequestHandler);
  }

  /**
   * Consultar información del recibo
   * @param receiptId
   * @param httpRequestHandler
   */
  getReceiptData(receiptId: number, verify: boolean, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${EnrollService.URL}/receipt-data/${receiptId}/${+!!verify}`, httpRequestHandler);
  }

  /**
   * Return registered personal emails from a person
   */
  personalEmails(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollService.URL}/personal-emails`, data, httpRequestHandler);
  }

  /**
   * Guardar correos a personas sin correo para firma electronica
   */
  saveEmails(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollService.URL}/save-emails`, data, httpRequestHandler);
  }

  /**
   * Envio de correos matriculas 50/50
   */
  fiftyFiftyReminder(yearId: number, opts: HttpRequestStream) {
    this.http.stream(`${EnrollService.URL}/${yearId}/fifty-fifty-reminder`, opts);
  }
}
