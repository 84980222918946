import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class StudentDiscountService {
  public static URL = environment.URL.API + 'student-discount';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de descuentos por estudiante
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(StudentDiscountService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Guardar descuento por estudiante
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(StudentDiscountService.URL, data, httpRequestHandler);
  }

  /**
   * Guardar descuento por multiples estudiantes
   */
  massSave(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(StudentDiscountService.URL + '/mass', data, httpRequestHandler);
  }

  /**
   * Eliminar descuento por estudiante
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${StudentDiscountService.URL}/${id}`, httpRequestHandler);
  }
}
