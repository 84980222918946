import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum TransportChangeType {
  Diary = 1,
  Programmed = 2,
  Attendance = 3,
  Citation = 4,
  Extracurricular = 5
}

@Injectable()
export class TransportChangeService {
  public static BASE_URL_API = environment.URL.API + 'transport-change';

  changeTypes = [
    { id: TransportChangeType.Diary, key: 'transportChange.diary', name: '' },
    { id: TransportChangeType.Programmed, key: 'transportChange.programmed', name: '' },
    { id: TransportChangeType.Attendance, key: 'attendance.msgTitle', name: '' },
    { id: TransportChangeType.Citation, key: 'citation.msgTitle', name: '' },
    { id: TransportChangeType.Extracurricular, key: 'portfolioConfiguration.extracurricular', name: '' }
  ];

  /**
   * Empty constructor
   * @param http HttpService
   * @param translate TranslateService
   */
  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    const lists = { changeTypes: this.changeTypes };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  getChangeType(id: number) {
    return this.changeTypes.find(ch => ch.id === id);
  }

  /**
   * Get student transport changes
   * @param enrollmentId number
   * @param type TransportChangeType
   * @param httpRequestHandler HttpRequestHandler
   */
  list(enrollmentId: number, type: TransportChangeType, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${TransportChangeService.BASE_URL_API}/${enrollmentId}/${type}/list`, httpRequestHandler);
  }

  /**
   * Get change
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${TransportChangeService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Get current plan
   * @param enrollmentId number
   * @param httpRequestHandler HttpRequestHandler
   */
  getCurrentPlan(enrollmentId: number, date: string, httpRequestHandler: HttpRequestHandler) {
    const q = date ? `?date=${date}` : ``;
    this.http.get(`${TransportChangeService.BASE_URL_API}/${enrollmentId}/plan${q}`, httpRequestHandler);
  }

  /**
   * Save student transport change
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${TransportChangeService.BASE_URL_API}`, data, httpRequestHandler);
  }

  /**
   * Delete student transport change
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${TransportChangeService.BASE_URL_API}/${id}`, httpRequestHandler);
  }
}
