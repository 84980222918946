import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { UserService } from '../services/api';
import { SessionService } from '../services/util';

@Injectable()
export class PermissionGuard implements CanActivate {
  constructor(private userService: UserService, private sessionService: SessionService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    // doing nothing when the current route has no permission to validate
    const permissions: string = next.data.permission;
    if (!permissions) return true;

    const splitted = permissions.split(',');

    // function that checks the permission for the current route
    const checkPermisssion = () => {
      for (const permission of splitted) {
        if (this.sessionService.permissions.includes(permission)) return true;
      }

      this.router.navigate(['/forbidden'], { queryParams: { permission: splitted[0], url: encodeURIComponent(state.url) } });
      return false;
    };

    // check the permission if the user's permissions are loaded
    if (this.sessionService.permissions) return checkPermisssion();

    // load the user's permissions and check the permission
    return this.userService
      .currentObservable({
        mapFn: res => {
          this.sessionService.setUser(res.user, res.permissions, res.actions);
          return checkPermisssion();
        },
      })
      .pipe(
        catchError(() => {
          this.sessionService.logout(false);
          this.router.navigate(['/auth', 'login'], { queryParams: { returnUrl: encodeURIComponent(state.url) } });
          return of(false);
        })
      );
  }
}
