export * from './section.service';
export * from './grade.service';
export * from './academic-year.service';
export * from './block.service';
export * from './area.service';
export * from './subject.service';
export * from './grade-group.service';
export * from './period.service';
export * from './year-class.service';
export * from './class-grade-group.service';
export * from './grading-type.service';
export * from './class-grading.service';
export * from './attendance.service';
export * from './attendance-type.service';
export * from './citation-reason.service';
export * from './citation.service';
export * from './performance-report.service';
export * from './academic-aspect.service';
export * from './value-judgment.service';
export * from './additional-report.service';
export * from './reinforcement-improvement-activity.service';
export * from './group-direction.service';
export * from './low-performance-report.service';
export * from './attendant-academic.service';
export * from './election.service';
export * from './middle-report.service';
export * from './assessment-config.service';
export * from './assessment.service';
export * from './student-academic.service';
export * from './middle-report-concept.service';
export * from './academic-configuration.service';
export * from './grade-configuration.service';
export * from './academic-projection.service';
export * from './meetings.service';
export * from './meeting.service';
export * from './ranking.service';
export * from './tutoring-price.service';
export * from './tutoring.service';
export * from './tutoring-time.service';
export * from './tutoring-tracking.service';
export * from './attendance-class.service';
export * from './attendance-obs-type.service';
export * from './promotion.service';
export * from './section-configuration.service';
export * from './analisys-by-group.service';
export * from './academic-quality-indicator.service';
export * from './school-diary-type.service';
export * from './school-diary.service';
export * from './school-diary-tracking.service';
