import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class EnrollElectronicSignatureService {
  public static URL = environment.URL.API + 'enroll-electronic-signature';
  public static NO_AUTH_URL = environment.URL.API + 'enroll-electronic-signature-no-auth';

  constructor(private http: HttpService) {}

  /**
   * Get electronic signature data
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  getData(id: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${EnrollElectronicSignatureService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Sign electronically
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  sign(data: any, httpRequestHandler: HttpRequestHandler) {
    return this.http.post(`${EnrollElectronicSignatureService.URL}/sign`, data, httpRequestHandler);
  }

  /**
   * Get data for no auth
   * @param token string
   * @param httpRequestHandler HttpRequestHandler
   */
  getDataNoAuth(token: string, httpRequestHandler: HttpRequestHandler) {
    httpRequestHandler.includeToken = false;
    return this.http.post(EnrollElectronicSignatureService.NO_AUTH_URL, { _token: token }, httpRequestHandler);
  }

  /**
   * Sign electronically for no auth
   * @param token string
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  signNoAuth(token: string, data: any, httpRequestHandler: HttpRequestHandler) {
    data._token = token;
    httpRequestHandler.includeToken = false;
    return this.http.post(`${EnrollElectronicSignatureService.NO_AUTH_URL}/sign`, data, httpRequestHandler);
  }

  /**
   * Download electronic signature agreement
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  downloadAgreement(id: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${EnrollElectronicSignatureService.URL}/${id}/electronic-signature-agreement`, httpRequestHandler);
  }

  /**
   * Download electronic signature agreement for no auth
   * @param token any
   * @param httpRequestHandler HttpRequestHandler
   */
  downloadAgreementNoAuth(token: string, httpRequestHandler: HttpRequestHandler) {
    httpRequestHandler.includeToken = false;
    return this.http.post(`${EnrollElectronicSignatureService.NO_AUTH_URL}/electronic-signature-agreement`, { _token: token }, httpRequestHandler);
  }
}
