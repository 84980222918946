import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class EPSService {
  public static URL = environment.URL.API + 'eps';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de EPS
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(EPSService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Agregar EPS
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(EPSService.URL, data, httpRequestHandler);
  }

  /**
   * Eliminar EPS
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${EPSService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Editar EPS
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${EPSService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar EPS
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${EPSService.URL}/${id}`, data, httpRequestHandler);
  }

  /**
   * Listado de EPS para combo.
   */
  list = () => {
    return this.http.observableGet(`${EPSService.URL}/list`, { mapFn: res => res.epss });
  };
}
