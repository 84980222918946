import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum TicketAssignedTab {
  InProcess = 'in-process',
  Historic = 'historic',
}

@Injectable()
export class TicketAssignedService {
  public static URL = environment.URL.API + 'ticket-assigned';

  constructor(private http: HttpService) {}

  /**
   * List assigned tickets
   *
   * @param params string
   * @param tab TicketAssignedTab
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, tab: TicketAssignedTab, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${TicketAssignedService.URL}/${tab}?${params}`, httpRequestHandler);
  }
}
