import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class MenuService {
  public static BASE_URL_API = environment.URL.API2 + 'menu';

  constructor(private http: HttpService) {}

  /**
   * Mostrar menu
   */
  index(httpRequestHandler: HttpRequestHandler) {
    this.http.get(MenuService.BASE_URL_API, httpRequestHandler);
  }

  /**
   * Guardar menu
   */
  store(children: any[], deleted: number[], httpRequestHandler: HttpRequestHandler) {
    this.http.post(MenuService.BASE_URL_API, { children: children, deleted: deleted }, httpRequestHandler);
  }

  /**
   * Listado de menus para select
   */
  list = () => {
    return this.http.observableGet(`${MenuService.BASE_URL_API}/list`, { mapFn: res => res.menus });
  };
}
