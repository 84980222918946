import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';

import { SelectAsyncComponent } from './select-async/select-async.component';
import { SelectYearComponent } from './select-year/select-year.component';
import { SelectComponent } from './select/select.component';
import { SelectLabelDirective, SelectOptionDirective } from './utils';

@NgModule({
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, NgSelectModule, FormsModule],
  declarations: [SelectAsyncComponent, SelectComponent, SelectYearComponent, SelectLabelDirective, SelectOptionDirective],
  exports: [SelectAsyncComponent, SelectComponent, SelectYearComponent, SelectLabelDirective, SelectOptionDirective],
})
export class SelectModule {}
