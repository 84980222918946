import { Component, Input, OnInit } from '@angular/core';

import { PagerService } from '../../../../services/util';

@Component({
  selector: 'app-pager-add',
  templateUrl: './pager-add.component.html',
  styleUrls: ['./pager-add.component.scss'],
})
export class PagerAddComponent implements OnInit {
  @Input()
  permission: string | string[];

  @Input()
  link = ['add'];

  constructor(public pager: PagerService) {}

  ngOnInit() {}
}
