import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from '../services/api';
import { SessionService } from '../services/util';

@Injectable()
export class FamilyGuard implements CanActivate {
  constructor(private userService: UserService, private sessionService: SessionService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    const familyId = +next.params.familyId;

    if (this.sessionService.user.families.includes(familyId)) return true;

    this.router.navigate(['/forbidden'], { queryParams: { url: encodeURIComponent(state.url) } });
    return false;
  }
}
