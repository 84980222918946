import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

import { HttpRequestHandler, HttpService } from '../util';

@Injectable()
export class ConfigService {
  public static BASE_URL_API = environment.URL.API + 'config/';

  constructor(private http: HttpService) {}

  /**
   * Get place to pay FAQ info
   * @param httpRequestHandler HttpRequestHandler
   */
  getPlaceToPayFAQInfo(httpRequestHandler: HttpRequestHandler) {
    this.http.get(ConfigService.BASE_URL_API + 'place-to-pay-faq-info', httpRequestHandler);
  }
}
