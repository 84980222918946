import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class TicketTypeService {
  public static URL = environment.URL.API + 'ticket-type';

  constructor(private http: HttpService) {}

  /**
   * List Ticket Type
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${TicketTypeService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get Ticket Type
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${TicketTypeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Ticket Type
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(TicketTypeService.URL, data, httpRequestHandler);
  }

  /**
   * Delete Ticket Type
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${TicketTypeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List Ticket Type
   * @param httpRequestHandler HttpRequestHandler
   */
  list = data => {
    return this.http.observablePost(`${TicketTypeService.URL}/list`, data, { mapFn: res => res.types });
  };
}
