import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class PersonTypeService {
  public static URL = environment.URL.API2 + 'person-type';

  constructor(private http: HttpService) {}

  /**
   * Index Person Types
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PersonTypeService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Delete Person Type
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${PersonTypeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get Person Type
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PersonTypeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Person Type
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(PersonTypeService.URL, data, httpRequestHandler);
  }

  list = () => {
    return this.http.observableGet(`${PersonTypeService.URL}/list`, { mapFn: res => res.personTypes });
  };
}
