import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class PositionService {
  public static URL = environment.URL.API + 'position';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de cargos
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(PositionService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Guardar cargo
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(PositionService.URL, data, httpRequestHandler);
  }

  /**
   * Eliminar cargo
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${PositionService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Obtener cargo
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PositionService.URL}/${id}`, httpRequestHandler);
  }

  list = () => {
    return this.http.observableGet(`${PositionService.URL}/list`, { mapFn: res => res.positions });
  };
}
