import { Injectable } from '@angular/core';
import { CustomAsyncValidation, CustomValidators } from 'app/modules/shared/form';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class TicketAreaService {
  public static URL = environment.URL.API + 'ticket-area';

  constructor(private http: HttpService) {}

  /**
   * List Ticket Area
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${TicketAreaService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get Ticket Area
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${TicketAreaService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Ticket Area
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(TicketAreaService.URL, data, httpRequestHandler);
  }

  /**
   * Delete Ticket Area
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${TicketAreaService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List Ticket Area
   * @param httpRequestHandler HttpRequestHandler
   */
  list = () => {
    return this.http.observableGet(`${TicketAreaService.URL}/list`, { mapFn: res => res.ticketsArea });
  };

  /**
   * Validation of existence of Ticket Area Prefix
   */
  exists: CustomAsyncValidation<any> = (id, prefix) => {
    return this.http.observableGet(`${TicketAreaService.URL}/exists?prefix=${encodeURIComponent(prefix)}&id=${id}`, {
      mapFn: CustomValidators.asyncMapFn
    });
  };
}
