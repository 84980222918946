import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum CostCenterReportType {
  ExpenseProject = 1,
  ExpenseCategory = 2,
  ExpenseCostCenter = 3,
  InvoiceComingDue = 4,
  InvoicePaid = 5,
}

@Injectable()
export class CostCenterReportService {
  public static URL2 = environment.URL.API2 + 'cost-center-report';
  reportTypes = [
    { id: CostCenterReportType.ExpenseProject, key: 'costCenter.expenseProject', name: '', permission: 'expense_project_report' },
    { id: CostCenterReportType.ExpenseCategory, key: 'costCenter.expenseCategory', name: '', permission: 'expense_category_report' },
    { id: CostCenterReportType.ExpenseCostCenter, key: 'costCenter.expenseCostCenter', name: '', permission: 'expense_cost_center_report' },
    { id: CostCenterReportType.InvoiceComingDue, key: 'costCenter.invoiceComingDue', name: '', permission: 'expense_cost_center_report' },
    { id: CostCenterReportType.InvoicePaid, key: 'costCenter.invoicePaid', name: '', permission: 'expense_cost_center_report' },
  ];

  constructor(private http: HttpService, private fn: FunctionsService, translate: TranslateService) {
    fn.refreshListPermissions(this.reportTypes, array => (this.reportTypes = array));
    const lists = {
      reportTypes: this.reportTypes,
    };
    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * Export Report
   */
  export(data: any, httpRequestHandler: HttpRequestHandler) {
    return this.http.post(`${CostCenterReportService.URL2}/export/`, data, httpRequestHandler);
  }
}
