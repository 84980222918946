import { EventEmitter, Injectable } from '@angular/core';

import { SignalRService } from '../util';
import { BaseBroadcast } from './base-broadcast';

@Injectable()
export class EnrollmentElectronicSignatureBroadcast extends BaseBroadcast {
  onSigned = new EventEmitter<any>();
  private _onSigned = data => this.onSigned.emit(data);

  constructor(signalRService: SignalRService) {
    super(signalRService, 'enrollment-electronic-signature-');

    this.setEvents({
      signed: this._onSigned,
    });
  }
}
