import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum PortfolioAcademicVerificationType {
  PeriodReport = 1,
  PerformanceReports = 2,
  MiddlePeriodReports = 3,
  AdditionalReinforcementReports = 4,
  Assessments = 5,
  Gradings = 6,
  AcademicProjection = 7,
  FinalReport = 8,
  Attendance = 9,
}

@Injectable()
export class PortfolioService {
  public static URL = environment.URL.API + 'portfolio';

  academicVerificationTypes = [
    { id: PortfolioAcademicVerificationType.PeriodReport, key: 'classGrading.periodReport', name: '', attendant: false, student: false },
    { id: PortfolioAcademicVerificationType.PerformanceReports, key: 'performanceReport.title', name: '', attendant: false, student: false },
    { id: PortfolioAcademicVerificationType.MiddlePeriodReports, key: 'middleReport.title', name: '', attendant: false, student: false },
    {
      id: PortfolioAcademicVerificationType.AdditionalReinforcementReports,
      key: 'groupDirection.additionalReportTitle',
      name: '',
      attendant: false,
      student: false,
    },
    { id: PortfolioAcademicVerificationType.Assessments, key: 'assessment.title', name: '', attendant: false, student: false },
    { id: PortfolioAcademicVerificationType.Gradings, key: 'classGrading.title', name: '', attendant: false, student: false },
    { id: PortfolioAcademicVerificationType.AcademicProjection, key: 'academicProjection.title', name: '', attendant: false, student: false },
    { id: PortfolioAcademicVerificationType.Attendance, key: 'general.attendance', name: '', attendant: false, student: false },
    { id: PortfolioAcademicVerificationType.FinalReport, key: 'classGrading.finalReport', name: '', attendant: false, student: false },
  ];

  constructor(private http: HttpService, fn: FunctionsService, translate: TranslateService) {
    const lists = { academicVerificationTypes: this.academicVerificationTypes };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * Save portfolio configuration per academic year
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  savePortfolioConfiguration(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PortfolioService.URL}/year-configuration`, data, httpRequestHandler);
  }

  /**
   * Get portfolio configuration per academic year
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  getPortfolioConfiguration(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PortfolioService.URL}/${id}/year-configuration`, httpRequestHandler);
  }

  /**
   * Get general configuration
   * @param httpRequestHandler HttpRequestHandler
   */
  getGeneralConfiguration(httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PortfolioService.URL}/general-configuration`, httpRequestHandler);
  }

  /**
   * Save general portfolio configuration
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  saveGeneralConfiguration(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PortfolioService.URL}/general-configuration`, data, httpRequestHandler);
  }

  /**
   * Get holiday configuration
   * @param httpRequestHandler HttpRequestHandler
   */
  getHolidayConfiguration(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PortfolioService.URL}/holiday-configuration`, data, httpRequestHandler);
  }

  /**
   * Save holiday configuration
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  saveHoliday(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PortfolioService.URL}/holiday`, data, httpRequestHandler);
  }

  /**
   * Delete holiday configuration
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  deleteHoliday(id: any, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${PortfolioService.URL}/holiday/${id}`, httpRequestHandler);
  }
}
