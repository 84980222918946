import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class MeetingsService {
  public static URL = environment.URL.API + 'meetings';

  constructor(private http: HttpService) {}

  /**
   * Listado de reuniones
   */
  mine(yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${MeetingsService.URL}/mine/${yearId}`, httpRequestHandler);
  }

  /**
   * Información del turno
   */
  meetingStudent(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${MeetingsService.URL}/meeting-student`, data, httpRequestHandler);
  }

  /**
   * Guardar selección de turno
   */
  selectSession(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${MeetingsService.URL}/select-session`, data, httpRequestHandler);
  }

  /**
   * Liberar turno
   */
  freeSession(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${MeetingsService.URL}/free-session`, data, httpRequestHandler);
  }

  /**
   * Listado de Grupos asociadas a un año lectivo.
   */
  listDates = data => {
    return this.http.observableGet(`${MeetingsService.URL}/list-dates/${data.id}`, { mapFn: res => res.meetingDates });
  };

  /**
   * Listado de Turnos disponibles para reunión.
   */
  listSchedule = data => {
    return this.http.observableGet(`${MeetingsService.URL}/list-schedule/${data.mggId}/${data.dateId}`, { mapFn: res => res.schedule });
  };
}
