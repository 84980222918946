import { Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

import { FunctionsService } from '../../../services/util';
import { ICustomErrorMessages } from '../extensions';

@Component({
  selector: 'app-form-control-error',
  templateUrl: './form-control-error.component.html',
  styleUrls: ['./form-control-error.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormControlErrorComponent),
    },
  ],
})
export class FormControlErrorComponent implements OnInit, ControlValueAccessor {
  @Input()
  formControlName: string;

  @Input()
  className: string;

  control: AbstractControl;
  formError: {
    message: string;
    params: any;
  };
  customErrorMessages: ICustomErrorMessages;

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
    private fn: FunctionsService
  ) {
    this.initFormError();
  }

  ngOnInit() {
    this.control = this.controlContainer.control.get(this.formControlName);
    this.customErrorMessages = (<FormGroup>this.control.parent).customErrorMessages;

    this.control.statusChanges.subscribe(status => {
      if (this.control.invalid && this.control.touched) {
        Object.keys(this.control.errors).every(errorName => {
          this.formError = this.getErrorMessage(errorName, this.control.errors[errorName]);
          return false;
        });
      } else this.initFormError();
    });

    if (this.control.touched) this.control.updateValueAndValidity();
  }

  initFormError() {
    this.formError = {
      message: '',
      params: null,
    };
  }

  private getErrorMessage(errorName: string, error: any) {
    if (this.customErrorMessages && this.customErrorMessages[this.formControlName] && this.customErrorMessages[this.formControlName][errorName]) {
      return {
        message: this.customErrorMessages[this.formControlName][errorName],
        params: error,
      };
    }

    const params: any = {};
    switch (errorName) {
      case 'minlength':
        params.value = error.requiredLength;
        break;
      case 'maxlength':
        params.value = error.requiredLength;
        break;
      case 'pattern':
        params.value = error.requiredPattern || error.value;
        break;
      case 'bennettEmail':
        params.value = error.domain;
        break;
      case 'minValue':
        params.value = error.min;
        break;
      case 'minField':
        params.value = error.min;
        break;
      case 'min':
        error.min = error.min.toString().replace('.', ',');
        error.min = error.min.split(',');
        if (error.min.length > 1) error.min = `${this.fn.formatNumber(error.min[0])},${error.min[1]}`;
        else error.min = this.fn.formatNumber(error.min[0]);
        params.value = error.min;
        break;
      case 'max':
        error.max = error.max.toString().replace('.', ',');
        error.max = error.max.split(',');
        if (error.max.length > 1) error.max = `${this.fn.formatNumber(error.max[0])},${error.max[1]}`;
        else error.max = this.fn.formatNumber(error.max[0]);
        params.value = error.max;
        break;
      case 'equalField':
        params.value = error.equal;
        break;
      case 'diffField':
        params.value = error.diff;
        break;
      case 'notBennettEmail':
        params.value1 = error.domain1;
        params.value2 = error.domain2;
        break;
      case 'dateGreaterThan':
        params.value = error.date;
        break;
      case 'numberGreaterThan':
        params.value = error.number;
        break;
      case 'numberGreaterOrEqualThan':
        params.value = error.number;
        break;
      case 'timeGreaterThan':
        params.value = error.time;
        break;
      case 'minDate':
        params.value = error.min;
        break;
      case 'minOrEqualDate':
        params.value = error.min;
        break;
      case 'maxDate':
        params.value = error.max;
        break;
      case 'maxOrEqualDate':
        params.value = error.max;
        break;
      case 'numberLowerThan':
        params.value = this.fn.formatNumber(error.number);
        break;
      case 'numberLowerOrEqualThan':
        params.value = this.fn.formatNumber(error.number);
        break;
      default:
        break;
    }

    return {
      message: 'validation.' + errorName,
      params: params,
    };
  }

  writeValue(value: any) {}
  registerOnChange(fn) {}
  registerOnTouched(fn) {}
}
