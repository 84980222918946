import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-content',
  templateUrl: './loading-content.component.html',
  styleUrls: ['./loading-content.component.scss']
})
export class LoadingContentComponent implements OnInit {
  /**
   * Loader size 0 | 1 | 2 | 3
   * @default 1
   */
  @Input() size: number;
  @Input() center: boolean;
  @Input() className: string;
  @Input() color = 'blue';

  constructor() {}

  ngOnInit() {}
}
