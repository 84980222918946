import { Directive, EventEmitter, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export class SelectOptions {
  loading = new BehaviorSubject<boolean>(null);
  changed = new EventEmitter<any[]>();

  private _options: any[];
  get options() {
    return this._options;
  }
  set options(value: any[]) {
    this._options = value;
    this.changed.emit(value);
  }

  constructor(private dataFn: (data?) => Observable<any[]>) {}

  fetch(config?: { data?: any; onLoaded?: (options: any[]) => void }): Subscription {
    this.loading.next(true);
    return this.dataFn(config ? config.data : null)
      .pipe(
        catchError(() => of([])),
        tap(() => this.loading.next(false))
      )
      .subscribe(res => {
        this.options = res;
        if (config && config.onLoaded) config.onLoaded(res);
      });
  }

  clear() {
    this.options = [];
  }
}

export interface ISelectLookupData {
  q?: string;
  initial?: any;
  data?: any;
}

export declare type SelectLookupFn = (data: ISelectLookupData) => Observable<any>;

@Directive({
  selector: 'ng-template[selectLabel]',
})
export class SelectLabelDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: 'ng-template[selectOption]',
})
export class SelectOptionDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}
