import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class AttendanceTypeService {
  public static URL = environment.URL.API + 'attendance-type';

  constructor(private http: HttpService) {}

  /**
   * List attendance types
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AttendanceTypeService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Store attendance type
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(AttendanceTypeService.URL, data, httpRequestHandler);
  }

  /**
   * Delete attendance type
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${AttendanceTypeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get attendance type
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AttendanceTypeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List attendance types
   */
  list = () => {
    return this.http.observableGet(`${AttendanceTypeService.URL}/list`, { mapFn: res => res.attendanceTypes });
  };
}
