import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class PaymentOptionService {
  public static URL = environment.URL.API + 'payment-option';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de opciones de pago
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(PaymentOptionService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Guardar opciones de pago
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(PaymentOptionService.URL, data, httpRequestHandler);
  }

  /**
   * Eliminar opciones de pago
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${PaymentOptionService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Editar opciones de pago
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PaymentOptionService.URL}/${id}`, httpRequestHandler);
  }
}
