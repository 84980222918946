import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class FinancialDiscountService {
  public static URL = environment.URL.API + 'financial-discount';

  constructor(private http: HttpService) {}

  /**
   * Listado de descuentos financieros especiales
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(FinancialDiscountService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Datss de descuento financiero
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${FinancialDiscountService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Guardar descuento financiero a estudiante
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(FinancialDiscountService.URL, data, httpRequestHandler);
  }

  /**
   * Eliminar descuento financieros especiales
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${FinancialDiscountService.URL}/${id}`, httpRequestHandler);
  }
}
