import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum PersonTemperatureType {
  Entry = 1,
  Departure = 2,
  Random = 3,
}

export enum StudentPollStatus {
  FilledOut = 1,
  FilledOutWithWarning = 2,
  WithoutFillingOut = 3,
}

@Injectable()
export class PersonTemperatureService {
  public static URL = environment.URL.API + 'person-temperature';

  types = [
    { id: PersonTemperatureType.Entry, key: 'personTemperature.type.entry', name: '' },
    { id: PersonTemperatureType.Departure, key: 'personTemperature.type.departure', name: '' },
    { id: PersonTemperatureType.Random, key: 'personTemperature.type.random', name: '' },
  ];

  studentPollStatuses = [
    {
      id: StudentPollStatus.FilledOut,
      key: 'personTemperature.stuPollStatus.filledOut',
      name: '',
      icon: 'fa-check-circle text-success',
      class: 'bg-success-light',
    },
    {
      id: StudentPollStatus.FilledOutWithWarning,
      key: 'personTemperature.stuPollStatus.filledOutWithWarning',
      name: '',
      icon: 'fa-exclamation-circle text-danger',
      class: 'bg-danger-light',
    },
    {
      id: StudentPollStatus.WithoutFillingOut,
      key: 'personTemperature.stuPollStatus.withoutFillingOut',
      name: '',
      icon: 'fa-exclamation-triangle text-warning',
      class: 'bg-warning-light',
    },
  ];

  constructor(private http: HttpService, private fn: FunctionsService, translate: TranslateService) {
    const lists = {
      types: this.types,
      studentPollStatuses: this.studentPollStatuses,
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  getType(type: PersonTemperatureType) {
    return this.types.find(t => t.id === type);
  }

  getStuPollStatus(status: StudentPollStatus) {
    return this.studentPollStatuses.find(s => s.id === status);
  }

  /**
   * List person temperature
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PersonTemperatureService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get person temperature
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PersonTemperatureService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save person temperature
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(PersonTemperatureService.URL, data, httpRequestHandler);
  }

  /**
   * Delete person temperature
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${PersonTemperatureService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List Person Temperature
   * @param httpRequestHandler HttpRequestHandler
   */
  list = () => {
    return this.http.observableGet(`${PersonTemperatureService.URL}/list`, { mapFn: res => res.personTemperature });
  };

  /**
   * Get student poll info
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  getStudentPollInfo(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PersonTemperatureService.URL}/poll-info`, data, httpRequestHandler);
  }
}
