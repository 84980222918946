import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pager-size-filter',
  templateUrl: './pager-size-filter.component.html',
  styleUrls: ['./pager-size-filter.component.scss']
})
export class PagerSizeFilterComponent implements OnInit {
  @Input()
  filter = true;

  constructor() {}

  ngOnInit() {}
}
