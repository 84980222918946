<ng-template #popContent>
  <div (clickOutside)="close()">
    <app-pager-range-date-column-filter-datepicker
      [months]="months"
      [fromDate]="fromDate"
      [toDate]="toDate"
      (datesChanges)="datesChanges($event)"
    ></app-pager-range-date-column-filter-datepicker>
    <div class="row with-top-margin-lg">
      <div class="col-12 text-center">
        <label>{{ ('mailLog.totalDays' | translate) + ': ' + numberOfDays }}</label>
      </div>
    </div>
    <div class="row" *ngIf="withHours">
      <div class="col-sm with-top-margin">
        <ngb-timepicker [(ngModel)]="fromHour" [meridian]="true" class="timepicker-sm"></ngb-timepicker>
      </div>
      <div class="col-sm with-top-margin">
        <ngb-timepicker [(ngModel)]="toHour" [meridian]="true" class="timepicker-sm"></ngb-timepicker>
      </div>
    </div>
    <hr class="mb-2" *ngIf="withHours" />
    <br *ngIf="!withHours" />
    <div class="row">
      <div class="col-md-12 text-center">
        <button class="btn btn-primary btn-mini mr-1" (click)="apply()">
          {{ 'general.applyFilter' | translate }}
        </button>
        <button class="btn btn-danger btn-mini" (click)="clear()">{{ 'general.clearFilter' | translate }}</button>
      </div>
    </div>
  </div>
</ng-template>

<div
  class="pos-relative"
  [ngbPopover]="popContent"
  triggers="manual"
  [placement]="placement"
  #p="ngbPopover"
  popoverClass="p-0"
  [autoClose]="'outside'"
>
  <div class="input-group input-group-sm w-auto" *ngIf="!date; else noDate" [ngClass]="{ 'mx-2': !label }">
    <button class="btn btn-outline-info btn-mini custom-button-full" (click)="open()">
      {{ label || 'general.filter' | translate }} <span class="fa fa-filter"></span>
    </button>
  </div>
  <ng-template #noDate>
    <div class="input-group input-group-sm w-auto" [ngClass]="{ 'mx-2': !label }">
      <button class="btn btn-info btn-mini custom-button" (click)="open()" placement="top" [ngbTooltip]="toolTipContent">
        {{ label || 'general.filter' | translate }} <span class="fa fa-filter"></span>
      </button>
      <div class="input-group-append">
        <button class="btn btn-danger" (click)="clear()" [ngbTooltip]="'general.clearFilter' | translate">
          <i class="fa fa-remove mr-0"></i>
        </button>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #toolTipContent>
  {{ ('general.from' | translate) + ' ' + formatDate((dateString?.split('|'))[0]) }}<br />
  {{ ('general.to' | translate) + ' ' + formatDate((dateString?.split('|'))[1]) }}
</ng-template>
