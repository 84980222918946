import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { IPagerFilter, LabelPosition, PagerService } from '../../../../services/util';

@Component({
  selector: 'app-pager-input-column-filter',
  templateUrl: './pager-input-column-filter.component.html',
  styleUrls: ['./pager-input-column-filter.component.scss']
})
export class PagerInputColumnFilterComponent implements OnInit, IPagerFilter {
  @Input() placeholder = 'general.search';
  @Input() name: string;
  @Input() default: string;
  @Input() label: string;
  @Input() labelPos: LabelPosition = 'left';

  filter: string;
  value: Subject<any> = new Subject();
  private changeSubject: Subject<string> = new Subject();

  @ViewChild('input', { static: false }) input: ElementRef;

  constructor(private pager: PagerService) {
    this.changeSubject.pipe(debounceTime(250)).subscribe(value => {
      this.value.next(value);
    });
  }

  ngOnInit() {
    this.pager.filterInitiated(this);
  }

  valueReady(value) {
    this.filter = value;
  }

  next(e) {
    this.changeSubject.next(e);
  }

  clear() {
    this.filter = null;
    this.next(this.filter);
    this.input.nativeElement.focus();
  }
}
