import { Injectable } from '@angular/core';
import { SelectLookupFn } from 'app/modules/shared/select';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class CommuneService {
  public static BASE_URL_API = environment.URL.API + 'commune';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de comuna
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(CommuneService.BASE_URL_API + '?' + params, httpRequestHandler);
  }

  /**
   * Agregar comuna
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(CommuneService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Eliminar comuna
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${CommuneService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Obtener comuna
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${CommuneService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar comuna
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${CommuneService.BASE_URL_API}/${id}`, data, httpRequestHandler);
  }

  /**
   * Select automeplete comuna
   */
  list: SelectLookupFn = data => {
    return this.http.observablePost(CommuneService.BASE_URL_API + '/list', data, { mapFn: res => res.communes });
  };
}
