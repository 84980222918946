export enum KeyCode {
  Left = 37,
  Up = 38,
  Right = 39,
  Down = 40,
  Escape = 27,
  Enter = 13,
  Space = 32,
  Backspace = 8,
}
