import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class ExtendedHoursService {
  public static BASE_URL_API = environment.URL.API + 'extended-hours';

  constructor(private http: HttpService) {}

  /**
   * List extended hours
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtendedHoursService.BASE_URL_API}?${params}`, httpRequestHandler);
  }

  /**
   * Get extended hours
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtendedHoursService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Save extended hours
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(ExtendedHoursService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Delete extended hours
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ExtendedHoursService.BASE_URL_API}/${id}`, httpRequestHandler);
  }
}
