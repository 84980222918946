import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum RouteBusType {
  Pickup = 1,
  Destination = 2
}

@Injectable()
export class RouteService {
  public static URL = environment.URL.API2 + 'route';

  constructor(private http: HttpService) {}

  /**
   * List routes
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${RouteService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Store route
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(RouteService.URL, data, httpRequestHandler);
  }

  /**
   * Delete route
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${RouteService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get route
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${RouteService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List routes from academic year
   */
  fromYear = ({ yearId }) => {
    return this.http.observableGet(`${RouteService.URL}/list/year/${yearId}`, { mapFn: res => res.routes });
  }
}
