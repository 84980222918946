import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

@Injectable()
export class ReturnPagerService {
  /**
   * Retorna los parametros de retorno
   */
  queryParams = {};

  /**
   * Retorna los parametros de retorno actuales
   */
  current: any = {};

  /**
   * Ruta a la cual se debe retornar
   */
  path: any[];

  /**
   * Fragment de la ruta de retorno
   */
  fragment: string;

  constructor(activated: ActivatedRoute, private router: Router) {
    this.queryParams = {};
    this.current = {};
    this.path = [];

    activated.queryParams.subscribe(params => {
      if (params.returnUrl) {
        this.current.returnUrl = params.returnUrl;

        const decoded = decodeURIComponent(params.returnUrl);
        const pagerParams = decoded.split('&');

        pagerParams.forEach(param => {
          const splitedParam = param.split('=');
          this.queryParams[splitedParam[0]] = splitedParam[1];
        });
      }
    });
  }

  back() {
    const extras: NavigationExtras = {
      queryParams: this.queryParams
    };
    if (this.fragment) extras.fragment = this.fragment;
    this.router.navigate(this.path, extras);
  }
}
