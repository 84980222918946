import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, ActivatedRoute } from '@angular/router';

import { SessionService } from '../services/util';

@Injectable()
export class GuestGuard implements CanActivate {
  constructor(private sessionService: SessionService, private router: Router, private route: ActivatedRoute) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const url = state.url;
    switch (this.sessionService.guest.type) {
      case 'admission_rating':
        if ('/g/rating' === url) return true;
        else {
          this.router.navigate(['/g/rating']);
          return true;
        }
      case 'admission_request':
        if ('/g/request' === url) return true;
        else {
          this.router.navigate(['/g/request']);
          return false;
        }
      case 'admission_risk_authorization':
        if ('/g/risk-authorization' === url) return true;
        else {
          this.router.navigate(['/g/risk-authorization']);
          return false;
        }
      case 'admission_data_authorization':
        if ('/g/data-authorization' === url) return true;
        else {
          this.router.navigate(['/g/data-authorization']);
          return false;
        }
    }
    return false;
  }
}
