import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum TicketTrackingTab {
  New = 'new',
  InProcess = 'in-process',
  Historic = 'historic',
}

export enum TicketTrackingReportType {
  TicketsList = 1,
}

export enum TicketTrackingReportFilterBy {
  AllTickets = 1,
  DateRange = 2,
  SpecificDate = 3,
}

@Injectable()
export class TicketTrackingService {
  public static URL = environment.URL.API + 'ticket-tracking';

  reportType = [{ id: TicketTrackingReportType.TicketsList, key: 'ticket.reportType.ticketList', name: '' }];

  filterBy = [
    { id: TicketTrackingReportFilterBy.AllTickets, key: 'ticket.filterBy.allTickets', name: '' },
    { id: TicketTrackingReportFilterBy.DateRange, key: 'ticket.filterBy.dateRange', name: '' },
    { id: TicketTrackingReportFilterBy.SpecificDate, key: 'ticket.filterBy.specificDate', name: '' },
  ];

  constructor(private http: HttpService, fn: FunctionsService, translate: TranslateService) {
    const lists = {
      filterBy: this.filterBy,
      reportType: this.reportType,
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * List tickets for tracking
   *
   * @param params string
   * @param tab TicketAuthorizationTab
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, tab: TicketTrackingTab, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${TicketTrackingService.URL}/${tab}?${params}`, httpRequestHandler);
  }

  /**
   * Mark ticket as read
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  markAsViewed(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${TicketTrackingService.URL}/mark-as-viewed`, { id: id }, httpRequestHandler);
  }

  /**
   * Generar reporte
   */
  generateReport(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${TicketTrackingService.URL}/reports`, data, httpRequestHandler);
  }
}
