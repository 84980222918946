import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class HouseTypeService {
  public static BASE_URL_API = environment.URL.API + 'house-type';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de Tipos de Casas
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(HouseTypeService.BASE_URL_API + '?' + params, httpRequestHandler);
  }

  /**
   * Agregar Tipo de Casa
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(HouseTypeService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Eliminar Tipo de Casa
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${HouseTypeService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Editar Tipo de Casa
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${HouseTypeService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar Tipo de Casa
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${HouseTypeService.BASE_URL_API}/${id}`, data, httpRequestHandler);
  }

  /**
   * Listado para combo de Tipos de Casa
   */
  list = () => {
    return this.http.observableGet(`${HouseTypeService.BASE_URL_API}/list`, { mapFn: res => res.houseTypes });
  };
}
