import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'bgColor' })
export class BgColorPipe implements PipeTransform {
  constructor() {}

  transform(value: number): string {
    switch (value) {
      case 1: // Verde
        return 'success';
      case 2: // Rojo
        return 'danger';
      case 3: // Amarillo
        return 'warning';
      case 4: // azul
        return 'primary';
      case 5: // gris
        return 'default';
    }
  }
}
