import { Injectable } from '@angular/core';
import { SelectLookupFn } from 'app/modules/shared/select';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';


@Injectable()
export class CityService {
  public static BASE_URL_API = environment.URL.API + 'city';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de ciudad
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(CityService.BASE_URL_API + '?' + params, httpRequestHandler);
  }

  /**
   * Agregar ciudad
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(CityService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Eliminar ciudad
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${CityService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Obtener ciudad
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${CityService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar ciudad
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${CityService.BASE_URL_API}/${id}`, data, httpRequestHandler);
  }

  /**
   * Select autocomplete ciudad
   */
  list: SelectLookupFn = data => {
    return this.http.observablePost(CityService.BASE_URL_API + '/list', data, { mapFn: res => res.cities });
  };
}
