import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { GC } from '../../modules/shared';
import { NotifyMessageService } from './notify-message.service';
import { SessionService } from './session.service';
import { SwalMessageService } from './swal-message.service';

interface HttpRequestBase {
  /**
   * default = true | whether to include the current token to the request headers
   */
  includeToken?: boolean;
}

export interface HttpRequestHandler extends HttpRequestBase {
  /**
   * callback called when request was successfully excuted
   */
  success?: (res: any) => void;
  /**
   * callback called when request had an error response
   */
  error?: (res: any, util: ErrorUtil) => void;
  /**
   * callback called when request has finished
   */
  always?: () => void;
  /**
   * default = `ErrorMessageType.Notify` | how to show default error when error response occurs
   */
  errorMsgType?: MessageType;
}

export interface HttpRequestObservable extends HttpRequestBase {
  /**
   * function to execute where map funtion is call
   */
  mapFn: (res: any) => any;
}

export interface HttpRequestStream extends HttpRequestBase {
  /**
   * callback events called when stream reaquest reports an event
   */
  events?: {
    [name: string]: (res) => void;
  };
  /**
   * callback called when stream reaquest has been opened
   */
  open?: (es: EventSource) => void;
  /**
   * callback called when request had an error response
   */
  error?: (res: any, util: ErrorUtil) => void;
  /**
   * callback called when request has triggerd 'finished' event
   */
  finished?: (res: any) => void;
  /**
   * callback called when request has finished
   */
  always?: () => void;
  /**
   * default = `ErrorMessageType.Notify` | how to show default error when error response occurs
   */
  errorMsgType?: MessageType;
}

export interface HttpRequestPromise extends HttpRequestBase {
  /**
   * key used for returning the value from de response
   */
  mapKey?: string;
  /**
   * callback called when request had an error response
   */
  error?: (err: any) => void;
  /**
   * default = `ErrorMessageType.Notify` | how to show default error when error response occurs
   */
  errorMsgType?: MessageType;
}

export interface ErrorUtil {
  /**
   * error object given by native angular http service
   */
  err: any;

  /**
   * Show default error `NotifyMessageService`
   */
  showNotifyError();

  /**
   * Show default error `SwalMessageService`
   */
  showSwalError(okHandler?: () => void);
}

export enum MessageType {
  None = 1,
  Notify = 2,
  Swal = 3,
}

@Injectable()
export class HttpService {
  private debounceTimeout;

  constructor(
    private http: HttpClient,
    private notify: NotifyMessageService,
    private swal: SwalMessageService,
    private sessionService: SessionService
  ) {}

  /**
   * performs a request with GET http method
   */
  public get(url: string, httpRequestHandler: HttpRequestHandler) {
    this.setHandlerDefaults(httpRequestHandler);
    const headers = this.getHeaders(httpRequestHandler);
    return this.handleResponse(this.http.get(url, { headers: headers }), httpRequestHandler);
  }

  /**
   * performs a request with POST http method
   */
  public post(url: string, data: any, httpRequestHandler: HttpRequestHandler) {
    this.setHandlerDefaults(httpRequestHandler);
    const headers = this.getHeaders(httpRequestHandler);
    return this.handleResponse(this.http.post(url, data, { headers: headers }), httpRequestHandler);
  }

  /**
   * performs a request with DELETE http method
   */
  public delete(url: string, httpRequestHandler: HttpRequestHandler) {
    this.setHandlerDefaults(httpRequestHandler);
    const headers = this.getHeaders(httpRequestHandler);
    return this.handleResponse(this.http.delete(url, { headers: headers }), httpRequestHandler);
  }

  /**
   * performs a request with PUT http method
   */
  public put(url: string, data: any, httpRequestHandler: HttpRequestHandler) {
    this.setHandlerDefaults(httpRequestHandler);
    const headers = this.getHeaders(httpRequestHandler);
    return this.handleResponse(this.http.put(url, data, { headers: headers }), httpRequestHandler);
  }

  stream(url: string, opts: HttpRequestStream) {
    const headers: any = {};

    if (opts.includeToken == null) opts.includeToken = true;
    if (opts.includeToken) headers.Authorization = 'Bearer ' + this.sessionService.token;
    if (opts.errorMsgType == null) opts.errorMsgType = MessageType.Notify;

    const es: EventSource = new EventSourcePolyfill(url, { headers });

    es.addEventListener(
      'open',
      (e: MessageEvent) => {
        if (opts.open) opts.open(es);
      },
      false
    );

    if (opts.events) {
      Object.keys(opts.events).forEach(key => {
        es.addEventListener(
          key,
          (e: MessageEvent) => {
            const res = e.data ? JSON.parse(e.data) : null;
            opts.events[key](res);
          },
          false
        );
      });
    }

    es.addEventListener(
      'error',
      (e: MessageEvent) => {
        es.close();
        if (opts.always) opts.always();

        this.handleError(e, opts.errorMsgType);
        const data = e.data ? JSON.parse(e.data) : null;
        if (opts.error) {
          const util: ErrorUtil = {
            err: e,
            showNotifyError: this.generateError.bind(this, e, MessageType.Notify, data),
            showSwalError: okHandler => this.generateError(e, MessageType.Swal, data, okHandler),
          };
          opts.error(data, util);
        }
      },
      false
    );

    es.addEventListener(
      'finished',
      (e: MessageEvent) => {
        es.close();
        if (opts.always) opts.always();

        const res = e.data ? JSON.parse(e.data) : null;
        if (opts.finished) opts.finished(res);
      },
      false
    );
  }

  private setHandlerDefaults(httpRequestHandler: HttpRequestHandler) {
    if (httpRequestHandler.includeToken == null) httpRequestHandler.includeToken = true;
    if (httpRequestHandler.errorMsgType == null) httpRequestHandler.errorMsgType = MessageType.Notify;
  }

  private setPromiseDefaults(httpRequestPromise: HttpRequestPromise) {
    if (httpRequestPromise.includeToken == null) httpRequestPromise.includeToken = true;
    if (httpRequestPromise.errorMsgType == null) httpRequestPromise.errorMsgType = MessageType.Notify;
  }

  private setObservableDefaults(httpRequestObservable: HttpRequestObservable) {
    if (httpRequestObservable.includeToken == null) httpRequestObservable.includeToken = true;
  }

  /**
   * returns the headers for an specific request based on guest config
   */
  private getHeaders(httpRequestBase: HttpRequestBase): HttpHeaders {
    let headers = new HttpHeaders();
    if (httpRequestBase.includeToken) headers = headers.append('Authorization', 'Bearer ' + this.sessionService.token);
    return headers;
  }

  /**
   * handle the response for a given request
   */
  private handleResponse(request: Observable<any>, httpRequestHandler: HttpRequestHandler) {
    return request
      .pipe(
        finalize(() => {
          if (httpRequestHandler.always) httpRequestHandler.always();
        })
      )
      .subscribe(
        res => {
          if (httpRequestHandler.success) httpRequestHandler.success(res);
        },
        err => {
          const data = this.handleError(err, httpRequestHandler.errorMsgType);
          if (httpRequestHandler.error) {
            const util: ErrorUtil = {
              err: err,
              showNotifyError: this.generateError.bind(this, err, MessageType.Notify, data),
              showSwalError: okHandler => this.generateError(err, MessageType.Swal, data, okHandler),
            };
            httpRequestHandler.error(data, util);
          }
        }
      );
  }

  /**
   * returns an Observable for a specific GET request
   */
  public observableGet(url: string, httpRequestObservable: HttpRequestObservable) {
    this.setObservableDefaults(httpRequestObservable);
    const headers = this.getHeaders(httpRequestObservable);
    return this.handleObservable(this.http.get(url, { headers: headers }), httpRequestObservable);
  }

  /**
   * returns an Observable for a specific POST request
   */
  public observablePost(url: string, data, httpRequestObservable: HttpRequestObservable) {
    this.setObservableDefaults(httpRequestObservable);
    const headers = this.getHeaders(httpRequestObservable);
    return this.handleObservable(this.http.post(url, data, { headers: headers }), httpRequestObservable);
  }

  /**
   * handle the response for a given request `Observable`
   */
  private handleObservable(request: Observable<any>, httpRequestObservable: HttpRequestObservable) {
    return request.pipe(map(res => httpRequestObservable.mapFn(res))).pipe(
      catchError(err => {
        this.handleError(err, MessageType.Notify);
        return of(null);
      })
    );
  }

  /**
   * returns a Promise form a specific GET request
   */
  public promiseGet(url: string, httpRequestPromise: HttpRequestPromise) {
    this.setPromiseDefaults(httpRequestPromise);
    const headers = this.getHeaders(httpRequestPromise);
    return this.http
      .get(url, { headers: headers })
      .pipe(
        map(res => {
          if (httpRequestPromise.mapKey) return res[httpRequestPromise.mapKey];
          else return res;
        })
      )
      .toPromise()
      .catch(err => this.handleError(err, httpRequestPromise.errorMsgType));
  }

  /**
   * returns a debounced Promised for a specific GET request
   */
  public debouncedPromiseGet(url: string, httpRequestPromise: HttpRequestPromise) {
    clearTimeout(this.debounceTimeout);

    return new Promise((resolve, reject) => {
      this.debounceTimeout = setTimeout(() => {
        this.setPromiseDefaults(httpRequestPromise);
        const headers = this.getHeaders(httpRequestPromise);
        this.http
          .get(url, { headers: headers })
          .pipe(
            map(res => {
              if (httpRequestPromise.mapKey) return res[httpRequestPromise.mapKey];
              else return res;
            })
          )
          .subscribe(
            res => resolve(res),
            err => this.handleError(err, httpRequestPromise.errorMsgType)
          );
      }, 250);
    });
  }

  /**
   * returns a Promise with an resolved empty array
   */
  public promiseEmptyArray() {
    return new Promise((resolve, reject) => {
      resolve([]);
    });
  }

  /**
   * handle the error for the requests
   */
  private handleError(err: any, type: MessageType) {
    let data: any;
    if (err.headers && ['application/json', 'application/json; charset=utf-8'].includes(err.headers.get('content-type'))) {
      data = err.error;
      if (
        err.status === 401 &&
        (data.type === GC.ERROR.TOKEN.INVALID ||
          data.type === GC.ERROR.TOKEN.MISSING ||
          data.type === GC.ERROR.TOKEN.EXPIRED ||
          data.type === GC.ERROR.NO_USER ||
          data.type === GC.ERROR.USER_BLOCKED)
      ) {
        this.sessionService.logout();
      }
    }
    this.generateError(err, type, data);

    if (!data) data = {};
    return data;
  }

  private generateError(err: any, type: MessageType, data: any, okHandler?: () => void) {
    if (type === MessageType.None) return;

    const basicError = () => {
      const errorMsg = err.statusText ? err.statusText : 'Server error';

      if (type === MessageType.Notify) this.notify.plainError('Error: ' + err.status, errorMsg);
      else if (type === MessageType.Swal) this.swal.plainError('Error: ' + err.status, errorMsg, okHandler);
    };

    if (!data) basicError();
    else {
      let errorKey: string;
      if (err.status === 404 && data.type === GC.ERROR.MODEL_NOT_FOUND) {
        errorKey = 'general.modelNotFound';
      } else if (err.status === 500 && data.type === GC.ERROR.QUERY && data.queryCode === GC.QUERY_CODE.REFERENCED) {
        errorKey = 'general.referencedError';
      } else if (err.status === 422 && data.type === GC.ERROR.FAILED_VALIDATION) {
        errorKey = 'validation.failed';
      }

      if (errorKey) {
        if (type === MessageType.Notify) this.notify.error('Error: ' + err.status, errorKey);
        else if (type === MessageType.Swal) this.swal.error('Error: ' + err.status, errorKey, okHandler);
      } else basicError();
    }
  }
}
