import { Routes } from '@angular/router';

import { AuthGuard, GuestGuard, LoginGuard } from './guards';
import { BoxLayoutGuard } from './guards/box-layout.guard';
import { ForbiddenComponent } from './modules/error/forbidden/forbidden.component';
import { NotFoundComponent } from './modules/error/not-found/not-found.component';
import { PlaceToPayFaqComponent } from './modules/error/place-to-pay-faq/place-to-pay-faq.component';
import { AdminLayoutComponent } from './modules/layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './modules/layouts/auth/auth-layout.component';
import { NoauthLayoutComponent } from './modules/layouts/noauth/noauth-layout.component';

export const AppRoutes: Routes = [
  {
    path: 'auth',
    component: AuthLayoutComponent,
    canActivate: [LoginGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule),
      },
    ],
  },
  {
    path: 'noauth',
    component: NoauthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/noauth/noauth.module').then(m => m.NoauthModule),
      },
    ],
  },
  {
    path: 'g',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard, BoxLayoutGuard, GuestGuard],
    data: {
      box: true,
      guest: true,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/guest/guest.module').then(m => m.GuestModule),
      },
    ],
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard, BoxLayoutGuard],
    data: {
      box: false,
      guest: false,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'forbidden',
        component: ForbiddenComponent,
        data: {
          breadcrumb: '403',
          status: false,
        },
      },
      {
        path: 'not-found',
        component: NotFoundComponent,
        data: {
          breadcrumb: '404',
          status: false,
        },
      },
      {
        path: 'place-to-pay-faq',
        component: PlaceToPayFaqComponent,
        data: {
          title: 'electronicPayment.frequentQuestions',
          breadcrumb: 'electronicPayment.faq',
          status: false,
        },
      },
      //#region Acudiente --------------------------------------------------------------------
      {
        path: 'family',
        loadChildren: () => import('./modules/attendant/family/attendant-family.module').then(m => m.AttendantFamilyModule),
      },
      {
        path: 'enroll',
        loadChildren: () => import('./modules/attendant/enroll/attendant-enroll.module').then(m => m.AttendantEnrollModule),
      },
      {
        path: 'price-simulator',
        loadChildren: () => import('./modules/attendant/price-simulator/attendant-price-simulator.module').then(m => m.AttendantPriceSimulatorModule),
      },
      {
        path: 'attendant-portfolio',
        loadChildren: () => import('./modules/attendant/portfolio/attendant-portfolio.module').then(m => m.AttendantPortfolioModule),
      },
      {
        path: 'person-election',
        loadChildren: () => import('./modules/attendant/election/attendant-election.module').then(m => m.AttendantElectionModule),
      },
      {
        path: 'attendant-academic',
        loadChildren: () => import('./modules/attendant/academic/attendant-academic.module').then(m => m.AttendantAcademicModule),
      },
      {
        path: 'meetings',
        loadChildren: () => import('./modules/attendant/meetings/meetings.module').then(m => m.MeetingsModule),
      },
      {
        path: 'attendant-polls',
        loadChildren: () => import('./modules/attendant/attendant-polls/attendant-polls.module').then(m => m.AttendantPollsModule),
      },
      //#endregion
      //#region Estudiante --------------------------------------------------------------------
      {
        path: 'student-academic',
        loadChildren: () => import('./modules/student/academic/student-academic.module').then(m => m.StudentAcademicModule),
      },
      //#endregion
      {
        path: 'configuration',
        loadChildren: () => import('./modules/configuration/configuration.module').then(m => m.ConfigurationModule),
      },
      {
        path: 'people',
        loadChildren: () => import('./modules/people/people.module').then(m => m.PeopleModule),
      },
      {
        path: 'admissions',
        loadChildren: () => import('./modules/admissions/admissions.module').then(m => m.AdmissionsModule),
      },
      {
        path: 'sports',
        loadChildren: () => import('./modules/sports/sports.module').then(m => m.SportsModule),
      },
      {
        path: 'library',
        loadChildren: () => import('./modules/library/library-dashboard.module').then(m => m.LibraryDashboardModule),
      },
      {
        path: 'portfolio',
        loadChildren: () => import('./modules/portfolio/portfolio.module').then(m => m.PortfolioModule),
      },
      {
        path: 'enrollment',
        loadChildren: () => import('./modules/enrollment/enrollment.module').then(m => m.EnrollmentModule),
      },
      {
        path: 'discounts',
        loadChildren: () => import('./modules/discounts/discounts.module').then(m => m.DiscountsModule),
      },
      {
        path: 'academic',
        loadChildren: () => import('./modules/academic/academic.module').then(m => m.AcademicModule),
      },
      {
        path: 'updates',
        loadChildren: () => import('./modules/updates/updates.module').then(m => m.UpdatesModule),
      },
      {
        path: 'nursing',
        loadChildren: () => import('./modules/nursing/nursing.module').then(m => m.NursingModule),
      },
      {
        path: 'transport',
        loadChildren: () => import('./modules/transport/transport.module').then(m => m.TransportModule),
      },
      {
        path: 'mailing',
        loadChildren: () => import('./modules/mailing/mailing.module').then(m => m.MailingModule),
      },
      {
        path: 'reports',
        loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule),
      },
      {
        path: 'help-desk',
        loadChildren: () => import('./modules/help-desk/help-desk.module').then(m => m.HelpDeskModule),
      },
      {
        path: 'tutoring',
        loadChildren: () => import('./modules/tutoring/tutoring-dashboard.module').then(m => m.TutoringDashboardModule),
      },
      {
        path: 'bi',
        loadChildren: () => import('./modules/bi/bi-dashboard.module').then(m => m.BiDashboardModule),
      },
      {
        path: 'human-talent',
        loadChildren: () => import('./modules/human-talent/human-talent.module').then(m => m.HumanTalentModule),
      },
      {
        path: 'costs-centers',
        loadChildren: () => import('./modules/costs-centers/costs-centers.module').then(m => m.CostsCentersModule),
      },
      {
        path: 'extracurriculars',
        loadChildren: () => import('./modules/extracurriculars/extracurriculars.module').then(m => m.ExtracurricularsModule),
      },
      {
        path: 'baby-love',
        loadChildren: () => import('./modules/baby-love/baby-love.module').then(m => m.BabyLoveModule),
      }
    ],
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: {
      breadcrumb: '404',
      status: false,
    },
  },
];
