import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class AcademicAspectService {
  public static URL = environment.URL.API + 'academic-aspect';

  constructor(private http: HttpService) {}

  /**
   * List of Academic Aspects
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(AcademicAspectService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Delete Academic Aspects
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${AcademicAspectService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Edit Academic Aspect
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AcademicAspectService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Update Academic Aspect
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${AcademicAspectService.URL}/${id}`, data, httpRequestHandler);
  }

  /**
   * Save Academic Aspect
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AcademicAspectService.URL}`, data, httpRequestHandler);
  }

  /**
   * List performance reports
   */
  fromYear = data => {
    return this.http.observableGet(`${AcademicAspectService.URL}/list/${data.id}`, { mapFn: res => res.academicAspects });
  };
}
