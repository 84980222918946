import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class ClassGradeGroupService {
  public static URL = environment.URL.API + 'class-grade-group';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de Clases por Grupo
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(ClassGradeGroupService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Eliminar Clase Grupo
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ClassGradeGroupService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Editar Clase Grupo
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ClassGradeGroupService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Guardar Clase Grupo
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ClassGradeGroupService.URL}`, data, httpRequestHandler);
  }
}
