import { Component } from '@angular/core';

import { NotifyMessageService } from '../../../services/util';

@Component({
  selector: 'app-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent {
  currentImg = '';

  constructor(public notifyMessage: NotifyMessageService) {
    const backgroundImg = ['bg-1.jpg', 'bg-2.jpg', 'bg-3.jpg', 'bg-4.jpg', 'bg-5.jpg'];
    const position: number = Math.floor(Math.random() * backgroundImg.length + 1 - 1);
    this.currentImg = `url(assets/img/bennett/${backgroundImg[position]})`;
  }
}
