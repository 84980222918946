import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class EnrollmentDocumentsService {

  yearChanged = new EventEmitter<number>();

  constructor() { }

}
