import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbDate, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';

export interface IPagerRangeDates {
  from: NgbDate;
  to: NgbDate;
}

@Component({
  selector: 'app-pager-range-date-column-filter-datepicker',
  templateUrl: './pager-range-date-column-filter-datepicker.component.html',
  styleUrls: ['./pager-range-date-column-filter-datepicker.component.scss'],
})
export class PagerRangeDateColumnFilterDatepickerComponent implements OnInit {
  @Input() fromDate: NgbDate;
  @Input() toDate: NgbDate;
  @Input() months: number;
  @Output() datesChanges = new EventEmitter<IPagerRangeDates>();

  navigated: boolean;
  hoveredDate: NgbDate;

  datePicker: NgbInputDatepicker;
  @ViewChild('dt', { static: false }) set setDp(value: NgbInputDatepicker) {
    this.datePicker = value;
    setTimeout(() => {
      if (this.fromDate && !this.navigated) {
        this.datePicker.navigateTo({ month: this.fromDate.month, year: this.fromDate.year });
        this.navigated = true;
      }
    }, 0);
  }

  constructor() {}

  ngOnInit() {}

  /**
   * Set selected date
   *
   * @param dt NgbDateStruct
   */
  onDateSelection(dt: any) {
    const date = new NgbDate(dt.year, dt.month, dt.day);

    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    this.datesChanges.emit({
      from: this.fromDate,
      to: this.toDate,
    });
  }

  /**
   * If mouse is hovered
   * @param dt NgbDateStruct
   */
  isHovered(dt: any) {
    const date = new NgbDate(dt.year, dt.month, dt.day);
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  /**
   * Evaluates if date is inside
   * @param dt NgbDateStruct
   */
  isInside(dt: any) {
    const date = new NgbDate(dt.year, dt.month, dt.day);
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  /**
   * Evaluates if date is in rage
   * @param dt NgbDateStruct
   */
  isRange(dt: any) {
    const date = new NgbDate(dt.year, dt.month, dt.day);
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }
}
