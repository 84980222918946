import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class AccDocTypeSequenceService {
  public static URL = environment.URL.API + 'acc-doc-type-sequence';

  constructor(private http: HttpService) {}

  /**
   * List accounting doc type sequences
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AccDocTypeSequenceService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get accounting doc type sequence
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AccDocTypeSequenceService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save accounting doc type sequence
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(AccDocTypeSequenceService.URL, data, httpRequestHandler);
  }

  /**
   * Delete accounting doc type sequence
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${AccDocTypeSequenceService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List accounting doc type sequences
   * @param httpRequestHandler HttpRequestHandler
   */
  list = () => {
    return this.http.observableGet(`${AccDocTypeSequenceService.URL}/list`, { mapFn: res => res.accDocTypeSequences });
  };
}
