import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class ValueJudgmentService {
  public static URL = environment.URL.API + 'value-judgment';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de Juicios de Valor.
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(ValueJudgmentService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Eliminar Juicio de Valor.
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ValueJudgmentService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Editar Juicio de Valor.
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ValueJudgmentService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Guardar Juicio de Valor.
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ValueJudgmentService.URL}`, data, httpRequestHandler);
  }

  /**
   * Listado de Juicios de Valor.
   * @param gradeGroupId ID de año lectivo
   */
  listByGrade = data => {
    return this.http.observableGet(`${ValueJudgmentService.URL}/list/${data.id}/${data.sports}`, { mapFn: res => res.yearClasses });
  };

  /**
   * Consulta los juicios de valor de un grado para un año lectivo.
   *
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  gradeValueJudgment(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ValueJudgmentService.URL}/grade-value-judgment`, data, httpRequestHandler);
  }

  /**
   * Guarda los juicios de valor de un grado para un año lectivo.
   *
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  saveGradeValueJudgment(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ValueJudgmentService.URL}/save-grade-value-judgment`, data, httpRequestHandler);
  }
}
