import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../../environments/environment';
import { FunctionsService, HttpRequestHandler, HttpService } from '../../util';

export enum CustomDiscountBasedOn {
  None = 0,
  Discount = 1,
  CustomDiscount = 2,
}

@Injectable()
export class CustomDiscountService {
  public static URL = environment.URL.API + 'custom-discount';

  basedOn = [
    { id: CustomDiscountBasedOn.None, key: 'general.none', name: '' },
    { id: CustomDiscountBasedOn.Discount, key: 'discount.msgTitle', name: '' },
    { id: CustomDiscountBasedOn.CustomDiscount, key: 'customDiscount.msgTitle', name: '' },
  ];

  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    const lists = { basedOn: this.basedOn };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * Mostrar listado de descuentos personalizados
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(CustomDiscountService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Guardar descuento personalizado
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(CustomDiscountService.URL, data, httpRequestHandler);
  }

  /**
   * Eliminar descuento personalizado
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${CustomDiscountService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Obtener descuento personalizado
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${CustomDiscountService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Obtener listado de descuentos personalizados de un estudiante
   */
  getStudentCustomDiscounts(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${CustomDiscountService.URL}/${id}/student-custom-discounts`, httpRequestHandler);
  }
}
