import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class IntellectualLevelService {
  public static BASE_URL_API = environment.URL.API + 'intellectual-level';

  constructor(private http: HttpService) {}

  /**
   * List intellectual levels
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${IntellectualLevelService.BASE_URL_API}?${params}`, httpRequestHandler);
  }

  /**
   * List intellectual levels
   */
  list = () => {
    return this.http.observableGet(`${IntellectualLevelService.BASE_URL_API}/list`, { mapFn: res => res.intellectualLevels });
  };

  /**
   * Store intellectual level
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(IntellectualLevelService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Delete intellectual level
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${IntellectualLevelService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Get intellectual level
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${IntellectualLevelService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Update intellectual level
   */
  update(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${IntellectualLevelService.BASE_URL_API}`, data, httpRequestHandler);
  }
}
