import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class BloodTypeService {
  public static URL = environment.URL.API + 'blood-type';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado tipo de sangre
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(BloodTypeService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Agregar tipo de sangre
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(BloodTypeService.URL, data, httpRequestHandler);
  }

  /**
   * Eliminar tipo de sangre
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${BloodTypeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Editar tipo de sangre
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${BloodTypeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar tipo de sangre
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${BloodTypeService.URL}/${id}`, data, httpRequestHandler);
  }

  /**
   * Listado de Tipos de sangre para combo.
   */
  list = () => {
    return this.http.observableGet(`${BloodTypeService.URL}/list`, { mapFn: res => res.bloodTypes });
  };
}
