import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class PensionFundService {
  public static BASE_URL_API = environment.URL.API + 'pension-fund';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de fondos de pension
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(PensionFundService.BASE_URL_API + '?' + params, httpRequestHandler);
  }

  /**
   * Agregar fondo de pension
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(PensionFundService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Eliminar fondo de pension
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${PensionFundService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Editar fondo de pension
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PensionFundService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar fondo de pension
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${PensionFundService.BASE_URL_API}/${id}`, data, httpRequestHandler);
  }

  /**
   * Listado de FONDO para combo.
   */
  list = () => {
    return this.http.observableGet(`${PensionFundService.BASE_URL_API}/list`, { mapFn: res => res.pensionFunds });
  };
}
