import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoadingContentModule } from '../loading-content';
import { DimmerComponent } from './dimmer.component';

@NgModule({
  imports: [CommonModule, LoadingContentModule],
  declarations: [DimmerComponent],
  exports: [DimmerComponent]
})
export class DimmerModule {
}
