<div [formGroup]="form">
  <ng-select
    [items]="options"
    [bindLabel]="valueField"
    [searchFn]="searchFn"
    [loading]="loading"
    formControlName="selected"
    (blur)="innerBlur()"
    [multiple]="multi"
    [closeOnSelect]="!multi"
    [searchable]="searchable"
    [clearable]="clearable"
    [appendTo]="appendTo"
    [placeholder]="placeholder | translate"
    [clearSearchOnAdd]="multi"
    [groupBy]="groupBy"
    #select
  >
    <ng-container *ngIf="labelTemplate; else noLabelTpl">
      <ng-template ng-label-tmp let-item="item" let-item$="item$">
        <ng-container *ngTemplateOutlet="labelTemplate?.templateRef; context: { $implicit: item, option$: item$ }"></ng-container>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="optionTemplate; else noOptionTpl">
      <ng-template ng-option-tmp let-item="item" let-item$="item$">
        <ng-container *ngIf="checkbox && multi">
          <input type="checkbox" class="mr-1" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" />
        </ng-container>

        <ng-container *ngTemplateOutlet="optionTemplate?.templateRef; context: { $implicit: item, option$: item$ }"></ng-container>
      </ng-template>

      <ng-container *ngIf="!labelTemplate && !multi; else noLabelTpl">
        <ng-template ng-label-tmp let-item="item" let-item$="item$">
          <ng-container *ngTemplateOutlet="optionTemplate?.templateRef; context: { $implicit: item, option$: item$ }"></ng-container>
        </ng-template>
      </ng-container>
    </ng-container>

    <ng-template #noLabelTpl>
      <ng-template ng-label-tmp let-item="item">
        <span class="ng-value-label">{{ item[displayField] }}</span>
      </ng-template>
    </ng-template>

    <ng-template #noOptionTpl>
      <ng-template ng-option-tmp let-item="item" let-item$="item$">
        <ng-container *ngIf="checkbox && multi">
          <input type="checkbox" class="mr-1" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" />
        </ng-container>

        <span class="ng-option-label">{{ item[displayField] }}</span>
      </ng-template>
    </ng-template>
  </ng-select>
</div>
