import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum HealthOptionType {
  Coexistence = 1,
  ComorbiditiesChildren = 2,
  ComorbiditiesFamily = 3,
  Disabilities = 4,
  HealthRegimen = 5,
  Transport = 6,
  TravelTime = 7,
}

@Injectable()
export class HealthOptionService {
  public static URL = environment.URL.API + 'health-option';

  types = [
    { id: HealthOptionType.Coexistence, key: 'healthOption.type.coexistence', name: '' },
    { id: HealthOptionType.ComorbiditiesChildren, key: 'healthOption.type.comorbiditiesChildren', name: '' },
    { id: HealthOptionType.ComorbiditiesFamily, key: 'healthOption.type.comorbiditiesFamily', name: '' },
    { id: HealthOptionType.Disabilities, key: 'healthOption.type.disabilities', name: '' },
    { id: HealthOptionType.HealthRegimen, key: 'healthOption.type.healthRegimen', name: '' },
    { id: HealthOptionType.Transport, key: 'healthOption.type.transport', name: '' },
    { id: HealthOptionType.TravelTime, key: 'healthOption.type.travelTime', name: '' },
  ];

  constructor(private http: HttpService, private fn: FunctionsService, translate: TranslateService) {
    const lists = { types: this.types };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  getType(type: HealthOptionType) {
    return this.types.find(t => t.id === type);
  }

  /**
   * Show health option list
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(HealthOptionService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Add health option
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(HealthOptionService.URL, data, httpRequestHandler);
  }

  /**
   * Delete health option
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${HealthOptionService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Edit health option
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${HealthOptionService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List health options
   */
  list = () => {
    return this.http.observableGet(`${HealthOptionService.URL}/list`, { mapFn: res => res.healthOptions });
  };
}
