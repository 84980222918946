<span
  class="px-2 py-1 b-radius-0"
  (click)="edit()"
  [ngClass]="{ 'hover-bg-light inline-editable': !disabled, 'full-span': fullSpan, border: border && !disabled }"
  *ngIf="!editing; else editTpl"
>
  <ng-container [ngSwitch]="type">
    <ng-template ngSwitchCase="text">
      <ng-container *ngIf="fullSpan && !form.controls['actual'].value; else valueTpl">
        &nbsp;
      </ng-container>
      <ng-template #valueTpl>
        {{ form.controls['actual'].value }}
      </ng-template>
    </ng-template>
    <ng-template ngSwitchCase="money"><i class="fa fa-usd mr-1 text-default"></i>{{ form.controls['actual'].value | formatNumber }}</ng-template>
    <ng-template ngSwitchCase="percentage">
      <span *ngIf="form.controls['actual'].value == null; else notNullTpl">
        {{ nullValue }}
      </span>
      <ng-template #notNullTpl>
        {{ form.controls['actual'].value | formatNumber: false }}
        <span class="ml-1 font-weight-bold text-default">%</span>
      </ng-template>
    </ng-template>
  </ng-container>
  <i class="fa fa-pencil ml-1 text-default" *ngIf="!disabled"></i>
</span>
<ng-template #editTpl>
  <div [formGroup]="form">
    <div class="input-group input-group-sm" (clickOutside)="clickOutside($event)">
      <ng-container [ngSwitch]="type">
        <input
          *ngSwitchCase="'text'"
          type="text"
          class="form-control"
          formControlName="temp"
          (keydown)="keydown($event)"
          [readOnly]="loading"
          #input
        />
        <app-input-money
          *ngSwitchCase="'money'"
          formControlName="temp"
          class="d-flex"
          style="flex: 1;"
          (keydown)="keydown($event)"
          [addon]="false"
          [prefix]="true"
          [readonly]="loading"
          #inputMoney
        ></app-input-money>
        <app-input-percentage
          *ngSwitchCase="'percentage'"
          formControlName="temp"
          class="d-flex"
          style="flex: 1;"
          (keydown)="keydown($event)"
          [addon]="false"
          [readonly]="loading"
          #inputPercentage
        ></app-input-percentage>
      </ng-container>

      <div class="input-group-append">
        <ng-template [ngIf]="!loading">
          <button class="btn btn-primary" type="button" [ngbTooltip]="'general.save' | translate" (click)="ok()">
            <i class="fa fa-check m-0"></i>
          </button>
          <button class="btn btn-default" type="button" [ngbTooltip]="'general.cancel' | translate" (click)="cancel()">
            <i class="fa fa-remove m-0"></i>
          </button>
        </ng-template>
        <div class="input-group-text" *ngIf="loading"><i class="fa fa-spinner fa-pulse"></i></div>
      </div>
    </div>
    <app-form-control-error formControlName="temp"></app-form-control-error>
  </div>
</ng-template>
