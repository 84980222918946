import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class TransportDiaryConsultationService {
  public static URL = environment.URL.API + 'transport-diary-consultation';

  constructor(private http: HttpService) {}

  /**
   * List students
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  index(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${TransportDiaryConsultationService.URL}`, data, httpRequestHandler);
  }

  /**
   * Print student's list
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  print(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${TransportDiaryConsultationService.URL}/print`, data, httpRequestHandler);
  }
}
