import { EventEmitter, Injectable } from '@angular/core';

import { SignalRService } from '../util';
import { BaseBroadcast } from './base-broadcast';

@Injectable()
export class NotificationBroadcast extends BaseBroadcast {
  onNew = new EventEmitter<any>();
  private _onNew = data => this.onNew.emit(data);

  constructor(signalRService: SignalRService) {
    super(signalRService, 'notifications-');

    this.setEvents({
      new: this._onNew,
    });
  }
}
