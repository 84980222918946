import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class ExtraActivityYearGradeService {
  public static URL = environment.URL.API2 + 'extra-activity-year-grade';

  constructor(private http: HttpService) {
  }

  /**
   * List Extracurricular Activities for Academic year
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtraActivityYearGradeService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get Extracurricular Activity for Academic year
   */
  get(yearId: number, id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtraActivityYearGradeService.URL}/${yearId}/${id}`, httpRequestHandler);
  }

  /**
   * Save Extracurricular Activity for Academic year
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(ExtraActivityYearGradeService.URL, data, httpRequestHandler);
  }

  /**
   * Delete Extracurricular Activity from Academic year
   */
  delete(yearId: number, extracurricularActivityId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ExtraActivityYearGradeService.URL}/${yearId}/${extracurricularActivityId}`, httpRequestHandler);
  }

  /**
   * List Extracurricular Activities for Academic year for select component
   */
  list = () => {
    return this.http.observableGet(`${ExtraActivityYearGradeService.URL}/list`, { mapFn: res => res.companies });
  };
}
