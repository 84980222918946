import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class GradeConfigurationService {
  public static URL = environment.URL.API + 'grade-configuration';

  constructor(private http: HttpService) {}

  /**
   * Listado de grados del año lectivo
   * @param id Id de año lectivo
   */
  getGradesConfiguration(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${GradeConfigurationService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Guardar configuración de grados del año lectivo
   */
  saveGradesConfiguration(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${GradeConfigurationService.URL}/${id}`, data, httpRequestHandler);
  }
}
