<section class="login p-fixed d-flex text-center common-img-bg" [ngStyle]="{ 'background-image': currentImg }">
  <div class="auth-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="login-card card-body auth-body">
          <div class="auth-box shadow auth-overflow">
            <router-outlet>
              <spinner></spinner>
            </router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<simple-notifications [options]="notifyMessage.options"></simple-notifications>
