<div class="input-group input-group-sm" *ngIf="addon; else noAddon">
  <ng-container *ngTemplateOutlet="noAddon"></ng-container>
  <div class="input-group-append">
    <div class="input-group-text"><i class="fa fa-percent"></i></div>
  </div>
</div>
<ng-template #noAddon>
  <input
    type="text"
    class="form-control form-control-sm"
    [(ngModel)]="value"
    [textMask]="{ mask: percentMask, modelClean: true }"
    [disabled]="isDisabled"
    [readOnly]="readonly"
    (ngModelChange)="onModelChange($event)"
    (blur)="blur()"
    (keypress)="handleKeyboardEvent($event)"
    #input
  />
</ng-template>
