import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class AttendantAccountingDocsService {
  public static URL = environment.URL.API + 'attendant-accounting-doc';

  constructor(private http: HttpService) {}

  /**
   * List ftp accounting docs
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AttendantAccountingDocsService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get accounting doc details
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, verify: boolean, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AttendantAccountingDocsService.URL}/${id}/${+!!verify}`, httpRequestHandler);
  }

  /**
   * Get financial third party of attendant's families
   * @param httpRequestHandler HttpRequestHandler
   */
  getAttendantFtps(httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${AttendantAccountingDocsService.URL}/attendant-frs`, httpRequestHandler);
  }
}
