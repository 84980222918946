<ng-template [ngIf]="control">
  <ng-template [ngIf]="control.pending" [ngIfElse]="errorTemplate">
    <div class="text-muted" [ngClass]="className"><i class="fa fa-spinner fa-pulse mr-1"></i>{{ 'validation.validating' | translate }}</div>
  </ng-template>
  <ng-template #errorTemplate>
    <div *ngIf="formError.message" class="text-danger" [ngClass]="className">
      <i class="fa fa-exclamation-circle mr-1"></i>
      {{ formError.message | translate: formError.params }}
    </div>
  </ng-template>
</ng-template>
