<div [ngClass]="{ 'p-l-10 p-r-10': !label }">
  <ng-container *ngIf="label; else noLabelTpl" [ngSwitch]="labelPos">
    <div class="d-table" *ngSwitchCase="'left'">
      <div class="d-table-cell text-nowrap pr-1">
        <label>{{ label | translate }}:</label>
      </div>
      <div class="d-table-cell w-100"><ng-container *ngTemplateOutlet="inputTpl"></ng-container></div>
    </div>
    <div *ngSwitchCase="'top'">
      <label>{{ label | translate }}:</label>
      <ng-container *ngTemplateOutlet="inputTpl"></ng-container>
    </div>
  </ng-container>
  <ng-template #noLabelTpl><ng-container *ngTemplateOutlet="inputTpl"></ng-container></ng-template>
</div>

<ng-template #inputTpl>
  <div class="input-column-filter pos-relative">
    <input
      type="text"
      [(ngModel)]="filter"
      class="form-control form-control-sm"
      [placeholder]="placeholder | translate"
      (ngModelChange)="next($event)"
      (select)="$event.stopPropagation()"
      [ngClass]="{ 'has-filter': !!filter }"
      #input
    />
    <i class="fa fa-remove clear-value hover-bg" (click)="clear()" *ngIf="filter"></i>
  </div>
</ng-template>
