import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum TutoringDayType {
  Online = 1,
  OnSite = 2,
  Both = 3,
}

@Injectable()
export class TutoringService {
  public static URL = environment.URL.API + 'tutoring';

  public tutoringDayTypes = [
    { id: TutoringDayType.Online, name: '', key: 'tutoring.online' },
    { id: TutoringDayType.OnSite, name: '', key: 'tutoring.onSite' },
    { id: TutoringDayType.Both, name: '', key: 'tutoring.both' },
  ];

  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    const lists = {
      tutoringDayTypes: this.tutoringDayTypes,
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * Index tutorings
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(TutoringService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Delete tutoring
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${TutoringService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get tutoring
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${TutoringService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save tutoring
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${TutoringService.URL}`, data, httpRequestHandler);
  }

  /**
   * List tutoring
   */
  list = () => {
    return this.http.observableGet(`${TutoringService.URL}/list`, { mapFn: res => res.tutoring });
  };
}
