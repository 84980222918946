<div
  id="pcoded"
  (window:resize)="onResize($event)"
  class="pcoded iscollapsed"
  theme-layout="horizontal"
  horizontal-placement="top"
  horizontal-layout="widebox"
  [attr.pcoded-device-type]="deviceType"
  vnavigation-view="view1"
>
  <div class="pcoded-overlay-box"></div>

  <div class="pcoded-container navbar-wrapper z-depth-0">
    <nav class="navbar header-navbar pcoded-header" header-theme="theme4">
      <div class="navbar-wrapper">
        <div class="navbar-logo no-sidebar" navbar-theme="theme4" [ngClass]="{ 'sidebar-testing': env.testing }">
          <a>
            <img class="img-fluid" src="assets/img/bennett/navbar-logo.png" alt="Colegio Bennett" title="Colegio Bennett" />
          </a>
          <a (click)="onMobileMenu()" class="mobile-options">
            <i class="ti-more"></i>
          </a>
        </div>

        <div class="navbar-container">
          <div>
            <ul class="nav-left">
              <li>
                <a href="javascript:;" appToggleFullscreen>
                  <i class="ti-fullscreen"></i>
                </a>
              </li>

              <li *ngIf="env.testing" class="text-default f-w-500 f-16 my-n1">AMBIENTE DE PRUEBAS</li>
            </ul>
            <ul [@mobileMenuTop]="isCollapsedMobile" class="nav-right" [ngClass]="isCollapsedMobile">
              <li class="header-notification lng-dropdown">
                <a href="javascript:;" id="dropdown-active-item">
                  <img src="/assets/img/icons/{{ language.flagIcon }}" alt="" class="mr-1 mt-n1" /> {{ language.name }}
                </a>
                <ul class="show-notification">
                  <li *ngFor="let item of languages">
                    <a href="javascript:;" (click)="changeLang(item)">
                      <img src="/assets/img/icons/{{ item.flagIcon }}" alt="" class="flag-language mt-n1" /> {{ item.name }}
                      <i class="fa fa-check m-l-5" *ngIf="translate.currentLang === item.lang"></i>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>

    <div class="pcoded-main-container">
      <div class="pcoded-wrapper">
        <div class="pcoded-content">
          <div class="pcoded-inner-content">
            <div class="main-body">
              <div class="page-wrapper">
                <app-title></app-title>
                <app-breadcrumbs></app-breadcrumbs>
                <div class="page-body">
                  <router-outlet> <spinner></spinner> </router-outlet>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<simple-notifications [options]="notify.options"></simple-notifications>
