import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class NotificationService {
  public static BASE_URL_API = environment.URL.API2 + 'notification';

  constructor(private http: HttpService) {}

  /**
   * Listado de notificaciones
   */
  index(page: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(NotificationService.BASE_URL_API + '?p=' + page, httpRequestHandler);
  }

  /**
   * Marcar una notificacion como vista
   */
  markAsSeen(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.put(NotificationService.BASE_URL_API + '/' + id + '/mark-as-seen', {}, httpRequestHandler);
  }
}
