<div class="pos-relative control-container">
  <div class="input-group input-group-sm">
    <input
      type="text"
      class="form-control form-control-sm"
      [value]="value.generated.actual"
      (blur)="inputBlur()"
      [ngbTooltip]="value.generated.actual"
      readonly
    />
    <div class="input-group-append">
      <button type="button" *ngIf="!copy" type="button" class="btn btn-{{ disabled ? 'default' : 'primary' }}" (click)="open()" [disabled]="disabled">
        <i class="fa fa-pencil mr-0"></i>
      </button>
      <div *ngIf="copy" ngbDropdown class="d-inline-block dropdown-default" placement="bottom-right" (openChange)="openChange($event)">
        <button
          type="button"
          class="btn btn-sm btn-copy-address rounded-0"
          [ngClass]="disabled ? 'btn-default' : 'btn-primary'"
          ngbDropdownToggle
          container="body"
          [disabled]="disabled"
        >
          <i class="fa fa-pencil mr-0"></i>
        </button>
        <div ngbDropdownMenu>
          <button ngbDropdownItem class="dropdown-item f-14 px-3 py-2" (click)="open()">
            <i class="fa fa-pencil mr-2"></i>{{ 'address.edit' | translate }}
          </button>
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem class="dropdown-item f-14 px-3 text-center f-w-600 text-primary" disabled *ngIf="copyList?.length">
            <i>{{ 'address.available' | translate }}</i>
          </button>
          <button
            ngbDropdownItem
            class="dropdown-item f-14 px-3 py-2"
            [ngbTooltip]="'address.copy' | translate"
            placement="left"
            container="body"
            *ngFor="let a of copyList"
            (click)="setCopyAddres(a.value)"
          >
            {{ a.parsed }}
          </button>
          <button ngbDropdownItem class="dropdown-item f-14 px-3 py-2" disabled *ngIf="!copyList?.length">
            <i>{{ 'address.noCopyList' | translate }}</i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <i
    class="fa fa-remove clear-value hover-bg cursor-pointer"
    [ngClass]="{ 'with-copy': copy }"
    (click)="clear()"
    *ngIf="value.plain && !disabled"
  ></i>
</div>

<app-modal [title]="title" #modal [size]="ModalSize.Large" (closed)="modalClosed()">
  <div class="modal-body">
    <form [formGroup]="form" *ngIf="!hidden">
      <div class="row">
        <div class="col-md-5">
          <div class="row">
            <div class="col-md-4 pr-0">
              <div class="form-group">
                <label>{{ 'general.type' | translate }}</label>
                <app-select appendTo="body" [options]="types" formControlName="streetType"></app-select>
                <app-form-control-error formControlName="streetType"></app-form-control-error>
              </div>
            </div>

            <div class="col-md-4 px-0">
              <div class="form-group">
                <label>{{ 'address.number' | translate }}/{{ 'address.letter' | translate }}/{{ 'address.bis' | translate }}</label>
                <div class="input-group">
                  <input type="text" class="form-control form-control-sm" formControlName="streetNumber" />
                </div>
                <app-form-control-error formControlName="streetNumber"></app-form-control-error>
              </div>
            </div>

            <div class="col-md-4 pr-0">
              <div class="form-group">
                <label>{{ 'address.cardinalPoint' | translate }}</label>
                <app-select appendTo="body" [options]="cardinalDirections" formControlName="cardinalDirection"></app-select>
                <app-form-control-error formControlName="cardinalDirection"></app-form-control-error>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-7">
          <div class="row">
            <div class="col-md-3 pr-0">
              <div class="form-group">
                <label>{{ 'general.type' | translate }}</label>
                <app-select appendTo="body" [options]="types2" formControlName="streetType2"></app-select>
                <app-form-control-error formControlName="streetType2"></app-form-control-error>
              </div>
            </div>

            <div class="col-md-6 px-0">
              <div class="form-group">
                <label>{{ 'address.plaqueNumber' | translate }}</label>
                <div class="input-group input-group-sm">
                  <input type="text" class="form-control form-control-sm flex-2-2" formControlName="number1" />
                  <div class="input-group-prepend input-group-append">
                    <span class="input-group-text">-</span>
                  </div>
                  <input type="text" class="form-control form-control-sm" formControlName="number2" />
                </div>
                <app-form-control-error formControlName="number1"></app-form-control-error>
                <app-form-control-error formControlName="number2"></app-form-control-error>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <label>{{ 'address.cardinalPoint' | translate }}</label>
                <app-select appendTo="body" [options]="cardinalDirections" formControlName="cardinalDirection2"></app-select>
                <app-form-control-error formControlName="cardinalDirection2"></app-form-control-error>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-2 pr-0">
          <div class="form-group">
            <label>{{ 'address.complement' | translate }} 1</label>
            <app-select appendTo="body" [options]="complements" formControlName="complement1"></app-select>
            <app-form-control-error formControlName="complement1"></app-form-control-error>
          </div>
        </div>

        <div class="col-md-2 px-0">
          <div class="form-group">
            <label>&nbsp;</label>
            <input type="text" class="form-control form-control-sm" formControlName="complement1Value" />
            <app-form-control-error formControlName="complement1Value"></app-form-control-error>
          </div>
        </div>

        <div class="col-md-2 pr-0">
          <div class="form-group">
            <label>{{ 'address.complement' | translate }} 2</label>
            <app-select appendTo="body" [options]="complements" formControlName="complement2"></app-select>
            <app-form-control-error formControlName="complement2"></app-form-control-error>
          </div>
        </div>

        <div class="col-md-2 px-0">
          <div class="form-group">
            <label>&nbsp;</label>
            <input type="text" class="form-control form-control-sm" formControlName="complement2Value" />
            <app-form-control-error formControlName="complement2Value"></app-form-control-error>
          </div>
        </div>

        <div class="col-md-2 pr-0">
          <div class="form-group">
            <label>{{ 'address.complement' | translate }} 3</label>
            <app-select appendTo="body" [options]="complements" formControlName="complement3"></app-select>
            <app-form-control-error formControlName="complement3"></app-form-control-error>
          </div>
        </div>

        <div class="col-md-2 pl-0">
          <div class="form-group">
            <label>&nbsp;</label>
            <input type="text" class="form-control form-control-sm" formControlName="complement3Value" />
            <app-form-control-error formControlName="complement3Value"></app-form-control-error>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>{{ 'address.generated' | translate }}</label>
            <input type="text" class="form-control form-control-sm generated-address f-w-500 text-body" [value]="value.generated.temp" readonly />
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" (click)="submit()" class="btn btn-primary btn-sm mr-1">{{ 'general.ok' | translate }}</button>
    <button type="button" (click)="modal.close()" class="btn btn-default btn-sm">{{ 'general.cancel' | translate }}</button>
  </div>
</app-modal>
