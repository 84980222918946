import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class ContractSectionService {
  public static URL = environment.URL.API2 + 'contract-section';

  constructor(private http: HttpService) {}

  /**
   * List Contract Sections for select component
   */
  list = () => {
    return this.http.observableGet(`${ContractSectionService.URL}/list`, { mapFn: res => res.sections });
  };

  /**
   * Index Contract Sections
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ContractSectionService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Delete Contract Section
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ContractSectionService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get Contract Section
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ContractSectionService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Contract Section
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(ContractSectionService.URL, data, httpRequestHandler);
  }
}
