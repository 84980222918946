import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class GradeService {
  public static URL = environment.URL.API + 'grade';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de Grados
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(GradeService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Agregar Grado
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(GradeService.URL, data, httpRequestHandler);
  }

  /**
   * Eliminar Grado
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${GradeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Obtener Grado
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${GradeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Listado de Grados para combo.
   */
  list = () => {
    return this.http.observableGet(`${GradeService.URL}/list`, { mapFn: res => res.grades });
  };

  /**
   * Listado de Grados asociados a un año lectivo
   */
  fromYear = data => {
    return this.http.observableGet(`${GradeService.URL}/list/year/${data.yearId}`, { mapFn: res => res.grades });
  };

  /**
   * Listado de Grados asociados a un año lectivo validando secciones del usuario
   * @param yearId ID de año lectivo
   */
  fromYearAcademic = data => {
    return this.http.observableGet(`${GradeService.URL}/list/year/${data.yearId}/academic`, { mapFn: res => res.grades });
  };
}
