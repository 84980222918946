<app-select
  [valueField]="valueField"
  [displayField]="displayField"
  [selectOptions]="selectOptions"
  [options]="options"
  [placeholder]="placeholder"
  appendTo="body"
  [(ngModel)]="filter"
  (ngModelChange)="next($event)"
></app-select>
