import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class BlMaritalStatusService {
  public static URL = environment.URL.API2 + 'bl-marital-status';

  constructor(private http: HttpService) {}

  /**
   * Index Baby Love Marital Status
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${BlMaritalStatusService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Delete Baby Love Marital Status
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${BlMaritalStatusService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get Baby Love Marital Status
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${BlMaritalStatusService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Baby Love Marital Status
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(BlMaritalStatusService.URL, data, httpRequestHandler);
  }

  list = () => {
    return this.http.observableGet(BlMaritalStatusService.URL + '/list/', { mapFn: res => res.blMaritalStatus });
  };
}
