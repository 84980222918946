import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class PortfolioBlockService {
  public static BASE_URL_API = environment.URL.API + 'portfolio-block';

  constructor(private http: HttpService) {}

  /**
   * List financial
   */
  index(params: string, data, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PortfolioBlockService.BASE_URL_API}?${params}`, data, httpRequestHandler);
  }

  /**
   * Block financially
   */
  block(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PortfolioBlockService.BASE_URL_API}/save`, data, httpRequestHandler);
  }

  /**
   * Unblock financially
   */
  unblock(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PortfolioBlockService.BASE_URL_API}/unblock`, data, httpRequestHandler);
  }
}
