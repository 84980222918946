import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class ProviderPaymentPeriodService {
  public static URL = environment.URL.API2 + 'provider-payment-period';

  constructor(private http: HttpService) {}

  /**
   * Index Provider Payment Periods
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ProviderPaymentPeriodService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Delete Provider Payment Priod
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ProviderPaymentPeriodService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get Provider Payment Priod
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ProviderPaymentPeriodService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Provider Payment Period
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(ProviderPaymentPeriodService.URL, data, httpRequestHandler);
  }

  list = () => {
    return this.http.observableGet(`${ProviderPaymentPeriodService.URL}/list`, { mapFn: res => res.paymentPeriods });
  };
}
