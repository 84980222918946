import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

import { PollItemType } from './poll-item.service';

export enum PollType {
  NoPoll = 1,
  HasAlert = 2,
  CompletedPoll = 3,
}

export enum PollFilterBy {
  Employees = 1,
  Students = 2,
}

@Injectable()
export class PollService {
  public static URL = environment.URL.API + 'poll';

  types = [
    { id: PollType.NoPoll, key: 'pollTracking.type.noPoll', name: '' },
    { id: PollType.HasAlert, key: 'pollTracking.type.hasAlert', name: '' },
    { id: PollType.CompletedPoll, key: 'pollTracking.type.completedPoll', name: '' },
  ];

  filterBy = [
    { id: PollFilterBy.Employees, key: 'employee.title', name: '', permission:'poll_tracking_employee_consultation' },
    { id: PollFilterBy.Students, key: 'student.title', name: '' },
  ];

  constructor(private http: HttpService, private fn: FunctionsService, translate: TranslateService) {
    const lists = {
      types: this.types,
      filterBy: this.filterBy,
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * List of historical polls
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  getHistoricalPolls(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PollService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * List of hitorical polls form a person
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  getMyHistoricalPolls(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PollService.URL}/my-polls?${params}`, httpRequestHandler);
  }

  /**
   * Get poll
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  getPoll(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PollService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save poll
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(PollService.URL, data, httpRequestHandler);
  }

  /**
   * Get poll items active
   * @param httpRequestHandler HttpRequestHandler
   */
  getActiveItems(from: PollItemType, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PollService.URL}/actives/${from}`, httpRequestHandler);
  }

  /**
   * Get family students polls
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  getFamilyPolls(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PollService.URL}/family-polls`, data, httpRequestHandler);
  }

  /**
   * Validate if student applies for poll
   * @param studentId number
   * @param httpRequestHandler HttpRequestHandler
   */
  validateStudent(studentId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PollService.URL}/validate-student/${studentId}`, httpRequestHandler);
  }

  /**
   * Send reminder of COVID-19 poll to the employee or attendance of student who have not complete the poll.
   */
  sendPollNotificationReminder(type: number, id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PollService.URL}/${type}/poll-notification-reminder/${id}`, httpRequestHandler);
  }

  /**
   * Send reminder of COVID-19 poll to the employees or attendance who have not completed the poll.
   */
  sendAllPollNotificationReminder(type: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PollService.URL}/${type}/poll-notification-reminder`, httpRequestHandler);
  }

  /**
   * Export poll tracking data
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  export(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PollService.URL}/export`, data, httpRequestHandler);
  }
}
