import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { FunctionsService, HttpRequestHandler, HttpService } from '../../util';

export enum DiscountAdjustmentFilterBy {
  ChangeableDiscounts = 1,
  TempCompDiscounts = 2,
}

@Injectable()
export class DiscountAdjustmentService {
  public static URL = environment.URL.API + 'discount-adjustment';

  filterBy = [
    { id: DiscountAdjustmentFilterBy.ChangeableDiscounts, key: 'discountAdjustment.changeableDiscounts', name: '' },
    { id: DiscountAdjustmentFilterBy.TempCompDiscounts, key: 'discountAdjustment.tempCompDiscounts', name: '' },
  ];

  constructor(private http: HttpService, fn: FunctionsService) {
    const lists = {
      filterBy: this.filterBy,
    };

    fn.handleListTranslation(lists, this);
  }

  /**
   * Listado paginador de matriculas con descuento cambiable
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(DiscountAdjustmentService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Datos de matricula
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(DiscountAdjustmentService.URL + `/${id}`, httpRequestHandler);
  }

  /**
   * Guardar ajuste de descuento en matricula
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(DiscountAdjustmentService.URL, data, httpRequestHandler);
  }

  /**
   * Eliminar descuento complementario temporal
   */
  deleteTempDiscount(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(DiscountAdjustmentService.URL + '/delete-comp-discount', data, httpRequestHandler);
  }
}
