import { Component, OnInit } from '@angular/core';

import { ReturnPagerService } from '../../../services/util';

@Component({
  selector: 'app-return-back',
  templateUrl: './return-back.component.html'
})
export class ReturnBackComponent implements OnInit {
  constructor(public returnPager: ReturnPagerService) {}

  ngOnInit() {}
}
