<ng-container *ngIf="!accounting; else accountingTpl">
  <span class="f-w-600 mr-1" [ngClass]="symbolClass">$</span>
  <span *ngIf="intValue != null"
    >{{ intValue }}
    <span class="small text-secondary" *ngIf="showDecimals">,{{ decValue }}</span>
  </span>
</ng-container>

<ng-template #accountingTpl>
  <div class="d-table">
    <div class="d-table-cell f-w-600" [ngClass]="symbolClass">$</div>
    <div class="d-table-cell text-right w-100" *ngIf="intValue != null">
      {{ intValue }}<span class="small text-secondary" *ngIf="showDecimals">,{{ decValue }}</span>
    </div>
  </div>
</ng-template>
