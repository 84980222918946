<div class="row">
  <div [ngClass]="firstColClass">
    <ng-content></ng-content>
    <span [ngbTooltip]="data.docTypeName" container="body">
      <span class="f-w-500 mr-1">{{ data.docTypeShort }}:</span>{{ data.document }}
    </span>
  </div>
  <div [ngClass]="lastColClass">
    <span>{{ data.fullName }}</span>
  </div>
</div>
