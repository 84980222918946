import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class YearClassService {
  public static URL = environment.URL.API + 'year-class';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de Clases
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(YearClassService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Eliminar Clase
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${YearClassService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Editar Clase
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${YearClassService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Guardar Clase
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${YearClassService.URL}`, data, httpRequestHandler);
  }

  /**
   * Listado de clases
   * @param gradeGroupId ID de año lectivo
   */
  listByGrade = data => {
    return this.http.observableGet(`${YearClassService.URL}/list/${data.id}/${data.sports}`, { mapFn: res => res.yearClasses });
  };
}
