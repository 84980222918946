import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'mimeIcon' })
export class MimeIconPipe implements PipeTransform {
  constructor() {}

  /**
   * @param value mime
   */
  transform(value: string, includeColor = false): string {
    const defaultColor = 'text-body';
    const iconClasses = [
      // Media
      { name: 'image', icon: 'fa-file-image-o', color: defaultColor },
      { name: 'audio', icon: 'fa-file-audio-o', color: defaultColor },
      { name: 'video', icon: 'fa-file-video-o', color: defaultColor },
      // Documents
      { name: 'application/pdf', icon: 'fa-file-pdf-o', color: 'text-danger' },
      { name: 'application/msword', icon: 'fa-file-word-o', color: 'text-primary' },
      { name: 'application/vnd.ms-word', icon: 'fa-file-word-o', color: 'text-primary' },
      { name: 'application/vnd.oasis.opendocument.text', icon: 'fa-file-word-o', color: 'text-primary' },
      { name: 'application/vnd.openxmlformats-officedocument.wordprocessingml', icon: 'fa-file-word-o', color: 'text-primary' },
      { name: 'application/vnd.ms-excel', icon: 'fa-file-excel-o', color: 'text-success' },
      { name: 'application/vnd.openxmlformats-officedocument.spreadsheetml', icon: 'fa-file-excel-o', color: 'text-success' },
      { name: 'application/vnd.oasis.opendocument.spreadsheet', icon: 'fa-file-excel-o', color: 'text-success' },
      { name: 'application/vnd.ms-powerpoint', icon: 'fa-file-powerpoint-o', color: 'text-danger' },
      { name: 'application/vnd.openxmlformats-officedocument.presentationml', icon: 'fa-file-powerpoint-o', color: 'text-danger' },
      { name: 'application/vnd.oasis.opendocument.presentation', icon: 'fa-file-powerpoint-o', color: 'text-danger' },
      { name: 'text/plain', icon: 'fa-file-text-o', color: defaultColor },
      { name: 'text/html', icon: 'fa-file-code-o', color: defaultColor },
      { name: 'application/json', icon: 'fa-file-code-o', color: defaultColor },
      // Archives
      { name: 'application/gzip', icon: 'fa-file-archive-o', color: defaultColor },
      { name: 'application/zip', icon: 'fa-file-archive-o', color: defaultColor }
    ];

    const temp = iconClasses.find(ic => value.startsWith(ic.name));
    if (temp) return temp.icon + (includeColor ? ' ' + temp.color : '');

    return 'fa-file-o';
  }
}
