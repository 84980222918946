import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'explode' })
export class ExplodePipe implements PipeTransform {
  constructor() {}

  transform(value: string, separator: string = ';'): string[] {
    return value.split(separator);
  }
}
