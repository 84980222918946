import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum DocumentVersionType {
  PositionProfile = 1
}

@Injectable()
export class DocumentVersionService {
  public static URL = environment.URL.API2 + 'document-version';

  constructor(private http: HttpService) {
  }

  /**
   * Index employee committee
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${DocumentVersionService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get employee committee
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${DocumentVersionService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save employee committee
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(DocumentVersionService.URL, data, httpRequestHandler);
  }

  /**
   * Delete employee committee
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${DocumentVersionService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Guardar logo de Version de documento
   * @param id Id de document
   */
  saveLogo(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${DocumentVersionService.URL}/${id}/logo`, data, httpRequestHandler);
  }

  /**
   * List employee committee for select component
   */
  list = () => {
    return this.http.observableGet(`${DocumentVersionService.URL}/list`, { mapFn: res => res.documentVersions });
  };
}
