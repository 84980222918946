import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpRequestStream, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum CitationReportType {
  PerDay = 1,
}

@Injectable()
export class CitationService {
  public static URL = environment.URL.API + 'citation';

  reportTypes = [{ id: CitationReportType.PerDay, key: 'citation.perDay', name: '' }];

  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    const lists = { reportTypes: this.reportTypes };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * List citations
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${CitationService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * List students
   */
  getStudents(params: string, yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${CitationService.URL}/students/${yearId}?${params}`, httpRequestHandler);
  }

  /**
   * Store citation
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(CitationService.URL, data, httpRequestHandler);
  }

  /**
   * Copy citation
   */
  copy(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${CitationService.URL}/copy`, data, httpRequestHandler);
  }

  /**
   * Delete student citation
   */
  deleteStudentCitation(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${CitationService.URL}/${id}/student`, httpRequestHandler);
  }

  /**
   * Notify again
   */
  notifyAgain(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${CitationService.URL}/${id}/notify-again`, httpRequestHandler);
  }

  /**
   * Delete citation
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${CitationService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get notification data
   */
  getNotification(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${CitationService.URL}/${id}/notification`, httpRequestHandler);
  }

  /**
   * Mailing
   */
  mailing(id: number, opts: HttpRequestStream) {
    this.http.stream(`${CitationService.URL}/${id}/mailing`, opts);
  }

  /**
   * List citations
   */
  list = data => {
    return this.http.observablePost(`${CitationService.URL}/list`, data, { mapFn: res => res.citations });
  };

  /**
   * Generate citation report
   */
  generateReport(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${CitationService.URL}/reports`, data, httpRequestHandler);
  }
}
