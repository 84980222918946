import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

import { AutocompleteTypeahead, HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class ThirdPartyService {
  public static BASE_URL_API = environment.URL.API2 + 'third-party';

  constructor(private http: HttpService) {}

  /**
   * List third parties
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(ThirdPartyService.BASE_URL_API + '?' + params, httpRequestHandler);
  }

  /**
   * Save third party
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(ThirdPartyService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Delete third party
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ThirdPartyService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Get third party
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ThirdPartyService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * List third parties
   * @param httpRequestHandler HttpRequestHandler
   */
  list(httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ThirdPartyService.BASE_URL_API}/list`, httpRequestHandler);
  }

  /**
   * Method for autocomplete fields
   */
  listFilter: AutocompleteTypeahead = (query, data) => {
    return this.http.observablePost(ThirdPartyService.BASE_URL_API + '/list-roles', { q: query, data: data }, { mapFn: ({ roles }) => roles });
  };
}
