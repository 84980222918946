import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class ExtDurationDiscountService {
  public static URL = environment.URL.API + 'extended-duration-discount';

  constructor(private http: HttpService) {}

  /**
   * listado de descuentos de estudiante de fechas de matricula extemporanea
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(ExtDurationDiscountService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Fechas extemporanas de grado y año lectivo
   */
  getExtendedDurations(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ExtDurationDiscountService.URL}/extended-durations`, data, httpRequestHandler);
  }

  /**
   * Guarda el porcentage personlizado de matricula extemporanea para el estudiante
   */
  setPercentage(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ExtDurationDiscountService.URL}/percentage`, data, httpRequestHandler);
  }
}
