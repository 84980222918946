import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDate, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Subject } from 'rxjs';

import { IPagerFilter, PagerService } from '../../../../services/util';
import { IPagerRangeDates } from './datepicker/pager-range-date-column-filter-datepicker.component';

declare const $;

@Component({
  selector: 'app-pager-range-date-column-filter',
  templateUrl: './pager-range-date-column-filter.component.html',
  styleUrls: ['./pager-range-date-column-filter.component.scss'],
})
export class PagerRangeDateColumnFilterComponent implements OnInit, IPagerFilter {
  date: Boolean = false;
  dateString: string;

  @Input() name: string;
  @Input() placement = 'right';
  @Input() withHours = true;
  @Input() default: string;
  @Input() label: string;

  fromDate: NgbDate;
  fromHour: any;

  toDate: NgbDate;
  toHour: any;

  months = 2;
  numberOfDays = 0;

  value: Subject<any> = new Subject();

  @ViewChild('p', { static: true }) popover: NgbPopover;

  private canClosePopover = true;

  /**
   * Empty constructor
   * @param calendar NgbCalendar
   * @param pager PagerService
   */
  constructor(private calendar: NgbCalendar, private pager: PagerService) {}

  /**
   * On dates selected
   *
   * @param dt NgbDateStruct
   */
  datesChanges(dates: IPagerRangeDates) {
    this.fromDate = dates.from;
    this.toDate = dates.to;
    this.calculateDays();
  }

  selectDate(dt: string) {
    const date = new Date(dt);
    this.fromDate = new NgbDate(date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate());
    this.toDate = this.fromDate;
    this.fromHour = {
      hour: 0,
      minute: 0,
    };
    this.toHour = {
      hour: 23,
      minute: 59,
    };
    this.apply();
  }

  /**
   * On init component
   */
  ngOnInit() {
    this.pager.filterInitiated(this);

    this.popover.shown.subscribe(() => {
      $('datatable-body').css('z-index', '-1');
    });
    this.popover.hidden.subscribe(() => {
      $('datatable-body').css('z-index', 'inherit');
    });

    this.resizeComponent(window.innerWidth);
  }

  valueReady(value) {
    const date = value;

    if (date != null) {
      this.date = true;
      this.dateString = date;
      const dateArray = date.split('|');
      const fromDate = moment(dateArray[0], 'YYYY-MM-DD HH:mm');
      const toDate = moment(dateArray[1], 'YYYY-MM-DD HH:mm');
      this.fromDate = new NgbDate(fromDate.year(), fromDate.month() + 1, fromDate.date());
      this.toDate = new NgbDate(toDate.year(), toDate.month() + 1, toDate.date());
      this.fromHour = { hour: fromDate.hours(), minute: fromDate.minutes() };
      this.toHour = { hour: toDate.hours(), minute: toDate.minutes() };
    }
  }

  /**
   * Open popover
   */
  open() {
    this.popover.open();
    if (this.dateString != null) {
      this.date = true;
      const dateArray = this.dateString.split('|');
      const fromDate = moment(dateArray[0], 'YYYY-MM-DD HH:mm');
      const toDate = moment(dateArray[1], 'YYYY-MM-DD HH:mm');
      this.fromDate = new NgbDate(fromDate.year(), fromDate.month() + 1, fromDate.date());
      this.toDate = new NgbDate(toDate.year(), toDate.month() + 1, toDate.date());
      this.fromHour = { hour: fromDate.hours(), minute: fromDate.minutes() };
      this.toHour = { hour: toDate.hours(), minute: toDate.minutes() };
      this.calculateDays();
    }
    this.canClosePopover = false;
    setTimeout(() => {
      this.canClosePopover = true;
    }, 100);
  }

  /**
   * Close popover
   */
  close() {
    if (this.canClosePopover && this.popover.isOpen()) this.popover.close();
  }

  /**
   * Clear dates
   */
  clear() {
    this.reset();
    this.emit();
    this.close();
  }

  /**
   * Emit dates
   */
  emit() {
    this.value.next(this.dateString);
  }

  /**
   * Apply filter
   */
  apply() {
    if (this.fromDate) {
      if (!this.toDate) {
        this.toDate = this.fromDate;
      }
      if (this.withHours) {
        this.dateString = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day} ${this.fromHour.hour}:${this.fromHour.minute}` as string;
        this.dateString += `|${this.toDate.year}-${this.toDate.month}-${this.toDate.day} ${this.toHour.hour}:${this.toHour.minute}` as string;
      } else {
        this.dateString = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day} 00:00` as string;
        this.dateString += `|${this.toDate.year}-${this.toDate.month}-${this.toDate.day} 23:59` as string;
      }
      this.date = true;
      this.emit();
      this.close();
    }
  }

  /**
   * Reset filter
   */
  reset() {
    this.date = false;
    this.dateString = null;
    this.fromDate = null;
    this.toDate = null;
    this.fromHour = {
      hour: 0,
      minute: 0,
    };
    this.toHour = {
      hour: 23,
      minute: 59,
    };
    this.calculateDays();
  }

  /**
   * Responsive event
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resizeComponent(window.innerWidth);
  }

  /**
   * Set months to resize component
   * @param innerWidth number
   */
  resizeComponent(innerWidth: number) {
    if (innerWidth < 550) {
      this.months = 1;
    } else {
      this.months = 2;
    }
  }

  /**
   * Format date string
   * @param date input date
   * @return string formated date
   */
  formatDate(date: string): string {
    return moment(date).format(this.withHours ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD');
  }

  /**
   * Calculate number of days
   */
  calculateDays() {
    if (this.toDate) {
      const date1 = moment(this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day, 'YYYY-MM-DD');
      const date2 = moment(this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day, 'YYYY-MM-DD');
      this.numberOfDays = date2.diff(date1, 'days') + 1;
    } else if (this.fromDate) this.numberOfDays = 1;
    else this.numberOfDays = 0;
  }
}
