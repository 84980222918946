import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';

import { HttpRequestHandler, HttpService } from '../../util';

export enum AccountType {
  Debit = 1,
  Credit = 2
}

export enum CostCenterType {
  Default = 1,
  None = 2,
  Custom = 3
}

@Injectable()
export class ConceptService {
  public static URL = environment.URL.API + 'concept';

  accountTypes = [{ id: AccountType.Debit, name: '', key: 'concept.debit' }, { id: AccountType.Credit, name: '', key: 'concept.credit' }];

  costCenterType = [
    { id: CostCenterType.Default, name: '', key: 'concept.default' },
    { id: CostCenterType.None, name: '', key: 'general.none' },
    { id: CostCenterType.Custom, name: '', key: 'general.custom' }
  ];

  /**
   * Empty constructor
   * @param http HttpService
   */
  constructor(private http: HttpService, private translate: TranslateService) {
    const udpateLangValues = () => {
      this.accountTypes.forEach(item => (item.name = translate.instant(item.key)));
      this.accountTypes = [...this.accountTypes];

      this.costCenterType.forEach(item => (item.name = translate.instant(item.key)));
      this.costCenterType = [...this.costCenterType];
    };
    udpateLangValues();
    translate.onLangChange.subscribe(value => udpateLangValues());
  }

  /**
   * List concepts
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(ConceptService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Save concept
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(ConceptService.URL, data, httpRequestHandler);
  }

  /**
   * Delete concept
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ConceptService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get concept
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ConceptService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List concepts
   * @param httpRequestHandler HttpRequestHandler
   */
  list = () => {
    return this.http.observableGet(`${ConceptService.URL}/list`, { mapFn: res => res.concepts });
  };
}
