import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class SectionConfigurationService {
  public static URL = environment.URL.API + 'section-configuration';

  constructor(private http: HttpService) {}

  /**
   * Get year section configuration
   */
  getSectionConfiguration(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${SectionConfigurationService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save year section configuration
   */
  saveSectionConfiguration(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${SectionConfigurationService.URL}/${id}`, data, httpRequestHandler);
  }
}
