import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum BlFamilyStatus {
  Created = 1,
  Registered = 2,
  Active = 3,
  Inactive = 4,
}

@Injectable()
export class BlFamilyService {
  public static URL = environment.URL.API2 + 'bl-family';

  familyStatus = [
    { id: BlFamilyStatus.Created, key: 'blFamily.created', name: 'blFamily.expired', class: 'label label-info' },
    { id: BlFamilyStatus.Registered, key: 'blFamily.registered', name: 'blFamily.registered', class: 'label label-primary' },
    { id: BlFamilyStatus.Active, key: 'blFamily.active', name: 'blFamily.active', class: 'label label-success' },
    { id: BlFamilyStatus.Inactive, key: 'blFamily.inactive', name: 'blFamily.inactive', class: 'label label-danger' },
  ];

  constructor(private http: HttpService, private fn: FunctionsService, translate: TranslateService) {
    const lists = {
      familyStatus: this.familyStatus,
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  getFamilyStatus(familyStatus: BlFamilyStatus) {
    return this.familyStatus.find(s => s.id === familyStatus);
  }

  /**
   * Index Baby Love Family
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${BlFamilyService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Delete Baby Love Family
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${BlFamilyService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get Baby Love Family
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${BlFamilyService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Baby Love Family
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(BlFamilyService.URL, data, httpRequestHandler);
  }

  /**
   * List Baby Love Family s
   * @param httpRequestHandler HttpRequestHandler
   */
  list = () => {
    return this.http.observableGet(`${BlFamilyService.URL}/list`, { mapFn: res => res.blFamily });
  };
}
