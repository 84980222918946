import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class TaxLiabilityService {
  public static BASE_URL_API = environment.URL.API + 'tax-liability';

  constructor(private http: HttpService) {}

  /**
   * Show tax liabilities list
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(TaxLiabilityService.BASE_URL_API + '?' + params, httpRequestHandler);
  }

  /**
   * Save tax liability
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(TaxLiabilityService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Delete tax liability
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${TaxLiabilityService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Get tax liability
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${TaxLiabilityService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Tax liabilities list
   */
  list = () => {
    return this.http.observableGet(`${TaxLiabilityService.BASE_URL_API}/list`, { mapFn: res => res.taxLiabilities });
  };
}
