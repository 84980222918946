import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class ARLService {
  public static BASE_URL_API = environment.URL.API + 'arl';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de ARL
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(ARLService.BASE_URL_API + '?' + params, httpRequestHandler);
  }

  /**
   * Agregar ARL
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(ARLService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Eliminar ARL
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ARLService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Editar ARL
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ARLService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar ARL
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${ARLService.BASE_URL_API}/${id}`, data, httpRequestHandler);
  }

  /*list(httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ARLService.BASE_URL_API}/list`, httpRequestHandler);
  }*/

  /**
   * Listado de ARLs para combo.
   */
  list = () => {
    return this.http.observableGet(`${ARLService.BASE_URL_API}/list`, { mapFn: res => res.arls });
  };
}
