import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { IPagerAddon, PagerService } from '../../../../services/util';

@Component({
  selector: 'app-pager-size',
  templateUrl: './pager-size.component.html'
})
export class PagerSizeComponent implements OnInit, IPagerAddon {
  size: number;
  value: Subject<any> = new Subject();

  constructor(private pager: PagerService) {}

  ngOnInit() {
    this.pager.sizeFilterInitiated(this);
  }

  valueReady(value) {
    this.size = value;
  }
}
