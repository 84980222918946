import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class StuExcCovidPollService {
  public static URL = environment.URL.API + 'stu-exc-covid-poll';

  constructor(private http: HttpService) {}

  /**
   * List exclusions
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${StuExcCovidPollService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * List students witout exclusions
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  massIndex(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${StuExcCovidPollService.URL}/mass?${params}`, httpRequestHandler);
  }

  /**
   * Save exclusions
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(StuExcCovidPollService.URL, data, httpRequestHandler);
  }

  /**
   * Save exclusions massively
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  massSave(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${StuExcCovidPollService.URL}/mass`, data, httpRequestHandler);
  }

  /**
   * Delete exclusions
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${StuExcCovidPollService.URL}/${id}`, httpRequestHandler);
  }
}
