import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class MaritalStatusService {
  public static BASE_URL_API = environment.URL.API + 'marital-status';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado estado civil
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(MaritalStatusService.BASE_URL_API + '?' + params, httpRequestHandler);
  }

  /**
   * Agregar estado civil
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(MaritalStatusService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Eliminar estado civil
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${MaritalStatusService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Editar estado civil
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${MaritalStatusService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar estado civil
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${MaritalStatusService.BASE_URL_API}/${id}`, data, httpRequestHandler);
  }

  /**
   * Listado de estado civil para combo.
   */
  list = () => {
    return this.http.observableGet(`${MaritalStatusService.BASE_URL_API}/list`, { mapFn: res => res.maritalStatus });
  };
}
