import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum EnrollExtHoursPlanTempStatus {
  Active = 1,
  Exception = 2,
  ExceptionPerYear = 3
}

@Injectable()
export class ExtendedHoursStudentService {
  public static BASE_URL_API = environment.URL.API + 'extended-hours-student';

  enrollExtHoursPlanTempStatus = [{}];

  extHoursPlanTempStatuses = [
    { id: EnrollExtHoursPlanTempStatus.Active, name: '', key: 'extendedHoursStudent.statusActive', class: 'border-success' },
    { id: EnrollExtHoursPlanTempStatus.Exception, name: '', key: 'extendedHoursStudent.statusException', class: 'border-black' }
  ];

  constructor(private http: HttpService, private translate: TranslateService) {
    const udpateLangValues = () => {
      this.extHoursPlanTempStatuses.forEach(item => (item.name = translate.instant(item.key)));
      this.extHoursPlanTempStatuses = [...this.extHoursPlanTempStatuses];
    };
    udpateLangValues();
    translate.onLangChange.subscribe(value => udpateLangValues());
  }

  getExtHoursPlanTempStatuses(status: EnrollExtHoursPlanTempStatus) {
    return this.extHoursPlanTempStatuses.find(sta => sta.id === status);
  }

  /**
   * Get plans data
   * @param enrollmentId number
   * @param httpRequestHandler HttpRequestHandler
   */
  getPlansData(enrollmentId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtendedHoursStudentService.BASE_URL_API}/plans-data/${enrollmentId}`, httpRequestHandler);
  }

  /**
   *
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  savePlan(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ExtendedHoursStudentService.BASE_URL_API}`, data, httpRequestHandler);
  }

  /**
   * Delete plan
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  deletePlan(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ExtendedHoursStudentService.BASE_URL_API}/plans-data/${id}`, httpRequestHandler);
  }

  /**
   * Get plans data
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  getStudentFractions(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ExtendedHoursStudentService.BASE_URL_API}/student-fractions`, data, httpRequestHandler);
  }

  /**
   * Add exception
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  addException(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ExtendedHoursStudentService.BASE_URL_API}/student-fractions-exception`, { id: id }, httpRequestHandler);
  }

  /**
   * Add exception
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  deleteException(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ExtendedHoursStudentService.BASE_URL_API}/student-fractions-exception/${id}`, httpRequestHandler);
  }

  /**
   * Add exception
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  addEventual(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ExtendedHoursStudentService.BASE_URL_API}/student-eventual-fraction`, data, httpRequestHandler);
  }

  /**
   * Add exception
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  deleteEventual(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ExtendedHoursStudentService.BASE_URL_API}/student-eventual-fraction/${id}`, httpRequestHandler);
  }
}
