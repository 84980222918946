import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class ReinforcementImprovementActivityService {
  public static URL = environment.URL.API + 'reinforcement-improvement-activity';

  constructor(private http: HttpService) {}

  /**
   * List groups
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ReinforcementImprovementActivityService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save reinforcement improvement activity
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ReinforcementImprovementActivityService.URL}`, data, httpRequestHandler);
  }
  
  /**
   * Save reinforcement improvement activity indicator
   */
  saveIndicator(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ReinforcementImprovementActivityService.URL}/indicator`, data, httpRequestHandler);
  }

  /**
   * List groups
   */
  getGroups(yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ReinforcementImprovementActivityService.URL}/${yearId}/groups`, httpRequestHandler);
  }

  /**
   * List students
   */
  getStudents(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ReinforcementImprovementActivityService.URL}/students`, data, httpRequestHandler);
  }

  /**
   * Print
   */
  print(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ReinforcementImprovementActivityService.URL}/${id}/print`, httpRequestHandler);
  }
}
