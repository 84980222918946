import { ChangeDetectorRef } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { FunctionsService } from 'app/services/util';
import { Subject } from 'rxjs';

export enum ILocalFilterType {
  Input,
  Select,
}

export interface ILocalFilter {
  name: string;
  value: Subject<any>;

  valueReady(value): void;

  type: ILocalFilterType;
}

export class LocalDt {
  /**
   * Registros base
   */
  private _rows: any[];
  public get rows(): any[] {
    return this._rows;
  }

  public set rows(v: any[]) {
    this._rows = v;
    this.filtered = v;

    this.filters.columns.forEach(f => {
      const value = this.filters.values[f.name];
      if (value) {
        f.value.next(value);
      }
    });
  }

  /**
   * Registros filtrados
   */
  private _filtered: any[];
  public get filtered(): any[] {
    return this._filtered;
  }

  public set filtered(v: any[]) {
    this._filtered = v;
    if (this.table) {
      this.table.rows = v;
      this.table.offset = 0;
      // this.cd.detectChanges();
    }
  }

  /**
   * Numero de filas a mostrar por página
   */
  private _size: number;
  public get size(): number {
    return this._size;
  }

  public set size(v: number) {
    this._size = v;
    this.table.offset = 0;
    this.table.limit = v;
    this.cd.detectChanges();
  }

  /**
   * Mostrar loader de tabla
   */
  private _loading = true;
  public get loading() {
    return this._loading;
  }

  public set loading(v: boolean) {
    this._loading = v;
    if (this.table) {
      this.table.loadingIndicator = v;
      setTimeout(() => {
        try {
          this.cd.detectChanges();
        } catch (e) {
        }
      }, 0);
    }
  }

  private cd: ChangeDetectorRef;
  private table: DatatableComponent;
  private fn: FunctionsService;
  private lastPage: number;

  private filters = {
    columns: [] as ILocalFilter[],
    values: {},
  };

  constructor() {
  }

  init(table: DatatableComponent, cd: ChangeDetectorRef, limit: number, fn: FunctionsService) {
    this.cd = cd;
    this.table = table;
    this.size = limit;
    this.loading = this._loading;
    this.fn = fn;
    this.table.page.subscribe(value => {
      this.lastPage = value.offset;
    });

    setTimeout(() => {
      if (this._filtered) {
        this.table.rows = this._filtered;
        this.table.offset = 0;
        this.cd.detectChanges();
      }

      if (this.lastPage != null) {
        this.table.offset = this.lastPage;
        this.cd.detectChanges();
      }

      this.filters.columns.forEach(f => {
        const value = this.filters.values[f.name];
        f.valueReady(value);
      });
    }, 0);
  }

  filterInitiated(filter: ILocalFilter, inColumn: boolean) {
    if (inColumn) {
      this.table.headerHeight = 80;
    }

    this.filters.columns.push(filter);
    filter.value.subscribe(value => {
      switch (filter.type) {
        case ILocalFilterType.Input:
          this.filters.values[filter.name] = this.fn.removeAccents(value);
          break;
        case ILocalFilterType.Select:
          this.filters.values[filter.name] = value;
          break;
      }
      if (value == null) {
        delete this.filters.values[filter.name];
      }

      this.filtered = this.rows.filter(item => {
        const validations = {};
        Object.keys(this.filters.values).forEach(filterName => {
          const getNestedObject = (nestedObj: any, path: string) => {
            return path.split('.').reduce((obj, key) =>
              (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
          };
          const currentFilter = this.filters.columns.find(x => x.name === filterName);
          if (!currentFilter) {
            validations[filterName] = true;
          } else if (currentFilter.type === ILocalFilterType.Input) {
            const regex = new RegExp(this.fn.removeAccents(this.filters.values[filterName]).replaceAll(' ', '.*.'));
            validations[filterName] =
              !!this.fn.removeAccents(getNestedObject(item, filterName) ? getNestedObject(item, filterName).toString() : '').match(regex) ||
              !this.filters.values[filterName];
          } else if (currentFilter.type === ILocalFilterType.Select) {
            validations[filterName] = !!(getNestedObject(item, filterName) === this.filters.values[filterName]);
          }
        });

        const validationResult = true;
        return Object.keys(validations).reduce((previous, current) => previous && validations[current], validationResult);
      });
    });
  }
}
