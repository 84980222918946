import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

import { HttpRequestHandler, HttpService } from '../util';

@Injectable()
export class TutoringCartService {
  public static URL = environment.URL.API + 'tutoring-cart';

  constructor(private http: HttpService) {}

  /**
   * Listado de tutorias disponibles
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(TutoringCartService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Datos de tutoria
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(TutoringCartService.URL + `/${id}`, httpRequestHandler);
  }

  /**
   * Agregar item al carrito de compras
   */
  add(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(TutoringCartService.URL + '/add', data, httpRequestHandler);
  }

  /**
   * Listado de items en carrito de compras
   */
  cart(httpRequestHandler: HttpRequestHandler) {
    this.http.get(TutoringCartService.URL + '/cart', httpRequestHandler);
  }

  /**
   * Cmabiar cantidad a item de carrito de compras
   */
  changeQty(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(TutoringCartService.URL + '/change-qty', data, httpRequestHandler);
  }

  /**
   * Eliminar item de carrito de compras
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(TutoringCartService.URL + `/${id}`, httpRequestHandler);
  }

  /**
   * Listado paginador de tutorias compradas por usuario
   */
  orders(params: string, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(TutoringCartService.URL + '/orders?' + params, httpRequestHandler);
  }

  /**
   * Checkear items del carrito de compras
   */
  checkOut(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(TutoringCartService.URL + '/check-out', data, httpRequestHandler);
  }

  /**
   * Generar sesión de pago en place to pay para el carrito
   */
  generatePaymentSession(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(TutoringCartService.URL + '/generate-payment-session', data, httpRequestHandler);
  }
}
