import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class EmployeeCommitteeService {
  public static URL = environment.URL.API2 + 'employee-committee';

  constructor(private http: HttpService) {
  }

  /**
   * Index employee committee
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${EmployeeCommitteeService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get employee committee
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${EmployeeCommitteeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save employee committee
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(EmployeeCommitteeService.URL, data, httpRequestHandler);
  }

  /**
   * Delete employee committee
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${EmployeeCommitteeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List employee committee for select component
   */
  list = () => {
    return this.http.observableGet(`${EmployeeCommitteeService.URL}/list`, { mapFn: res => res.employeeCommittees });
  };
}
