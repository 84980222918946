import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum PaymentAgreementStatus {
  InProgress,
  Finished
}

export enum PaymentType {
  MonthlyBilling = 1,
  Cheque = 2
}

export enum PaymentAgreementScheduleType {
  Enrollment = 1,
  Debt = 2
}

export enum PaymentAgreementReportType {
  PaymentAgreementsPerYear = 1
}

@Injectable()
export class PaymentAgreementService {
  public static URL = environment.URL.API + 'payment-agreement';

  agreementStatuses = [
    { id: PaymentAgreementStatus.InProgress, name: '', key: 'paymentAgreement.inProgress', class: 'label label-primary label-sm' },
    { id: PaymentAgreementStatus.Finished, name: '', key: 'paymentAgreement.finished', class: 'label label-success label-sm' }
  ];

  paymentTypes = [
    { id: PaymentType.MonthlyBilling, name: '', key: 'paymentTypes.monthlyBilling' },
    { id: PaymentType.Cheque, name: '', key: 'paymentTypes.cheque' }
  ];

  reportTypes = [{ id: PaymentAgreementReportType.PaymentAgreementsPerYear, name: '', key: 'paymentAgreement.perYear' }];

  /**
   * Empty constructor
   * @param http HttpService
   */
  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    const lists = {
      agreementStatuses: this.agreementStatuses,
      paymentTypes: this.paymentTypes,
      reportTypes: this.reportTypes
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * Get status
   * @param id number
   */
  getStatus(id: number) {
    return this.agreementStatuses.find(status => status.id === id);
  }

  /**
   * Get payment type
   * @param id number
   */
  getPaymenType(id: number) {
    return this.paymentTypes.find(type => type.id === id);
  }

  /**
   * List payment agreements
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(PaymentAgreementService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Create payment agreement
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  create(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PaymentAgreementService.URL}/create`, data, httpRequestHandler);
  }

  /**
   * Save payment agreement
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  saveStudentAgreement(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PaymentAgreementService.URL}/student-agreement-save`, data, httpRequestHandler);
  }

  /**
   * Update unregistered deposit
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  updateUnregisteredDeposit(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PaymentAgreementService.URL}/update-unregistered-deposit`, data, httpRequestHandler);
  }

  /**
   * Delete payment agreement
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${PaymentAgreementService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Delete student payment agreement
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  deleteStudentAgreement(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${PaymentAgreementService.URL}/${id}/student-agreement`, httpRequestHandler);
  }

  /**
   * Get payment agreement
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PaymentAgreementService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get payment agreement
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  getStudentAgreement(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PaymentAgreementService.URL}/student-agreement`, data, httpRequestHandler);
  }

  /**
   * Finish payment agreement
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  finish(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PaymentAgreementService.URL}/${id}/finish`, httpRequestHandler);
  }

  /**
   * List payment agreements
   * @param httpRequestHandler HttpRequestHandler
   */
  list = () => {
    return this.http.observableGet(`${PaymentAgreementService.URL}/list`, { mapFn: res => res.agreements });
  };

  /**
   * Detalle de convenio de pago de estudiante
   */
  getPaymentAgreementStudentDetail(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PaymentAgreementService.URL}/student/${id}`, httpRequestHandler);
  }

  /**
   * Open payment agreement
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  open(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PaymentAgreementService.URL}/${id}/open`, httpRequestHandler);
  }

  /**
   * Export payment agreements
   * @param yearId number
   * @param httpRequestHandler HttpRequestHandler
   */
  export(yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PaymentAgreementService.URL}/${yearId}/export`, httpRequestHandler);
  }

  /**
   * Generar reporte
   */
  generateReport(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PaymentAgreementService.URL}/reports`, data, httpRequestHandler);
  }
}
