import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-label-person',
  templateUrl: './label-person.component.html',
  styleUrls: ['./label-person.component.scss']
})
export class LabelPersonComponent implements OnInit {
  @Input() data: any;
  @Input() firstColClass = 'col-md-3';
  @Input() lastColClass = 'col-md-9 pl-0';

  constructor() {}

  ngOnInit() {}
}
