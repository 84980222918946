
import {filter} from 'rxjs/operators';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { SessionService } from '../../../../services/util';

@Component({
  selector: 'app-title',
  template: ''
})
export class TitleComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private translate: TranslateService,
    private sessionService: SessionService
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      let currentRoute = this.route.root;
      let title = '';
      do {
        const childrenRoutes = currentRoute.children;
        currentRoute = null;
        childrenRoutes.forEach(r => {
          if (r.outlet === 'primary') {
            title = r.snapshot.data.title ? r.snapshot.data.title : r.snapshot.data.breadcrumb;
            currentRoute = r;
          }
        });
      } while (currentRoute);

      const newTitle = title ? translate.instant(title) : '';
      const counterTitle = sessionService.unseenCounter ? '(' + sessionService.unseenCounter + ') ' : '';

      this.titleService.setTitle(counterTitle + newTitle + ' • Bennettsoft 2.0');
    });
  }
}
