import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class SchoolDiaryTrackingService {
  public static URL = environment.URL.API2 + 'school-diary-tracking';

  constructor(private http: HttpService) {}

  /**
   * List of school diaries for tracking
   * @param params
   * @param httpRequestHandler
   */
  indexTracking(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(SchoolDiaryTrackingService.URL + '?' + params, httpRequestHandler);
  }
}
