import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-submit-button',
  templateUrl: './submit-button.component.html'
})
export class SubmitButtonComponent implements OnInit {
  @Input() loading: boolean;
  @Input() text = 'general.save';
  @Input() type = 'submit';
  @Input() color = 'primary';
  @Input() btnClass: string;
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}
}
