import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum TaskExecutionStatus {
  Completed = 1,
  CompletedWarning = 2,
  Failed = 3,
  NotExecuted = 4,
}

@Injectable()
export class TaskScheduleService {
  public static URL = environment.URL.API2 + 'task-schedule';

  taskExecutionStatuses = [
    { id: TaskExecutionStatus.Completed, key: 'adminDashboard.executionStatus.completed', name: '' },
    { id: TaskExecutionStatus.CompletedWarning, key: 'adminDashboard.executionStatus.completedWarning', name: '' },
    { id: TaskExecutionStatus.Failed, key: 'adminDashboard.executionStatus.failed', name: '' },
    { id: TaskExecutionStatus.NotExecuted, key: 'adminDashboard.executionStatus.notExecuted', name: '' },
  ];

  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    const lists = { taskExecutionStatuses: this.taskExecutionStatuses };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  getExecutionStatus(status: TaskExecutionStatus) {
    return this.taskExecutionStatuses.find(tes => tes.id === status);
  }

  /**
   * List task schedules
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(TaskScheduleService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Get task schedule
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${TaskScheduleService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save task schedule
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(TaskScheduleService.URL, data, httpRequestHandler);
  }

  /**
   * Delete task schedule
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${TaskScheduleService.URL}/${id}`, httpRequestHandler);
  }
}
