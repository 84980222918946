import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum VoucherItemType {
  Payment = 1,
  Deduction = 2,
}

export interface IVoucherItem {
  voucherId: number;
  value: number;
  type: VoucherItemType;
  name: string;
  code: string;
}

@Injectable()
export class VoucherUploadService {
  public static URL = environment.URL.API2 + 'voucher-upload';

  voucherError = [
    { id: true, name: '', key: 'voucherUpload.voucherError.yes' },
    { id: false, name: '', key: 'voucherUpload.voucherError.no' },
  ];

  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    const lists = {
      voucherError: this.voucherError,
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * Index voucher uploads
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${VoucherUploadService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get voucher upload details
   */
  get(id: number, params: string, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${VoucherUploadService.URL}/${id}?${params}`, httpRequestHandler);
  }

  /**
   * Get voucher upload details
   */
  notify(id: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${VoucherUploadService.URL}/notify/${id}`, httpRequestHandler);
  }

  /**
   * upload vouchers file
   */
  checkFile(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${VoucherUploadService.URL}/check-file`, data, httpRequestHandler);
  }

  /**
   * Save vouchers file
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${VoucherUploadService.URL}`, data, httpRequestHandler);
  }

  /**
   * Delete vouchers file
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${VoucherUploadService.URL}/${id}`, httpRequestHandler);
  }
}
