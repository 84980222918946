import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class ExpenseProjectService {
  public static URL = environment.URL.API2 + 'expense-project';

  constructor(private http: HttpService) {}

  /**
   * Index Expense Project
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExpenseProjectService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Delete Expense Project
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ExpenseProjectService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get Expense Project
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExpenseProjectService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Expense Project
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(ExpenseProjectService.URL, data, httpRequestHandler);
  }

  list = () => {
    return this.http.observableGet(ExpenseProjectService.URL + '/list/', { mapFn: res => res.expenseProject });
  };
}
