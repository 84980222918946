<ng-template [ngIf]="pager" [ngIfElse]="noPagerTemplate">
  <ng-template [ngIf]="link" [ngIfElse]="noLinkTemplate">
    <button
      type="button"
      class="btn {{ color }} btn-mini btn-actions"
      [ngbTooltip]="tooltip | translate"
      *ngxPermissionsOnly="permission"
      [disabled]="disabled"
      [routerLink]="link"
      [queryParams]="pager.queryParams"
    >
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </button>
  </ng-template>
  <ng-template #noLinkTemplate>
    <button
      type="button"
      class="btn {{ color }} btn-mini btn-actions"
      [ngbTooltip]="tooltip | translate"
      *ngxPermissionsOnly="permission"
      [disabled]="disabled"
      (click)="onClick.emit()"
    >
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </button>
  </ng-template>
</ng-template>
<ng-template #noPagerTemplate>
  <button
    type="button"
    class="btn {{ color }} btn-mini btn-actions"
    [ngbTooltip]="tooltip | translate"
    *ngxPermissionsOnly="permission"
    [disabled]="disabled"
    (click)="onClick.emit()"
  >
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </button>
</ng-template>

<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>
