import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class PayrollProvisionTypeService {
  public static URL = environment.URL.API2 + 'payroll-provision-type';

  constructor(private http: HttpService) {}

  /**
   * Index Payroll provision type
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PayrollProvisionTypeService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Delete payroll provision type
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${PayrollProvisionTypeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get payroll provision type
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PayrollProvisionTypeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save payroll provision type
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(PayrollProvisionTypeService.URL, data, httpRequestHandler);
  }

  list = () => {
    return this.http.observableGet(`${PayrollProvisionTypeService.URL}/list`, { mapFn: res => res.payrollProvisionType });
  };
}
