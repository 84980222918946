import { Injectable } from '@angular/core';
import { SelectLookupFn } from 'app/modules/shared/select';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class FamilyService {
  public static URL = environment.URL.API + 'family';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado de familias
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(FamilyService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Listado de las familias asociadas al usuario actual
   */
  myFamilies(httpRequestHandler: HttpRequestHandler) {
    this.http.get(FamilyService.URL + '/my-families', httpRequestHandler);
  }

  /**
   * Listado de las familias asociadas al usuario actual para actualizacion de datos de emergencia
   */
  myFamiliesWs(httpRequestHandler: HttpRequestHandler) {
    this.http.get(FamilyService.URL + '/my-families-ws', httpRequestHandler);
  }

  /**
   * Listado de familias admitidas
   */
  admitted(httpRequestHandler: HttpRequestHandler) {
    this.http.get(FamilyService.URL + '/admitted', httpRequestHandler);
  }

  /**
   * Datos de familia admitida
   *  @param id Id de admission
   */
  admittedFamily(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(FamilyService.URL + '/admitted/' + id, httpRequestHandler);
  }

  /**
   * Guardar familia
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(FamilyService.URL + '/save', data, httpRequestHandler);
  }

  /**
   * Guardar datos de emergencia
   */
  saveWs(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(FamilyService.URL + '/save-ws', data, httpRequestHandler);
  }

  /**
   * Guardar foto de acudiente
   * @param id Id de acudiente
   */
  saveAttendantPhoto(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${FamilyService.URL}/save/photo/${id}/attendant`, data, httpRequestHandler);
  }

  /**
   * Obtener familia
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${FamilyService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Listado de personas de admisión que no han sido creados de una familia
   * @param id Id de familia
   */
  admissionPersons(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${FamilyService.URL}/admission-persons/${id}`, httpRequestHandler);
  }

  /**
   * Buscar acudiente por tipo de documento y documento
   */
  checkAttendant(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${FamilyService.URL}/check-attendant`, data, httpRequestHandler);
  }

  /**
   * Select autocomplete familias
   */
  list: SelectLookupFn = data => {
    return this.http.observablePost(FamilyService.URL + '/list', data, { mapFn: res => res.data });
  };

  /**
   * Acudientes de familia [vivos]
   * @param id Id de familia
   */
  attendants = data => {
    return this.http.observableGet(`${FamilyService.URL}/${data.id}/attendants`, { mapFn: res => res.attendants });
  };

  /**
   * Datos básicos de familia de estudiante
   */
  info(studentId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${FamilyService.URL}/info/${studentId}`, httpRequestHandler);
  }
}
