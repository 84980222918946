import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class AnalisysByGroupService {
  public static URL = environment.URL.API + 'analisys-by-group';

  constructor(private http: HttpService) {}

  /**
   * Analisis por grupo
   */
  get(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(AnalisysByGroupService.URL, data, httpRequestHandler);
  }

  /**
   * Exportar Analisis por grupo
   */
  export(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AnalisysByGroupService.URL}/export`, data, httpRequestHandler);
  }
}
