import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../../environments/environment';
import { FunctionsService, HttpRequestHandler, HttpService } from '../../util';

export enum MailAddonType {
  Header = 1,
  Footer = 2,
}

@Injectable()
export class MailAddonService {
  public static BASE_URL_API = environment.URL.API + 'mail-addon';

  public types = [
    { id: MailAddonType.Header, name: '', key: 'mailAddon.header' },
    { id: MailAddonType.Footer, name: '', key: 'mailAddon.footer' },
  ];

  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    const lists = {
      types: this.types,
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * Mostrar listado Mail Addon
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(MailAddonService.BASE_URL_API + '?' + params, httpRequestHandler);
  }

  /**
   * Agregar Mail Addon
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(MailAddonService.BASE_URL_API, data, httpRequestHandler);
  }

  /**
   * Eliminar Mail Addon
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${MailAddonService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Editar Mail Addon
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${MailAddonService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar Mail Addon
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${MailAddonService.BASE_URL_API}/${id}`, data, httpRequestHandler);
  }

  /**
   * Listado para Combo de Mail Addon
   * @param data.type: 0 = todos, 1 = Header, 2 = Footer
   */
  list = data => {
    return this.http.observableGet(`${MailAddonService.BASE_URL_API}/list/${data.type}`, { mapFn: res => res.mailAddons });
  };
}
