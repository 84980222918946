import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CustomValidators } from 'app/modules/shared/form';
import { SelectLookupFn } from 'app/modules/shared/select';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

export enum StudentStatus {
  Pre = 1,
  Graduate = 2,
  Withdrawn = 3,
  Enrolled = 4,
}

@Injectable()
export class StudentService {
  public static URL = environment.URL.API + 'student';

  statuses = [
    { id: StudentStatus.Pre, name: '', key: 'student.pre', class: 'label label-info' },
    { id: StudentStatus.Graduate, name: '', key: 'student.graduate', class: 'label label-success' },
    { id: StudentStatus.Withdrawn, name: '', key: 'student.withdrawn', class: 'label label-danger' },
    { id: StudentStatus.Enrolled, name: '', key: 'student.enrolled', class: 'label label-primary' },
  ];

  constructor(private http: HttpService, translate: TranslateService, private fb: FormBuilder) {
    const udpateLangValues = () => {
      this.statuses.forEach(item => (item.name = translate.instant(item.key)));
    };
    udpateLangValues();
    translate.onLangChange.subscribe(value => udpateLangValues());
  }

  getStatus(status: StudentStatus) {
    return this.statuses.find(sta => sta.id === status);
  }

  /**
   * Crea un `FormGroup` con los campos de estudiante
   * @param data datos para asignar al formulario
   */
  createForm(data?: any): FormGroup {
    const form = this.fb.group({
      id: [null],
      admissionStudentId: [null],
      photo: [null],
      docTypeId: [null, [Validators.required]],
      document: [null, [Validators.required, Validators.maxLength(30)]],
      firstName: [null, [Validators.required, Validators.maxLength(30)]],
      middleName: [null, [Validators.maxLength(30)]],
      firstSurname: [null, [Validators.required, Validators.maxLength(30)]],
      secondSurname: [null, [Validators.maxLength(30)]],
      birthDate: [null, [Validators.required]],
      genderId: [null, [Validators.required]],
      gradeId: [{ value: null, disabled: true }],
      schoolEmail: [{ value: null, disabled: true }],
      homeAddress: [null, [Validators.required, Validators.maxLength(120)]],
      neighborhoodId: [null, [Validators.required]],
      personEmailId: [null],
      email: [null, [CustomValidators.email, CustomValidators.notBennettEmail, Validators.maxLength(100)]],
      bloodTypeId: [null],
      epsId: [null, []],
      prepaidMedicineId: [null, []],
      medicalBackground: [null, [Validators.maxLength(1000)]],
      surgicalBackground: [null, [Validators.maxLength(1000)]],
      familyBackground: [null, [Validators.maxLength(1000)]],
      allergies: [null, [Validators.maxLength(1000)]],
      controlMedications: [null, [Validators.maxLength(1000)]],
      otherImportantData: [null, [Validators.maxLength(1000)]],
      birthCityId: [null],
      mobileNumber: [null, [Validators.maxLength(20)]],
      vaccineScheme: [null],
      disability: [null],
      liveWithElderly: [null],
      liveWithHealthCare: [null],
      liveWith: [null],
      disabilities: [null],
      healthRegimen: [null],
      transport: [null],
      travelTime: [null],
      covid19Risks: [null],
      covid19FamilyRisks: [null],
      schools: this.fb.array([]),
    });

    if (data) {
      form.patchValue(data);
      data.schools.forEach(school => this.addSchool(form, school));
      form.data = data;
    }

    return form;
  }

  addSchool(studentForm: FormGroup, school?: any) {
    const form = this.fb.group({
      id: [null],
      name: [null, [Validators.required, Validators.maxLength(100)]],
      countryId: [null, [Validators.required]],
      grades: [null, [Validators.required, Validators.maxLength(50)]],
      years: [null, [CustomValidators.integer, Validators.min(1), Validators.max(99)]],
    });
    if (school) form.patchValue(school);
    (<FormArray>studentForm.controls.schools).push(form);
  }

  /**
   * Mostrar listado de estudiantes
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(StudentService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Datos de estudiante
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${StudentService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Guardar datos de estudiante
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${StudentService.URL}/save`, data, httpRequestHandler);
  }

  /**
   * Select autocomplete
   */
  list: SelectLookupFn = data => {
    return this.http.observablePost(StudentService.URL + '/list', data, { mapFn: res => res.data });
  };

  /**
   * Select autocomplete enrolled students
   */
  enrolledList: SelectLookupFn = data => {
    return this.http.observablePost(StudentService.URL + '/enrolled-list', data, { mapFn: res => res.data });
  };
}
