import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';

import { FunctionsService, HttpRequestHandler, HttpService } from '../../util';
import { StatusClass } from '../sports/sport-borrowing.service';

export enum LibraryBorrowingStatus {
  NotReturned = 1,
  Returned = 2,
}

export enum LibraryBorrowingFilterBy {
  Students = 1,
  Teachers = 2,
  Employees = 3,
  Others = 4,
}

export enum LibraryBorrowingReportType {
  BorrowingList = 1,
}

@Injectable()
export class LibraryBorrowingService {
  public static URL = environment.URL.API + 'library-borrowing';

  statuses = [
    { id: LibraryBorrowingStatus.Returned, name: '', key: 'sportBorrowing.returned', class: StatusClass.Returned },
    { id: LibraryBorrowingStatus.NotReturned, name: '', key: 'sportBorrowing.notReturned', class: StatusClass.NotReturned },
  ];

  reportTypes = [{ id: LibraryBorrowingReportType.BorrowingList, name: '', key: 'sportBorrowing.reports.borrowingList' }];

  filterBy = [
    { id: LibraryBorrowingFilterBy.Students, name: '', key: 'student.title' },
    { id: LibraryBorrowingFilterBy.Teachers, name: '', key: 'general.teachers' },
    { id: LibraryBorrowingFilterBy.Employees, name: '', key: 'employee.title' },
    { id: LibraryBorrowingFilterBy.Others, name: '', key: 'general.others' },
  ];

  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    const lists = {
      statuses: this.statuses,
      filterBy: this.filterBy,
      reportTypes: this.reportTypes,
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  getStatus(status: LibraryBorrowingStatus) {
    return this.statuses.find(sta => sta.id === status);
  }

  /**
   * List library borrowings
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(LibraryBorrowingService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Get library borrowing
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${LibraryBorrowingService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get reservation details
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  getReservationDetails(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${LibraryBorrowingService.URL}/reservation/${id}`, httpRequestHandler);
  }

  /**
   * Delete reservation
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  freeReservation(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${LibraryBorrowingService.URL}/free-reservation/${id}`, httpRequestHandler);
  }

  /**
   * Save library borrowing
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(LibraryBorrowingService.URL, data, httpRequestHandler);
  }

  /**
   * Save library borrowing
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  addReservation(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${LibraryBorrowingService.URL}/reservation`, data, httpRequestHandler);
  }

  /**
   * Retiurn library borrowing
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  return(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${LibraryBorrowingService.URL}/return`, { id: id }, httpRequestHandler);
  }

  /**
   * Generate report
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  generateReport(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${LibraryBorrowingService.URL}/reports`, data, httpRequestHandler);
  }

  /**
   * Get return date
   * @param httpRequestHandler HttpRequestHandler
   */
  getReturnDate(httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${LibraryBorrowingService.URL}/return-date`, httpRequestHandler);
  }

  /**
   * Save return date
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  saveReturnDate(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${LibraryBorrowingService.URL}/return-date`, data, httpRequestHandler);
  }
}
