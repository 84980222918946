import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const httpRequest = req.clone();
    httpRequest.headers.append('Cache-Control', 'no-cache');
    httpRequest.headers.append('Pragma', 'no-cache');
    httpRequest.headers.append('Expires', moment().toString());
    return next.handle(httpRequest);
  }
}
