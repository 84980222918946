import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import swal, { SweetAlertType } from 'sweetalert2';

import { ITranslateString } from '.';

export interface ISwalConfirmationButtons {
  ok?: {
    text?: string;
    handler?: () => void;
    class?: string;
  };
  cancel?: {
    text?: string;
    handler?: (reason: string) => void;
    show?: boolean;
  };
}

const defaults = {
  confirmButtonText: 'general.ok',
  cancelButtonText: 'general.cancel',
  confirmButtonClass: 'bg-danger',
  cancelButtonClass: 'bg-default',
};

@Injectable()
export class SwalMessageService {
  constructor(private translate: TranslateService) {}

  confirmationAjax(title: string | ITranslateString, msg: string | ITranslateString, buttons: ISwalConfirmationButtons) {
    const toTranslate = [defaults.confirmButtonText, defaults.cancelButtonText];

    if (buttons.ok && buttons.ok.text) toTranslate.push(buttons.ok.text);
    if (buttons.cancel && buttons.cancel.text) toTranslate.push(buttons.cancel.text);

    this.translate.get(toTranslate).subscribe(trans => {
      swal({
        title: typeof title === 'string' ? this.translate.instant(title) : this.translate.instant(title.key, title.params),
        text: typeof msg === 'string' ? this.translate.instant(msg) : this.translate.instant(msg.key, msg.params),
        type: 'question',
        showCancelButton: buttons.cancel && buttons.cancel.show === false ? false : true,
        cancelButtonClass: defaults.cancelButtonClass,
        confirmButtonClass: buttons.ok && buttons.ok.class ? buttons.ok.class : defaults.confirmButtonClass,
        confirmButtonText: buttons.ok && buttons.ok.text ? trans[buttons.ok.text] : trans[defaults.confirmButtonText],
        cancelButtonText: buttons.cancel && buttons.cancel.text ? trans[buttons.cancel.text] : trans[defaults.cancelButtonText],
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          return new Promise(function (resolve, reject) {
            if (buttons.ok && buttons.ok.handler) buttons.ok.handler();
          });
        },
      }).then(
        ok => {},
        reason => {
          if (buttons.cancel && buttons.cancel.handler) buttons.cancel.handler(reason);
        }
      );
    });
  }

  confirmation(title: string, msg: string | ITranslateString, buttons: ISwalConfirmationButtons) {
    const toTranslate = [defaults.confirmButtonText, defaults.cancelButtonText, title];

    if (buttons.ok && buttons.ok.text) toTranslate.push(buttons.ok.text);
    if (buttons.cancel && buttons.cancel.text) toTranslate.push(buttons.cancel.text);

    this.translate.get(toTranslate).subscribe(trans => {
      swal({
        title: trans[title],
        text: typeof msg === 'string' ? this.translate.instant(msg) : this.translate.instant(msg.key, msg.params),
        type: 'question',
        showCancelButton: buttons.cancel && buttons.cancel.show === false ? false : true,
        cancelButtonClass: defaults.cancelButtonClass,
        confirmButtonClass: buttons.ok && buttons.ok.class ? buttons.ok.class : defaults.confirmButtonClass,
        confirmButtonText: buttons.ok && buttons.ok.text ? trans[buttons.ok.text] : trans[defaults.confirmButtonText],
        cancelButtonText: buttons.cancel && buttons.cancel.text ? trans[buttons.cancel.text] : trans[defaults.cancelButtonText],
      }).then(
        ok => {
          if (buttons.ok && buttons.ok.handler) buttons.ok.handler();
        },
        reason => {
          if (buttons.cancel && buttons.cancel.handler) buttons.cancel.handler(reason);
        }
      );
    });
  }

  success(title: string, msg: string | ITranslateString, okHandler?: () => void) {
    this.openSwal(title, msg, 'success', okHandler);
  }

  warning(title: string, msg: string | ITranslateString, okHandler?: () => void) {
    this.openSwal(title, msg, 'warning', okHandler);
  }

  error(title: string, msg: string | ITranslateString, okHandler?: () => void) {
    this.openSwal(title, msg, 'error', okHandler);
  }

  info(title: string, msg: string | ITranslateString, okHandler?: () => void) {
    this.openSwal(title, msg, 'info', okHandler);
  }

  private openSwal(title: string, msg: string | ITranslateString, type: SweetAlertType, okHandler?: () => void) {
    const trans = {
      title: this.translate.instant(title),
      msg: typeof msg === 'string' ? this.translate.instant(msg) : this.translate.instant(msg.key, msg.params),
    };

    swal(trans.title, trans.msg, type)
      .then(() => {
        if (okHandler) okHandler();
      })
      .catch(() => {
        if (okHandler) okHandler();
      });
  }

  plainError(title: string, msg: string, okHandler?: () => void) {
    swal(title, msg, 'error')
      .then(() => {
        if (okHandler) okHandler();
      })
      .catch(() => {
        if (okHandler) okHandler();
      });
  }

  close() {
    swal.close();
  }
}
