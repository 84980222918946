import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class PrepaidMedicineService {
  public static URL = environment.URL.API + 'prepaid-medicine';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado medicina prepagada
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(PrepaidMedicineService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Agregar medicina prepagada
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(PrepaidMedicineService.URL, data, httpRequestHandler);
  }

  /**
   * Eliminar medicina prepagada
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${PrepaidMedicineService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Editar medicina prepagada
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PrepaidMedicineService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar medicina prepagada
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${PrepaidMedicineService.URL}/${id}`, data, httpRequestHandler);
  }

  /**
   * Listado de Grados para combo.
   */
  list = () => {
    return this.http.observableGet(`${PrepaidMedicineService.URL}/list`, { mapFn: res => res.prepaidMedicines });
  };
}
