import { Pipe, PipeTransform } from '@angular/core';

import { FunctionsService } from '../services/util';

@Pipe({ name: 'ageFromDate' })
export class AgeFromDatePipe implements PipeTransform {
  constructor(private fn: FunctionsService) {}

  /**
   * @param value fecha en formato ISO
   */
  transform(value: string): number {
    return value ? this.fn.calculateAge(value) : null;
  }
}
