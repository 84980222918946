import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

export enum UpdateAdditionType {
  Value = 1,
  Reason = 2,
}
@Injectable({
  providedIn: 'root',
})
export class BudgetService {
  public static URL = environment.URL.API2 + 'budget';

  constructor(private http: HttpService) {}

  /**
   * Presupuestos según año lectivo
   * @param yearId Id de año lectivo
   */
  getCostsCentersBudget(yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${BudgetService.URL}/costs-centers-budget/${yearId}`, httpRequestHandler);
  }

  /**
   * Actualizar valor de presupuesto para el centro de costo
   */
  setCostCenterBudgetValue(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${BudgetService.URL}/costs-centers-budget/update-value`, data, httpRequestHandler);
  }

  /**
   * Cierra el presupuesto
   */
  closeBudget(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${BudgetService.URL}/costs-centers-budget/close`, data, httpRequestHandler);
  }

  /**
   * Adiciones para presupuesto de un centro de costo
   * @param yearId Id de año lectivo
   */
  getCostCenterBudgetAdditions(costCenterId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${BudgetService.URL}/costs-centers-budget-additions/${costCenterId}`, httpRequestHandler);
  }

  /**
   * Save addition
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  saveAddition(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${BudgetService.URL}/costs-centers-budget/addition`, data, httpRequestHandler);
  }

  /**
   * Delete addition
   */
  deleteAddition(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${BudgetService.URL}/costs-centers-budget/delete-addition/${id}`, httpRequestHandler);
  }

  /**
   * Actualiza valor o razon de adición
   */
  updateAddition(data: any, httpRequestHandler: HttpRequestHandler, type: UpdateAdditionType) {
    if (type == UpdateAdditionType.Value) {
      this.http.put(`${BudgetService.URL}/costs-centers-budget/update-addition-value`, data, httpRequestHandler);
    } else if (type == UpdateAdditionType.Reason) {
      this.http.put(`${BudgetService.URL}/costs-centers-budget/update-addition-reason`, data, httpRequestHandler);
    }
  }
}
