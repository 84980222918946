import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class AcademicQualityIndicatorService {
  public static URL = environment.URL.API + 'academic-quality-indicator';

  constructor(private http: HttpService) {}

  /**
   * Indicador de calidad
   */
  get(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(AcademicQualityIndicatorService.URL, data, httpRequestHandler);
  }

  /**
   * Exportar Indicador de calidad
   */
  export(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AcademicQualityIndicatorService.URL}/export`, data, httpRequestHandler);
  }
}
