<div class="row">
  <div class="col-md-{{ leftCols }}">
    <div class="row ml-0 v-tab-header-container shadow-sm">
      <div
        *ngFor="let tab of tabs"
        class="px-2 py-1 hover-bg text-body w-100 v-tab-header {{ tab.headerClass }}"
        (click)="select(tab.id)"
        [ngClass]="{ selected: tab.id === activeId }"
      >
        <ng-template [ngTemplateOutlet]="tab.headerTpl?.templateRef"></ng-template>
      </div>
    </div>
    <div class="row ml-0">
      <ng-content select="v-custom-content"></ng-content>
    </div>
  </div>
  <div class="col-md-{{ 12 - leftCols }}">
    <ng-template ngFor let-tab [ngForOf]="tabs">
      <div *ngIf="tab.id === activeId"><ng-template [ngTemplateOutlet]="tab.contentTpl?.templateRef"></ng-template></div>
    </ng-template>
  </div>
</div>
