import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';


import { ModalComponent } from './modal.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [CommonModule, TranslateModule, NgbModalModule],
  declarations: [ModalComponent],
  exports: [ModalComponent]
})
export class ModalModule {
}
