import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class VaccineService {
  public static URL = environment.URL.API2 + 'vaccine';

  constructor(private http: HttpService) {}

  /**
   * List vaccines
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${VaccineService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get vaccine
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${VaccineService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save vaccine
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(VaccineService.URL, data, httpRequestHandler);
  }

  /**
   * Delete vaccine
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${VaccineService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List vaccines
   * @param httpRequestHandler HttpRequestHandler
   */
  list = () => {
    return this.http.observableGet(`${VaccineService.URL}/list`, { mapFn: res => res.vaccines });
  };
}
