import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../../environments/environment';
import { FunctionsService, HttpRequestHandler, HttpService } from '../../util';

export enum EnrollmentDurationType {
  Increment = 1,
  Discount = 2,
}

@Injectable()
export class EnrollmentDurationService {
  public static URL = environment.URL.API + 'enrollment-duration';

  enrollmentDurationType = [
    { id: EnrollmentDurationType.Increment, key: 'enrollmentDuration.type.increment', name: '' },
    { id: EnrollmentDurationType.Discount, key: 'enrollmentDuration.type.discount', name: '' },
  ];

  constructor(private http: HttpService, private fn: FunctionsService, translate: TranslateService) {
    const lists = {
      enrollmentDurationType: this.enrollmentDurationType,
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * Mostrar listado de Dias de estudio
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(EnrollmentDurationService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Guardar Dias de estudio
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(EnrollmentDurationService.URL, data, httpRequestHandler);
  }

  /**
   * Eliminar Dias de estudio
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${EnrollmentDurationService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Editar Dias de estudio
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${EnrollmentDurationService.URL}/${id}`, httpRequestHandler);
  }
}
