import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../../environments/environment';
import { FunctionsService, HttpRequestHandler, HttpService } from '../../util';

export enum PeriodSectionType {
  Normal = 1,
  Grading = 2,
  PeriodReport = 3,
  MiddleReport = 4,
  PerformanceReport = 5,
  AdditionalReport = 6,
  Assessment = 7,
  Attendance = 8,
}

@Injectable()
export class PeriodService {
  public static URL = environment.URL.API + 'period';

  sectionTypes = [
    { id: PeriodSectionType.Normal, key: 'period.sectionType.teacherReport', name: '' },
    { id: PeriodSectionType.Grading, key: 'period.sectionType.gradings', name: '' },
    { id: PeriodSectionType.PeriodReport, key: 'period.sectionType.periodReport', name: '' },
    { id: PeriodSectionType.MiddleReport, key: 'period.sectionType.middleReport', name: '' },
    { id: PeriodSectionType.PerformanceReport, key: 'period.sectionType.performanceReport', name: '' },
    { id: PeriodSectionType.AdditionalReport, key: 'period.sectionType.additionalReport', name: '' },
    { id: PeriodSectionType.Assessment, key: 'period.sectionType.assessment', name: '' },
    { id: PeriodSectionType.Attendance, key: 'period.sectionType.attendance', name: '' },
  ];

  constructor(private http: HttpService, fn: FunctionsService, translate: TranslateService) {
    const lists = { sectionTypes: this.sectionTypes };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * Mostrar listado de Periodos
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(PeriodService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Eliminar Periodo
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${PeriodService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Editar Periodo
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PeriodService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar Periodo
   */
  update(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${PeriodService.URL}/${id}`, data, httpRequestHandler);
  }

  /**
   * Guardar Periodo
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PeriodService.URL}`, data, httpRequestHandler);
  }

  /**
   * Editar Secciones del Periodo
   */
  getSections(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PeriodService.URL}/${id}/sections`, httpRequestHandler);
  }

  /**
   * Guardar Secciones del Período
   */
  saveSections(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PeriodService.URL}/${id}/sections`, data, httpRequestHandler);
  }

  /**
   * Listado para combo de periodos
   */
  list = () => {
    return this.http.observableGet(`${PeriodService.URL}/list`, { mapFn: res => res.periods });
  };

  /**
   * Listado para combo de periodos por año lectivo
   */
  fromYear = data => {
    return this.http.observableGet(`${PeriodService.URL}/list/${data.yearId}`, { mapFn: res => res.periods });
  };

  /**
   * Datos para opciones de año lectivo
   */
  yearOptions(yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PeriodService.URL}/options/${yearId}`, httpRequestHandler);
  }

  /**
   * Guardar datos para opciones de año lectivo
   */
  saveYearOptions(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PeriodService.URL}/options`, data, httpRequestHandler);
  }
}
