import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DatatableComponent, SortPropDir } from '@swimlane/ngx-datatable';

import { PagerService } from '../../../services/util';

@Directive({
  selector: 'ngx-datatable[appPager]'
})
export class PagerDatatableDirective implements OnInit {
  @Input() defaultSort: SortPropDir;

  /**
   * Evento que se dispara cada vez que se ha cambiado el estado de la tabla (página, filtros, ordenamiento, etc)
   */
  @Output() onPager = new EventEmitter();

  constructor(private host: DatatableComponent, private el: ElementRef, private pager: PagerService) {}

  ngOnInit() {
    this.pager.onPager.subscribe(() => this.onPager.emit());
    this.pager.init(this.host, this.defaultSort);
    this.el.nativeElement.classList.add('data-table');
  }
}
