import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { FunctionsService, HttpRequestHandler, HttpService } from '../../util';

export enum RequestStatus {
  Pending = 1,
  Finished = 2,
  Both = 3,
}

export enum RequestTypes {
  Reentry = 1,
  SecondChild = 2, 
  ThirdChild = 3, 
}

@Injectable()
export class AdmissionRequestService {
  public static BASE_URL_API = environment.URL.API2 + 'admission-request';

  status = {
    requestStatus: new BehaviorSubject<RequestStatus>(undefined),
  };

  list = {
    requestStatus: [
      { id: RequestStatus.Pending, name: '', key: 'admissionRequest.status.pending', inForm: true, class: 'label label-warning' },
      { id: RequestStatus.Finished, name: '', key: 'admissionRequest.status.finished', inForm: true, class: 'label label-success' },
      { id: RequestStatus.Both, name: '', key: 'admissionRequest.status.both' },
    ],
  };

  requestTypes = [
    { id: RequestTypes.Reentry, name: '', key: 'admissionRequest.types.reentry' },
    { id: RequestTypes.SecondChild, name: '', key: 'admissionRequest.types.secondChild' },
    { id: RequestTypes.ThirdChild, name: '', key: 'admissionRequest.types.thirdChild' },
  ];

  constructor(private http: HttpService, private fn: FunctionsService, private translate: TranslateService) {
    const udpateLangValues = () => {
      this.list.requestStatus.forEach(item => (item.name = translate.instant(item.key)));

      this.requestTypes.forEach(item => (item.name = translate.instant(item.key)));
      this.requestTypes = [...this.requestTypes];
    };
    udpateLangValues();
    translate.onLangChange.subscribe(value => udpateLangValues());
  }

  /**
   * List admission requests
   *
   * @param params string
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  index(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AdmissionRequestService.BASE_URL_API}/index/${id}`, httpRequestHandler);
  }

  /**
   * Get admission request
   */
   get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AdmissionRequestService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Delete admission request
   */
   delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${AdmissionRequestService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Save admission request
   */
   save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(AdmissionRequestService.BASE_URL_API, data, httpRequestHandler);
  }
}
