import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class TutoringTimeService {
  public static URL = environment.URL.API + 'tutoring-time';

  constructor(private http: HttpService) {}

  /**
   * Index tutoring times
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(TutoringTimeService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Delete tutoring time
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${TutoringTimeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get tutoring time
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${TutoringTimeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save tutoring time
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${TutoringTimeService.URL}`, data, httpRequestHandler);
  }

  /**
   * Get tutoring time
   */
  list(httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${TutoringTimeService.URL}/list`, httpRequestHandler);
  }
}
