<section class="offline-404 d-flex text-center">
  <!-- Container-fluid starts -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 mt-5">
        <!-- auth body start -->
        <div>
          <h1><i class="fa fa-ban text-danger"></i> {{ 'forbidden.title' | translate }}</h1>
          <h2>{{ 'forbidden.description' | translate }}</h2>
          <p>
            <strong>{{ 'forbidden.requestedURL' | translate }}:</strong> {{ contentURL }}
            <br />
            <ng-container *ngIf="permission">
              <strong>{{ 'forbidden.requestedPermission' | translate }}:</strong> {{ permission.displayName }}
            </ng-container>
          </p>
        </div>
        <!-- auth body end -->
      </div>
    </div>
  </div>
</section>
