import { Injectable } from '@angular/core';
import { CustomAsyncValidation, CustomValidators } from 'app/modules/shared/form';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class TelegramNotificationService {
  public static URL = environment.URL.API + 'telegram-notification';

  constructor(private http: HttpService) {}

  /**
   * List Telegram Notification
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${TelegramNotificationService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get Telegram Notification
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${TelegramNotificationService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Telegram Notification
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(TelegramNotificationService.URL, data, httpRequestHandler);
  }

  /**
   * Delete Telegram Notification
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${TelegramNotificationService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Validation of existence of Telegram Notification name
   */
  exists: CustomAsyncValidation<any> = (id, name) => {
    return this.http.observableGet(`${TelegramNotificationService.URL}/exists?name=${encodeURIComponent(name)}&id=${id}`, {
      mapFn: CustomValidators.asyncMapFn
    });
  };
}
