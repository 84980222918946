import { Injectable } from '@angular/core';
import { AutocompleteTypeahead, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class InvoiceConceptService {
  public static URL = environment.URL.API2 + 'invoice-concept';
  constructor(private http: HttpService) {}

  list = () => {
    return this.http.observableGet(`${InvoiceConceptService.URL}/list`, { mapFn: res => res.personTypes });
  };

  /**
   * Method for autocomplete fields
   */
  listFilter: AutocompleteTypeahead = (query, data) => {
    return this.http.observablePost(InvoiceConceptService.URL + '/list-filter', { q: query, data: data }, { mapFn: ({ concepts }) => concepts });
  };
}
