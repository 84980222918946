import { Component, Input, OnInit } from '@angular/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { Subject } from 'rxjs';

import { SelectOptions } from '../../select/utils';
import { ILocalFilter, ILocalFilterType, LocalDt } from '../local-dt';

@Component({
  selector: 'app-local-select-column-filter',
  templateUrl: './local-select-column-filter.component.html',
  styleUrls: ['./local-select-column-filter.component.scss'],
})
export class LocalSelectColumnFilterComponent implements OnInit, ILocalFilter {
  @Input() localDt: LocalDt;
  @Input() col: TableColumn;
  @Input() valueField: string = 'id';
  @Input() displayField: string = 'name';
  @Input() selectOptions: SelectOptions;
  @Input() options: any[];
  @Input() placeholder: string;

  filter: string;
  value: Subject<any> = new Subject();
  type: ILocalFilterType = ILocalFilterType.Select;
  public name: string;
  private changeSubject: Subject<string> = new Subject();

  constructor() {
    this.changeSubject.subscribe(value => {
      this.value.next(value);
    });
  }

  ngOnInit() {
    this.name = this.col.prop.toString();
    this.localDt.filterInitiated(this, true);
  }

  valueReady(value: string) {
    this.filter = value;
  }

  next(e) {
    this.changeSubject.next(e);
  }
}
