import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

export enum OtherChargeApplyIn {
  Enrollment = 1,
  Monthly = 2,
  Custom = 3,
}

export enum OtherChargeValueType {
  Fixed,
  Calculated,
}

@Injectable()
export class PricesService {
  public static URL = environment.URL.API + 'prices';

  yearChanged = new EventEmitter<number>();

  otherChargeApplyInTypes = [
    { id: OtherChargeApplyIn.Enrollment, name: '', key: 'menu.main.enrollment', class: 'label label-success' },
    { id: OtherChargeApplyIn.Monthly, name: '', key: 'prices.monthly', class: 'label label-primary' },
    { id: OtherChargeApplyIn.Custom, name: '', key: 'prices.custom', class: 'label label-info' }
  ];

  otherChargeValueTypes = [
    { id: OtherChargeValueType.Fixed, name: '', key: 'general.fixed' },
    { id: OtherChargeValueType.Calculated, name: '', key: 'general.calculated' }
  ];

  /**
   * Constructor
   * @param http HttpService
   * @param translate TranslateService
   */
  constructor(private http: HttpService, translate: TranslateService) {
    const udpateLangValues = () => {
      this.otherChargeApplyInTypes.forEach(item => (item.name = translate.instant(item.key)));
      this.otherChargeValueTypes.forEach(item => (item.name = translate.instant(item.key)));
    };
    udpateLangValues();
    translate.onLangChange.subscribe(value => udpateLangValues());
  }

  /**
   * Obtener elemento de la lista otherChargeApplyInTypes
   * @param id OtherChargeApplyIn
   */
  getOtherChargeApplyIn(id: OtherChargeApplyIn) {
    return this.otherChargeApplyInTypes.find(element => element.id === id);
  }

  /**
   * Tarifas de grado segun año lectivo
   * @param id Id de año lectivo
   */
  getGrades(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PricesService.URL}/grades/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar valor de grado para año lectivo
   */
  setGradeValue(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${PricesService.URL}/grades`, data, httpRequestHandler);
  }

  /**
   * Tarifas de transporte segun año lectivo
   * @param id Id de año lectivo
   */
  getTransport(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PricesService.URL}/transport/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar valor de sector para año lectivo
   */
  setSectorValue(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${PricesService.URL}/transport`, data, httpRequestHandler);
  }

  /**
   * Actualizar porcentaje de descuento para tarifas de proveedor
   */
  setProviderDiscount(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${PricesService.URL}/transport/provider-discount`, data, httpRequestHandler);
  }

  /**
   * Actualizar valor de servicio de seguimiento de transporte
   */
  setTrackingValue(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${PricesService.URL}/transport/tracking`, data, httpRequestHandler);
  }

  /**
   * Actualizar valor de servicio de seguimiento de transporte
   */
  setEventualValue(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${PricesService.URL}/transport/eventual`, data, httpRequestHandler);
  }

  /**
   * Tarifas de pasos segun año lectivo
   * @param id Id de año lectivo
   */
  getSteps(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PricesService.URL}/steps/${id}`, httpRequestHandler);
  }

  /**
   * Grupos de reglas de salidas de paso de matricula
   * @param id Id de paso de matricula
   */
  getStep(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PricesService.URL}/steps/${id}/get`, httpRequestHandler);
  }

  /**
   * Actualizar tipo de valor de una salida de una regla
   */
  setOptionValueType(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${PricesService.URL}/steps/option/value-type`, data, httpRequestHandler);
  }

  /**
   * Actualizar valor de una salida de una regla
   */
  setOptionValue(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${PricesService.URL}/steps/option/value`, data, httpRequestHandler);
  }

  /**
   * Guardar datos de sub-regla para valor
   */
  saveSubrule(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PricesService.URL}/steps/option/sub-rule`, data, httpRequestHandler);
  }

  /**
   * Eliminar sub-regla
   */
  deleteSubrule(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${PricesService.URL}/steps/option/sub-rule/${id}`, httpRequestHandler);
  }

  /**
   * Listar otros cargos
   */
  getOtherCharges(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PricesService.URL}/other-charges/${id}`, httpRequestHandler);
  }

  /**
   * Obtener información de otros cargos
   */
  getOtherCharge(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PricesService.URL}/other-charges/${id}/get`, httpRequestHandler);
  }

  /**
   * Guardar otro cargo
   */
  saveOtherCharge(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PricesService.URL}/other-charges/save`, data, httpRequestHandler);
  }

  /**
   * Guardar regla de otro cargo
   */
  saveOtherChargeRule(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PricesService.URL}/other-charges/save-rule`, data, httpRequestHandler);
  }

  /**
   * Eliminar otro cargo
   */
  deleteOtherCharge(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${PricesService.URL}/other-charges/${id}`, httpRequestHandler);
  }

  /**
   * Eliminar regla de otro cargo
   */
  deleteOtherChargeRule(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${PricesService.URL}/other-charges/${id}/rule`, httpRequestHandler);
  }

  /**
   * Obtener listado de precios por estudiante por año
   */
  getStudentYearValues(yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PricesService.URL}/student-year-value/${yearId}`, httpRequestHandler);
  }

  /**
   * Guardar precios por estudiante por año
   */
  saveStudentYearValue(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PricesService.URL}/student-year-value`, data, httpRequestHandler);
  }

  /**
   * Eliminar precios por estudiante por año
   */
  deleteStudentYearValue(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${PricesService.URL}/student-year-value/${id}`, httpRequestHandler);
  }

  /**
   * Get extended hours plan prices list
   */
  getExtendedHoursPlanList(yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PricesService.URL}/extended-hours-plan/${yearId}`, httpRequestHandler);
  }

  /**
   * Save extended hours plan value
   */
  saveExtendedHoursPlanValue(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PricesService.URL}/extended-hours-plan`, data, httpRequestHandler);
  }

  /**
   * Guardar valor de la fracción por año
   */
  saveYearFractionValue(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PricesService.URL}/extended-hours-plan/fraction-value`, data, httpRequestHandler);
  }

  /**
   * Get deferrend balances
   */
  getDefferredBalances(yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PricesService.URL}/deferred-balance/${yearId}`, httpRequestHandler);
  }

  /**
   * Save deferred balance
   */
  saveDeferredBalance(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PricesService.URL}/deferred-balance`, data, httpRequestHandler);
  }

  /**
   * Delete deferred balance
   */
  deleteDeferredBalance(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${PricesService.URL}/deferred-balance/${id}`, httpRequestHandler);
  }

  /**
   * Get extracurricular prices general list
   */
  getExtraPriceGeneralList(yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PricesService.URL}/extracurricular-prices-general/${yearId}`, httpRequestHandler);
  }

  /**
   * Save extracurricular prices value
   */
  saveExtraPriceGeneralValue(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PricesService.URL}/extracurricular-prices-general`, data, httpRequestHandler);
  }

  /**
   * Delete extracurricular prices value
   */
  deleteExtraPriceGeneralValue(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${PricesService.URL}/extracurricular-prices-general/${id}`, httpRequestHandler);
  }

  /**
   * List financial third parties for deferred balance
   * @param httpRequestHandler HttpRequestHandler
   */
  listFtps = data => {
    return this.http.observablePost(`${PricesService.URL}/deferred-balance/ftps`, data, { mapFn: res => res.ftps });
  };
}
