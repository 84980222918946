import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { FunctionsService, HttpRequestHandler, HttpService } from '../../util';

export enum AdmissionStatus {
  Cancelled = 0,
  InProgress = 1,
  Committee = 2,
  Finished = 3,
}

export enum AdmissionInterviewStatus {
  Pending = 1,
  Done = 2,
}

export enum AdmissionAppointmentStatus {
  Scheduled = 1,
  Confirmed = 2,
  Done = 3,
}

export enum AdmissionVisitStatus {
  None = 0,
  Pending = 1,
  Done = 2,
}

export enum AdmissionHouseholdType {
  FamilyUnit = 1,
  LiveWith = 2,
  Both = 3,
  None = 4,
}

export enum AdmissionAssessmentResult {
  None = 0,
  Approved = 1,
  NotApproved = 2,
}

export enum AdmissionCommitteeStatus {
  Admitted = 1,
  Rejected = 0,
  None = 2,
}

export enum AdmissionCommitteeProgress {
  RevisionNone = 0,
  RevisionProgress = 1,
  RevisionFinished = 2,
}

export enum AdmissionCommitteeMember {
  CommitteeFinalDecision = 0,
}

export enum AdmissionRequestStatus {
  Pending = 1,
  Sent = 2,
  Finished = 3,
}

export enum AdmissionAdditionalAttendant {
  None = 0,
  New = 1,
  Principal = 2,
}

export enum AdmissionVisitFinalConceptScore {
  NotAccepted = 0,
  AcceptedWithRecomendations = 1,
  Accepted = 2,
}

export enum AdmissionAttendantAuthorization {
  AuthorizedPostInterview = 1,
  Authorized = 2,
}

@Injectable()
export class AdmissionService {
  public static URL = environment.URL.API + 'admission';
  public static URL2 = environment.URL.API2 + 'admission';

  status = {
    admission: new BehaviorSubject<AdmissionStatus>(undefined),
    interview: new BehaviorSubject<AdmissionInterviewStatus>(undefined),
    appointment: new BehaviorSubject<AdmissionAppointmentStatus>(undefined),
    assessment: new BehaviorSubject<boolean>(undefined),
    visit: new BehaviorSubject<AdmissionVisitStatus>(undefined),
    tracing: new BehaviorSubject<boolean>(undefined),
    cancellation: new BehaviorSubject<boolean>(undefined),
    request: new BehaviorSubject<AdmissionRequestStatus>(undefined),
    observation: new BehaviorSubject<boolean>(undefined),
  };

  list = {
    admission: [
      { id: AdmissionStatus.InProgress, name: '', key: 'admission.status.inProgress' },
      { id: AdmissionStatus.Committee, name: '', key: 'admission.status.committee' },
      { id: AdmissionStatus.Finished, name: '', key: 'admission.status.finished' },
      { id: AdmissionStatus.Cancelled, name: '', key: 'admission.status.cancelled' },
    ],
    imageAuthorization: [
      { id: true, name: '', key: 'admission.authorize' },
      { id: false, name: '', key: 'admission.notAuthorize' },
    ],
  };

  readonly householdTypes = [
    { id: AdmissionHouseholdType.FamilyUnit, name: 'Nucleo Familiar' },
    { id: AdmissionHouseholdType.LiveWith, name: 'Convive con el estudiante' },
    { id: AdmissionHouseholdType.Both, name: 'Nucleo Familiar y Convive con el estudiante' },
  ];

  constructor(private http: HttpService, private fn: FunctionsService, private translate: TranslateService) {
    const udpateLangValues = () => {
      this.list.admission.forEach(item => (item.name = translate.instant(item.key)));
      this.list.imageAuthorization.forEach(item => (item.name = translate.instant(item.key)));
    };
    udpateLangValues();
    translate.onLangChange.subscribe(value => udpateLangValues());
  }

  static interviewReportUrl(id: number) {
    return environment.URL.WEB + 'reports/admission/interview/' + id;
  }

  /**
   * Mostrar listado de admisión
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(AdmissionService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Obtener los estados de las etapas de la admisión
   */
  getStatus(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AdmissionService.URL}/status/${id}`, httpRequestHandler);
  }

  /**
   * Obtener los estados detallados de las etapas de la admisión
   */
  getFullStatus(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AdmissionService.URL}/status/${id}/full`, httpRequestHandler);
  }

  /**
   * Obtener entrevista de admisión
   */
  getInterview(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AdmissionService.URL}/interview/${id}`, httpRequestHandler);
  }

  /**
   * Agregar/Actualizar entrevista de admisión
   */
  saveInterview(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/interview/${id}`, data, httpRequestHandler);
  }

  /**
   * Guardar nuevo entrevistador
   * @param id number
   * @param interviewer any
   * @param httpRequestHandler HttpRequestHandler
   */
  saveNewInterviewer(id: number, interviewer: any, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${AdmissionService.URL}/appointment/new-interviewer`, { id: id, interviewer: interviewer }, httpRequestHandler);
  }

  /**
   * Obtejer PDF de la entrevista
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  getInterviewPDF(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AdmissionService.URL}/interview/report/${id}`, httpRequestHandler);
  }

  /**
   * Marcar admisión, saltar entrevista
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  interviewDoesNotApply(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/interview/does-not-apply`, data, httpRequestHandler);
  }

  /**
   * Revertir marca para que se le aplique entrevista a la admisión
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  applyInterview(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AdmissionService.URL}/interview/revert/${id}`, httpRequestHandler);
  }

  /**
   * Listado de entrevistadores
   */
  interviewers = () => {
    return this.http.observableGet(`${AdmissionService.URL}/interviewers`, { mapFn: res => res.interviewers });
  };

  /**
   * Listado de entrevistadores según disponibilidad
   */
  interviewersByAvailability = data => {
    return this.http.observableGet(`${AdmissionService.URL}/interviewers/${data}`, { mapFn: res => res.interviewers });
  };

  /**
   * Listado de entrevistadores según disponibilidad
   */
  interviewersHistory(httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AdmissionService.URL}/interviewers-history`, httpRequestHandler);
  }

  /***
   * Listado de turnos de persona
   */
  personAvailability(personId: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AdmissionService.URL}/interviewers/${personId}/availability?` + this.fn.objectToQueryParams(data), httpRequestHandler);
  }

  /**
   * Obtener datos de cita de entrevista
   */
  getAppointment(admissionId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AdmissionService.URL}/appointment/${admissionId}`, httpRequestHandler);
  }

  /**
   * Agendar Cita de entrevista
   */
  scheduleAppointment(availabilityId: number, admissionId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${AdmissionService.URL}/appointment/schedule`, { admissionId, availabilityId }, httpRequestHandler);
  }

  /**
   * Cancelar Cita de entrevista
   */
  cancelAppointment(availabilityId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${AdmissionService.URL}/appointment/cancel`, { availabilityId }, httpRequestHandler);
  }

  /**
   * Confirmar Cita de entrevista
   */
  confirmAppointment(availabilityId: number, tourEmployeeId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${AdmissionService.URL}/appointment/confirm`, { availabilityId, tourEmployeeId }, httpRequestHandler);
  }

  /**
   * Cancelar Confirmacion de  Cita de entrevista
   */
  cancelConfirmation(availabilityId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.put(`${AdmissionService.URL}/appointment/cancel-confirmation`, { availabilityId }, httpRequestHandler);
  }

  /**
   * Listado de personas que pueden realizar tour
   */
  tourPeople = () => {
    return this.http.observableGet(`${AdmissionService.URL}/tour-people`, { mapFn: res => res.tourPeople });
  };

  /**
   * Obtener datos de post entrevista de admisión
   */
  getPostInterview(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AdmissionService.URL}/post-interview/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar datos de post entrevista de admisión
   */
  savePostInterview(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/post-interview/${id}`, data, httpRequestHandler);
  }

  /**
   * Obtener datos de seguimiento de admisión
   */
  getTracing(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AdmissionService.URL}/tracing/${id}`, httpRequestHandler);
  }

  /**
   * Agregar observación de seguimiento de admisión
   */
  saveTracing(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/tracing/${id}`, data, httpRequestHandler);
  }

  /**
   * Obtener datos de evaluacion de estudiantes de admisión
   */
  getAssessment(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AdmissionService.URL}/assessment/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar datos de evaluacion de estudiantes de admisión
   */
  saveAssessment(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/assessment`, data, httpRequestHandler);
  }

  /**
   * Obtener datos de visita domiciliaria
   */
  getVisit(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AdmissionService.URL}/visit/${id}`, httpRequestHandler);
  }

  /**
   * Guardar datos de visita domiciliaria de de admisión
   */
  saveVisit(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/visit/${id}`, data, httpRequestHandler);
  }

  /**
   * No aplicar visita a admisión
   */
  disableVisit(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/visit/${id}/disable`, data, httpRequestHandler);
  }

  /**
   * Aplicar visita a admisión
   */
  enableVisit(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/visit/${id}/enable`, {}, httpRequestHandler);
  }

  /**
   * Notificar por correo a acudientes para calificación
   */
  sendEmailForRating(admissionId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/notify/${admissionId}/rating`, {}, httpRequestHandler);
  }

  /**
   * Notificar por correo a acudientes para solicitud de cupo
   */
  sendEmailForRequest(admissionId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/notify/${admissionId}/request`, {}, httpRequestHandler);
  }

  /**
   * Notificar a coordinadores para realizacion de evaluación
   */
  sendEmailForAssessment(admissionId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/notify/${admissionId}/assessment`, {}, httpRequestHandler);
  }

  /**
   * Notificar a empleado para realizacion de visita domiciliaria
   */
  sendEmailForVisit(admissionId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/notify/${admissionId}/visit`, {}, httpRequestHandler);
  }

  /**
   * Notificar al acudiente para que autorice la consulta a las centrales de riesgo
   */
  sendEmailForAuthorization(attendantId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/notify/${attendantId}/risk-authorization`, {}, httpRequestHandler);
  }

  /**
   * Notificar al acudiente para que autorice la consulta a las centrales de riesgo
   */
  sendEmailForDataAuthorization(attendantId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/notify/${attendantId}/data-authorization`, {}, httpRequestHandler);
  }

  /**
   * Obtener calificación de acudiente
   */
  getRating(admissionAttendantId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AdmissionService.URL}/rating/${admissionAttendantId}`, httpRequestHandler);
  }

  /**
   * Guardar calificación de acudiente
   */
  saveRating(admissionAttendantId: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/rating/${admissionAttendantId}`, data, httpRequestHandler);
  }

  /**
   * Obtener datos de cancelación de estudiantes de admisión
   */
  getCancellation(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AdmissionService.URL}/cancellation/${id}`, httpRequestHandler);
  }

  /**
   * Actualizar datos de cancelación de estudiantes de admisión
   */
  saveCancellation(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/cancellation`, data, httpRequestHandler);
  }

  /**
   * Enviar admisión a comité
   */
  sendToCommittee(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/committee/${id}/send`, {}, httpRequestHandler);
  }

  /**
   * Obtener datos de comité de admisión
   */
  getCommittee(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AdmissionService.URL}/committee/${id}`, httpRequestHandler);
  }

  /**
   * Guardar datos de riesgo de los acudientes
   */
  saveCommitteeAttendant(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/committee/attendant`, data, httpRequestHandler);
  }

  /**
   * Obtener datos estudiantes para miembro del comité de admisión
   */
  getCommitteeStudents(id: number, admissionId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AdmissionService.URL}/committee/${id}/${admissionId}/students`, httpRequestHandler);
  }

  /**
   * Guardar calificación de miembro de comité para un estudiante
   */
  saveCommitteeStudent(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/committee/save-student`, data, httpRequestHandler);
  }

  /**
   * Guardar datos de comité de admisión
   */
  saveCommittee(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/committee/${id}`, data, httpRequestHandler);
  }

  /**
   * Notificar por correo a acudientes resultado de comité de admisiones
   */
  sendEmailCommitteResult(admissionId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/notify/${admissionId}/committee`, {}, httpRequestHandler);
  }

  /**
   * Obtener datos de solicitud de cupo
   */
  getRequest(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AdmissionService.URL}/request/${id}`, httpRequestHandler);
  }

  /**
   * Guardar datos de solicitud de cupo
   */
  saveRequest(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/request/${id}`, data, httpRequestHandler);
  }

  /**
   * Guardar solicitud de cupo (completa)
   */
  saveRequestAll(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/request/${id}/save-all`, data, httpRequestHandler);
  }

  /**
   * Guardar datos de solicitud de cupo (estudiante)
   */
  saveStudentRequest(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/request/${id}/student`, data, httpRequestHandler);
  }

  /**
   * Guardar datos de solicitud de cupo (estudiante)
   */
  saveAttendantRequest(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/request/${id}/attendant`, data, httpRequestHandler);
  }

  /**
   * Guardar foto de estudiante de admisión
   */
  saveStudentPhoto(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/request/${id}/student/photo`, data, httpRequestHandler);
  }

  /**
   * Guardar foto de acudiente de admisión
   */
  saveAttendantPhoto(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/request/${id}/attendant/photo`, data, httpRequestHandler);
  }

  /**
   * Guardar documento de estudiante de admisión
   */
  saveStudentDocument(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/request/${id}/student/document`, data, httpRequestHandler);
  }

  /**
   * Guardar documento de acudiente de admisión
   */
  saveAttendantDocument(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/request/${id}/attendant/document`, data, httpRequestHandler);
  }

  /**
   * Eliminar documento de estudiante de admisión
   */
  deleteStudentDocument(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${AdmissionService.URL}/request/${id}/student/document`, httpRequestHandler);
  }

  /**
   * Eliminar documento de acudiente de admisión
   */
  deleteAttendantDocument(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${AdmissionService.URL}/request/${id}/attendant/document`, httpRequestHandler);
  }

  /**
   * Guardar acudiente principal como adicional
   *
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  saveAsAdditionalAttendant(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/request/as-additional`, { id: id }, httpRequestHandler);
  }

  /**
   * Eliminar acudiente adicional
   *
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  deleteAdditionalAttendant(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/request/delete-additional-attendant`, { id: id }, httpRequestHandler);
  }

  /**
   * Guardar documento de referencia
   * @param id Id de admisión
   * @param type tipo de referencia. 1:Comercial, 2:Familia Bennet
   */
  saveReferenceDocument(id: number, type: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/request/${id}/doc-reference/${type}`, data, httpRequestHandler);
  }

  /**
   * Eliminar documento de referencia
   * @param id Id de admisión
   * @param type tipo de referencia. 1:Comercial, 2:Familia Bennet
   */
  deleteReferenceDocument(id: number, type: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${AdmissionService.URL}/request/${id}/doc-reference/${type}`, httpRequestHandler);
  }

  /**
   * Tomar solicitud de cupo para edición
   */
  takeRequest(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/request/${id}/take`, null, httpRequestHandler);
  }

  /**
   * Autorizar acudiente para consulta a centrales de riesgo
   * @param attendantId number
   * @param httpRequestHandler HttpRequestHandler
   */
  attendantRiskAuthorization(attendantId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/request/${attendantId}/risk-authorization`, null, httpRequestHandler);
  }

  /**
   * Autorizar acudiente para consulta a centrales de riesgo
   * @param attendantId number
   * @param httpRequestHandler HttpRequestHandler
   */
  attendantDataAuthorization(attendantId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/request/${attendantId}/data-authorization`, null, httpRequestHandler);
  }

  /**
   * Autorizar acudiente para consulta a centrales de riesgo
   * @param attendantId number
   * @param authorization boolean
   * @param type boolean
   * @param httpRequestHandler HttpRequestHandler
   */
  admissionSaveAuthorization(attendantId: number, authorization: boolean, type: boolean, httpRequestHandler: HttpRequestHandler) {
    this.http.post(
      `${AdmissionService.URL}/request/${attendantId}/admission-save-authorization`,
      { authorization: authorization, type: type },
      httpRequestHandler
    );
  }

  /**
   * Guardar documento del acudiente de centrales de riesgo
   * @param id Id del acudiente
   * @param data Archivo
   * @param httpRequestHandler HttpRequestHandler
   */
  saveAuthorizationDocument(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/committee/${id}/authorization-document`, data, httpRequestHandler);
  }

  /**
   * Guardar documento del acudiente de centrales de riesgo
   * @param id Id del acudiente
   * @param httpRequestHandler HttpRequestHandler
   */
  deleteAuthorizationDocument(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${AdmissionService.URL}/committee/${id}/authorization-document`, httpRequestHandler);
  }

  reopen(studentId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/cancellation/reopen`, { id: studentId }, httpRequestHandler);
  }

  /**
   * Return to attendant
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  returnToAttendant(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AdmissionService.URL}/request/${id}/return-to-attendant`, httpRequestHandler);
  }

  /**
   * Export admission data
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  export(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL}/export`, data, httpRequestHandler);
  }

  /**
   * Links Admission familiy to the Bennett family
   * @param data any
   * @param httpRequestHandler
   */
  linkFamily(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AdmissionService.URL2}/link-family`, data, httpRequestHandler);
  }
}
