import { Injectable } from '@angular/core';
import { CustomAsyncValidation, CustomValidators } from 'app/modules/shared/form';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpService } from '../../util';

@Injectable()
export class MailNotificationService {
  public static BASE_URL_API = environment.URL.API + 'mail-notification';

  constructor(private http: HttpService) {}

  /**
   * Mostrar listado Notificaciones de Correo.
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(MailNotificationService.BASE_URL_API + '?' + params, httpRequestHandler);
  }

  /**
   * Crear y/o Actualizar Notificación de Correo.
   */
  save(id: number, data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${MailNotificationService.BASE_URL_API}/notification/${id}`, data, httpRequestHandler);
  }

  /**
   * Eliminar Notificación de Correo.
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${MailNotificationService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Editar Notificación de Correo.
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${MailNotificationService.BASE_URL_API}/${id}`, httpRequestHandler);
  }

  /**
   * Listado de variables de notificacion
   */
  variables(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${MailNotificationService.BASE_URL_API}/${id}/variables`, httpRequestHandler);
  }

  /**
   * Validacion de existencia de nombre de Notificación de Correo
   */
  exists: CustomAsyncValidation<any> = (id, name) => {
    return this.http.observableGet(`${MailNotificationService.BASE_URL_API}/exists?name=${encodeURIComponent(name)}&id=${id}`, {
      mapFn: CustomValidators.asyncMapFn
    });
  };
}
