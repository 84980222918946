import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum AssessmentCateItemType {
  Text = 1,
  Descriptive = 2,
  Grade = 3,
  UniqueSelection = 4,
  MultipleSelection = 5,
  ValueJudgment = 6
}

export enum AssessmentLimitationType {
  None = 0,
  Only = 1,
  Except = 2
}

@Injectable()
export class AssessmentConfigService {
  public static URL = environment.URL.API + 'assessment-config';

  assessmentCateItemTypes = [
    { id: AssessmentCateItemType.Text, key: 'general.text', name: '', requiresOptions: false, textRight: false, responseRequired: false },
    {
      id: AssessmentCateItemType.Descriptive,
      key: 'general.descriptive',
      name: '',
      requiresOptions: false,
      textRight: false,
      responseRequired: true
    },
    { id: AssessmentCateItemType.Grade, key: 'general.grade', name: '', requiresOptions: false, textRight: true, responseRequired: true },
    {
      id: AssessmentCateItemType.UniqueSelection,
      key: 'general.uniqueSelection',
      name: '',
      requiresOptions: true,
      textRight: false,
      responseRequired: true
    },
    {
      id: AssessmentCateItemType.MultipleSelection,
      key: 'general.multipleSelection',
      name: '',
      requiresOptions: true,
      textRight: false,
      responseRequired: false
    },
    {
      id: AssessmentCateItemType.ValueJudgment,
      key: 'general.valueJudgment',
      name: '',
      requiresOptions: false,
      textRight: true,
      responseRequired: true
    }
  ];

  assessmentLimitationTypes = [
    { id: AssessmentLimitationType.None, key: 'assessmentConfig.none', name: '', requiresSubject: false },
    { id: AssessmentLimitationType.Only, key: 'assessmentConfig.only', name: '', requiresSubject: true },
    { id: AssessmentLimitationType.Except, key: 'assessmentConfig.except', name: '', requiresSubject: true }
  ];

  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    const lists = {
      assessmentCateItemTypes: this.assessmentCateItemTypes,
      assessmentLimitationTypes: this.assessmentLimitationTypes
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  getAssCateItemType(id: number) {
    return this.assessmentCateItemTypes.find(item => item.id === id);
  }

  getAssLimitationType(id: number) {
    return this.assessmentLimitationTypes.find(item => item.id === id);
  }

  /**
   * Show assessments config list
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(AssessmentConfigService.URL + '?' + params, httpRequestHandler);
  }

  /**
   * Get assessment config
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AssessmentConfigService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save assessment config
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AssessmentConfigService.URL}`, data, httpRequestHandler);
  }

  /**
   * Delete assessment config
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${AssessmentConfigService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get assessment categories
   */
  getCategories(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AssessmentConfigService.URL}/${id}/categories`, httpRequestHandler);
  }

  /**
   * Save assessment category
   */
  saveCategory(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AssessmentConfigService.URL}/category`, data, httpRequestHandler);
  }

  /**
   * Delete assessment category
   */
  deleteCategory(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${AssessmentConfigService.URL}/${id}/category`, httpRequestHandler);
  }

  /**
   * Get assessment category item
   */
  getCategoryItem(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${AssessmentConfigService.URL}/${id}/category-item`, httpRequestHandler);
  }

  /**
   * Save assessment category item
   */
  saveCategoryItem(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${AssessmentConfigService.URL}/category-item`, data, httpRequestHandler);
  }

  /**
   * Delete assessment category item
   */
  deleteCategoryItem(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${AssessmentConfigService.URL}/${id}/category-item`, httpRequestHandler);
  }
}
