import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export interface ICostCenterConfig {
  id: number;
  pcMaxAmount: number;
  pcMaxAmountPerc: number;
  pcRefundPercentage: number;
  pcAccount: number;
}
export interface IDataConfig {
  costCenterConfig: ICostCenterConfig;
}
@Injectable()
export class CostCenterConfigService {
  public static URL = environment.URL.API2 + 'cost-center-config';

  constructor(private http: HttpService) {}

  /**
   * Get Cost Center Config
   */
  get(httpRequestHandler: HttpRequestHandler) {
    this.http.get(CostCenterConfigService.URL, httpRequestHandler);
  }

  /**
   * Save Cost Center Config
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(CostCenterConfigService.URL, data, httpRequestHandler);
  }
}
