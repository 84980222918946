import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum PettyCashReportType {
  Interface = 1,
}

export interface IPettyCash {
  costCenterId: number;
  employeeId: number;
  value: number;
  used: number;
  balance: number;
  name: string;
  costCenters: [];
  enabled: boolean;
  refundValue: number;
}

export interface IDataPettyCash {
  pettyCash: IPettyCash[];
  count: number;
}

export enum RefundStatus {
  Unrecorded = 0,
  Registered = 1,
  Authorized = 2,
  Paid = 3,
}

export enum RefundLogType {
  Registered = 1,
  Authorized = 2,
  Paid = 3,
  Unauthorized = 4,
  Normal = 5,
}
@Injectable()
export class PettyCashService {
  public static URL = environment.URL.API2 + 'petty-cash';

  refundStatus = [
    { id: RefundStatus.Registered, key: 'refund.registered', name: 'refund.registered', class: 'label label-info' },
    { id: RefundStatus.Authorized, key: 'refund.authorized', name: 'refund.authorized', class: 'label label-primary' },
    { id: RefundStatus.Paid, key: 'refund.paid', name: 'refund.paid', class: 'label label-success' },
  ];

  refundLogTypes = [
    { id: RefundLogType.Registered, key: 'refund.trackingStatus.registered', name: '', class: 'bg-warning-light' },
    { id: RefundLogType.Authorized, key: 'refund.trackingStatus.authorized', name: '', class: 'bg-primary-light' },
    { id: RefundLogType.Paid, key: 'refund.trackingStatus.paid', name: '', class: 'bg-success-light' },
    { id: RefundLogType.Unauthorized, key: 'refund.trackingStatus.unauthorized', name: '', class: 'bg-warning-light' },
    { id: RefundLogType.Normal, key: 'enroll.trackingStatus.normal', name: '', class: '' },
  ];

  reportTypes = [{ id: PettyCashReportType.Interface, key: 'pettyCash.pettyCashInterface', name: '', permission: 'accounting_interface' }];

  constructor(private http: HttpService, private fn: FunctionsService, translate: TranslateService) {
    fn.refreshListPermissions(this.reportTypes, array => (this.reportTypes = array));
    const lists = {
      refundStatus: this.refundStatus,
      refundLogTypes: this.refundLogTypes,
      reportTypes: this.reportTypes,
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  getType(type: RefundStatus) {
    return this.refundStatus.find(t => t.id === type);
  }

  getStatus(status: RefundStatus) {
    return this.refundStatus.find(s => s.id === status);
  }

  /**
   * Index Petty Cash
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PettyCashService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Delete Petty Cash
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${PettyCashService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Get Petty Cash
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PettyCashService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Petty Cash
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(PettyCashService.URL, data, httpRequestHandler);
  }

  /**
   * List Petty Cash
   * @param httpRequestHandler HttpRequestHandler
   */
  list = ({ id }) => {
    return this.http.observableGet(`${PettyCashService.URL}/list/${id}`, { mapFn: res => res.pettyCashes });
  };

  /**
   * Get Petty Cash by employee
   */
  getEmployeePettyCashes(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PettyCashService.URL}/employee/${id}`, httpRequestHandler);
  }

  /**
   * Get Petty Cashes
   */
  getPettyCashes(httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PettyCashService.URL}/petty-cashes/`, httpRequestHandler);
  }

  /**
   * Request refund
   */
  requestRefund(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PettyCashService.URL}/request-refund/`, data, httpRequestHandler);
  }

  /**
   * Delete Petty Cash Refund
   */
  deleteRefund(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${PettyCashService.URL}/delete-refund/${id}`, httpRequestHandler);
  }

  /**
   * List Petty Cash refunds
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  getRefunds(params: string, paramsRoute: any, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PettyCashService.URL}/refunds/${paramsRoute.pettyCashId}?${params}`, httpRequestHandler);
  }

  /**
   * Save Manager Authorize
   * @param httpRequestHandler HttpRequestHandler
   */
  managerAuthorize(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PettyCashService.URL}/manager-authorize`, data, httpRequestHandler);
  }

  /**
   * Datos de seguimiento de Reembolso
   * @param id Id de reembolso
   */
  tracking(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PettyCashService.URL}/tracking/${id}`, httpRequestHandler);
  }

  /**
   * Guardar observacion de reembolso
   */
  saveTracking(data, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PettyCashService.URL}/tracking`, data, httpRequestHandler);
  }

  /**
   * Save Accountant Register
   * @param httpRequestHandler HttpRequestHandler
   */
  accountRegister(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PettyCashService.URL}/account-register`, data, httpRequestHandler);
  }

  /**
   * Print refund PDF
   */
  print(id: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${PettyCashService.URL}/print/${id}`, httpRequestHandler);
  }

  /**
   * Generate CSV File
   */
  downloadReport(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PettyCashService.URL}/interfaz/`, data, httpRequestHandler);
  }
}
