import { Injectable } from '@angular/core';

import { environment } from './../../../../environments/environment';
import { HttpRequestHandler, HttpService } from './../../util/http.service';

export enum AuthorizationType {
  SportsAuthorization = 1,
  LibraryAuthorization = 2,
  PortfolioAuthorization = 3,
  ExtracurricularAuthorization = 4
}

@Injectable()
export class EnrollmentAuthorizationService {
  public static BASE_URL_API = environment.URL.API + 'enrollment-authorization';

  constructor(private http: HttpService) {
  }

  /**
   * List libraries
   */
  index(type: AuthorizationType, params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollmentAuthorizationService.BASE_URL_API}?${params}`, { type: type }, httpRequestHandler);
  }

  /**
   * List libraries
   */
  list(httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${EnrollmentAuthorizationService.BASE_URL_API}/list`, httpRequestHandler);
  }

  /**
   * Store library
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${EnrollmentAuthorizationService.BASE_URL_API}/save`, data, httpRequestHandler);
  }

  /**
   * Delete library
   */
  delete(id: number, type: AuthorizationType, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${EnrollmentAuthorizationService.BASE_URL_API}/${id}/${type}`, httpRequestHandler);
  }

  /**
   * Get library
   */
  get(id: number, type: AuthorizationType, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${EnrollmentAuthorizationService.BASE_URL_API}/${id}/${type}`, httpRequestHandler);
  }
}
