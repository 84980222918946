import { Location } from '@angular/common';
import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnMode, DatatableComponent, SortPropDir } from '@swimlane/ngx-datatable';
import { Subject, Subscription } from 'rxjs';

import { FunctionsService } from './functions.service';

export type LabelPosition = 'top' | 'left';

export interface IFilterData {
  name: string;
  value: string;
}

export interface IPagerAddon {
  value: Subject<any>;

  valueReady(value): void;
}

export interface IPagerFilter extends IPagerAddon {
  name: string;
  default: any;
}

export class PagerHiddenFilter implements IPagerFilter {
  value: Subject<any> = new Subject();
  name: string;
  default: any;

  constructor(name: string) {
    this.name = name;
  }

  valueReady(value) {
  }

  set(value: any) {
    this.value.next(value);
  }
}

@Injectable()
export class PagerService {
  private readonly defaultNullChar = '---';

  private readonly defaults = {
    size: 10,
    filter: '',
    orderBy: '',
    order: null,
    page: 0
  };

  private _rows = [];
  public get rows() {
    return this._rows;
  }

  public set rows(v: any[]) {
    this._rows = v;
    this.table.rows = v;
  }

  /**
   * Número de registros a mostrar por página
   */
  private _size = this.defaults.size;
  private get size() {
    return this._size;
  }

  private set size(v: number) {
    this.page = this.defaults.page;
    this._size = v;
    this.table.limit = v;
  }

  /**
   * Filtro general
   */
  private _filter = this.defaults.filter;
  private get filter() {
    return this._filter;
  }

  private set filter(v: string) {
    this.page = this.defaults.page;
    this._filter = v;
  }

  /**
   * Página actual de la tabla
   */
  private _page = this.defaults.page;
  private get page() {
    return this._page;
  }

  private set page(v: number) {
    this._page = v;
    if (this.table) this.table.offset = v;
  }

  /**
   * Dirección y campo de ordenamiento de actual de la tabla
   */
  private _sort: SortPropDir = {
    prop: this.defaults.orderBy,
    dir: this.defaults.order
  };
  private get sort() {
    return this._sort;
  }

  private set sort(v: SortPropDir) {
    this.page = this.defaults.page;
    this._sort = v;
  }

  /**
   * Total de registros
   */
  private _count = 0;
  public get count() {
    return this._count;
  }

  public set count(v: number) {
    // validar consistencia de página actual con numero de registros
    const maxPages: number = Math.ceil(v / this.size);
    if (maxPages > 0 && this.page + 1 > maxPages) {
      this.page--;
      this.update();
    }

    this._count = v;
    this.table.count = v;
  }

  /**
   * Mostrar loader de tabla
   */
  private _loading = true;
  public get loading() {
    return this._loading;
  }

  public set loading(v: boolean) {
    this._loading = v;
    this.table.loadingIndicator = v;
    this.table.recalculate();
  }

  public queryParams: any;
  public onPager = new EventEmitter();
  public onFilterReady = new EventEmitter<IFilterData>();
  public onFilterChange = new EventEmitter<IFilterData>();

  private filters = {
    size: null as IPagerAddon,
    general: null as IPagerAddon,
    columns: [] as IPagerFilter[],
    values: {}
  };

  private currentParams: any = {};
  public table: DatatableComponent;
  private pushUrl: boolean;

  private subs = new Subscription();

  constructor(private activated: ActivatedRoute, private router: Router, private location: Location, private fn: FunctionsService) {
  }

  /**
   * Inicializa la captura de parametros por URL actuales para actualizar el paginador:Pager
   */
  public init(table: DatatableComponent, defaultSort: SortPropDir) {
    this.pushUrl = true;
    this.table = table;
    this.table.limit = this.size;

    /** asigno la columna de ordenamiento por defecto */
    if (defaultSort) {
      this.sort.prop = defaultSort.prop;
      this.sort.dir = defaultSort.dir ? defaultSort.dir : <any>'asc';
    }

    this.initNoTable();

    this.table.externalPaging = true;
    this.table.externalSorting = true;
    this.table.sorts = [this.sort];
    this.table.messages = this.fn.dtMessages;
    this.table.footerHeight = 40;
    this.table.headerHeight = -1; // auto
    this.table.rowHeight = -1; // 'auto'
    this.table.columnMode = ColumnMode.flex;

    this.table.page.subscribe(value => {
      this.page = value.offset;
      this.update();
    });

    this.table.sort.subscribe(value => {
      this.sort = { prop: value.column.prop, dir: value.newValue };
      this.update();
    });
  }

  initNoTable() {
    this.subs.add(
      this.activated.queryParams.subscribe(params => {
        setTimeout(() => {
          if (params.s) this.size = Number(params.s);
          if (params.f) this.filter = decodeURIComponent(params.f);
          if (params.ob) this.sort.prop = params.ob;
          if (params.o) this.sort.dir = params.o;
          if (params.p) this.page = Number(params.p) - 1;

          /** asignacion de filtros por defecto */
          this.filters.columns.forEach(f => {
            if (f.default != null) {
              this.filters.values['f_' + f.name] = f.default;
            }
          });

          Object.keys(params).forEach(key => {
            if (key.startsWith('f_')) {
              this.filters.values[key] = decodeURIComponent(params[key]);
            } else if (key !== 's' && key !== 'f' && key !== 'ob' && key !== 'o' && key !== 'p') {
              this.currentParams[key] = params[key].indexOf('=') === -1 ? params[key] : encodeURIComponent(params[key]);
            }
          });

          /** Inicializar valores de filtros por columna, filtro general y tamaño de paginas */
          this.filters.columns.forEach(f => {
            let value = this.filters.values['f_' + f.name];
            if (value === this.defaultNullChar) value = null;
            f.valueReady(value);
            if (this.pushUrl) this.onFilterReady.emit({ name: f.name, value });
          });
          if (this.filters.general) this.filters.general.valueReady(this.filter);
          if (this.filters.size) this.filters.size.valueReady(this.size);

          if (this.pushUrl) {
            this.setUrlParams();
            this.pushUrl = false;
          }

          this.onPager.emit();
        }, 0);
      })
    );
    this.subs.unsubscribe();
  }

  reset() {
    this._size = this.defaults.size;
    this._filter = this.defaults.filter;
    this._rows = [];
    this._page = this.defaults.page;
    this._sort.prop = this.defaults.orderBy;
    this._sort.dir = this.defaults.order;
    this._count = 0;
    this._loading = true;
    this.filters.values = {};
    this.currentParams = {};
    this.subs.unsubscribe();
  }

  generalFilterInitiated(filter: IPagerAddon) {
    this.filters.general = filter;

    filter.value.subscribe(value => {
      this.filter = value;
      this.update();
    });
  }

  sizeFilterInitiated(filter: IPagerAddon) {
    this.filters.size = filter;

    filter.value.subscribe(value => {
      this.size = value;
      this.update();
    });
  }

  filterInitiated(filter: IPagerFilter) {
    this.filters.columns.push(filter);
    filter.value.subscribe(value => {
      if (value == null && filter.default != null) value = this.defaultNullChar;

      this.page = this.defaults.page;
      delete this.filters.values['f_' + filter.name];
      if (value != null && value !== '') this.filters.values['f_' + filter.name] = value;

      this.onFilterChange.emit({ name: filter.name, value });
      this.update();
    });
  }

  addHiddenFilter(name: string) {
    const filter = new PagerHiddenFilter(name);
    this.filterInitiated(filter);
    return filter;
  }

  private setUrlParams() {
    const params = this.getParams(true, true);

    this.queryParams = {};
    if (params) this.queryParams.returnUrl = encodeURIComponent(params);

    this.router.navigate([], {
      queryParams: this.getParamsObject(),
      queryParamsHandling: 'merge',
      preserveFragment: true,
      relativeTo: this.activated,
      replaceUrl: true
    });
  }

  /**
   * Refresca la URL y actualiza los datos de la tabla
   */
  private update() {
    this.setUrlParams();
    this.onPager.emit();
  }

  /**
   * Retorna el valor de un filtro
   * @param key Nombre del filtro
   */
  public getFilterValue(key: string): any {
    return this.filters.values[`f_${key}`];
  }

  /**
   * Retorna el valor de todos los filtros
   */
  public getFilterValues() {
    const value: any = {};
    Object.keys(this.filters.values).forEach(key => {
      value[key.substring(2, key.length)] = this.filters.values[key];
    });
    return value;
  }

  public getParamsObject() {
    const params: any = { p: null, s: null, f: null, ob: null, o: null };

    if (this.page !== this.defaults.page) params.p = this.page + 1;
    if (this.size !== this.defaults.size) params.s = this.size;
    if (this.filter) params.f = encodeURIComponent(this.filter);
    if (this.sort.prop) params.ob = this.sort.prop;
    if (this.sort.dir) params.o = this.sort.dir;

    this.filters.columns.forEach(filter => {
      params[`f_${filter.name}`] = null;
    });

    Object.keys(this.filters.values).forEach(key => {
      params[key] = encodeURIComponent(this.filters.values[key]);
    });

    return params;
  }

  /**
   * Retorna los parametros controlados por el paginador
   * @param includeCurrent Determina si se incluyen los parametros no controlados por el paginador
   * @param includeDefaultNulls Determina si se incluyen los parametros que tienen valor por defecto pero con valor de filtro en `null`
   */
  public getParams(includeCurrent = false, includeDefaultNulls = false): string {
    const params: string[] = [];

    if (this.page !== this.defaults.page) params.push('p=' + (this.page + 1));
    if (this.size !== this.defaults.size) params.push('s=' + this.size);
    if (this.filter) params.push('f=' + encodeURIComponent(this.filter));
    if (this.sort.prop) params.push('ob=' + this.sort.prop);
    if (this.sort.dir) params.push('o=' + this.sort.dir);

    Object.keys(this.filters.values).forEach(key => {
      const filter = this.filters.columns.find(f => f.name === key.substring(2));
      const value = this.filters.values[key];

      if (includeDefaultNulls) {
        params.push(key + '=' + encodeURIComponent(value));
      } else {
        if (filter.default != null && value === this.defaultNullChar) return;
        params.push(key + '=' + encodeURIComponent(value));
      }
    });
    if (includeCurrent) Object.keys(this.currentParams).forEach(key => params.push(key + '=' + this.currentParams[key]));

    return params.join('&');
  }
}
