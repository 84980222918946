<label class="label-control mb-0">
  {{ 'general.show' | translate }}&nbsp;
  <select class="form-control form-control-sm input-sm full-data-show-entry" [(ngModel)]="size" (ngModelChange)="value.next($event)">
    <option [ngValue]="10">10</option>
    <option [ngValue]="25">25</option>
    <option [ngValue]="50">50</option>
    <option [ngValue]="100">100</option>
  </select>
  &nbsp;{{ 'general.records' | translate }}
</label>
