import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Host, Input, OnInit, Optional, Output, SkipSelf, ViewChild } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { ModalSize } from 'app/modules/shared';

import { FunctionsService } from '../../../services/util';
import { CustomValidators } from '../form';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-address-generator',
  templateUrl: './address-generator.component.html',
  styleUrls: ['./address-generator.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AddressGeneratorComponent)
    }
  ]
})
export class AddressGeneratorComponent implements OnInit, ControlValueAccessor {
  @Input() title = '';
  @Input() formControlName: string;
  @Input() copy: boolean;

  @Output() copyOpen = new EventEmitter<any>();
  copyList: any[];
  value = {
    plain: null as string,
    generated: {
      actual: null as string,
      temp: null as string
    }
  };
  disabled: boolean;
  form: FormGroup;
  rId: string;
  control: AbstractControl;
  hidden = true;
  types = [
    { id: 'AV', name: 'Avenida' },
    { id: 'CL', name: 'Calle' },
    { id: 'CC', name: 'Callejón' },
    { id: 'CR', name: 'Carrera' },
    { id: 'CA', name: 'Casa' },
    { id: 'CD', name: 'Condominio' },
    { id: 'DG', name: 'Diagonal' },
    { id: 'HC', name: 'Hacienda' },
    { id: 'KM', name: 'Km' },
    { id: 'TR', name: 'Transversal' },
    { id: 'VI', name: 'Vía' },
    { id: 'VDA', name: 'Vereda' }
  ];
  types2 = [
    { id: 'NR', name: '#' },
    { id: 'AV', name: 'Avenida' },
    { id: 'CL', name: 'Calle' },
    { id: 'CC', name: 'Callejón' },
    { id: 'CR', name: 'Carrera' },
    { id: 'CA', name: 'Casa' },
    { id: 'CD', name: 'Condominio' },
    { id: 'DG', name: 'Diagonal' },
    { id: 'HC', name: 'Hacienda' },
    { id: 'KM', name: 'Km' },
    { id: 'TR', name: 'Transversal' },
    { id: 'VI', name: 'Vía' },
    { id: 'VDA', name: 'Vereda' }
  ];
  cardinalDirections = [
    { id: 'N', name: 'Norte' },
    { id: 'S', name: 'Sur' },
    { id: 'E', name: 'Este' },
    { id: 'O', name: 'Oeste' }
  ];
  complements = [
    { id: 'A', name: 'Apto' },
    { id: 'C', name: 'Casa' },
    { id: 'M', name: 'Condominio' },
    { id: 'R', name: 'Conjunto residencial' },
    { id: 'E', name: 'Edificio' },
    { id: 'X', name: 'Etapa' },
    { id: 'L', name: 'Local' },
    { id: 'Z', name: 'Manzana' },
    { id: 'P', name: 'Piso' },
    { id: 'S', name: 'Sector' },
    { id: 'T', name: 'Torre' }
  ];
  ModalSize = ModalSize;
  @ViewChild('modal', { static: false }) modal: ModalComponent;
  private field = Object.freeze({
    separator: ';',
    length: 13
  });
  private index = Object.freeze({
    streetType: 0,
    streetNumber: 1,
    cardinalDirection: 2,
    streetType2: 3,
    number1: 4,
    number2: 5,
    cardinalDirection2: 6,
    complement1: 7,
    complement1Value: 8,
    complement2: 9,
    complement2Value: 10,
    complement3: 11,
    complement3Value: 12
  });

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
    public fb: FormBuilder,
    private cd: ChangeDetectorRef,
    fn: FunctionsService
  ) {
    this.rId = fn.randomId();

    this.form = this.fb.group({
      streetType: [null, [Validators.required]],
      streetNumber: [null, [Validators.required, CustomValidators.alphanumeric]],
      cardinalDirection: [null, []],
      streetType2: ['NR', [Validators.required]],
      number1: [null, [Validators.required, CustomValidators.alphanumeric]],
      number2: [null, [CustomValidators.alphanumeric]],
      cardinalDirection2: [null, []],
      complement1: [null, []],
      complement1Value: [null, [CustomValidators.alphanumeric]],
      complement2: [null, []],
      complement2Value: [null, [CustomValidators.alphanumeric]],
      complement3: [null, []],
      complement3Value: [null, [CustomValidators.alphanumeric]]
    });

    this.form.valueChanges.subscribe(value => {
      let generated = '';
      if (value.streetType) {
        generated += this.types.find(item => item.id === value.streetType).name;
      }
      if (value.streetNumber) {
        generated += ' ' + value.streetNumber;
      }
      if (value.cardinalDirection) {
        generated += ' ' + this.cardinalDirections.find(item => item.id === value.cardinalDirection).name;
      }
      if (value.streetType2) {
        generated += ' ' + this.types2.find(item => item.id === value.streetType2).name;
      }
      if (value.number1) {
        generated += ' ' + value.number1;
      }
      if (value.number2) {
        generated += ' - ' + value.number2;
      }
      if (value.cardinalDirection2) {
        generated += ' ' + this.cardinalDirections.find(item => item.id === value.cardinalDirection2).name;
      }

      if (value.complement1) {
        generated += ' ' + this.complements.find(item => item.id === value.complement1).name;
      }
      if (value.complement1Value) {
        generated += ' ' + value.complement1Value;
      }

      if (value.complement2) {
        generated += ' ' + this.complements.find(item => item.id === value.complement2).name;
      }
      if (value.complement2Value) {
        generated += ' ' + value.complement2Value;
      }

      if (value.complement3) {
        generated += ' ' + this.complements.find(item => item.id === value.complement3).name;
      }
      if (value.complement3Value) {
        generated += ' ' + value.complement3Value;
      }

      this.value.generated.temp = generated;
      cd.detectChanges();
    });
  }

  writeValue(value: string) {
    this.value.plain = value;

    // convertir fecha plana a generada --------------------------------
    if (!value) {
      this.value.generated.actual = '';
      return;
    }

    this.value.generated.actual = this.parseAddress(value);
  }

  parseAddress(addr: string) {
    const values = addr.split(';');
    let parsed = '';
    let curr: string;

    curr = values[this.index.streetType];
    if (curr) {
      parsed += this.types.find(item => item.id === curr).name;
    }

    curr = values[this.index.streetNumber];
    if (curr) {
      parsed += ' ' + curr;
    }

    curr = values[this.index.cardinalDirection];
    if (curr) {
      parsed += ' ' + this.cardinalDirections.find(item => item.id === curr).name;
    }

    curr = values[this.index.streetType2];
    if (curr) {
      parsed += ' ' + this.types2.find(item => item.id === curr).name;
    }

    curr = values[this.index.number1];
    if (curr) {
      parsed += ' ' + curr;
    }

    curr = values[this.index.number2];
    if (curr) {
      parsed += ' - ' + curr;
    }

    curr = values[this.index.cardinalDirection2];
    if (curr) {
      parsed += ' ' + this.cardinalDirections.find(item => item.id === curr).name;
    }

    curr = values[this.index.complement1];
    if (curr) {
      parsed += ' ' + this.complements.find(item => item.id === curr).name;
    }

    curr = values[this.index.complement1Value];
    if (curr) {
      parsed += ' ' + curr;
    }

    curr = values[this.index.complement2];
    if (curr) {
      parsed += ' ' + this.complements.find(item => item.id === curr).name;
    }

    curr = values[this.index.complement2Value];
    if (curr) {
      parsed += ' ' + curr;
    }

    curr = values[this.index.complement3];
    if (curr) {
      parsed += ' ' + this.complements.find(item => item.id === curr).name;
    }

    curr = values[this.index.complement3Value];
    if (curr) {
      parsed += ' ' + curr;
    }

    return parsed;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.touchChange = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  ngOnInit() {
    if (this.formControlName) {
      this.control = this.controlContainer.control.get(this.formControlName);
    }
  }

  inputBlur() {
    this.touchChange();
    if (this.control) {
      this.control.updateValueAndValidity();
    }
  }

  modalClosed() {
    this.hidden = true;
    this.cd.detectChanges();
  }

  open() {
    setTimeout(() => {
      this.hidden = false;
      this.cd.detectChanges();
    }, 0);
    this.modal.open();
    this.form.reset();

    if (!this.value.plain) {
      return;
    }

    const values = this.value.plain.split(this.field.separator);
    this.form.patchValue({
      streetType: values[this.index.streetType],
      streetNumber: values[this.index.streetNumber],
      cardinalDirection: values[this.index.cardinalDirection],
      streetType2: values[this.index.streetType2],
      number1: values[this.index.number1],
      number2: values[this.index.number2],
      cardinalDirection2: values[this.index.cardinalDirection2],
      complement1: values[this.index.complement1],
      complement1Value: values[this.index.complement1Value],
      complement2: values[this.index.complement2],
      complement2Value: values[this.index.complement2Value],
      complement3: values[this.index.complement3],
      complement3Value: values[this.index.complement3Value]
    });
  }

  submit() {
    if (!this.form.validate()) {
      return;
    }

    const data = this.form.value;
    const values = new Array(this.field.length);
    values[this.index.streetType] = data.streetType;
    values[this.index.streetNumber] = data.streetNumber;
    values[this.index.cardinalDirection] = data.cardinalDirection;
    values[this.index.streetType2] = data.streetType2;
    values[this.index.number1] = data.number1;
    values[this.index.number2] = data.number2;
    values[this.index.cardinalDirection2] = data.cardinalDirection2;
    values[this.index.complement1] = data.complement1;
    values[this.index.complement1Value] = data.complement1Value;
    values[this.index.complement2] = data.complement2;
    values[this.index.complement2Value] = data.complement2Value;
    values[this.index.complement3] = data.complement3;
    values[this.index.complement3Value] = data.complement3Value;

    this.writeValue(values.join(this.field.separator));
    this.propagateChange(this.value.plain);
    this.modal.close();
  }

  clear() {
    this.value = {
      plain: null,
      generated: {
        actual: null,
        temp: null
      }
    };
    this.propagateChange(null);
    this.cd.markForCheck();
  }

  openChange(e) {
    if (e) {
      const setCopyList = (newList: string[]) => {
        this.copyList = newList
          .filter((a, i) => newList.indexOf(a) === i && a !== this.value.plain) // quitar duplicados
          .map(a => {
            return { value: a, parsed: this.parseAddress(a) };
          });
      };

      this.copyOpen.emit(setCopyList);
    }
  }

  setCopyAddres(addr: string) {
    this.writeValue(addr);
    this.propagateChange(this.value.plain);
  }

  private propagateChange = (val: string) => {
  };

  private touchChange = () => {
  };
}
