import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class TutoringTrackingService {
  public static URL = environment.URL.API + 'tutoring-tracking';

  constructor(private http: HttpService) {}

  /**
   * Index tutorings
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(TutoringTrackingService.URL + '?' + params, httpRequestHandler);
  }
}
