import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpRequestHandler, HttpRequestObservable, HttpService, MessageType } from '../../util';

@Injectable()
export class UserService {
  static URL = environment.URL.API + 'user';
  static URL2 = environment.URL.API2 + 'user';

  constructor(private http: HttpService) {}

  login(data: any, httpRequestHandler: HttpRequestHandler) {
    httpRequestHandler.includeToken = false;
    this.http.post(UserService.URL2 + '/login', data, httpRequestHandler);
  }

  googleLogin(idToken: string, httpRequestHandler: HttpRequestHandler) {
    httpRequestHandler.includeToken = false;
    this.http.post(UserService.URL2 + '/google-login', { idToken: idToken }, httpRequestHandler);
  }

  logout(httpRequestHandler: HttpRequestHandler) {
    httpRequestHandler.errorMsgType = MessageType.None;
    this.http.get(UserService.URL + '/logout', httpRequestHandler);
  }

  myPermissions(httpRequestHandler: HttpRequestHandler) {
    this.http.get(UserService.URL2 + '/myPermissions', httpRequestHandler);
  }

  current(httpRequestHandler: HttpRequestHandler) {
    this.http.get(UserService.URL2 + '/current', httpRequestHandler);
  }

  currentObservable(httpRequestObservable: HttpRequestObservable) {
    return this.http.observableGet(UserService.URL2 + '/current', httpRequestObservable);
  }

  guestLogin(token: string, httpRequestHandler: HttpRequestHandler) {
    httpRequestHandler.includeToken = false;
    this.http.post(UserService.URL + '/guest-login', { token }, httpRequestHandler);
  }

  recoverPassword(data: any, httpRequestHandler: HttpRequestHandler) {
    httpRequestHandler.includeToken = false;
    this.http.post(UserService.URL + '/recover-password', data, httpRequestHandler);
  }

  validateRecoveryToken(token, httpRequestHandler: HttpRequestHandler) {
    httpRequestHandler.includeToken = false;
    this.http.post(UserService.URL + '/validate-recovery-token', { token }, httpRequestHandler);
  }

  newPassword(data: any, httpRequestHandler: HttpRequestHandler) {
    httpRequestHandler.includeToken = false;
    this.http.post(UserService.URL + '/new-password', data, httpRequestHandler);
  }

  changePassword(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(UserService.URL + '/change-password', data, httpRequestHandler);
  }

  unblock(token: string, httpRequestHandler: HttpRequestHandler) {
    httpRequestHandler.includeToken = false;
    this.http.post(UserService.URL + '/unblock', { token }, httpRequestHandler);
  }

  autoLogin(token: string, httpRequestHandler: HttpRequestHandler) {
    httpRequestHandler.includeToken = false;
    this.http.post(UserService.URL + '/auto-login', { token }, httpRequestHandler);
  }
}
