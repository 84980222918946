<div [ngClass]="{ 'p-l-10 p-r-10': !label }">
  <ng-container *ngIf="label; else noLabelTemplate" [ngSwitch]="labelPos">
    <div class="d-table" *ngSwitchCase="'left'">
      <div class="d-table-cell text-nowrap pr-1">
        <label>{{ label | translate }}:</label>
      </div>
      <div class="d-table-cell w-100"><ng-container *ngTemplateOutlet="selectTemplate"></ng-container></div>
    </div>
    <div *ngSwitchCase="'top'">
      <label>{{ label | translate }}:</label>
      <ng-container *ngTemplateOutlet="selectTemplate"></ng-container>
    </div>
  </ng-container>
  <ng-template #noLabelTemplate> <ng-container *ngTemplateOutlet="selectTemplate"></ng-container> </ng-template>
</div>
<ng-template #selectTemplate>
  <ng-container *ngIf="selectOptions; else noSelectOptions">
    <app-select
      [valueField]="valueField"
      [displayField]="displayField"
      [selectOptions]="selectOptions"
      [(ngModel)]="selected"
      [appendTo]="label ? null : 'body'"
      (itemChange)="onChange($event)"
      [searchable]="searchable"
      [clearable]="clearable"
      [placeholder]="'general.select'"
      [ngClass]="{ 'has-filter': !!selected }"
      [multi]="multi"
    >
      <ng-template selectOption let-option>
        <ng-template [ngIf]="templateRef" [ngIfElse]="defaultTemplate">
          <ng-container *ngTemplateOutlet="templateRef; context: { $implicit: option }"></ng-container>
        </ng-template>
        <ng-template #defaultTemplate> {{ option[displayField] }} </ng-template>
      </ng-template>
    </app-select>
  </ng-container>
  <ng-template #noSelectOptions>
    <app-select
      [valueField]="valueField"
      [displayField]="displayField"
      [options]="items"
      [(ngModel)]="selected"
      [appendTo]="label ? null : 'body'"
      (itemChange)="onChange($event)"
      [searchable]="searchable"
      [clearable]="clearable"
      [placeholder]="'general.select'"
      [ngClass]="{ 'has-filter': !!selected }"
      [multi]="multi"
    >
      <ng-template selectOption let-option>
        <ng-template [ngIf]="templateRef" [ngIfElse]="defaultTemplate">
          <ng-container *ngTemplateOutlet="templateRef; context: { $implicit: option }"></ng-container>
        </ng-template>
        <ng-template #defaultTemplate> {{ option[displayField] }} </ng-template>
      </ng-template>
    </app-select>
  </ng-template>
</ng-template>
