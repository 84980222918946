import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class EducationLevelService {

  public static URL = environment.URL.API2 + 'education-level';

  constructor(private http: HttpService) {
  }

  /**
   * Index Education Level
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${EducationLevelService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get Education Level
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${EducationLevelService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Education Level
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(EducationLevelService.URL, data, httpRequestHandler);
  }

  /**
   * Delete Education Level
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${EducationLevelService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List Education Level for select component
   */
  list = () => {
    return this.http.observableGet(`${EducationLevelService.URL}/list`, { mapFn: res => res.educationLevel });
  };
}
