import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class LowPerformanceReportService {
  public static URL = environment.URL.API + 'low-performance-report';

  constructor(private http: HttpService) {}

  /**
   * List low performance students
   */
  index(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${LowPerformanceReportService.URL}`, data, httpRequestHandler);
  }

  /**
   * Export low performance students
   */
  export(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${LowPerformanceReportService.URL}/export`, data, httpRequestHandler);
  }
}
