import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

import { HttpRequestHandler, HttpService } from '../util';

@Injectable()
export class DashboardInfoService {
  public static URL = environment.URL.API2 + 'dashboard';

  constructor(private http: HttpService) {}

  /**
   * Información tecnica de conexiones
   */
  connections(httpRequestHandler: HttpRequestHandler) {
    this.http.get(DashboardInfoService.URL + '/connections', httpRequestHandler);
  }

  /**
   * Información tecnica detallada de conexiones
   */
  connectionsDetail(httpRequestHandler: HttpRequestHandler) {
    this.http.get(DashboardInfoService.URL + '/connections/detail', httpRequestHandler);
  }

  /**
   * Información detallada de conexiones de personas
   */
  peopleDetail(type: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(DashboardInfoService.URL + '/people/' + type, httpRequestHandler);
  }

  /**
   * Datos de tareas programadas
   */
  tasks(date: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(DashboardInfoService.URL + '/tasks', { date: date }, httpRequestHandler);
  }

  /**
   * Datos de chart de conexiones
   */
  connectionsChart(httpRequestHandler: HttpRequestHandler) {
    this.http.get(DashboardInfoService.URL + '/connections/chart', httpRequestHandler);
  }
}
